import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { TransitionProps } from '@material-ui/core/transitions';
import ITarjetaRechazaProps from './types';

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  ),
);

function TarjetaRechazadaModal({ open, callBackAceptar, callBackClose }: ITarjetaRechazaProps) {
  const { t } = useTranslation();
  const [aprenderMas, setAprenderMas] = useState<boolean>(false);
  const handleClickAprenderMas = () => {
    setAprenderMas(true);
  };

  const handleClickAtras = () => {
    setAprenderMas(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="pago-fallido-titulo"
      aria-describedby="pago-fallido-descripcion"
    >
      {aprenderMas ? (
        <DialogContent className="text-center">
          <h2 className="font-normal text-red-500">
            {t('motivos_comunes_por_los_que_se_rechaza_las_tarjetas')}
          </h2>
          <h3 className="font-medium">{`${t('es_posible_motivos_subtitulo_modal')}:`}</h3>
          <ul className="list-inside text-left leading-relaxed">
            <Trans i18nKey="motivos_rechazo_modal">
              <li>index0</li>
              <li>index1</li>
              <li>index2</li>
              <li>index3</li>
              <li>index4</li>
            </Trans>
          </ul>
          <div className="text-left">
            <Button color="primary" size="large" onClick={handleClickAtras}>
              {t('atras')}
            </Button>
          </div>
        </DialogContent>
      ) : (
        <>
          <DialogContent className="text-center">
            <h2 className="font-normal text-red-500">{t('_oh_no_')}</h2>
            <h3 className="font-normal">
              <Trans i18nKey="el_cargo_a_tu_tarjeta_fue__declinado">
                <span className="text-red-500">index0</span>
              </Trans>
            </h3>
            <p className="text-lg">
              <Trans i18nKey="es_posible_que_se_requiera_autorizacion_de_tu_banco_modal_info">
                <span className="font-bold">index0</span>
              </Trans>
            </p>
            <Button
              color="primary"
              size="large"
              startIcon={<InfoIcon />}
              onClick={handleClickAprenderMas}
            >
              {t('saber_mas')}
            </Button>
          </DialogContent>
          <DialogActions>
            <div className="w-full flex justify-center">
              <div className="mr-4">
                <Button onClick={callBackClose} color="inherit" variant="contained">
                  {t('cancelar')}
                </Button>
              </div>
              <div>
                <Button onClick={callBackAceptar} color="primary" variant="contained">
                  {t('intentar_con_otra_tarjeta')}
                </Button>
              </div>
            </div>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default TarjetaRechazadaModal;
