import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BackButtonProps } from './type';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

// eslint-disable-next-line import/prefer-default-export
export const BackButton: FC<BackButtonProps> = ({
  label: externalLabel,
  onClick,
  showIcon = true,
  customIcon,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const label = externalLabel || t('regresar_al_menu');
  const renderIcon = () => {
    if (!showIcon) return null;
    if (customIcon) return customIcon;

    return (
      <svg className="w-5 h-5 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    );
  };

  return (
    <div className="w-full">
      <button
        type="button"
        onClick={onClick}
        className="inline-flex items-center text-[#3B82F6] hover:text-blue-700 font-medium text-base transition-colors"
      >
        {renderIcon()}
        <span>{label}</span>
      </button>
    </div>
  );
};
