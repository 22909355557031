import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Avatar,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  IconButton,
  Button,
  TextField,
} from '@material-ui/core';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {
  getCatalogoAgem,
  getCatalogoAgel,
  getCatalogoAgea,
  getCatEstablecimientoSalud,
  getCatalogoTipoEstablecimiento,
} from '@utils/getCatalogos';
import obtenerDatosGeograficos from '@utils/geografia';
import { crearArchivo } from '@utils/sendFiles';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import { obtenerURLArchivo } from '@utils/getFiles';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { ICatStrEstandar, ICatalogosEstandar } from '@common/types';
import { validacionSoloNumeros, validacionEmail } from '@constants/validaciones';
import useStyles from './styles';
import { IFormularioConsultoriosProps } from './types';

function FormularioConsultorios({
  idMedico,
  pais,
  consultorios,
  setConsultorios,
  idActualizar,
  setIdActualizar,
  setDisabledButton,
  catAGEE,
}: IFormularioConsultoriosProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const recargarAgemRef = useRef(false);
  const recargarAgelRef = useRef(false);
  const recargarAgeaRef = useRef(false);
  const recargarClinicaHospitalRef = useRef(false);
  const [idConsultorio, setidConsultorio] = useState<number>(-1);
  const [nombre, setNombre] = useState<string>('');
  const [clinicaHospital, setClinicaHospital] = useState<ICatStrEstandar>({ id: '', label: '' });
  const [tipoEstablecimiento, setTipoEstablecimiento] = useState<ICatalogosEstandar>({
    id: 0,
    label: '',
  });
  const [otroEstablecimiento, setOtroEstablecimiento] = useState<string>('');
  const [nombreEstablecimiento, setNombreEstablecimiento] = useState<string>('');
  const [estaEnHospital, setEstaEnHospital] = useState<string>('si');
  const [agee, setAgee] = useState<ICatStrEstandar | null>(null);
  const [agem, setAgem] = useState<ICatStrEstandar | null>(null);
  const [agel, setAgel] = useState<ICatStrEstandar | null>(null);
  const [agea, setAgea] = useState<ICatStrEstandar | null>(null);
  const [calle, setCalle] = useState<string>('');
  const [num1, setNum1] = useState<string>('');
  const [num2, setNum2] = useState<string>('');
  const [cp, setCp] = useState<string>('');
  const [telefono1, setTelefono1] = useState<string>('');
  const [extension1, setExtension1] = useState<string>('');
  const [telefono2, setTelefono2] = useState<string>('');
  const [extension2, setExtension2] = useState<string>('');
  const [telefono3, setTelefono3] = useState<string>('');
  const [extension3, setExtension3] = useState<string>('');
  const [correoElectronico, setCorreoElectronico] = useState<string>('');
  const [sitioWeb, setSitioWeb] = useState<string>('');
  const [tienePacientes, setTienePacientes] = useState<number>(0);
  const [showDelete2, setShowDelete2] = useState<boolean>(false);
  const [showDelete3, setShowDelete3] = useState<boolean>(false);
  const [showTel2, setShowTel2] = useState<boolean>(false);
  const [showTel3, setShowTel3] = useState<boolean>(false);
  const [refAgem, setRefAgem] = useState<boolean>(true);
  const [refAgel, setRefAgel] = useState<boolean>(true);
  const [refAgea, setRefAgea] = useState<boolean>(true);
  const [catAgem, setCatAgem] = useState<Array<ICatStrEstandar>>([]);
  const [catAgel, setCatAgel] = useState<Array<ICatStrEstandar>>([]);
  const [catAgea, setCatAgea] = useState<ICatStrEstandar[]>([]);
  const [catClinicaHospital, setCatClinicaHospital] = useState<ICatStrEstandar[]>([]);
  const [catTipoEstablecimiento, setCatTipoEstablecimiento] = useState<ICatalogosEstandar[]>([]);
  const [logotipo, setLogotipo] = useState<string>('');
  const [urlImage, setUrlImage] = useState<string>('');
  const [errores, setErrores] = useState({
    email: { error: false, helperText: '' },
  });
  const [selObjArchivo, setSelObjArchivo] = useState<{ archivo: File; idLista: number } | null>(
    null,
  );
  const geo = obtenerDatosGeograficos(pais);

  const handleChangeClinicaHospital = (event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setClinicaHospital(newValue);
    } else {
      setClinicaHospital({ id: '', label: '' });
    }
  };

  const seleccionarArchivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = idActualizar >= 0 ? consultorios[idActualizar].idConsultorio : idConsultorio;
    if (event.target.files && event.target.files.length) {
      setSelObjArchivo({ archivo: event.target.files[0], idLista: id });
    } else {
      setSelObjArchivo(null);
    }
  };
  const verImagen = (idArchivo: number, pathArchivo: string) => {
    obtenerURLArchivo(idMedico, null, idArchivo, null, pathArchivo, 'imagenConsultorio')
      .then((response) => response.json())
      .then((result) => {
        if (result.code !== 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `Ocurrio un error al obtener el archivo: ${result.error}`,
            }),
          );
        } else if (result.data.url) {
          setUrlImage(result.data.url);
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `Ocurrio un error al obtener el archivo: ${err}`,
          }),
        );
      });
  };

  const handleChangeTipoEstablecimiento = (event: any, newValue: ICatalogosEstandar | null) => {
    if (newValue) {
      setTipoEstablecimiento(newValue);
    } else {
      setTipoEstablecimiento({ id: 0, label: '' });
    }
  };

  const handleChangeOtroEstablecimiento = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setOtroEstablecimiento(val);
  };

  const handleChangeNombreEstablecimiento = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setNombreEstablecimiento(val);
  };

  const handleChangeAgea = (event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setAgea(newValue);
    } else {
      setAgea(null);
    }
  };

  const handleAgemShow = () => {
    setRefAgem(false);
  };
  const handleAgemHide = () => {
    setRefAgem(true);
  };
  const handleAgelShow = () => {
    setRefAgel(false);
  };
  const handleAgelHide = () => {
    setRefAgel(true);
  };

  const handleAgeaHide = () => {
    setRefAgea(true);
  };

  const handleAgeaShow = () => {
    setRefAgea(false);
  };

  const handleChangeAgee = (event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setAgee(newValue);
    } else {
      setAgee(null);
    }
  };

  const handleChangeAgem = (event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setAgem(newValue);
    } else {
      setAgem(null);
    }
  };

  const handleChangeAgel = (event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setAgel(newValue);
    } else {
      setAgel(null);
    }
  };

  const handleChangeEstaEnHospital = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target as HTMLInputElement;
    setEstaEnHospital(val.value);
  };

  const handleChangeCalle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setCalle(val);
  };

  const handleChangeNum1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setNum1(val);
  };

  const handleChangeNum2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setNum2(val);
  };

  const handleChangeCP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setCp(val);
    }
  };

  const handleChangeTelefono1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setTelefono1(val);
    }
  };

  const handleChangeExtension1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setExtension1(val);
    }
  };

  const handleChangeTelefono2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setTelefono2(val);
    }
  };

  const handleChangeExtension2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setExtension2(val);
    }
  };
  const handleChangeTelefono3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setTelefono3(val);
    }
  };

  const handleChangeExtension3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    if (validacionSoloNumeros(val)) {
      setExtension3(val);
    }
  };

  const handleChangeNombre = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setNombre(val);
  };

  const handleChangeCorreoElectronico = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setCorreoElectronico(val.toLowerCase());
    setErrores((preV) => ({ ...preV, email: { helperText: '', error: false } }));
  };

  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setErrores((preV) => ({ ...preV, email: { helperText: '', error: false } }));
    } else {
      setErrores((preV) => ({
        ...preV,
        email: { helperText: t('email_incorrecto'), error: true },
      }));
    }
  };

  const handleChangeSitioWeb = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setSitioWeb(val);
  };

  const handleDeleteTel2 = () => {
    if (showTel3) {
      setTelefono2(telefono3);
      setExtension2(extension3);
      setTelefono3('');
      setExtension3('');
      setShowTel3(false);
      setShowDelete3(false);
    } else {
      setShowTel2(false);
      setShowDelete2(false);
      setTelefono2('');
      setExtension2('');
    }
  };

  const handleDeleteTel3 = () => {
    setTelefono3('');
    setExtension3('');
    setShowTel3(false);
    setShowDelete3(false);
  };

  const handleAddTelefono = () => {
    if (showTel2) {
      setShowTel3(true);
    } else {
      setShowTel2(true);
    }
  };

  const clearFormulario = () => {
    setTienePacientes(0);
    setClinicaHospital({ id: '', label: '' });
    setTipoEstablecimiento({ id: 0, label: '' });
    setOtroEstablecimiento('');
    setNombreEstablecimiento('');
    setAgee(null);
    setAgem(null);
    setAgel(null);
    setAgea(null);
    setCalle('');
    setNum1('');
    setNum2('');
    setCp('');
    setTelefono1('');
    setExtension1('');
    setTelefono2('');
    setExtension2('');
    setTelefono3('');
    setExtension3('');
    setNombre('');
    setCorreoElectronico('');
    setSitioWeb('');
    setCatAgem([]);
    setCatAgel([]);
    setCatAgea([]);
    setIdActualizar(-1);
    setShowTel3(false);
    setShowTel2(false);
    setRefAgem(true);
    setRefAgel(true);
    setRefAgea(true);
    setLogotipo('');
  };

  const handleAddConsultorio = () => {
    const numeroID = idActualizar >= 0 ? consultorios[idActualizar].idConsultorio : idConsultorio;
    if (numeroID < 0) {
      setidConsultorio((prev) => prev - 1);
    }
    const obj = {
      tienePacientes,
      idConsultorio: numeroID,
      perteneceHospital: estaEnHospital === 'si',
      /* eslint-disable no-nested-ternary */
      label:
        estaEnHospital === 'si'
          ? clinicaHospital.label
          : tipoEstablecimiento.id === 7
            ? `${otroEstablecimiento.toUpperCase()} ${nombreEstablecimiento.toUpperCase()}`
            : `${tipoEstablecimiento.label.toUpperCase()} ${nombreEstablecimiento.toUpperCase()}`,
      clinicaHospital,
      nombre,
      correoElectronico,
      sitioWeb,
      agee,
      agem,
      agel,
      agea,
      calle,
      num1,
      num2,
      cp,
      telefono1: {
        telefono: telefono1,
        extension: extension1,
      },
      telefono2: {
        telefono: telefono2,
        extension: extension2,
      },
      telefono3: {
        telefono: telefono3,
        extension: extension3,
      },
      logotipo,
      porDefecto: false,
      borrado: false,
      tipoEstablecimiento,
      otroEstablecimiento,
      nombreEstablecimiento,
    };
    if (idActualizar >= 0) {
      setConsultorios((prevCons) => {
        const nuevoArr = [...prevCons];
        nuevoArr[idActualizar] = obj;
        return nuevoArr;
      });
    } else {
      setConsultorios((prevCons) => [...prevCons, obj]);
    }
    setDisabledButton(false);
    clearFormulario();
  };

  const checkDisableAddConsultorio = () =>
    (estaEnHospital === 'si' &&
      ((geo.agee && geo.agee.required && !agee) ||
        (geo.agem && geo.agem.required && !agem) ||
        (geo.agel && geo.agel.required && !agel) ||
        (geo.calle && geo.calle.required && !calle) ||
        !clinicaHospital.id ||
        !nombre ||
        !telefono1 ||
        errores.email.error)) ||
    (estaEnHospital === 'no' &&
      ((geo.agee && geo.agee.required && !agee) ||
        (geo.agem && geo.agem.required && !agem) ||
        (geo.agel && geo.agel.required && !agel) ||
        (geo.calle && geo.calle.required && !calle) ||
        !tipoEstablecimiento.id ||
        tipoEstablecimiento.id === 0 ||
        (tipoEstablecimiento.id === 7 && !otroEstablecimiento) ||
        !nombreEstablecimiento ||
        !nombre ||
        !telefono1 ||
        errores.email.error));

  useEffect(() => {
    getCatalogoTipoEstablecimiento().then((result: any) => setCatTipoEstablecimiento(result));
  }, []);

  useEffect(() => {
    if (agee) {
      handleAgemShow();
      setAgem(null);
      getCatalogoAgem(agee.id).then((result: ICatStrEstandar[]) => {
        setCatAgem(result);
        dispatch(setLoading(false));
      });
    } else {
      handleAgemHide();
      setAgem(null);
      setClinicaHospital({ id: '', label: '' });
      dispatch(setLoading(false));
    }
  }, [agee]);

  useEffect(() => {
    if (agee?.id && agem?.id) {
      dispatch(setLoading(true));
      getCatEstablecimientoSalud(agee.id, agem.id)
        .then((response) => response.json())
        .then((result) => {
          setCatClinicaHospital(result.datos);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        });
      handleAgelShow();
      setAgel(null);
      getCatalogoAgel(agee.id, agem.id).then((result: ICatStrEstandar[]) => {
        setCatAgel(result);
        dispatch(setLoading(false));
      });
      setClinicaHospital({ id: '', label: '' });
    } else {
      handleAgelHide();
      setClinicaHospital({ id: '', label: '' });
      setAgel(null);
    }
  }, [agem]);

  useEffect(() => {
    if (recargarAgemRef.current) {
      recargarAgemRef.current = false;
      setAgem(consultorios[idActualizar].agem);
    }
  }, [catAgem]);

  useEffect(() => {
    if (recargarAgelRef.current) {
      recargarAgelRef.current = false;
      setAgel(consultorios[idActualizar].agel);
    }
  }, [catAgel]);

  useEffect(() => {
    if (recargarAgeaRef.current) {
      recargarAgeaRef.current = false;
      setAgea(consultorios[idActualizar].agea);
    }
  }, [catAgea]);

  useEffect(() => {
    if (recargarClinicaHospitalRef.current) {
      recargarClinicaHospitalRef.current = false;
      setClinicaHospital(consultorios[idActualizar].clinicaHospital);
    }
  }, [catClinicaHospital]);

  useEffect(() => {
    setUrlImage('');
    if (idActualizar >= 0) {
      dispatch(setLoading(true));
      recargarAgemRef.current = true;
      recargarAgelRef.current = true;
      recargarAgeaRef.current = true;
      recargarClinicaHospitalRef.current = true;
      setTipoEstablecimiento(consultorios[idActualizar].tipoEstablecimiento);
      setOtroEstablecimiento(consultorios[idActualizar].otroEstablecimiento);
      setNombreEstablecimiento(consultorios[idActualizar].nombreEstablecimiento);
      setEstaEnHospital(consultorios[idActualizar].perteneceHospital ? 'si' : 'no');
      setRefAgem(false);
      setRefAgel(false);
      setRefAgea(false);
      setTienePacientes(consultorios[idActualizar].tienePacientes);
      setAgee(consultorios[idActualizar].agee);
      setCalle(consultorios[idActualizar].calle);
      setNum1(consultorios[idActualizar].num1);
      setNum2(consultorios[idActualizar].num2);
      setCp(consultorios[idActualizar].cp);
      setTelefono1(consultorios[idActualizar].telefono1.telefono);
      setExtension1(consultorios[idActualizar].telefono1.extension);
      setTelefono2(consultorios[idActualizar].telefono2.telefono);
      setExtension2(consultorios[idActualizar].telefono2.extension);
      setTelefono3(consultorios[idActualizar].telefono3.telefono);
      setExtension3(consultorios[idActualizar].telefono3.extension);
      setCorreoElectronico(consultorios[idActualizar].correoElectronico);
      setSitioWeb(consultorios[idActualizar].sitioWeb);
      setNombre(consultorios[idActualizar].nombre);
      if (consultorios[idActualizar].logotipo && consultorios[idActualizar].logotipo.length > 0) {
        setLogotipo(consultorios[idActualizar].logotipo);
        verImagen(consultorios[idActualizar].idConsultorio, consultorios[idActualizar].logotipo);
      }
      if (consultorios[idActualizar].telefono2.telefono) {
        setShowTel2(true);
      }
      if (consultorios[idActualizar].telefono3.telefono) {
        setShowTel3(true);
      }
      dispatch(setLoading(false));
    }
  }, [idActualizar]);

  useEffect(() => {
    if (agee?.id && agem?.id && agel?.id) {
      dispatch(setLoading(true));
      handleAgeaShow();
      setAgea(null);
      getCatalogoAgea(agee.id, agem.id, agel.id)
        .then((result: ICatStrEstandar[]) => {
          setCatAgea(result);
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        });
    } else {
      handleAgeaHide();
      setAgea(null);
    }
  }, [agel]);

  useEffect(() => {
    if (selObjArchivo && selObjArchivo.archivo.size > 1048576) {
      setSelObjArchivo(null);
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('el_archivo_selecionado_pesa_mas_de_1_mega'),
        }),
      );
      return;
    }
    if (selObjArchivo) {
      dispatch(setLoading(true));
      const data = new FormData();
      // añadimos la informacion importante y OJO el archivo debe ir siempre al último o el back no parseara correctamente
      // los parametros que se le adjunta
      data.append('idLista', String(selObjArchivo.idLista));
      data.append('idMedico', String(idMedico));
      data.append('nombre', String(selObjArchivo.archivo?.name));
      data.append('tipo', selObjArchivo.archivo?.type);
      data.append('peso', String(selObjArchivo.archivo?.size));
      data.append('registro', 'imagenConsultorio');
      data.append('file', selObjArchivo?.archivo);
      crearArchivo(data)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            verImagen(parseInt(result.datos?.idArchivo, 10), result.datos?.url);
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `Ocurrio un error al procesar el archivo: ${err}`,
            }),
          );
        });
    }
  }, [selObjArchivo]);

  return (
    <div>
      <h2 className="text-blue-800 font-normal m-0">{t('alta_de_consultorio')}</h2>
      <span>
        {t(
          '_la_informacion_que_registres_sera_usada_para_identificar_expedientes_clinicos_recetas_etc_',
        )}
      </span>
      <hr />
      <div className="py-4">
        <FormControl component="fieldset">
          <FormLabel>{`${t('_es_dentro_de_una_clinca_hospital_')}*`}</FormLabel>
          <RadioGroup
            value={estaEnHospital}
            onChange={handleChangeEstaEnHospital}
            aria-label="esta-dentro-clinica-hospitla"
            name="esta-clinica-hospital"
            row
          >
            <FormControlLabel
              value="si"
              control={<Radio color="primary" />}
              label={t('si')}
              disabled={!!tienePacientes}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label={t('no')}
              disabled={!!tienePacientes}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <hr />
      <div className="flex justify-center items-center flex-col">
        <Avatar className={classes.ninetyPx} src={urlImage}>
          {urlImage.length === 0 && <AddToPhotosIcon fontSize="large" />}
        </Avatar>
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={seleccionarArchivo}
            disabled={idActualizar < 0}
          />
          <HtmlTooltip
            title={idActualizar < 0 ? `${t('administrador_alta_consultorio_luego_logo')}` : ''}
          >
            <span>
              <Button component="span" color="primary" disabled={idActualizar < 0}>
                {t('agregar_logotipo')}
              </Button>
            </span>
          </HtmlTooltip>
        </label>
      </div>
      {geo.agee && (
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <h3 className="font-normal m-0 text-gray-500 mb-2">
              {`${t(`${geo.agee.label}`)}${geo.agee.required ? '*' : ''}`}
            </h3>
            <Autocomplete
              options={catAGEE}
              getOptionLabel={(option) => option.label}
              fullWidth
              noOptionsText={t('sin_resultados')}
              value={agee}
              onChange={(e, nV) => handleChangeAgee(e, nV)}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
              disabled={!!tienePacientes}
            />
          </div>
          {geo.agem && (
            <div>
              <h3 className="font-normal m-0 text-gray-500 mb-2">
                {`${t(`${geo.agem.label}`)}${geo.agem.required ? '*' : ''}`}
              </h3>
              <Autocomplete
                disabled={refAgem || !!tienePacientes}
                options={catAgem}
                getOptionLabel={(option) => option.label}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={agem}
                onChange={(e, nV) => handleChangeAgem(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
          )}
        </div>
      )}
      {geo.agel && (
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <h3 className="text-gray-500 font-normal m-0 mb-2">
              {`${t(`${geo.agel.label}`)}${geo.agel.required ? '*' : ''}`}
            </h3>
            <Autocomplete
              disabled={refAgel || !!tienePacientes}
              options={catAgel}
              getOptionLabel={(option) => option.label}
              fullWidth
              noOptionsText={t('sin_resultados')}
              value={agel}
              onChange={(e, nV) => handleChangeAgel(e, nV)}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          </div>
          {geo.agea && (
            <div>
              <h3 className="text-gray-500 font-normal m-0 mb-2">
                {`${t(`${geo.agea.label}`)}${geo.agea.required ? '*' : ''}`}
              </h3>
              <Autocomplete
                disabled={refAgea || !!tienePacientes}
                id="combo-box-agea-residente"
                options={catAgea}
                getOptionLabel={(option) => option.label}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={agea}
                onChange={(e, nV) => handleChangeAgea(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            </div>
          )}
        </div>
      )}
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className="col-span-9">
          <h3 className="text-gray-500 font-normal m-0 mb-2">
            {`${t(`${geo.calle?.label}`)}${geo.calle?.required ? '*' : ''}`}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 150 }}
            onChange={handleChangeCalle}
            value={calle}
            disabled={!!tienePacientes}
          />
        </div>
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2">
            {`${t(`${geo.num1?.label}`)}${geo.num1?.required ? '*' : ''}`}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
            onChange={handleChangeNum1}
            value={num1}
            disabled={!!tienePacientes}
          />
        </div>
        {geo.num2 && (
          <div>
            <h3 className="text-gray-500 font-normal m-0 mb-2">
              {`${t(`${geo.num2.label}`)}${geo.num2.required ? '*' : ''}`}
            </h3>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
              onChange={handleChangeNum2}
              value={num2}
              disabled={!!tienePacientes}
            />
          </div>
        )}
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2">
            {`${t(`${geo.cp?.label}_av`)}${geo.cp?.required ? '*' : ''}`}
          </h3>
          <TextField
            variant="outlined"
            fullWidth
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 5 }}
            onChange={handleChangeCP}
            value={cp}
            disabled={!!tienePacientes}
          />
        </div>
      </div>
      {estaEnHospital === 'si' && (
        <div className="mt-4">
          <h3 className="font-normal m-0 text-gray-500 mb-2">
            {`${t('nombre_de_la_clinica_hospital')}*`}
          </h3>
          <Autocomplete
            options={catClinicaHospital}
            getOptionLabel={(option) => option.label}
            fullWidth
            noOptionsText={t('sin_resultados')}
            value={clinicaHospital}
            onChange={(e, nV) => handleChangeClinicaHospital(e, nV)}
            renderInput={(params) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
            disabled={!!tienePacientes}
          />
        </div>
      )}
      {estaEnHospital === 'no' && (
        <>
          <div className="flex items-center mt-4">
            <div className="w-4/12">
              <h3 className="font-normal m-0 text-gray-500 mb-2">
                {`${t('administrador_tipo_establecimiento')}*`}
              </h3>
              <Autocomplete
                options={catTipoEstablecimiento}
                getOptionLabel={(option) => option.label}
                fullWidth
                noOptionsText={t('sin_resultados')}
                value={tipoEstablecimiento}
                onChange={(e, nV) => handleChangeTipoEstablecimiento(e, nV)}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
                disabled={!!tienePacientes}
              />
            </div>
            {tipoEstablecimiento && tipoEstablecimiento.id === 7 && (
              <div className="w-4/12 ml-4">
                <h3 className="text-gray-500 font-normal m-0 mb-2">
                  {`${t('administrador_otro_establecimiento')}*`}
                </h3>
                <TextField
                  variant="outlined"
                  fullWidth
                  inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
                  onChange={handleChangeOtroEstablecimiento}
                  value={otroEstablecimiento}
                  disabled={!!tienePacientes}
                />
              </div>
            )}
            <div className="w-4/12 ml-4">
              <h3 className="text-gray-500 font-normal m-0 mb-2">
                {`${t('administrador_nombre_establecimiento')}*`}
              </h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 150 }}
                onChange={handleChangeNombreEstablecimiento}
                value={nombreEstablecimiento}
                disabled={!!tienePacientes}
              />
            </div>
          </div>
        </>
      )}
      <div className="w-full mt-4">
        <h3 className="text-gray-500 font-normal m-0 mb-2">
          {`${t('nombre_o_numero_de_consultorio')}*`}
        </h3>
        <TextField
          variant="outlined"
          fullWidth
          inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 80 }}
          onChange={handleChangeNombre}
          value={nombre}
          disabled={!!tienePacientes}
        />
      </div>
      <div className="grid grid-cols-3 gap-4 mt-4">
        <div className="grid grid-cols-6 gap-4 items-end">
          <div className="col-span-3">
            <h3 className="text-gray-500 font-normal m-0 mb-2">{`${t('telefono_con_lada')}*`}</h3>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
              onChange={handleChangeTelefono1}
              value={telefono1}
            />
          </div>
          <div className="col-span-2">
            <h3 className="text-gray-500 font-normal m-0 mb-2">{t('extension')}</h3>
            <TextField
              variant="outlined"
              fullWidth
              placeholder={t('numero')}
              inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
              onChange={handleChangeExtension1}
            />
          </div>
        </div>
        {showTel2 && (
          <div
            onMouseEnter={() => setShowDelete2(true)}
            onMouseLeave={() => setShowDelete2(false)}
            className="grid grid-cols-6 gap-4 mt-4 items-end"
          >
            <div className="col-span-3">
              <h3 className="text-gray-500 font-normal m-0 mb-2">{`${t('telefono_con_lada')}*`}</h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                onChange={handleChangeTelefono2}
                value={telefono2}
              />
            </div>
            <div className="col-span-2">
              <h3 className="text-gray-500 font-normal m-0 mb-2">{t('extension')}</h3>
              <TextField
                variant="outlined"
                fullWidth
                placeholder={t('numero')}
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                onChange={handleChangeExtension2}
                value={extension2}
              />
            </div>
            {showDelete2 && (
              <div className="self-center">
                <IconButton aria-label="delete" onClick={handleDeleteTel2} color="primary">
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
        )}
        {showTel3 && (
          <div
            onMouseEnter={() => setShowDelete3(true)}
            onMouseLeave={() => setShowDelete3(false)}
            className="grid grid-cols-6 gap-4 mt-4 items-end"
          >
            <div className="col-span-3">
              <h3 className="text-gray-500 font-normal m-0 mb-2">{`${t('telefono_con_lada')}*`}</h3>
              <TextField
                variant="outlined"
                fullWidth
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                onChange={handleChangeTelefono3}
                value={telefono3}
              />
            </div>
            <div className="col-span-2">
              <h3 className="text-gray-500 font-normal m-0 mb-2">{t('extension')}</h3>
              <TextField
                variant="outlined"
                fullWidth
                placeholder={t('numero')}
                inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 10 }}
                onChange={handleChangeExtension3}
                value={extension3}
              />
            </div>
            {showDelete3 && (
              <div className="self-center">
                <IconButton aria-label="delete" onClick={handleDeleteTel3} color="primary">
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
        )}
        {(!showTel2 || !showTel3) && (
          <div className="flex items-end">
            <Button
              color="primary"
              size="small"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddTelefono}
              disabled={showTel2 && showTel3}
            >
              {t('agregar_telefono')}
            </Button>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2">
            {t('correo_electronico_del_consultorio')}
          </h3>
          <TextField
            fullWidth
            variant="outlined"
            type="email"
            InputProps={{
              inputProps: { autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 },
            }}
            onChange={handleChangeCorreoElectronico}
            value={correoElectronico}
            onBlur={handleBlurEmail}
            helperText={errores.email.helperText}
            error={errores.email.error}
            FormHelperTextProps={{ className: classes.absoluteBottom }}
          />
        </div>
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2">{t('sitio_web')}</h3>
          <TextField
            fullWidth
            variant="outlined"
            inputProps={{ autocomplete: 'off', 'aria-autocomplete': 'none', maxLength: 100 }}
            onChange={handleChangeSitioWeb}
            value={sitioWeb}
          />
        </div>
      </div>
      <div className="flex justify-between my-4 items-center">
        <span className="text-gray-500">*{t('campos_obligatorios')}</span>
        {idActualizar >= 0 ? (
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={handleAddConsultorio}
            disabled={checkDisableAddConsultorio()}
          >
            {t('actualizar')}
          </Button>
        ) : (
          <Button
            color="primary"
            size="small"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddConsultorio}
            disabled={checkDisableAddConsultorio()}
          >
            {t('agregar_otro_consultorio')}
          </Button>
        )}
      </div>
      <hr />
    </div>
  );
}

export default FormularioConsultorios;
