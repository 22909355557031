import { ThunkDispatch } from 'redux-thunk';
import { ICatalogosEstandar } from '@common/types';
import { IConsultorio } from '@containers/MedicoInformacion/MisConsultorios/types';
import { getPerfilMedicoConsultorio, getPerfilMedicoConsultoriosSimple } from '@utils/getCatalogos';
import { setLoading } from '../loading/actions';
import {
  ConsultoriosActionTypes,
  RESET_CONSULTORIO,
  RESET_CONSULTORIOS,
  SET_CONSULTORIO,
  SET_CONSULTORIOS,
} from './types';
import { LoadingActionTypes } from '../loading/types';

export function setConsultorio(consultorio: IConsultorio): ConsultoriosActionTypes {
  return {
    type: SET_CONSULTORIO,
    payload: consultorio,
  };
}

export function setConsultorios(consultorios: ICatalogosEstandar[]): ConsultoriosActionTypes {
  return {
    type: SET_CONSULTORIOS,
    payload: consultorios,
  };
}

export function resetConsultorio(): ConsultoriosActionTypes {
  return {
    type: RESET_CONSULTORIO,
  };
}

export function resetConsultorios(): ConsultoriosActionTypes {
  return {
    type: RESET_CONSULTORIOS,
  };
}

export const getConsultorios =
  (idMedico: number, idOrganizacion: number) =>
  (dispatch: ThunkDispatch<object, object, ConsultoriosActionTypes | LoadingActionTypes>) => {
    dispatch(setLoading(true));
    getPerfilMedicoConsultoriosSimple(idMedico, idOrganizacion)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setConsultorios(result.code === 200 ? result.data : []));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setConsultorios([]));
        dispatch(setLoading(false));
      });
  };

const consultorioInitial = {
  tienePacientes: 0,
  idConsultorio: 0,
  perteneceHospital: false,
  label: '',
  clinicaHospital: { id: '', label: '' },
  tipoEstablecimiento: { id: 0, label: '' },
  otroEstablecimiento: '',
  nombreEstablecimiento: '',
  nombre: '',
  correoElectronico: '',
  sitioWeb: '',
  agee: null,
  agem: null,
  agel: null,
  agea: null,
  calle: '',
  num1: '',
  num2: '',
  cp: '',
  telefono1: { telefono: '', extension: '' },
  telefono2: { telefono: '', extension: '' },
  telefono3: { telefono: '', extension: '' },
  logotipo: '',
  porDefecto: false,
  borrado: false,
};

export const getConsultorio =
  (idMedico: number, idConsultorio: number) =>
  (dispatch: ThunkDispatch<object, object, ConsultoriosActionTypes | LoadingActionTypes>) => {
    // Se desactivaron los setLoadings aquí porque desactiva el de la pantalla de operaciones en el perfil médico,
    // y se hace innecesario por la existencia de otras consultas que se ejecutan después de esta
    getPerfilMedicoConsultorio(idMedico, idConsultorio)
      .then((response) => response.json())
      .then((result) =>
        dispatch(setConsultorio(result.code === 200 ? result.data : consultorioInitial)),
      )
      .catch(() => dispatch(setConsultorio(consultorioInitial)));
  };
