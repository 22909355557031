import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  Button,
  StepConnector,
  AppBar,
  Toolbar,
  CssBaseline,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Check from '@material-ui/icons/Check';
import { RootState } from 'src/store';
import { setSnackComplete } from '@actions/snackbar/types';
import { getEmailMe, getSessionKeyBack } from '@utils/commonStore';
import { getMeMedico } from '@actions/me/actions';
import { setLoading } from '@actions/loading/actions';
import {
  sendPerfilSuperAdministrador,
  sendSAMiOrganizacionOrganizacion,
  sendSAMiOrganizacionOrganizaciones,
  sendSAMiOrganizacionAdministrador,
} from '@utils/sendInfo';
import { deleteSAMiOrganizacionAdministrador } from '@utils/deleteInfo';
import {
  getSAOrganizacionOrganizaciones,
  getSAMiOrganizacionAdministradores,
} from '@utils/getCatalogos';
import { getOrganizacion } from '@actions/organizaciones/actions';
import { arrayBufferToBase64, str2ab, str2AB } from '@common/base64Convert';
import DocIdCorrectoAdmin from '@components/ModalDialogs/DocIdCorrectoAdmin';
import Perfil from './PerfilSuperAdministrador';
import Organizacion from './Organizacion';
import OtrasOrganizaciones from './OtrasOrganizaciones';
import AdministradoresOrg from './AdministradoresOrg';
import useStyles from './styles';
import { IDatosAdministrador, IDatosOtraOrganizacion } from './types';
import { IDatosOrganizacion } from '../AdmonInformacion/types';

const SuperAdmonInformacionComponente = () => {
  const classesGen = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { idOrg, objType, objOrgId, objOrgLabel, objAdminId } = location.state || {
    idOrg: -1,
    objType: '',
    objOrgId: '',
    objOrgLabel: '',
    objAdminId: '',
  };
  const {
    idMedico,
    nombre,
    primerApellido,
    rol,
    email: emailSend,
  } = useSelector((state: RootState) => state.Me);
  const { organizacion } = useSelector((state: RootState) => state.Organizaciones);
  const { idOrganizacion } = organizacion;
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showModalDocId, setShowModalDocId] = useState<boolean>(false);
  const [objSuperAdministrador, setObjSuperAdministrador] = useState<IDatosAdministrador>({
    organizacion: {
      id: '',
      label: '',
    },
    nombre: '',
    apellidoP: '',
    apellidoS: '',
    docId: '',
    sexo: '',
    fechaNac: {
      dia: '',
      mes: '',
      anio: '',
    },
    celular: {
      numero: '',
      codigo: '',
    },
    telefono: {
      numero: '',
      codigo: '',
    },
    email: '',
    emailLog: '',
    nacionalidad: {
      id: '',
      label: '',
    },
    id: -1,
    password: '',
  });
  const [objAdministrador, setObjAdministrador] = useState<IDatosAdministrador>({
    organizacion: {
      id: '',
      label: '',
    },
    nombre: '',
    apellidoP: '',
    apellidoS: '',
    docId: '',
    sexo: '',
    fechaNac: {
      dia: '',
      mes: '',
      anio: '',
    },
    celular: {
      numero: '',
      codigo: '',
    },
    telefono: {
      numero: '',
      codigo: '',
    },
    email: '',
    emailLog: '',
    nacionalidad: {
      id: '',
      label: '',
    },
    id: -1,
    password: '',
  });
  const [contrasenia, setContrasenia] = useState<string>('');
  const [contraseniaRepetida, setContraseniaRepetida] = useState<string>('');
  const [objOrganizacion, setObjOrganizacion] = useState<IDatosOrganizacion>(organizacion);
  const [objOtraOrganizacion, setObjOtraOrganizacion] = useState<IDatosOtraOrganizacion>({
    id: -1,
    nombreOrganizacion: '',
    administradores: [],
    licencias: 0,
    licenciasUsadas: 0,
    licenciasCompartidas: 0,
    borrado: false,
    actualizado: false,
  });
  const [steps] = useState([
    t('administrador_mis_datos'),
    t('administrador_mi_organizacion'),
    t('administrador_otras_organizaciones'),
    t('administrador_administradores'),
  ]);
  const [otrasOrganizaciones, setOtrasOrganizaciones] = useState<IDatosOtraOrganizacion[]>([]);
  const [administradores, setAdministradores] = useState<IDatosAdministrador[]>([]);
  const [usuarioExistente, setUsuarioExistente] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [disabledNext, setDisabledNext] = useState<boolean>(true);
  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#1E40AF',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#1E40AF',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);
  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#1E40AF',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#1E40AF',
      zIndex: 1,
      fontSize: 18,
    },
  });
  const QontoStepIcon = (props: StepIconProps) => {
    const classes2 = useQontoStepIconStyles();
    const { active, completed } = props;
    return (
      <div
        className={clsx(classes2.root, {
          [classes2.active]: active,
        })}
      >
        {completed ? <Check className={classes2.completed} /> : <div className={classes2.circle} />}
      </div>
    );
  };
  const limpiarOtraOrganizacion = () => {
    setObjOtraOrganizacion({
      id: -1,
      nombreOrganizacion: '',
      administradores: [],
      licencias: 0,
      licenciasUsadas: 0,
      licenciasCompartidas: 0,
      borrado: false,
      actualizado: false,
    });
  };
  const limpiarAdministrador = () => {
    setObjAdministrador({
      organizacion: {
        id: '',
        label: '',
      },
      nombre: '',
      apellidoP: '',
      apellidoS: '',
      docId: '',
      sexo: '',
      fechaNac: {
        dia: '',
        mes: '',
        anio: '',
      },
      celular: {
        numero: '',
        codigo: '',
      },
      telefono: {
        numero: '',
        codigo: '',
      },
      email: '',
      emailLog: '',
      nacionalidad: {
        id: '',
        label: '',
      },
      id: -1,
      password: '',
    });
    setContrasenia('');
    setContraseniaRepetida('');
  };
  const limpiarLocState = () => {
    history.replace({
      state: {
        idOrg: -1,
        objType: '',
        objOrgId: '',
        objOrgLabel: '',
        objAdminId: '',
      },
    });
  };
  const limpiarObjetosIniciales = () => {
    limpiarLocState();
    limpiarAdministrador();
    limpiarOtraOrganizacion();
  };
  const handleSteps = (newStep: number) => {
    limpiarObjetosIniciales();
    setActiveStep(newStep);
  };
  const peticionOtrasOrganizaciones = () => {
    dispatch(setLoading(true));
    getSAOrganizacionOrganizaciones(idMedico, idOrg && idOrg !== -1 ? idOrg : idOrganizacion)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          // setOtrasOrganizaciones([]);
          setOtrasOrganizaciones(result.data);
          if (activeStep === 2) {
            setDisabledNext(result.data.length === 0);
          }
        } else {
          setOtrasOrganizaciones([]);
          setDisabledNext(false);
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
        setDisabledNext(false);
      });
  };
  const peticionOrganizacionAdministradores = (evalState: boolean = true) => {
    dispatch(setLoading(true));
    getSAMiOrganizacionAdministradores(idMedico, idOrganizacion)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setAdministradores(result.data);
          if (evalState && objType === 'administrador') {
            peticionOtrasOrganizaciones();
            if (objAdminId === '-1') {
              setObjAdministrador({
                ...objAdministrador,
                organizacion: {
                  id: objOrgId,
                  label: objOrgLabel,
                },
              });
            } else {
              setObjAdministrador(
                result.data.find(
                  (admin: IDatosAdministrador) => admin.id === parseInt(objAdminId, 10),
                ),
              );
            }
          }
        }
        dispatch(setLoading(false));
        setLoaded(true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };
  const deleteAdministrador = (idDelete: number, idAdminOrg: string) => {
    deleteSAMiOrganizacionAdministrador({
      idMedico,
      idDelete,
      idOrganizacion: idAdminOrg,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-delete-success'),
            }),
          );
          peticionOrganizacionAdministradores();
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error-delete'),
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };
  const encriptionData = async () => {
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    const binaryDerString = window.atob(getSessionKeyBack());
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
    // email encriptado
    const emailC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(objAdministrador.email),
    );
    // contraseña encriptada
    const contraseniaC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(objAdministrador.password),
    );
    const updateData = {
      ...objAdministrador,
      email: arrayBufferToBase64(emailC),
      password: arrayBufferToBase64(contraseniaC),
      emailSend,
    };
    sendSAMiOrganizacionAdministrador({
      idMedico,
      idOrganizacion,
      datos: updateData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          limpiarLocState();
          limpiarAdministrador();
          setAdministradores([]);
          peticionOrganizacionAdministradores(false);
          peticionOtrasOrganizaciones();
        } else if (result.code === 409 || result.code === 410 || result.code === 411) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t(result.msg),
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error'),
            }),
          );
        }
      })
      .catch(() => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: t('message-error'),
          }),
        );
      });
  };
  const saveData = () => {
    if (activeStep === 0) {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'info',
          mensaje: t('message-info'),
        }),
      );
      const updData = {
        ...objSuperAdministrador,
        emailSend,
        emailLog: emailSend,
      };
      sendPerfilSuperAdministrador({ idMedico, datos: updData })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            dispatch(getMeMedico(getEmailMe()));
            setDisabledNext(false);
          } else if (result.code === 409) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t(result.msg),
              }),
            );
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('message-error'),
              }),
            );
          }
        })
        .catch(() => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error'),
            }),
          );
        });
    } else if (activeStep === 1) {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'info',
          mensaje: t('message-info'),
        }),
      );
      sendSAMiOrganizacionOrganizacion({
        idMedico,
        idRol: rol.id,
        datos: { ...objOrganizacion, emailSend },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            dispatch(getOrganizacion(idMedico, result.id));
            setDisabledNext(false);
          }
        })
        .catch(() => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error'),
            }),
          );
        });
    } else if (activeStep === 2) {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'info',
          mensaje: t('message-info'),
        }),
      );
      sendSAMiOrganizacionOrganizaciones({
        idMedico,
        idOrganizacion,
        datos: otrasOrganizaciones,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            // setOtrasOrganizaciones([]);
            limpiarOtraOrganizacion();
            peticionOtrasOrganizaciones();
          }
        })
        .catch(() => {
          peticionOtrasOrganizaciones();
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('message-error'),
            }),
          );
        });
    } else if (activeStep === 3) {
      if (objAdministrador.id === -1 && !usuarioExistente) {
        setShowModalDocId(true);
      } else {
        encriptionData();
      }
    }
  };

  useEffect(() => {
    if (activeStep === 2) {
      peticionOtrasOrganizaciones();
    } else if (activeStep === 3) {
      setOtrasOrganizaciones(otrasOrganizaciones.filter((org) => org.id > 0));
      peticionOrganizacionAdministradores();
      setDisabledSave(true);
    }
  }, [activeStep]);
  useEffect(() => {
    if (objType === 'organizacion') {
      setActiveStep(2);
    } else if (objType === 'administrador') {
      setActiveStep(3);
    }
  }, [objType]);
  useEffect(() => {
    setObjOrganizacion(organizacion);
  }, [organizacion]);
  useEffect(() => {
    peticionOrganizacionAdministradores();
  }, []);

  return (
    <>
      <DocIdCorrectoAdmin
        open={showModalDocId}
        callBackClose={() => {
          setShowModalDocId(false);
        }}
        callBackAceptar={async () => {
          await encriptionData();
          setShowModalDocId(false);
        }}
        docId={objAdministrador.docId}
        email={objAdministrador.email}
        password={objAdministrador.password}
      />
      <CssBaseline />
      <div className="p-4 bg-white">
        <h1 className="text-blue-800 font-normal m-0 text-center">
          {`${t('bienvenido')}, ${nombre || ''} ${primerApellido || ''}`}
        </h1>
        <div className="flex content-center justify-center">
          <Button disabled={activeStep === 0} onClick={() => handleSteps(activeStep - 1)}>
            <ArrowBackIosIcon />
          </Button>
          <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Button
            onClick={() => handleSteps(activeStep + 1)}
            disabled={activeStep === steps.length - 1 || disabledNext}
          >
            <ArrowForwardIosIcon />
          </Button>
        </div>
        {loaded && administradores.length === 0 && (
          <div className="text-center p-4 bg-yellow-300 text-gray-800">
            {t('si_no_llenas_los_datos_no_podras_administrar_la_cuenta')}
          </div>
        )}
        {activeStep === 0 && (
          <Perfil
            objSuperAdministrador={objSuperAdministrador}
            setObjSuperAdministrador={setObjSuperAdministrador}
            setDisabledSave={setDisabledSave}
            setDisabledNext={setDisabledNext}
          />
        )}
        {activeStep === 1 && (
          <Organizacion
            objOrganizacion={objOrganizacion}
            setObjOrganizacion={setObjOrganizacion}
            setDisabledSave={setDisabledSave}
            setDisabledNext={setDisabledNext}
          />
        )}
        {activeStep === 2 && (
          <OtrasOrganizaciones
            objOrganizacion={objOtraOrganizacion}
            setObjOrganizacion={setObjOtraOrganizacion}
            organizaciones={otrasOrganizaciones}
            setOrganizaciones={setOtrasOrganizaciones}
            setDisabledSave={setDisabledSave}
          />
        )}
        {activeStep === 3 && (
          <AdministradoresOrg
            administradores={administradores}
            objAdministrador={objAdministrador}
            setObjAdministrador={setObjAdministrador}
            contrasenia={contrasenia}
            setContrasenia={setContrasenia}
            contraseniaRepetida={contraseniaRepetida}
            setContraseniaRepetida={setContraseniaRepetida}
            otrasOrganizaciones={otrasOrganizaciones}
            setUsuarioExistente={setUsuarioExistente}
            saveData={saveData}
            deleteAdministrador={deleteAdministrador}
          />
        )}
      </div>
      <AppBar position="sticky" color="inherit" className={classesGen.bottomSticky}>
        <Toolbar className={classesGen.divButton}>
          <div>
            <Button
              variant="contained"
              color="primary"
              disabled={activeStep === 0}
              onClick={() => handleSteps(activeStep - 1)}
            >
              {t('atras')}
            </Button>
          </div>
          <div className="flex flex-row">
            <Button variant="contained" color="primary" onClick={saveData} disabled={disabledSave}>
              {t('guardar')}
            </Button>
            <div className="px-1" />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSteps(activeStep + 1)}
              disabled={activeStep === steps.length - 1 || disabledNext}
            >
              {t('siguiente')}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default SuperAdmonInformacionComponente;
