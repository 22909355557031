import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  Slide,
  IconButton,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { validacionSoloNumeros } from '@constants/validaciones';
import { ICatalogosEstandar } from '@common/types';
import { IModalTiempoExposicionProps } from './types';
import { ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentarios } from '../../../types';

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  ),
);

function ModalTiempoExposicion(props: IModalTiempoExposicionProps) {
  const {
    open,
    callBackClose,
    callBackSave,
    callBackUpdate,
    nombre,
    datosQuimicos,
    datoActualizar,
  } = props;
  const { t } = useTranslation();
  const [tiempoSeleccionComentarios, setTiempoSeleccionComentarios] =
    useState<ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentarios>({
      id: -1,
      tipoQuimico: { id: 0, label: '' },
      txtQuimicoOtro: '',
      txtTiempo: '',
      radioSeleccion: undefined,
      comentarios: '',
    });
  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    callBackClose(event);
    setTiempoSeleccionComentarios({
      id: -1,
      tipoQuimico: { id: 0, label: '' },
      txtQuimicoOtro: '',
      txtTiempo: '',
      radioSeleccion: undefined,
      comentarios: '',
    });
  };
  useEffect(() => {
    if (datoActualizar.id !== -1) {
      setTiempoSeleccionComentarios({
        id: datoActualizar.id,
        tipoQuimico: datoActualizar.tipoQuimico || { id: 0, label: '' },
        txtQuimicoOtro: datoActualizar.txtDb || '',
        txtTiempo: datoActualizar.txtTiempo || '',
        radioSeleccion: datoActualizar.radioSeleccion,
        comentarios: datoActualizar.comentarios,
      });
    } else {
      setTiempoSeleccionComentarios({
        id: -1,
        tipoQuimico: { id: 0, label: '' },
        txtQuimicoOtro: '',
        txtTiempo: '',
        radioSeleccion: undefined,
        comentarios: '',
      });
    }
  }, [datoActualizar]);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
        <h2 className="font-normal">{nombre}</h2>
        <IconButton aria-label="cerrar" color="inherit">
          <CloseIcon onClick={(e) => handleClose(e)} />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2 text-left">
            {t('antecedentes_laborales_modal_quimico_tipo')}
          </h3>
        </div>
        <div className="mb-2">
          <Autocomplete
            options={datosQuimicos}
            getOptionLabel={(option: any) => option.label}
            noOptionsText={t('sin_resultados')}
            value={tiempoSeleccionComentarios.tipoQuimico}
            onChange={(event: any, newValue: ICatalogosEstandar | null) => {
              setTiempoSeleccionComentarios({
                ...tiempoSeleccionComentarios,
                tipoQuimico: newValue || { id: 0, label: '' },
                txtQuimicoOtro: '',
              });
            }}
            fullWidth
            renderInput={(params: any) => (
              <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
            )}
          />
        </div>
        {tiempoSeleccionComentarios.tipoQuimico.id === 52 && (
          <div className="mb-2">
            <TextField
              variant="outlined"
              fullWidth
              label={t('especifica')}
              InputProps={{
                inputProps: {
                  maxLength: 50,
                  autoComplete: 'off',
                  'aria-autocomplete': 'none',
                },
              }}
              value={tiempoSeleccionComentarios.txtQuimicoOtro}
              onChange={(e) => {
                setTiempoSeleccionComentarios({
                  ...tiempoSeleccionComentarios,
                  txtQuimicoOtro: e.target.value,
                });
              }}
            />
          </div>
        )}
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2 text-left">
            {`${t('antecedentes_laborales_modal_tiempo_exposicion')}*`}
          </h3>
        </div>
        <div className="grid grid-cols-4 gap-4 mb-2">
          <TextField
            id="txtTiempo"
            value={tiempoSeleccionComentarios.txtTiempo}
            onChange={(e) => {
              const valorNumerico = e.target.value;
              if (valorNumerico === '' || validacionSoloNumeros(valorNumerico)) {
                setTiempoSeleccionComentarios({
                  ...tiempoSeleccionComentarios,
                  txtTiempo: e.target.value,
                });
              }
            }}
            inputProps={{
              maxLength: 2,
              autoComplete: 'off',
            }}
            variant="outlined"
            fullWidth
          />
          <RadioGroup
            row
            aria-label="empresa"
            name="empresa"
            value={tiempoSeleccionComentarios.radioSeleccion}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTiempoSeleccionComentarios({
                ...tiempoSeleccionComentarios,
                radioSeleccion: (event.target as HTMLInputElement).value,
              });
            }}
          >
            <FormControlLabel value="H" control={<Radio color="primary" />} label={t('hora_s_')} />
            <FormControlLabel
              value="M"
              control={<Radio color="primary" />}
              label={t('minuto_s_')}
            />
          </RadioGroup>
        </div>
        <div>
          <h3 className="text-gray-500 font-normal m-0 mb-2 text-left">{t('comentarios')}</h3>
        </div>
        <TextField
          id="comentarios"
          placeholder={t('comentarios')}
          value={tiempoSeleccionComentarios.comentarios}
          onChange={(e) => {
            setTiempoSeleccionComentarios({
              ...tiempoSeleccionComentarios,
              comentarios: e.target.value,
            });
          }}
          inputProps={{
            maxLength: 400,
            autoComplete: 'off',
          }}
          multiline
          rows="4"
          variant="outlined"
          fullWidth
        />
        <div className="w-full flex justify-between py-2">
          <p className="text-gray-600">
            * &nbsp;
            {t('campos_obligatorios')}
          </p>
          <div className="flex flex-row">
            <div className="pr-2">
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={(e) => {
                  handleClose(e);
                }}
              >
                {t('cancelar')}
              </Button>
            </div>
            <div>
              {tiempoSeleccionComentarios && tiempoSeleccionComentarios.id === -1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="large"
                  onClick={(e) => {
                    callBackSave(tiempoSeleccionComentarios);
                    handleClose(e);
                  }}
                  disabled={
                    tiempoSeleccionComentarios.txtTiempo === '' ||
                    tiempoSeleccionComentarios.radioSeleccion === undefined
                  }
                >
                  {t('guardar')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="large"
                  onClick={(e) => {
                    callBackUpdate(tiempoSeleccionComentarios);
                    handleClose(e);
                  }}
                  disabled={
                    tiempoSeleccionComentarios.txtTiempo === '' &&
                    tiempoSeleccionComentarios.radioSeleccion === undefined
                  }
                >
                  {t('actualizar')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ModalTiempoExposicion;
