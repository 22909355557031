import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InfoIcon } from 'lucide-react';
import { RootState } from 'src/store';
import TragosEstandar from '@components/AlertasInformacion/TragosEstandar';
import { edadCalculada, iEdadMenorOIgual } from '@common/functions';
import TipoDeAlcohol from './TipoDeAlcohol';
import { IAlcoholProps } from './types';
import { ITipoAlcohol, toxicomaniaInitial } from '../types';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { AddButton } from '@components/AddButton';
import TextField from '@components/FormElemntsLibrary/TextField';

function Alcohol(props: IAlcoholProps) {
  const { hayCambios, toxicomania, setToxicomania, getAlcohol } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { sexo, dia, mes, anio } = useSelector((state: RootState) => state.BasicosPaciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const edadPaciente = edadCalculada(anio, mes, dia);
  const [openTragos, setOpenTragos] = useState<boolean>(false);
  const estatus = ['niega', 'activo', 'ex-bebedor'].map((es) => ({ value: es, label: t(es) }));
  const meno2horas = ['si', 'no'].map((es) => ({ value: es, label: t(es) }));
  const frecuencias = [
    'diario',
    '1_vez_por_semanas',
    '2_veces_por_semana',
    '3_veces_por_semana',
    '4_veces_por_semana',
    '5_veces_por_semana',
    '6_veces_por_semana',
    'mensual_o_menos',
  ].map((frec) => ({ id: frec, label: t(frec) }));
  const arrTipoAlcoholInitial = {
    arrTabla: ['I'],
    tipoAlcohol: [{ label: '', grUnidad: 0 }],
    cantidadAlcohol: [''],
  };

  const handleInputChange = (e: { name: string; value: any }) => {
    setToxicomania({
      ...toxicomania,
      alcohol: {
        ...toxicomania.alcohol,
        [e.name]: e.value,
      },
    });
  };

  const handleDeleteRow = (indx: number) => {
    if (toxicomania.alcohol.arrTipoAlcohol.arrTabla.length > 1) {
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomania.alcohol,
          arrTipoAlcohol: {
            arrTabla: toxicomania.alcohol.arrTipoAlcohol.arrTabla.filter((_item, j) => indx !== j),
            tipoAlcohol: toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol.filter(
              (_item, j) => indx !== j,
            ),
            cantidadAlcohol: toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol.filter(
              (_item, j) => indx !== j,
            ),
          },
        },
      });
    } else {
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomania.alcohol,
          arrTipoAlcohol: arrTipoAlcoholInitial,
        },
      });
    }
  };

  const handleClickAgregarOtroTipo = () => {
    if (toxicomania.alcohol.arrTipoAlcohol.arrTabla.length < 10) {
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomania.alcohol,
          arrTipoAlcohol: {
            arrTabla: toxicomania.alcohol.arrTipoAlcohol.arrTabla.concat(
              `${
                toxicomania.alcohol.arrTipoAlcohol.arrTabla[
                  toxicomania.alcohol.arrTipoAlcohol.arrTabla.length - 1
                ]
              }I`,
            ),
            tipoAlcohol: toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol.concat({
              label: '',
              grUnidad: 0,
            }),
            cantidadAlcohol: toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol.concat(''),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (toxicomania.alcohol.consumoAlcohol === 'niega') {
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomaniaInitial.alcohol,
          consumoAlcohol: 'niega',
        },
      });
    } else if (toxicomania.alcohol.consumoAlcohol === 'ex-bebedor') {
      setToxicomania({
        ...toxicomania,
        alcohol: {
          ...toxicomania.alcohol,
          consumeEnMenosDeDosHoras: '',
        },
      });
    }
  }, [toxicomania.alcohol.consumoAlcohol]);
  useEffect(() => {
    setToxicomania({
      ...toxicomania,
      alcohol: getAlcohol(toxicomania.alcohol),
    });
  }, [
    toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol,
    toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol,
    toxicomania.alcohol.frecuencia,
    toxicomania.alcohol.consumeEnMenosDeDosHoras,
  ]);

  return (
    <>
      <TragosEstandar open={openTragos} setOpen={setOpenTragos} />
      <CheckboxGroup
        name="consumoAlcohol"
        label={t('alcohol')}
        options={estatus}
        value={toxicomania.alcohol.consumoAlcohol}
        setValue={handleInputChange}
        disabled={hayCambios}
        distribution="vertical-flex"
      />
      {['activo', 'ex-bebedor'].includes(toxicomania.alcohol.consumoAlcohol) && (
        <>
          <div
            className={`grid grid-cols-1 md:grid-cols-${
              toxicomania.alcohol.consumoAlcohol === 'activo' ? '2' : '3'
            } gap-y-2 gap-x-4`}
          >
            <NumberInput
              name="edadInicio"
              label={t('edad_de_inicio')}
              value={toxicomania.alcohol.edadInicio}
              isInteger
              isString
              setValue={handleInputChange}
              validacion={(val) =>
                val.length > 0 &&
                !iEdadMenorOIgual(
                  { edad: Number(val), claveEdad: 5, periodo: 'anios' },
                  edadPaciente,
                )
                  ? t('la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente')
                  : ''
              }
              min={0}
              max={99}
              disabled={hayCambios}
            />
            {toxicomania.alcohol.consumoAlcohol === 'ex-bebedor' && (
              <NumberInput
                name="edadTermino"
                label={t('edad_de_termino')}
                value={toxicomania.alcohol.edadTermino}
                isInteger
                isString
                setValue={handleInputChange}
                validacion={(val) => {
                  if (val.length > 0) {
                    if (
                      !iEdadMenorOIgual(
                        { edad: Number(val), claveEdad: 5, periodo: 'anios' },
                        edadPaciente,
                      )
                    ) {
                      return t(
                        'la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente',
                      );
                    }
                    if (
                      iEdadMenorOIgual(
                        { edad: Number(val), claveEdad: 5, periodo: 'anios' },
                        {
                          edad: parseInt(toxicomania.alcohol.edadInicio, 10),
                          claveEdad: 5,
                          periodo: 'anios',
                        },
                      )
                    ) {
                      return t('la_edad_de_termino_es_menor_a_la_edad_actual_de_inicio');
                    }
                  }
                  return '';
                }}
                min={0}
                max={99}
                disabled={hayCambios}
              />
            )}
            <SelectInput
              name="frecuencia"
              options={frecuencias}
              value={toxicomania.alcohol.frecuencia}
              setValue={handleInputChange}
              isNumeric={false}
              isDisabled={hayCambios}
            />
          </div>
          {toxicomania.alcohol.arrTipoAlcohol.arrTabla.map((tabla, indx) => (
            <TipoDeAlcohol
              hayCambios={hayCambios}
              toxicomania={toxicomania}
              setToxicomania={setToxicomania}
              index={indx}
              handleDeleteRow={handleDeleteRow}
              key={tabla}
            />
          ))}
          <AddButton
            variant="enabled"
            label={t('agregar_tipo_de_alcohol')}
            onClick={handleClickAgregarOtroTipo}
            disabled={
              hayCambios ||
              toxicomania.alcohol.arrTipoAlcohol.arrTabla.length >= 10 ||
              toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol.some(
                (alcohol: ITipoAlcohol) => !alcohol.label,
              )
            }
          />
          <div className="grid grid-cols-1 md:grid-cols-3">
            {!esPaciente && (
              <>
                <TextField
                  name="totalAproxAlcohol"
                  label={t('total_aproximado_de_alcohol')}
                  value={toxicomania.alcohol.totalAproxAlcohol}
                  adornment={{
                    text: t('gr_de_alcohol'),
                    position: 'end',
                  }}
                  disabled={hayCambios}
                />
                <div className="flex flex-col justify-center md:gap-1 mb-4 md:mb-0 pl-4">
                  <button
                    type="button"
                    className="text-base text-blue-500 mb-2"
                    onClick={() => setOpenTragos(true)}
                    disabled={hayCambios}
                  >
                    <div className="flex flex-row text-sm">
                      <InfoIcon className="mr-2" size={15} />
                      {t('equivalencias_de_tragos_estandar')}
                    </div>
                  </button>
                  {toxicomania.alcohol.consumoAlcohol === 'activo' && (
                    <div>
                      {toxicomania.alcohol.alarmaConsumoIntensivo && (
                        <p className="text-red-500">{t('consumo_intensivo_de_alcohol')}</p>
                      )}
                      {!esPaciente && toxicomania.alcohol.alarmaPosibleTrastorno && (
                        <p className="text-red-500">
                          {t('posible_trastorno_por_el_uso_de_alcohol')}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
            {toxicomania.alcohol.consumoAlcohol === 'activo' &&
              toxicomania.alcohol.totalAproxAlcohol &&
              ((sexo === 'M' && parseInt(toxicomania.alcohol.totalAproxAlcohol, 10) >= 52) ||
                (sexo === 'H' && parseInt(toxicomania.alcohol.totalAproxAlcohol, 10) >= 78)) && (
                <CheckboxGroup
                  containerClass={esPaciente ? 'col-span-3' : ''}
                  name="consumeEnMenosDeDosHoras"
                  label={t(
                    sexo === 'M'
                      ? t('consume_5_tragos_de_estas_bebidas_en_menos_de_2_horas')
                      : t('consume_6_tragos_de_estas_bebidas_en_menos_de_2_horas'),
                  )}
                  options={meno2horas}
                  value={toxicomania.alcohol.consumeEnMenosDeDosHoras}
                  setValue={handleInputChange}
                  disabled={hayCambios}
                  distribution="vertical-flex"
                />
              )}
          </div>
          {!esPaciente && (
            <TextArea
              containerClass="mt-4 md:mt-0"
              name="comentarios"
              value={toxicomania.alcohol.comentarios}
              setValue={handleInputChange}
              maxLength={300}
              disabled={hayCambios}
            />
          )}
        </>
      )}
    </>
  );
}

export default Alcohol;
