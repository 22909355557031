import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTable, useSortBy, usePagination } from 'react-table';
import dayjs from 'dayjs';
import { TableBody, TableCell, TableHead, TableRow, Button, Chip } from '@material-ui/core';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowDropUpIcon from '@material-ui/icons/ArrowUpwardRounded';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import MaUTable from '@material-ui/core/Table';
import { RootState } from 'src/store';
import Pagination from '@components/PaginationButtons';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import {
  IObjPacienteSexo,
  IObjPacienteSexoTotales,
  IObjPacienteSexoHabitos,
  IObjAreasOportunidad,
  IObjPacienteCategoriaPorcentajeSexo,
  IObjPacienteDia,
  IObjPacienteCategoriaSexo,
  IObjPacienteAccidente,
  IObjPacienteIncapacidad,
  IObjPacienteEpidemiologia,
  IObjGloborisk,
  IObjAAA,
  IObjCancerPulmon,
  IObjCancerCervico,
  IObjAudiometria,
  IObjEMP,
  IObjHabito,
  IObjPacienteDictamen,
  IObjPacienteReporteGeneral,
  IObjPacienteFaltante,
} from '../types';
import { ITablaProps } from './types';
import useStyles from '../styles';

dayjs.locale('es');

function TableView({
  columns,
  data,
  pageCount: controlledPageCount = 1,
  pageSize: controlledPageSize = 10,
  // findNext = undefined,
  // findMore = false,
  verDetalle = undefined,
  callBackVerDetalle = undefined,
  sort = true,
  centered = false,
  firstColumnIsTitle = false,
}: ITablaProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable<
    | IObjPacienteSexo
    | IObjPacienteSexoTotales
    | IObjPacienteSexoHabitos
    | IObjAreasOportunidad
    | IObjPacienteCategoriaPorcentajeSexo
    | IObjPacienteDia
    | IObjPacienteCategoriaSexo
    | IObjPacienteAccidente
    | IObjPacienteIncapacidad
    | IObjPacienteEpidemiologia
    | IObjGloborisk
    | IObjAAA
    | IObjCancerPulmon
    | IObjCancerCervico
    | IObjAudiometria
    | IObjEMP
    | IObjHabito
    | IObjPacienteReporteGeneral
    | IObjPacienteFaltante
    | IObjPacienteDictamen
  >(
    {
      columns,
      data,
      pageCount: controlledPageCount,
      initialState: { pageIndex: 0, pageSize: controlledPageSize },
    },
    useSortBy,
    usePagination,
  );
  const { rol } = useSelector((state: RootState) => state.Me);
  // Render the UI for your table
  return (
    <div className="bg-white shadow">
      <MaUTable {...getTableProps()}>
        <TableHead className="bg-[#3b82f6]">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) =>
                rol.grupo === 'salud' || column.id !== 'verDetalle' ? (
                  <TableCell
                    {...column.getHeaderProps(sort ? column.getSortByToggleProps() : {})}
                    className={`${
                      index === 0
                        ? 'rounded-tl-md'
                        : `${index === headerGroup.headers.length - 1 ? 'rounded-tr-md' : ''}`
                    }`}
                  >
                    <div
                      className={`text-white ${
                        (index === 0 && firstColumnIsTitle) || !centered ? '' : 'text-center'
                      }`}
                    >
                      {column.render('Header')}
                      {column.isSorted && column.isSortedDesc && (
                        <KeyboardArrowUp className="align-bottom" />
                      )}
                      {column.isSorted && !column.isSortedDesc && (
                        <KeyboardArrowDown className="align-bottom" />
                      )}
                    </div>
                  </TableCell>
                ) : null,
              )}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell, colIndex) => {
                  if (cell.column) {
                    switch (cell.column.id) {
                    }
                  }
                  if (cell.column?.id === 'diferencia') {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        <div className="text-center text-gray-700 ">
                          {cell.value < 0 ? (
                            <ArrowDropDownIcon className="text-red-500 align-bottom" />
                          ) : null}
                          {cell.value > 0 ? (
                            <ArrowDropUpIcon className="text-green-500 align-bottom" />
                          ) : null}
                          {cell.value < 0 ? cell.value * -1 : cell.value}
                        </div>
                      </TableCell>
                    );
                  }
                  if (cell.column?.id === 'diagnosticos') {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.value.length > 0
                          ? cell.value.map((diagnostico: string) => (
                              <Chip label={diagnostico} variant="outlined" />
                            ))
                          : null}
                      </TableCell>
                    );
                  }
                  if (cell.column?.id === 'rangoClasificacion') {
                    let colorCss = classes.colorYellow;
                    if (
                      cell.value === 'riesgo_muy_alto' ||
                      cell.value === 'riesgo_habito_alto' ||
                      cell.value === 'riesgo_cancer_pulmon' ||
                      cell.value === 'riesgo_aaa'
                    ) {
                      colorCss = classes.colorRed;
                    } else if (
                      cell.value === 'riesgo_alto' ||
                      cell.value === 'riesgo_habito_medio'
                    ) {
                      colorCss = classes.colorOrange;
                    }
                    return (
                      <TableCell {...cell.getCellProps()} className="inherit">
                        <h4 className={`${colorCss} font-normal m-0`}>{t(cell.value)}</h4>
                      </TableCell>
                    );
                  }
                  if (cell.column?.id === 'estudio') {
                    const colorCss = (() => {
                      if (cell.value.diasRestantes < 0) {
                        return classes.colorRed;
                      }
                      if (cell.value.diasRestantes < 15) {
                        return classes.colorOrange;
                      }
                      return classes.colorBlack;
                    })();
                    const txtDiasRestantes =
                      cell.value.diasRestantes < 0
                        ? t('estudio_expirado')
                        : cell.value.diasRestantes;
                    const txtFechaSiguiente =
                      cell.value.diasRestantes < 0 ? t('expiro_fecha') : t('proximo_estudio');
                    return (
                      <TableCell {...cell.getCellProps()} className="inherit">
                        <HtmlTooltip
                          title={`${txtFechaSiguiente}: ${dayjs(
                            `${cell.value.fechaSiguiente}`,
                            'YYYY M D',
                          ).format('DD-MMM-YYYY')}`}
                        >
                          <h4 className={`${colorCss} font-normal m-0`}>{txtDiasRestantes}</h4>
                        </HtmlTooltip>
                      </TableCell>
                    );
                  }
                  if (cell.column?.id === 'contacto') {
                    return (
                      <TableCell {...cell.getCellProps()} className="inherit">
                        <div className="flex flex-row content-center items-center pb-2">
                          <PhoneIphoneIcon color="primary" />
                          <h4 className="text-gray-500 font-normal m-0 pl-2">
                            {cell.value.celular}
                          </h4>
                        </div>
                        <div className="flex flex-row content-center items-center pb-2">
                          <PhoneIcon color="primary" />
                          <h4 className="text-gray-500 font-normal m-0 pl-2">
                            {cell.value.telefono}
                          </h4>
                        </div>
                        <div className="flex flex-row content-center items-center">
                          <EmailIcon color="primary" />
                          <h4 className="text-gray-500 font-normal m-0 pl-2">{cell.value.email}</h4>
                        </div>
                      </TableCell>
                    );
                  }
                  if (
                    rol.grupo === 'salud' &&
                    cell.column?.id === 'verDetalle' &&
                    callBackVerDetalle
                  ) {
                    return (
                      <TableCell {...cell.getCellProps()} width="140" align="right">
                        {(!verDetalle || verDetalle[rowIndex]) && (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => callBackVerDetalle(cell.value, rowIndex)}
                          >
                            {t('ver_detalle')}
                          </Button>
                        )}
                      </TableCell>
                    );
                  }
                  if (cell.column?.id === 'sugerencia' && callBackVerDetalle) {
                    return (
                      <TableCell {...cell.getCellProps()} className="inherit">
                        <Button
                          color="primary"
                          onClick={() => callBackVerDetalle(cell.value, rowIndex)}
                        >
                          {t(cell.value || 'ver_detalle')}
                        </Button>
                      </TableCell>
                    );
                  }
                  if (['frecuencia', 'tipo'].includes(cell.column?.id)) {
                    return (
                      <TableCell {...cell.getCellProps()} className="inherit">
                        {Array.isArray(cell.value) ? (
                          cell.value.map((value: string) => (
                            <h4 className="text-gray-500 font-normal pl-2">{t(value)}</h4>
                          ))
                        ) : (
                          <h4 className="text-gray-500 font-normal pl-2">{t(cell.value)}</h4>
                        )}
                      </TableCell>
                    );
                  }
                  if (
                    ['grado0', 'grado1', 'grado2', 'grado3', 'grado4'].includes(cell.column?.id)
                  ) {
                    return (
                      <TableCell {...cell.getCellProps()} className="inherit">
                        <div className="flex flex-col text-center justify-center">
                          <h4 className="text-gray-500 font-normal pl-2">{cell.value[0]}</h4>
                          <h4 className="text-gray-500 font-normal pl-2">{cell.value[1]}</h4>
                        </div>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell {...cell.getCellProps()}>
                      <div
                        className={`text-md text-gray-700 ${`font-${
                          colIndex === 0 && firstColumnIsTitle ? 'bold' : 'normal'
                        }`} ${
                          cell.column?.id === 'comentarios' ||
                          (colIndex === 0 && firstColumnIsTitle) ||
                          !centered
                            ? 'text-left'
                            : 'text-center'
                        }`}
                      >
                        {cell.value}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
      <Pagination
        pageIndex={pageIndex}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
      />
    </div>
  );
}

export default TableView;
