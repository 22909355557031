import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog as DialogUI, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { XIcon, TriangleAlert } from 'lucide-react';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertDialogProps from './types';
import { SubmitButton } from '@components/FormElemntsLibrary/SubmitButton';
import { Button } from '@components/FormElemntsLibrary/Button';
import { useBreakPoint } from '@hooks/useBreakpoint';

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  ),
);

function Dialog(props: IAlertDialogProps) {
  const {
    type = 'button',
    title,
    description,
    children,
    text,
    open,
    onSubmit,
    onClose,
    alert,
    textAlign = 'justify',
    textSize = 'base',
    textColor = 'text-gray-700',
    submitButtonText,
    acceptButtonText,
    cancelButtonText,
    acceptButtonColor = 'primary',
    cancelButtonColor = 'error',
    fullScreen = false,
    maxWidth = 'xs',
  } = props;
  const { t } = useTranslation();
  const isWideScreen = useBreakPoint();

  return (
    <DialogUI
      open={open}
      TransitionComponent={Transition}
      onClose={() => onClose()}
      aria-labelledby="control-diagnostico-titulo"
      aria-describedby="control-diagnostico-descripcion"
      fullScreen={fullScreen}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle>
        <div className="relative flex items-center h-16 bg-white border-b border-[#C4C4C4]">
          <div className="flex-1 text-center">
            <div className="flex flex-row justify-center gap-x-4">
              {alert && <TriangleAlert size={24} color="#c2410c" />}
              <h4 className={`font-semibold text-base ${alert ? 'text-orange-700' : ''}`}>
                {title}
              </h4>
            </div>
            {description && <h5 className="text-center font-normal text-sm mt-1">{description}</h5>}
          </div>
          <div className="absolute right-0 top-2 cursor-pointer" onClick={() => onClose()}>
            <XIcon size={20} className="text-gray-600" />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="w-full h-full md:mt-0 flex flex-col justify-between bg-white md:z-40">
          <div className="w-full h-auto pb-4">
            <section className="w-full h-fit py-4">
              {text ? (
                <span className={`flex text-${textSize} text-${textAlign} ${textColor}`}>
                  {text}
                </span>
              ) : (
                children
              )}
            </section>
          </div>
          <div className={isWideScreen ? 'w-full' : ''}>
            {onSubmit ? (
              <SubmitButton saveCallback={onSubmit} label={submitButtonText || t('guardar')} />
            ) : (
              <div className="flex flex-row gap-x-4 border-t-[1px] border-t-gray-200 p-4">
                <Button
                  onClick={() => onClose(type === 'options' ? true : undefined)}
                  label={acceptButtonText || t('aceptar')}
                  color={acceptButtonColor}
                  size="base"
                />
                {type === 'options' && (
                  <Button
                    onClick={() => onClose(false)}
                    label={cancelButtonText || t('cancelar')}
                    color={cancelButtonColor}
                    size="base"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </DialogUI>
  );
}

export default Dialog;
