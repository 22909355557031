import { IFechaObj, IPhone } from '@common/types';

export interface ISignUpProps {
  esPaciente: boolean;
}

export interface IRegistro {
  nombre: string;
  primerApellido: string;
  celular: IPhone;
  fijo: IPhone;
  sexo: string;
  fechaNac: IFechaObj;
  docId: string;
  noPoseeDocId: boolean;
  noRecuerdaDocId: boolean;
  numeroDocumento: string;
  fechaIngreso: IFechaObj;
  numeroSeguro: string;
  numeroEmpleado: string;
  email: string;
  emailRepetido: string;
  noPoseeEmail: boolean;
  contrasenia: string;
  contraseniaRepetida: string;
  noTieneEmail: boolean;
  pinPaciente: string;
}

export const registroInitial = {
  nombre: '',
  primerApellido: '',
  celular: { codigo: '', numero: '' },
  fijo: { codigo: '', numero: '' },
  sexo: '',
  fechaNac: { dia: '', mes: '', anio: '' },
  docId: '',
  noPoseeDocId: false,
  noRecuerdaDocId: false,
  numeroDocumento: '',
  fechaIngreso: { dia: '', mes: '', anio: '' },
  numeroSeguro: '',
  numeroEmpleado: '',
  email: '',
  emailRepetido: '',
  noPoseeEmail: false,
  contrasenia: '',
  contraseniaRepetida: '',
  noTieneEmail: false,
  pinPaciente: '',
};
