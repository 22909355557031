import {
  CHANGE_CARRERA,
  CHANGE_EMAIL,
  CHANGE_ESPECIALIDAD,
  CHANGE_ID_MEDICO,
  CHANGE_NOMBRE,
  CHANGE_PRIMER_APELLIDO,
  CHANGE_ROL,
  CHANGE_SEGUNDO_APELLIDO,
  CHANGE_TOKEN,
  SET_ME,
  MeActionTypes,
  MeState,
  CHANGE_SEXO,
  CHANGE_ACTIVO,
  CHANGE_TIEMPO,
  RESET_ME,
} from '@actions/me/types';
import { getToken, getEmailMe } from '@utils/commonStore';

const initialState: MeState = {
  token: getToken(),
  rol: {
    id: -1,
    rol: '',
    tipo: '',
    grupo: '',
  },
  email: getEmailMe(),
  idMedico: 0,
  idUsuario: 0,
  nombre: '',
  primerApellido: '',
  segundoApellido: '',
  carrera: {
    titulo: '',
    escuela: '',
    cedula: '',
  },
  sexo: '',
  especialidades: [],
  activo: false,
  tiempoRestante: '',
  customer: '',
  secciones: [],
  pais: 'mx',
  esPaciente: false,
  validar: false,
  enOrganizacion: false,
  orgsAdministradas: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function MeReducer(state = initialState, action: MeActionTypes): MeState {
  switch (action.type) {
    case CHANGE_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case CHANGE_ROL:
      return {
        ...state,
        rol: action.payload,
      };
    case CHANGE_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case CHANGE_ID_MEDICO:
      return {
        ...state,
        idMedico: action.payload,
      };
    case CHANGE_NOMBRE:
      return {
        ...state,
        nombre: action.payload,
      };
    case CHANGE_PRIMER_APELLIDO:
      return {
        ...state,
        primerApellido: action.payload,
      };
    case CHANGE_SEGUNDO_APELLIDO:
      return {
        ...state,
        segundoApellido: action.payload,
      };
    case CHANGE_CARRERA:
      return {
        ...state,
        carrera: action.payload,
      };
    case CHANGE_ESPECIALIDAD:
      return {
        ...state,
        especialidades: action.payload,
      };
    case CHANGE_SEXO:
      return {
        ...state,
        sexo: action.payload,
      };
    case CHANGE_ACTIVO:
      return {
        ...state,
        activo: action.payload,
      };
    case CHANGE_TIEMPO:
      return {
        ...state,
        tiempoRestante: action.payload,
      };
    case SET_ME:
      return action.payload;
    case RESET_ME:
      return {
        ...initialState,
        token: '',
        email: '',
      };
    default:
      return state;
  }
}

export default MeReducer;
