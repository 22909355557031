import setLoading from '@actions/loading/actions';
import { SetSnackClose, setSnackComplete } from '@actions/snackbar/types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { obtenerURLArchivo } from '@utils/getFiles';

export const useApiRequest = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const msgLoading: string[] = ['', t('message-info'), t('message-delete')];
  const msgSuccess: string[] = [
    t('message_get_success'),
    t('message-success'),
    t('message-delete-success'),
  ];
  const msgError: string[] = [
    t('message-get-error'),
    t('message-error'),
    t('message-error-delete'),
  ];
  const msgWarning: string[] = [
    t('message-error-general'),
    t('message-error-warning'),
    t('message-error-delete'),
  ];

  const apiRequest = async (props: {
    type: 'get' | 'send' | 'delete'; // Define el tipo de peticion, si es para obtener (get), enviar (get) o eliminar (delete) datos. Esto determina el tipo de mensajes que se muestran de respuesta o de error
    requestFunction: Promise<Response>; // Llama a la función de la petición
    successFunction?: (result?: any) => void; // (Opcional) Llama a la función definida en caso de obtener un resultado satisfactorio (code === 200)
    errorFunction?: (result?: any) => void; // (Opcional) Llama a la función definida en caso de obtener un resultado insatisfactorio (code !== 200)
    catchFunction?: () => void; // (Opcional) Llama a la función definida en caso de obtener un error en la(s) petición(es)
    alwaysFunction?: () => void; // (Opcional) Llama a una función que se ejecuta siempre, ya sea que el resultado sea satisfactorio o no
    successMessage?: string; // (Opcional) Define el mensaje que se muestra si el resultado de la petición es satisfactorio (code === 200)
    errorMessage?: string; // (Opcional) Muestra un mensaje de error si el resultado de la petición no es satisfactorio (code !== 200)
    loading?: boolean; // (Opcional) Determina si se muestra el componente que opaca la vista para indicar que se está ejecutando una consulta
    toJson?: boolean; // (Opcional) Determina si el resultado devuelto se parsea a formato JSON o no. Si es falso, quedan obsoletos los parámetros "errorMessage", "errorFunction" y "alwaysFunction"
    showMsgs?: boolean; // (Opcional) Determina si se muestra o no el mensaje de ejecución de la consulta
    showMsgLoading?: boolean; // (Opcional) Determina si se muestran todos los mensajes
    showMsgSuccess?: boolean; // (Opcional) Determina si se muestra o no el mensaje de respuesta satisfactoria
    showMsgError?: boolean; // (Opcional) Determina si se muestra o no el mensaje de respuesta insatisfactoria
  }) => {
    const {
      type,
      requestFunction,
      successFunction,
      errorFunction,
      catchFunction,
      alwaysFunction,
      successMessage,
      errorMessage,
      loading = true,
      toJson = true,
      showMsgs = true,
      showMsgLoading = true,
      showMsgSuccess = true,
      showMsgError = true,
    } = props;
    const tp = ['get', 'send', 'delete'].indexOf(type);
    if (loading) {
      dispatch(setLoading(true));
    }
    if (tp > 0 && showMsgs && showMsgLoading) {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'info',
          mensaje: msgLoading[tp],
        }),
      );
    }
    requestFunction
      .then((response: Response) => (toJson ? response.json() : response))
      .then((result: any) => {
        if (!toJson || result.code === 200) {
          if (successFunction) {
            successFunction(result);
          }
          if (showMsgs && showMsgSuccess) {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: successMessage || msgSuccess[tp],
              }),
            );
          } else {
            dispatch(SetSnackClose());
          }
        } else if (toJson) {
          if (errorFunction) {
            errorFunction(result);
          }
          if (showMsgs && showMsgError) {
            const msg = errorMessage || (result.msg ? t(result.msg) : '');
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${msgError[tp]}${msg ? `: ${msg}` : ''}`,
              }),
            );
          } else if (!errorFunction) {
            dispatch(SetSnackClose());
          }
          if (alwaysFunction) {
            alwaysFunction();
          }
        }
        if (loading) {
          dispatch(setLoading(false));
        }
      })
      .catch((error: any) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: error instanceof TypeError
              ? t('error_inesperado')
              : `${t('error_inesperado')}: ${error.toString()}`,
          }),
        );
        if (catchFunction) {
          catchFunction();
        } else {
          dispatch(SetSnackClose());
        }
        if (alwaysFunction) {
          alwaysFunction();
        }
        if (loading) {
          dispatch(setLoading(false));
        }
      });
  };

  const apiRequests = async (props: {
    type: 'get' | 'send'; // Define el tipo de peticion, si es para obtener (get) o enviar (get) datos. Esto determina el tipo de mensajes que se muestran de respuesta o de error
    requestFunctions: Promise<Response>[]; // Define la funciones de las peticiones
    successFunctions?: ((result?: any) => void)[]; // (Opcional) Llama a la funciones definidas en caso de obtener resultados satisfactorios (code === 200)
    errorFunctions?: ((result?: any) => void)[]; // (Opcional) Llama a la funciones definidas en caso de obtener resultados insatisfactorios (code !== 200)
    catchFunction?: () => void; // (Opcional) Llama a la función definida en caso de obtener un error en la(s) petición(es)
    alwaysFunction?: () => void; // (Opcional) Llama a una función que se ejecuta siempre, ya sea que el resultado sea satisfactorio o no
    successMessage?: string; // (Opcional) Define el mensaje que se muestra si el resultado de la petición es satisfactorio (code === 200)
    loading?: boolean; // (Opcional) Determina si se muestra el componente que opaca la vista para indicar que se está ejecutando una consulta
    toJson?: boolean | number[]; // (Opcional) Determina si el resultado devuelto se parsea a formato JSON o no. Puede recibir 'true', para indicar que todos se parsean, o una lista de números para indicar los índices de los resultados que se van a parsear (se cuentan desde cero). Si este parámetro es falso, quedan obsoletos los parámetros "errorFunctions" y "alwaysFunction"
    showMsgs?: boolean; // (Opcional) Determina si se muestra o no el mensaje de ejecución de la consulta
    showMsgLoading?: boolean; // (Opcional) Determina si se muestran todos los mensajes
    showMsgSuccess?: boolean; // (Opcional) Determina si se muestra o no el mensaje de respuesta satisfactoria
    showMsgError?: boolean; // (Opcional) Determina si se muestra o no el mensaje de respuesta insatisfactoria
  }) => {
    const {
      type,
      requestFunctions,
      successFunctions = [],
      errorFunctions = [],
      catchFunction,
      alwaysFunction,
      successMessage,
      loading = true,
      toJson = true,
      showMsgs = true,
      showMsgLoading = true,
      showMsgSuccess = true,
      showMsgError = true,
    } = props;
    const tp = ['get', 'send', 'delete'].indexOf(type);
    if (successFunctions?.length && requestFunctions.length > successFunctions.length) {
      throw new Error(
        'Error en la ejecución de los request: El número de peticiones no puede exceder al número de funciones satisfactorias',
      );
    } else if (errorFunctions?.length && requestFunctions.length > errorFunctions.length) {
      throw new Error(
        'Error en la ejecución de los request: El número de peticiones no puede exceder al número de funciones de error',
      );
    } else {
      if (loading) {
        dispatch(setLoading(true));
      }
      if (tp > 0 && showMsgs && showMsgLoading) {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'info',
            mensaje: msgLoading[tp],
          }),
        );
      }
      let error = '';
      Promise.all(requestFunctions)
        .then((responses: Response[]) => {
          Promise.all(
            responses.map((r: Response, i: number) =>
              toJson === true || (Array.isArray(toJson) && toJson.includes(i)) ? r.json() : r,
            ),
          )
            .then((results: any[]) => {
              results.forEach((result: any, index: number) => {
                const tJson = toJson === true || (Array.isArray(toJson) && toJson.includes(index));
                if (!tJson || result.code === 200) {
                  if (successFunctions?.length) {
                    successFunctions[index](result);
                  }
                } else if (tJson) {
                  if (errorFunctions?.length) {
                    errorFunctions[index](result);
                  }
                  error = `${msgError[tp]}${result.msg ? `: ${t(result.msg)}` : ''}`;
                }
              });
              if (alwaysFunction) {
                alwaysFunction();
              }
              if (loading) {
                dispatch(setLoading(false));
              }
            })
            .catch((error) => {
              if (catchFunction) {
                catchFunction();
              }
              if (showMsgs && showMsgError) {
                dispatch(
                  setSnackComplete({
                    open: true,
                    severity: 'error',
                    mensaje: `${msgWarning[tp]} ${error.toString()}`,
                  }),
                );
              } else if (!catchFunction) {
                dispatch(SetSnackClose());
              }
              if (loading) {
                dispatch(setLoading(false));
              }
            })
            .finally(() => {
              if (
                showMsgs &&
                ((!error.length && showMsgSuccess) || (error.length > 0 && showMsgError))
              ) {
                if (error.length > 0) {
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'error',
                      mensaje: error,
                    }),
                  );
                } else {
                  dispatch(
                    setSnackComplete({
                      open: true,
                      severity: 'success',
                      mensaje: successMessage || msgSuccess[tp],
                    }),
                  );
                }
              } else {
                dispatch(SetSnackClose());
              }
            });
        })
        .catch((error) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: error instanceof TypeError
                ? t('error_inesperado')
                : `${t('error_inesperado')}: ${error.toString()}`,
            }),
          );
          if (catchFunction) {
            catchFunction();
          } else {
            dispatch(SetSnackClose());
          }
          if (loading) {
            dispatch(setLoading(false));
          }
        });
    }
  };

  const apiRequestFile = async (props: {
    type: 'get' | 'delete';
    idMedico: number | null;
    idPaciente: number | null;
    idArchivo: number;
    nombreArchivo: string | null;
    pathArchivo: string;
    tipoArchivo: string;
    successFunction?: (result?: any) => void; // (Opcional) Llama a la función definida en caso de obtener un resultado satisfactorio (code === 200)
  }) => {
    const {
      type,
      idMedico,
      idPaciente,
      idArchivo,
      nombreArchivo,
      pathArchivo,
      tipoArchivo,
      successFunction,
    } = props;
    if (type === 'get') {
      obtenerURLArchivo(idMedico, idPaciente, idArchivo, nombreArchivo, pathArchivo, tipoArchivo)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200 && result.data.url) {
            if (successFunction) {
              successFunction(result.data.url);
            } else {
              window.open(result.data.url, '_blank')?.focus();
            }
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-file')}${result.msg ? `: ${result.msg}` : ''}`,
              }),
            );
          }
        })
        .catch((err) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-file')}: ${err}`,
            }),
          );
        });
    } else {
      obtenerURLArchivo(idMedico, idPaciente, idArchivo, nombreArchivo, pathArchivo, tipoArchivo)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200 && result.data.url) {
            if (successFunction) {
              successFunction(result.data.url);
            } else {
              window.open(result.data.url, '_blank')?.focus();
            }
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-file')}${result.msg ? `: ${result.msg}` : ''}`,
              }),
            );
          }
        })
        .catch((err) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-file')}: ${err}`,
            }),
          );
        });
    }
  };

  return { apiRequest, apiRequests, apiRequestFile };
};
