import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trash2 } from 'lucide-react';
import DeleteDialog from '@components/DeleteDialog';
import { validacionSoloNumeros } from '@constants/validaciones';
import ITipoDeAlcoholProps from './types';
import { ITipoAlcohol } from '../../types';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import { ICatStrEstandar } from '@common/types';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const arrTipoAlcohol: ITipoAlcohol[] = [
  { label: 'cerveza_al_5_lata', grUnidad: 13 },
  { label: 'cerveza_al_5_caguama', grUnidad: 36.92 },
  { label: 'pulque_al_6_3_jarrito', grUnidad: 13 },
  { label: 'ron_al_38_vaso', grUnidad: 13 },
  { label: 'ron_al_40_botela_750', grUnidad: 235.5 },
  { label: 'ron_al_40_botella_980', grUnidad: 307.7 },
  { label: 'vino_al_12_copa', grUnidad: 13 },
  { label: 'vino_al_12_botella', grUnidad: 70.6 },
  { label: 'coctel_destilado_al_45', grUnidad: 13 },
  { label: 'tequila_caballito', grUnidad: 13 },
  { label: 'tequila_al_38_botella', grUnidad: 283.4 },
  { label: 'whisky_al_40_vaso', grUnidad: 13 },
  { label: 'whisky_al_40_botella_750', grUnidad: 235.5 },
  { label: 'vodka_al_40_vaso', grUnidad: 13 },
  { label: 'vodka_al_40_botella_450', grUnidad: 141.31 },
  { label: 'vodka_al_40_botella_750', grUnidad: 235.56 },
  { label: 'otro_(trago_estandar)', grUnidad: 13 },
];

function TipoDeAlcohol(props: ITipoDeAlcoholProps) {
  const { hayCambios, toxicomania, setToxicomania, index, handleDeleteRow } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const tiposAlcohol: ICatStrEstandar[] = [
    'cerveza_al_5_lata',
    'cerveza_al_5_caguama',
    'pulque_al_6_3_jarrito',
    'ron_al_38_vaso',
    'ron_al_40_botela_750',
    'ron_al_40_botella_980',
    'vino_al_12_copa',
    'vino_al_12_botella',
    'coctel_destilado_al_45',
    'tequila_caballito',
    'tequila_al_38_botella',
    'whisky_al_40_vaso',
    'whisky_al_40_botella_750',
    'vodka_al_40_vaso',
    'vodka_al_40_botella_450',
    'vodka_al_40_botella_750',
    'otro_(trago_estandar)',
  ].map((alcohol) => ({ id: alcohol, label: t(alcohol) }));

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  return (
    <>
      <DeleteDialog
        key={`dialog-alcohol-${index}`}
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={`${t('_esta_seguro_que_desea_eliminar_el_tipo_de_alcohol')}${
          toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol[index].label
            ? ` ${t(toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol[index].label)}`
            : ''
        }${t('_?')}`}
        callBackAceptar={() => handleDeleteRow(index)}
        callBackClose={handleAlertClose}
      />
      <div
        key={`div-alcohol-${index}`}
        className="border-solid border border-gray-300 rounded pt-4 pb-2 mb-3 flex"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4 px-4 w-full">
          <SelectInput
            key={`tipo-alcohol-${index}`}
            name="label"
            label={t('tipo_de_alcohol')}
            options={tiposAlcohol}
            value={toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol[index].label}
            setValue={(e) =>
              setToxicomania({
                ...toxicomania,
                alcohol: {
                  ...toxicomania.alcohol,
                  arrTipoAlcohol: {
                    ...toxicomania.alcohol.arrTipoAlcohol,
                    tipoAlcohol: toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol.map((tipo, indx) =>
                      indx === index
                        ? arrTipoAlcohol.find((tipo) => tipo.label === e.value) || {
                            label: '',
                            grUnidad: 0,
                          }
                        : tipo,
                    ),
                  },
                },
              })
            }
            isNumeric={false}
            isDisabled={hayCambios}
          />
          <NumberInput
            key={`cantidad-alcohol-${index}`}
            name="cantidadAlcohol"
            label={t('cantidad')}
            value={toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol[index]}
            setValue={(e) =>
              setToxicomania({
                ...toxicomania,
                alcohol: {
                  ...toxicomania.alcohol,
                  arrTipoAlcohol: {
                    ...toxicomania.alcohol.arrTipoAlcohol,
                    cantidadAlcohol: toxicomania.alcohol.arrTipoAlcohol.cantidadAlcohol.map(
                      (cantidad, indx) => (indx === index ? String(e.value) : cantidad),
                    ),
                  },
                },
              })
            }
            min={0}
            max={99}
            disabled={hayCambios}
          />
        </div>
        <IconButton
          color="primary"
          aria-label="delete"
          onClick={() => setDeleteAlert(true)}
          disabled={hayCambios}
          className="h-12 self-center"
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </>
  );
}

export default TipoDeAlcohol;
