import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setRutaAntNoPatologicos, setRutaPrincipal } from '@actions/rutasExpediente/actions';
import { useApiRequest } from '@hooks/useApiRequest';
import { RootState } from 'src/store';
import { setHigiene, setHigieneUsuario } from '@actions/paciente/actions';
import {
  antecedentesNoPatologicosGuardarHigiene,
  antecedentesNoPatologicosUsuarioPacienteGuardarHigiene,
  antecedentesNoPatologicosUsuarioGuardarHigiene,
} from '@utils/sendInfo';
import { IHigiene, higieneInitial } from './types';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { ModalForm } from '@components/modals/ModalForm';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import { IFormSectionComponent } from '@components/FormSections/type';
import FormSections from '@components/FormSections';

function Higiene() {
  const dispatch = useDispatch();
  const { apiRequest, apiRequests } = useApiRequest();
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    expediente: {
      antNoPatologicos: { higiene },
    },
    usuario: {
      antNoPatologicos: { higiene: higieneUsuario },
    },
    cambios: {
      antNoPatologicos: { higiene: cambios },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [higieneTemp, setHigieneTemp] = useState<IHigiene>(higieneInitial);

  const isWideScreen = useBreakPoint();
  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);

  const handleInputChange = (e: { name: string; value: any }) =>
    setHigieneTemp((prevData) => ({ ...prevData, [e.name]: e.value }));

  const getDatosHigiene = (datos: IHigiene) => ({
    banio: datos.banio,
    banioComentario: datos.banioComentario,
    bucal: datos.bucal,
    ropa: datos.ropa,
    ropaComentario: datos.ropaComentario,
    comentarios: datos.comentarios,
  });
  const getDatosExpediente = (datos: IHigiene) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    higiene: getDatosHigiene(datos),
  });
  const getDatosUsuario = (datos: IHigiene) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    higiene: getDatosHigiene(datos),
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(higieneTemp);
    const sendFunctions = [antecedentesNoPatologicosGuardarHigiene(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarHigiene(datosToSend));
    }
    apiRequests({
      type: 'send',
      requestFunctions: sendFunctions,
      successFunctions: [
        () => dispatch(setHigiene({ ...higieneTemp, loaded: true })),
        () => dispatch(setHigieneUsuario({ ...higieneTemp, loaded: true })),
      ],
    });
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: antecedentesNoPatologicosUsuarioGuardarHigiene(getDatosUsuario(higieneTemp)),
      successFunction: () => {
        dispatch(setHigiene({ ...higieneTemp, loaded: true }));
        dispatch(setRutaAntNoPatologicos('alimentacion'));
      },
    });
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    apiRequest({
      type: 'send',
      requestFunction: antecedentesNoPatologicosGuardarHigiene(getDatosExpediente(higieneUsuario)),
      successFunction: () => {
        dispatch(setHigiene(higieneUsuario));
        setHigieneTemp(higieneUsuario);
      },
    });
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarHigiene(
        getDatosExpediente(higiene),
      ),
      successFunction: () => dispatch(setHigieneUsuario(higiene)),
    });
  };

  useEffect(() => {
    if (higiene.loaded && !higieneTemp.loaded) {
      setHigieneTemp(higiene);
    }
  }, [higiene]);

  const banio = [
    { value: 'D', label: t('antecedentes-pnp-higiene-diario') },
    { value: '2D', label: t('antecedentes-pnp-higiene-dos-dias') },
    { value: '3D', label: t('antecedentes-pnp-higiene-tres-dias') },
    { value: 'OTRO', label: t('antecedentes-pnp-higiene-otro') },
  ];
  const bucal = [
    { value: '1', label: t('antecedentes-pnp-higiene-una') },
    { value: '2', label: t('antecedentes-pnp-higiene-dos') },
    { value: '3', label: t('antecedentes-pnp-higiene-tres') },
    { value: 'MENOS', label: t('antecedentes-pnp-higiene-menos') },
  ];
  const ropa = [
    { value: 'D', label: t('antecedentes-pnp-higiene-diario') },
    { value: '2D', label: t('antecedentes-pnp-higiene-dos-dias') },
    { value: '3D', label: t('antecedentes-pnp-higiene-tres-dias') },
    { value: 'OTRO', label: t('antecedentes-pnp-higiene-otro') },
  ];

  const checksGroups: {
    name: string;
    options: { value: string; label: string }[];
    value: string;
    comentario: string;
  }[] = [
    {
      name: 'banio',
      options: banio,
      value: higieneTemp.banio,
      comentario: higieneTemp.banioComentario,
    },
    {
      name: 'bucal',
      options: bucal,
      value: higieneTemp.bucal,
      comentario: '',
    },
    {
      name: 'ropa',
      options: ropa,
      value: higieneTemp.ropa,
      comentario: higieneTemp.ropaComentario,
    },
  ];

  const components: IFormSectionComponent[] = [
    {
      component: (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-2 gap-x-4 md:mt-2">
          {checksGroups.map((group, index) => (
            <div
              className={`flex flex-col pl-6 ${
                index > 0 && isWideScreen ? 'border-l-[1px] border-gray-300' : ''
              }
            `}
            >
              <CheckboxGroup
                containerClass="pb-0"
                name={group.name}
                label={t(`antecedentes-pnp-higiene-${group.name}`)}
                options={group.options}
                distribution="vertical"
                value={group.value}
                setValue={handleInputChange}
                disabled={hayCambios}
              />
              {group.value === 'OTRO' && (
                <TextInput
                  name={`${group.name}Comentario`}
                  label=""
                  placeholder={t('comentarios')}
                  value={group.comentario}
                  setValue={handleInputChange}
                  disabled={hayCambios}
                  maxLength={30}
                />
              )}
            </div>
          ))}
        </div>
      ),
    },
    // {
    //   component:
    //     <NumberInput
    //       name="suenio"
    //       label={t(`antecedentes-pnp-higiene-suenio`)}
    //       fixedValue={t('hora_s_')}
    //       value={higieneTemp.suenio}
    //       setValue={handleInputChange}
    //       disabled={hayCambios}
    //     />
    // },
    {
      hide: esPaciente,
      component: (
        <TextArea
          name="comentarios"
          value={higieneTemp.comentarios}
          setValue={handleInputChange}
          maxLength={500}
          disabled={hayCambios}
        />
      ),
    },
  ];

  return (
    <ModalForm
      title={t('antecedentes-pnp-higiene')}
      id="antecedentes-pnp-higiene-form"
      aceptarCambios={guardarDatosDeUsuarioAExpediente}
      rechazarCambios={guardarDatosDeExpedienteAUsuario}
      submitForm={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
      hayCambios={hayCambios}
      cambios={cambios}
      backAction={() => dispatch(setRutaPrincipal('main'))}
      noXMargin
    >
      <FormSections components={components} />
    </ModalForm>
  );
}

export default Higiene;
