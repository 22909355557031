import { useTranslation } from 'react-i18next';
import DeteccionTempranaCancer from '../../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/DeteccionTempranaCancer';
import AneurismaAortaAbdominal from '../../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionCardiovascular/AneurismaAortaAbdominal';
import PrevencionCardiovascularDiv from '../../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionCardiovascular/PrevencionCardiovascular';
import DeteccionCancerCervicalDiv from '../../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/DeteccionTempranaCancer/DeteccionCancerCervical';
import PrevencionAuditiva from '../../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionRiesgos/PrevencionAuditiva';
import ExamenMedicoPeriodico from '../../../TabsPaciente/DiagnosticoPlan/Plan/MedicinaPreventiva/ExamenMedicoPeriodico';
import { IDetallesProps } from './types';
import { ChevronLeft } from 'lucide-react';
import { Button } from '@components/FormElemntsLibrary/Button';

const Detalles = (props: IDetallesProps) => {
  const {
    selectorTabla,
    setVerDetalle,
    inxSeleccionado,
    tablaCardiovascularDetalle,
    tablaAAADetalle,
    tablaCancerPulmonDetalle,
    tablaCancerCervicoDetalle,
    tablaAudiometriasDetalle,
    tablaEMPDetalle,
    tableGloborisk,
  } = props;
  const { t } = useTranslation();

  /* OBTENER DETALLE */
  const getDetalleTabla = () => {
    switch (selectorTabla) {
      case 'riesgo_cardiovascular':
        return (
          <PrevencionCardiovascularDiv
            presion={tablaCardiovascularDetalle[inxSeleccionado].rangoPa || 0}
            colesterol={tablaCardiovascularDetalle[inxSeleccionado].rangoColesterol || 0}
            valor={tablaCardiovascularDetalle[inxSeleccionado].valor || ''}
            sexo={tablaCardiovascularDetalle[inxSeleccionado].sexo ? 'M' : 'H'}
            toxi={tablaCardiovascularDetalle[inxSeleccionado].consumoTabaco ? 'activo' : 'falso'}
            esDiabetico={tablaCardiovascularDetalle[inxSeleccionado].esDiabetico || 0}
            colesterolResultado={tablaCardiovascularDetalle[inxSeleccionado].valorColesterol || 0}
            fechaColesterol={tablaCardiovascularDetalle[inxSeleccionado].fecha || ''}
            tableGloborisk={tableGloborisk}
            edad={tablaCardiovascularDetalle[inxSeleccionado].edad || 0}
          />
        );
      case 'aaa':
        return (
          <AneurismaAortaAbdominal txtToxicomania={tablaAAADetalle[inxSeleccionado].toxicomania} />
        );
      case 'cancer_pulmon':
        return (
          <DeteccionTempranaCancer
            selIdPaciente={tablaCancerPulmonDetalle[inxSeleccionado].idPaciente || 0}
            selEdadPaciente={tablaCancerPulmonDetalle[inxSeleccionado].edad || 0}
          />
        );
      case 'cancer_cervical':
        return (
          <DeteccionCancerCervicalDiv
            edad={tablaCancerCervicoDetalle[inxSeleccionado].edad}
            sendFecha={tablaCancerCervicoDetalle[inxSeleccionado].fecha}
            sendShowCervicoUterino
          />
        );
      case 'audiometria':
        return (
          <PrevencionAuditiva
            nivel={tablaAudiometriasDetalle[inxSeleccionado].nivel}
            fechaEstudio={tablaAudiometriasDetalle[inxSeleccionado].estudio.fechaEstudio}
          />
        );
      case 'emp':
        return (
          <ExamenMedicoPeriodico
            fechaEstudio={tablaEMPDetalle[inxSeleccionado].estudio.fechaEstudio}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="py-4 w-fit">
        <Button
          color="primary"
          label={t('regresar')}
          onClick={() => setVerDetalle(false)}
          icon={<ChevronLeft />}
        />
      </div>
      {getDetalleTabla()}
    </>
  );
};

export default Detalles;
