import React from 'react';
import { Star } from 'lucide-react';
import { ThankYouModalProps } from './types';
import { useTranslation } from 'react-i18next';

const ThankYouModal: React.FC<ThankYouModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose}></div>

      <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4 p-6 relative z-10">
        <div className="flex justify-center mb-6">
          <div className="bg-orange-400 p-3 rounded-full">
            <Star className="w-8 h-8 text-white" />
          </div>
        </div>

        <div className="text-center space-y-4">
          <h2 className="text-2xl font-bold text-gray-800">{t('completar_registro_gracias')}</h2>

          <p className="text-emerald-500 text-lg font-medium">
            {t('completar_registro_info_enviada')}
          </p>

          <p className="text-gray-700">{t('completar_registro_info_visible')}</p>

          <button
            onClick={onClose}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-3 px-4 rounded-md transition-colors mt-6"
          >
            {t('completar_registro_ir_a')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouModal;
