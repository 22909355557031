import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Avatar, CssBaseline, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import loading from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { ReactComponent as LogoIcon } from '@assets/logo/Logotipo_Color.svg';
import { RootState } from 'src/store';
import { getToken, getUser, removeUserSession } from '@utils/commonStore';
import { limpiarReduxSesion } from '@utils/reduxCommon';
import { checkSession, sendLoguot } from '@utils/sendInfo';
import MenuPaciente from './MenuPaciente';
import useStyles from './styles';

function NavbarPaciente() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { nombre, primerApellido, segundoApellido } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation('translation-patient');
  /** state del componente */
  const [pacienteEl, setPacienteEl] = useState<null | HTMLButtonElement>(null);
  const [pacienteName, setPacienteName] = useState('');
  const [intentos, setIntentos] = useState(0);
  const intentosMaximos = 3;
  const openPacienteMenu = Boolean(pacienteEl);
  useEffect(() => {
    setPacienteName(
      `${nombre ? nombre[0] : ''}${primerApellido ? primerApellido[0] : ''}${
        segundoApellido ? segundoApellido[0] : ''
      }`,
    );
  }, [nombre, primerApellido, segundoApellido]);

  /* VALIDAR LA SESIÓN */
  useEffect(() => {
    const verificarSesion = async () => {
      try {
        const obj = { token: getToken(), usuario: getUser() };
        checkSession(obj)
          .then((result) => result.json())
          .then((data) => {
            if (data.code === 400) {
              removeUserSession();
              limpiarReduxSesion();
              history.push('/login');
            }
          })
          .catch(() => {
            removeUserSession();
            limpiarReduxSesion();
            history.push('/login');
          });
        setIntentos(0); // Reiniciar intentos si la sesión sigue activa
      } catch (error) {
        if (intentos + 1 >= intentosMaximos) {
          removeUserSession();
          limpiarReduxSesion();
          history.push('/login');
        } else {
          setIntentos((prev) => prev + 1);
        }
      }
    };
    const interval = setInterval(verificarSesion, 30 * 60 * 1000); // Cada 30 minutos
    return () => clearInterval(interval);
  }, [intentos]);

  /** Abrir el menú doctor */
  const handleMenuPaciente = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPacienteEl(e.currentTarget);
  };

  /** Cerrar el menú del doctor */
  const handleCloseDoctor = () => {
    setPacienteEl(null);
  };

  const cerrarSesion = () => {
    dispatch(loading(true));
    const obj = { token: getToken(), usuario: getUser() };
    sendLoguot(obj)
      .then((result) => result.json())
      .then((data) => {
        if (data.code === 200) {
          removeUserSession();
          limpiarReduxSesion();
          history.push('/login-patient');
        }
        dispatch(loading(false));
      })
      .catch((err) => {
        if (err.toString() === 'SyntaxError: Unexpected token E in JSON at position 0') {
          removeUserSession();
          limpiarReduxSesion();
          history.push('/login-patient');
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        }
        dispatch(loading(false));
      });
  };

  /** Regresa el navbar y el sidebar */
  return (
    <>
      <CssBaseline />
      <div className="text-white bg-white z-30">
        <AppBar
          position="fixed"
          color="inherit"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: false,
          })}
        >
          <Toolbar>
            <div className="text-gray-900 w-100 flex-grow">
              <LogoIcon height="55" width="150" />
            </div>
            <Button
              color="default"
              startIcon={<PersonIcon />}
              endIcon={
                <Avatar variant="rounded" style={{ backgroundColor: '#1D4ED8' }} alt={pacienteName}>
                  {pacienteName}
                </Avatar>
              }
              onClick={handleMenuPaciente}
              disableElevation
            >
              {`${nombre} ${primerApellido} ${segundoApellido}`}
            </Button>
            <MenuPaciente
              anchorEl={pacienteEl}
              handleClose={handleCloseDoctor}
              open={openPacienteMenu}
              handleLogout={cerrarSesion}
            />
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}

export default NavbarPaciente;
