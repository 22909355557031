import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en/translation.json';
import enTranslationPatient from './locales/en/translation-patient.json';
import esTranslation from './locales/es/translation.json';
import esTranslationPatient from './locales/es/translation-patient.json';

const resources = {
  en: {
    translation: enTranslation,
    'translation-patient': { ...enTranslation, ...enTranslationPatient },
  },
  es: {
    translation: esTranslation,
    'translation-patient': { ...esTranslation, ...esTranslationPatient },
  },
};

i18n
  /**
   * Usarr Backend para cargar por defecto los locales definidos dentro de la carpeta
   * public/locales/
   */
  .use(Backend)
  /**
   * User el laguage detector para determinar el lenguage basado en las cookies
   * el navegador y el sistema operativo
   */
  .use(LanguageDetector)
  /**
   * pasa i18n hacia to react-i18next
   */
  .use(initReactI18next)
  .init({
    /** Lenguage por defecto en caso de un error es el español */
    resources,
    fallbackLng: 'es',
    debug: true,
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  });

export default i18n;
