import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import PreviousPageIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextPageIcon from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import useStyles from './styles';
import { IPaginationProps } from './types';

dayjs.locale('es');

function Pagination({
  pageIndex,
  pageCount,
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
}: IPaginationProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return pageCount > 1 ? (
    <div className="flex pt-1 justify-end pb-2">
      <div className="flex pagination content-between items-center">
        <div className={classes.paginationButtonContainer}>
          <Button
            className={classes.paginationButton}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            variant="contained"
            color="primary"
            startIcon={<FirstPageIcon />}
          />
        </div>
        <div className={classes.paginationButtonContainer}>
          <Button
            className={classes.paginationButton}
            onClick={previousPage}
            disabled={!canPreviousPage}
            variant="contained"
            color="primary"
            startIcon={<PreviousPageIcon />}
          />
        </div>
        <span className="text-gray-500 mx-4">
          {`${pageIndex + 1} ${t('pagina_de')} ${pageCount}`}
        </span>
        <div className={classes.paginationButtonContainer}>
          <Button
            className={classes.paginationButton}
            onClick={nextPage}
            disabled={!canNextPage}
            variant="contained"
            color="primary"
            startIcon={<NextPageIcon />}
          />
        </div>
        <div className={classes.paginationButtonContainer}>
          <Button
            className={classes.paginationButton}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            variant="contained"
            color="primary"
            startIcon={<LastPageIcon />}
          />
        </div>
      </div>
    </div>
  ) : null;
}

export default Pagination;
