import { ProgressBar } from '@components/ProgressBar';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { FC } from 'react';
import { MenuProps } from './type';

export const Menu: FC<MenuProps> = ({ title, children, percentageBar }) => {
  const isWideScreen = useBreakPoint();
  return (
    <div className="w-full pt-4 md:pt-0 min-h-screen h-auto z-0 bg-white md:w-[98%] md:min-h-4 md:h-fit md:bg-transparent">
      <section className="w-full h-auto md:h-[100%]">
        {!isWideScreen && <h1 className="w-full text-2xl font-semibold p-2">{title}</h1>}

        {!isWideScreen && percentageBar && percentageBar < 100 && (
          <ProgressBar percentage={percentageBar} />
        )}

        <nav className="w-full h-auto md:h-[100%] md:flex md:flex-wrap md:gap-y-[4px] px-4 md:px-0 mt-4 md:mt-0">
          {children}
        </nav>
      </section>
    </div>
  );
};
