interface IDiagnosticoControl {
  frecSigueIndicaciones: string;
  frecAcudeConsultas: string;
  frecSigueDieta: string;
  diasUltimaMedicion: number | null;
}

interface IDiagnosticoMedicamento {
  id: number;
  nombre: string;
  validado: boolean;
}

export interface IDiagnosticoGeneral {
  id: number;
  idDiagnostico: number;
  categoria: 'corazon-cronico' | 'nervioso-dolor' | 'respiratorio-corporal';
  nombre: string;
  posee: '' | 'si' | 'no';
  medicamentos: string[];
  noMedicamento: boolean;
  sexo: 'H' | 'M' | 'A';
  control: IDiagnosticoControl | null;
  validado: boolean;
}

export const diagnosticoGeneralInitial: IDiagnosticoGeneral = {
  id: 0,
  idDiagnostico: 0,
  categoria: 'corazon-cronico',
  nombre: '',
  posee: '',
  medicamentos: [],
  noMedicamento: false,
  sexo: 'A',
  control: null,
  validado: false,
};

export interface IDiagnosticosGenerales {
  loaded: boolean;
  contenidoTabla: IDiagnosticoGeneral[];
}

export const diagnosticosGeneralesInitial: IDiagnosticosGenerales = {
  loaded: false,
  contenidoTabla: [],
};
