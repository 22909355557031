import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControlLabel, Checkbox, Collapse } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { RawDraftContentState } from 'draft-js';
import { ICatalogosEstandar, IFechaObj } from '@common/types';
import { RootState } from 'src/store';
import DeleteDialog from '@components/DeleteDialog';
import { hospitalizacionesGuardarSinAntecedentes } from '@utils/sendInfo';
import { hospitalizacionesEliminarHospitalizacion } from '@utils/deleteInfo';
import { getCatHospDuracion } from '@utils/getCatalogos';
import { useApiRequest } from '@hooks/useApiRequest';
import { setHospitalizaciones, setSinHospitalizaciones } from '@actions/paciente/actions';
import ListaHospitalizaciones from './ListaHospitalizaciones/index';
import FormHospitalizacion from './FormHospitalizacion';

function Hospitalizacion() {
  const { t } = useTranslation();
  const { apiRequest } = useApiRequest();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { sinHospitalizaciones, hospitalizaciones } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const [catDuracion, setCatDuracion] = useState<ICatalogosEstandar[]>([]);
  /* alertas */
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [cambioAlert, setCambioAlert] = useState<boolean>(false);
  /* variables formulario */
  const [idBD, setIDBD] = useState<number>(-1);
  const [diagnosticoRelacionadoALaHospitalizacion, setDiagnosticoRelacionadoALaHospitalizacion] =
    useState<ICatalogosEstandar>({ id: 0, label: '' });
  const [motivoHospitalizacion, setMotivoHospitalizacion] = useState<string>('');
  const [duracion, setDuracion] = useState<ICatalogosEstandar>({ id: 0, label: '' });
  const [fecha, setFecha] = useState<IFechaObj>({ dia: '', mes: '', anio: '' });
  const [comentarios, setComentarios] = useState<RawDraftContentState>({
    entityMap: {},
    blocks: [],
  });
  /* variables para los modales */
  const [indexToDelete, setIndexToDelete] = useState<number>(-1);
  const [indexToUpdate, setIndexToUpdate] = useState<number>(-1);

  const [liTraExpand, setLiTraExpand] = useState(true);
  const [numListaT, setNumListaT] = useState(hospitalizaciones.length);

  /* FUNCIONES DE LAS ALERTAS */
  /* funcion para cerrar la alerta de borrado */
  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  /* funcion para cerrar la alerta de edición */
  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };
  /* funcion para cerrar la alerta de activar sin antecedentes de alergias */
  const handleCambioAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCambioAlert(false);
  };

  const editHospitalizacion = (indice = indexToUpdate) => {
    const {
      idBD: indiceBD,
      diagnosticoRelacionadoALaHospitalizacion: diagnosticoRelacionadoALaHospitalizacionAEdit,
      motivoHospitalizacion: motivoHospitalizacionAEdit,
      duracion: duracionAEdit,
      fecha: fechaAEdit,
      comentarios: comentariosAEdit,
    } = hospitalizaciones[indice];
    /* setemos las variables del formulario y cerramos la alerta */
    setDiagnosticoRelacionadoALaHospitalizacion(diagnosticoRelacionadoALaHospitalizacionAEdit);
    setMotivoHospitalizacion(motivoHospitalizacionAEdit);
    setDuracion(duracionAEdit);
    setFecha(fechaAEdit);
    setComentarios(comentariosAEdit);
    setIDBD(indiceBD);
    setEditAlert(false);
    window.scrollTo({ top: 470, left: 0, behavior: 'smooth' });
  };

  const deleteHospitalizacion = () => {
    apiRequest({
      type: 'delete',
      requestFunction: hospitalizacionesEliminarHospitalizacion({
        idMedico,
        idPaciente,
        idBD: hospitalizaciones[indexToDelete].idBD,
        consulta: idConsulta,
        idConsultorio,
      }),
      successFunction: () => {
        dispatch(
          setHospitalizaciones(hospitalizaciones.filter((_transf, indx) => indx !== indexToDelete)),
        );
        setIndexToDelete(-1);
      },
    });
    setDeleteAlert(false);
  };

  const handleSinHospitalizacion = (sinHosp: boolean) => {
    apiRequest({
      type: 'send',
      requestFunction: hospitalizacionesGuardarSinAntecedentes({
        idMedico,
        idPaciente,
        idConsulta,
        idConsultorio,
        sinHospitalizacion: sinHosp,
      }),
      successFunction: () => {
        setSinHospitalizaciones(sinHosp);
        dispatch(setSinHospitalizaciones(sinHosp));
      },
    });
    setCambioAlert(false);
  };

  useEffect(() => {
    getCatHospDuracion().then((result: ICatalogosEstandar[]) => {
      setCatDuracion(result);
    });
  }, []);

  /* EFECTOS PARA LA PAGINA */
  useEffect(() => {
    setNumListaT(hospitalizaciones.length);
  }, [hospitalizaciones]);

  return (
    <>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pnp-historias-eliminar')}
        callBackAceptar={deleteHospitalizacion}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_este_diagnostico_se_borrara_el_que_estas_creando')}
        callBackAceptar={editHospitalizacion}
        callBackClose={handleEditAlertClose}
      />
      <DeleteDialog
        open={cambioAlert}
        titulo={t('_atencion_')}
        descripcion={t('_estas_seguro__si_existe_registros_anteriores_se_eliminaran')}
        callBackAceptar={() => handleSinHospitalizacion(true)}
        callBackClose={handleCambioAlertClose}
      />
      <div>
        <h2 className="text-blue-800 font-normal m-0">{t('hospitalizaciones')}</h2>
        <FormControlLabel
          control={
            <Checkbox
              checked={sinHospitalizaciones}
              onChange={() => {
                if (sinHospitalizaciones) {
                  handleSinHospitalizacion(false);
                } else {
                  setCambioAlert(true);
                }
              }}
              name="sinAntecedentesTransfusionales"
              color="primary"
            />
          }
          label={t('sin_antecedentes_de_hospitalizaciones')}
          disabled={!!hospitalizaciones.length}
        />
        {!sinHospitalizaciones && (
          <FormHospitalizacion
            idPaciente={idPaciente}
            catDuracion={catDuracion}
            idBD={idBD}
            setIDBD={setIDBD}
            diagnosticoRelacionadoALaHospitalizacion={diagnosticoRelacionadoALaHospitalizacion}
            setDiagnosticoRelacionadoALaHospitalizacion={
              setDiagnosticoRelacionadoALaHospitalizacion
            }
            motivoHospitalizacion={motivoHospitalizacion}
            setMotivoHospitalizacion={setMotivoHospitalizacion}
            duracion={duracion}
            setDuracion={setDuracion}
            fecha={fecha}
            setFecha={setFecha}
            comentarios={comentarios}
            setComentarios={setComentarios}
          />
        )}
        {numListaT > 0 && (
          <div className="mt-4">
            <h3 className="text-blue-500 font-medium">
              {`${t('lista_de')} ${t('hospitalizaciones')}: ${numListaT}`}
            </h3>
            <Collapse in={liTraExpand}>
              {hospitalizaciones.map((hospitalizacion, indx) => (
                <ListaHospitalizaciones
                  key={hospitalizacion.idBD}
                  hospitalizacion={hospitalizacion}
                  index={indx}
                  setEditAlert={setEditAlert}
                  setDeleteAlert={setDeleteAlert}
                  idBD={idBD}
                  setIndexToUpdate={setIndexToUpdate}
                  setIndexToDelete={setIndexToDelete}
                  editHospitalizacion={editHospitalizacion}
                  diagnosticoRelacionadoALaHospitalizacionVerificar={
                    diagnosticoRelacionadoALaHospitalizacion
                  }
                  motivoHospitalizacionVerificar={motivoHospitalizacion}
                  duracionVerificar={duracion}
                  fechaVerificar={fecha}
                  comentariosVerificar={comentarios}
                />
              ))}
            </Collapse>
            <div className="text-center">
              <Button
                onClick={() => setLiTraExpand((prevVal: boolean) => !prevVal)}
                color="primary"
              >
                {liTraExpand ? <span>{t('ver_menos')}</span> : <span>{t('ver_mas')}</span>}
                {liTraExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Hospitalizacion;
