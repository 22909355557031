import { RawDraftContentState } from 'react-draft-wysiwyg';
import { ICatalogosCie10, ICatalogosEstandar, ICatStrEstandar } from '@common/types';

export interface IBusinessDoctor {
  start: any;
  end: any;
}

export interface IObjPacienteDia {
  fecha: string;
  total: number;
  nuevos: number;
  subsecuentes: number;
  registrados: number;
  diferencia: number;
}

export interface IObjPacienteCategoriaSexo {
  categoria: string;
  hombres: number;
  mujeres: number;
  total: number;
}

export interface IObjPacienteCategoriaPorcentajeSexo {
  categoria: string;
  porcentaje: number;
  mujeres: number;
  hombres: number;
  total: number;
}

export interface IObjPacienteSexo {
  mujeres: number;
  hombres: number;
  total: number;
}

export interface IObjPacienteSexoTotales {
  categoria: string;
  mujeres: number;
  hombres: number;
  totalAlarma: number;
  totalDatos: number;
  sin_datos: number;
}


export interface IObjPacienteFaltante {
  nombre: string;
  numeroEmpleado: string;
  ocupacion: string;
  distGeo4: string;
  supervisor: string;
}

export interface IObjPacienteAccidente {
  fecha: string;
  nombreEmpleado: string;
  numeroEmpleado: string;
  causaAccidente: string;
  naturalezaLesion: string;
  incapacidad: string;
  diasIncapacidad: number;
  tipoRiesgo: string;
}

export interface IObjPacienteIncapacidad {
  nombreEmpleado: string;
  numeroEmpleado: string;
  fecha: string;
  tipoIncidencia: string;
  folioImss: string;
  folioInterno: string;
  diasIncapacidad: number;
  fechaInicio: string;
  ramoSeguro: string;
  tipoRiesgo: string;
  secuela: string;
  controlIncapacidad: string;
}

export interface IObjPacienteCumple {
  nombre: string;
  iniciales: string;
  telefono: string;
  edad: number;
}

export interface IObjAreasOportunidad {
  tipo: string;
  titulo: string;
  diagnosticoHabito: number;
  perdidaPresentismo: number;
  perdidaAusentismo: number;
  perdidaTotal: number;
}

export interface IObjPacienteEpidemiologia {
  nombre: string;
  edad: number;
  sexo: string;
  diagnosticos: string[];
  ocupacion: string;
}

export interface IObjCampania {
  campaniaId: number;
  campaniaNombre: string;
  correoAsunto: string;
  correoCuerpo: RawDraftContentState;
  documento: string;
}

export const campaniaInitial = {
  campaniaId: -1,
  campaniaNombre: '',
  correoAsunto: '',
  correoCuerpo: { entityMap: {}, blocks: [] },
  documento: '',
};

export interface IObjExamenAlarma {
  titulo: string;
  expirado: number;
  porExpirar: number;
  totalAlarma: number;
  totalDatos: number;
  sin_datos: number;
}

export interface IObjPacienteSexoHabitos {
  tipo: string;
  titulo: string;
  mujeres: number;
  hombres: number;
  totalAlarma: number;
  totalDatos: number;
  sin_datos: number;
  exConsumidor: number;
  comentarios: string;
  perdida: number;
  verDetalle: string;
}

export interface IObjGraphCardiovascular {
  riesgo_muy_alto: number;
  riesgo_alto: number;
  riesgo_intermedio: number;
  riesgo_bajo: number;
  riesgo_nulo: number;
  sin_datos: number;
  totalAlarma: number;
  totalDatos: number;
}

export interface IObjGloborisk {
  rangoClasificacion: string;
  valorPa: number;
  valor: string;
  consumoTabaco: number;
  edad: number;
  esDiabetico: number;
  imc: number;
  valorColesterol: number;
  rangoColesterol: number;
  rangoEdad: number;
  rangoPa: number;
  sexo: number;
  nombre: string;
  contacto: IObjContacto;
  idPaciente: number;
  fecha: string;
}

export interface IObjAAA {
  toxicomania: string;
  idPaciente: number;
}

export interface IObjCancerPulmon {
  contacto: IObjContacto;
  idPaciente: number;
  edad: number;
}

export interface IObjCancerCervico {
  contacto: IObjContacto;
  rangoClasificacion: string;
  sugerencia: string;
  edad: number;
  fecha: string;
}

interface IObjEstudio {
  fechaEstudio: string;
  fechaSiguiente: string;
  diasRestantes: number;
}

export interface IObjAudiometria {
  contacto: IObjContacto;
  idPaciente: number;
  nivel: number;
  estudio: IObjEstudio;
  sugerencia: string;
  edad: number;
  fecha: string;
}

export interface IObjEMP {
  contacto: IObjContacto;
  idPaciente: number;
  estudio: IObjEstudio;
  sugerencia: string;
  fecha: string;
}

export interface IObjHabito {
  nombre: IObjEstudio;
  rangoClasificacion: string;
  frecuencia: string;
  consumo: string;
}

export interface IObjPacienteReporteGeneral {
  nombre: string;
  sexo: string;
  edad: number;
  ocupacion: string;
  gradoSalud: number;
  riesgoOperativo: string;
  distGeo1: string;
  distGeo2: string;
  distGeo3: string;
  distGeo4: string;
  supervisor: string;
}

export interface IObjGraphGradosSalud {
  grado0: number;
  grado1: number;
  grado2: number;
  grado3: number;
  grado4: number;
  totalDatos: number;
}

export interface IObjGraphRiesgosOperativos {
  conRiesgo: number;
  sinRiesgo: number;
  totalDatos: number;
}

export interface IObjGradosSalud {
  diagnostico: string;
  grado0: number[];
  grado1: number[];
  grado2: number[];
  grado3: number[];
  grado4: number[];
  totalDatos: number;
}

export interface IObjRiesgoOperativo {
  diagnostico: string;
  riesgoOperativo: number;
  mujeres: number;
  hombres: number;
  totalDatos: number;
}

// export interface IButtonBack {
//   accionClick: any;
// }

interface IObjContacto {
  email: string;
  celular: number;
  telefono: number;
}

export interface ITableView {
  columns: any;
  data: any[];
  findNext: any;
  findMore: boolean;
  showDataDiv: any;
}

export interface IDistGeo {
  distGeo1: string;
  distGeo2: string;
  distGeo3: string;
  distGeo4: string;
}

export interface ICatDistGeo {
  distGeo1: ICatStrEstandar[];
  distGeo2: ICatStrEstandar[];
  distGeo3: ICatStrEstandar[];
  distGeo4: ICatStrEstandar[];
}

export interface IFiltrosSalud {
  distGeo1: string;
  distGeo2: string;
  distGeo3: string;
  distGeo4: string;
  tipoRol: 'todos' | 'administrativo' | 'operativo';
}

export interface IFiltrosReporteEpidemiologia {
  fecha: IBusinessDoctor;
  consultorio: number;
  // agees: ICatStrEstandar[];
  // agems: ICatStrEstandar[];
  edad: number[];
  sexo: string;
  diagnosticos: ICatalogosCie10[];
  ocupaciones: ICatalogosEstandar[];
}

export interface IObjPacienteDictamen {
  tipoExamen: ICatalogosEstandar;
  puestoActual: ICatalogosEstandar;
  dictamen: ICatalogosEstandar;
  restricciones: ICatalogosEstandar[];
  recomendaciones: ICatalogosEstandar[];
}
