import { ICatalogosEstandar } from '@common/types';

export interface ITiempoSeleccionComentarios {
  id: number;
  txtTiempo: string;
  radioSeleccion: string | undefined;
  comentarios: string;
}

export interface ITiempoSeleccionComentariosFecha {
  id: number;
  txtTiempo: string;
  radioSeleccion: string | undefined;
  fechaCreacion: string;
  comentarios: string;
}

export interface IDbTiempoSeleccionComentariosFecha {
  id: number;
  proteccion: {
    tapones: boolean;
    orejeras: boolean;
    conchas: boolean;
    otra: boolean;
  };
  percepcion: 'muy_intenso' | 'intenso' | 'moderado' | 'normal';
  txtDb: string;
  txtTiempo: string;
  radioSeleccion: string | undefined;
  fechaCreacion: string;
  comentarios: string;
}

export interface IObjComentariosFecha {
  id: number;
  comentarios: string;
  fechaCreacion: string;
}

interface IGroupSelectorFisicoOpciones {
  arrayTemperaturasAltas: ITiempoSeleccionComentariosFecha[];
  arrayTemperaturasBajas: ITiempoSeleccionComentariosFecha[];
  arrayRadiacionIonizante: ITiempoSeleccionComentariosFecha[];
  arrayRadiacionNoIonizante: ITiempoSeleccionComentariosFecha[];
  arrayVentilacion: ITiempoSeleccionComentariosFecha[];
  arrayIluminacion: ITiempoSeleccionComentariosFecha[];
  arrayRuido: IDbTiempoSeleccionComentariosFecha[];
  arrayVibracion: ITiempoSeleccionComentariosFecha[];
  arrayFluidoElectrico: ITiempoSeleccionComentariosFecha[];
  arrayOtro: ITiempoSeleccionComentariosFecha[];
}

interface IGroupSelectorMecanicoOpciones {
  arrayAtrapamiento: ITiempoSeleccionComentariosFecha[];
  arrayAtropellamientosVehiculos: ITiempoSeleccionComentariosFecha[];
  arrayCaidaObjetos: ITiempoSeleccionComentariosFecha[];
  arrayCaidasMismoNivel: ITiempoSeleccionComentariosFecha[];
  arrayCaidasDiferenteNivel: ITiempoSeleccionComentariosFecha[];
  arrayContactoSuperficiesTrabajo: ITiempoSeleccionComentariosFecha[];
  arrayChoques: ITiempoSeleccionComentariosFecha[];
  arrayContactoElectrico: ITiempoSeleccionComentariosFecha[];
  arrayCortes: ITiempoSeleccionComentariosFecha[];
  arrayProyeccionFluidos: ITiempoSeleccionComentariosFecha[];
  arrayProyeccionFragmentos: ITiempoSeleccionComentariosFecha[];
  arrayPinchazos: ITiempoSeleccionComentariosFecha[];
  arrayOtro: ITiempoSeleccionComentariosFecha[];
}

export interface ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentarios {
  id: number;
  tipoQuimico: ICatalogosEstandar;
  txtQuimicoOtro: string;
  txtTiempo: string;
  radioSeleccion: string | undefined;
  comentarios: string;
}

export interface ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha {
  id: number;
  tipoQuimico: ICatalogosEstandar;
  txtQuimicoOtro: string;
  txtTiempo: string;
  radioSeleccion: string | undefined;
  comentarios: string;
  fechaCreacion: string;
}

interface IGroupSelectorQuimicoOpciones {
  arrayAerosoles: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[];
  arrayHumos: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[];
  arrayGaseosos: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[];
  arrayLiquidos: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[];
  arraySolidos: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[];
  arrayNeblinas: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[];
  arrayPolvos: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[];
  arrayVapores: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[];
  arrayOtro: ITipoQuimicoTxtQuimicoTxtTiempoSeleccionComentariosFecha[];
}

interface IGroupSelectorBiologicoOpciones {
  arrayAnimales: ITiempoSeleccionComentariosFecha[];
  arrayBacterias: ITiempoSeleccionComentariosFecha[];
  arrayHongos: ITiempoSeleccionComentariosFecha[];
  arrayParasitos: ITiempoSeleccionComentariosFecha[];
  arrayVectores: ITiempoSeleccionComentariosFecha[];
  arrayVirus: ITiempoSeleccionComentariosFecha[];
  arrayOtro: ITiempoSeleccionComentariosFecha[];
}

interface IGroupSelectorErgonomicoOpciones {
  arrayRepeticion: ITiempoSeleccionComentariosFecha[];
  arrayPosturaIncomoda: ITiempoSeleccionComentariosFecha[];
  arrayFuerzaExcesiva: ITiempoSeleccionComentariosFecha[];
  arrayPosicionesEstacionarias: ITiempoSeleccionComentariosFecha[];
  arrayPresionDirecta: ITiempoSeleccionComentariosFecha[];
  arrayTemperaturasExtremas: ITiempoSeleccionComentariosFecha[];
  arrayVibraciones: ITiempoSeleccionComentariosFecha[];
  arrayExtresContacto: ITiempoSeleccionComentariosFecha[];
  arrayOtro: ITiempoSeleccionComentariosFecha[];
}

interface IGroupSelectorPsicosocialOpciones {
  arrayCargasTrabajoExcesivas: IObjComentariosFecha[];
  arrayFaltaControlTrabajo: IObjComentariosFecha[];
  arrayJornadasTrabajoSuperiores: IObjComentariosFecha[];
  arrayRotacionTurnos: IObjComentariosFecha[];
  arrayPeriodosCortosRecuperacion: IObjComentariosFecha[];
  arrayInterferenciaRelacion: IObjComentariosFecha[];
  arrayLiderazgoNegativo: IObjComentariosFecha[];
  arrayHostigamiento: IObjComentariosFecha[];
  arrayAcoso: IObjComentariosFecha[];
  arrayMalosTratos: IObjComentariosFecha[];
  arrayOtro: IObjComentariosFecha[];
}

interface IGroupSelectorFisico {
  seleccionado: number;
  opcionesFisico: IGroupSelectorFisicoOpciones;
}

interface IGroupSelectorMecanico {
  seleccionado: number;
  opcionesMecanico: IGroupSelectorMecanicoOpciones;
}

interface IGroupSelectorQuimico {
  seleccionado: number;
  opcionesQuimico: IGroupSelectorQuimicoOpciones;
}

interface IGroupSelectorBiologico {
  seleccionado: number;
  opcionesBiologico: IGroupSelectorBiologicoOpciones;
}

interface IGroupSelectorErgonomico {
  seleccionado: number;
  opcionesErgonomico: IGroupSelectorErgonomicoOpciones;
}

interface IGroupSelectorPsicosocial {
  seleccionado: number;
  opcionesPsicosocial: IGroupSelectorPsicosocialOpciones;
}

interface IGroupSelectors {
  fisico: IGroupSelectorFisico;
  mecanico: IGroupSelectorMecanico;
  quimico: IGroupSelectorQuimico;
  biologico: IGroupSelectorBiologico;
  ergonomico: IGroupSelectorErgonomico;
  psicosocial: IGroupSelectorPsicosocial;
}

export interface IGroupSelectorsRiesgo {
  id: number;
  allNo: boolean;
  negadas: string[];
  grupoSelectores: IGroupSelectors;
}

export const groupSelectorRiesgoInitial: IGroupSelectorsRiesgo = {
  id: -1,
  allNo: false,
  negadas: [],
  grupoSelectores: {
    fisico: {
      seleccionado: -1,
      opcionesFisico: {
        arrayTemperaturasAltas: [],
        arrayTemperaturasBajas: [],
        arrayRadiacionIonizante: [],
        arrayRadiacionNoIonizante: [],
        arrayVentilacion: [],
        arrayIluminacion: [],
        arrayRuido: [],
        arrayVibracion: [],
        arrayFluidoElectrico: [],
        arrayOtro: [],
      },
    },
    mecanico: {
      seleccionado: -1,
      opcionesMecanico: {
        arrayAtrapamiento: [],
        arrayAtropellamientosVehiculos: [],
        arrayCaidaObjetos: [],
        arrayCaidasMismoNivel: [],
        arrayCaidasDiferenteNivel: [],
        arrayContactoSuperficiesTrabajo: [],
        arrayChoques: [],
        arrayContactoElectrico: [],
        arrayCortes: [],
        arrayProyeccionFragmentos: [],
        arrayProyeccionFluidos: [],
        arrayPinchazos: [],
        arrayOtro: [],
      },
    },
    quimico: {
      seleccionado: -1,
      opcionesQuimico: {
        arrayAerosoles: [],
        arrayHumos: [],
        arrayGaseosos: [],
        arrayLiquidos: [],
        arraySolidos: [],
        arrayNeblinas: [],
        arrayPolvos: [],
        arrayVapores: [],
        arrayOtro: [],
      },
    },
    biologico: {
      seleccionado: -1,
      opcionesBiologico: {
        arrayAnimales: [],
        arrayBacterias: [],
        arrayHongos: [],
        arrayParasitos: [],
        arrayVectores: [],
        arrayVirus: [],
        arrayOtro: [],
      },
    },
    ergonomico: {
      seleccionado: -1,
      opcionesErgonomico: {
        arrayRepeticion: [],
        arrayPosturaIncomoda: [],
        arrayFuerzaExcesiva: [],
        arrayPosicionesEstacionarias: [],
        arrayPresionDirecta: [],
        arrayTemperaturasExtremas: [],
        arrayVibraciones: [],
        arrayExtresContacto: [],
        arrayOtro: [],
      },
    },
    psicosocial: {
      seleccionado: -1,
      opcionesPsicosocial: {
        arrayCargasTrabajoExcesivas: [],
        arrayFaltaControlTrabajo: [],
        arrayJornadasTrabajoSuperiores: [],
        arrayRotacionTurnos: [],
        arrayPeriodosCortosRecuperacion: [],
        arrayInterferenciaRelacion: [],
        arrayLiderazgoNegativo: [],
        arrayHostigamiento: [],
        arrayAcoso: [],
        arrayMalosTratos: [],
        arrayOtro: [],
      },
    },
  },
};

export interface IObjTabEmpresa {
  id: number;
  radioEmpresa: string | undefined;
  opcionConsultorio: ICatalogosEstandar;
  txtEmpresa: string;
  opcionOcupacion: ICatalogosEstandar;
  txtOcupacion: string;
  radioSindicalizado: string | undefined;
  fechaIngresoMes: string | undefined;
  fechaIngresoAnio: string | undefined;
  fechaEgresoMes: string | undefined;
  fechaEgresoAnio: string | undefined;
  checkTrabajoActual: boolean;
  guardadoOk: boolean;
}

export const tabEmpresaInitial: IObjTabEmpresa = {
  id: -1,
  radioEmpresa: undefined,
  opcionConsultorio: { id: 0, label: '' },
  txtEmpresa: '',
  opcionOcupacion: { id: 0, label: '' },
  txtOcupacion: '',
  radioSindicalizado: undefined,
  fechaIngresoMes: undefined,
  fechaIngresoAnio: undefined,
  fechaEgresoMes: undefined,
  fechaEgresoAnio: undefined,
  checkTrabajoActual: false,
  guardadoOk: false,
};

export interface IObjArrayAccidentesLaborales {
  id: number;
  fechaDia: string;
  fechaMes: string;
  fechaAnio: string;
  lugarAccidente: ICatalogosEstandar;
  turnoAccidente: ICatalogosEstandar;
  radioCausaAccidente: string | undefined;
  tipoCausaAccidente: ICatalogosEstandar;
  txtCausaAccidente: string;
  radioNaturalezaLesion: string | undefined;
  tipoNaturalezaLesion: ICatalogosEstandar;
  txtNaturalezaLesion: string;
  segmentoLesionado: ICatalogosEstandar;
  ubicacionLesion: ICatalogosEstandar;
  gravedad: ICatalogosEstandar;
  porcentajeIncapacidad: ICatalogosEstandar;
  radioEncuestaNOM035: string | undefined;
  radioAmeritaSeguimiento: string | undefined;
  tipoRiesgo: ICatalogosEstandar;
  radioDiscapacidad: string | undefined;
  diasIncapacidad: number | undefined;
  comentarios: string;
}

export const arrayAccidentesLaboralesInitial: IObjArrayAccidentesLaborales = {
  id: -1,
  fechaDia: '',
  fechaMes: '',
  fechaAnio: '',
  lugarAccidente: { id: 0, label: '' },
  turnoAccidente: { id: 0, label: '' },
  radioCausaAccidente: undefined,
  tipoCausaAccidente: { id: 0, label: '' },
  txtCausaAccidente: '',
  radioNaturalezaLesion: undefined,
  tipoNaturalezaLesion: { id: 0, label: '' },
  txtNaturalezaLesion: '',
  segmentoLesionado: { id: 0, label: '' },
  ubicacionLesion: { id: 0, label: '' },
  gravedad: { id: 0, label: '' },
  porcentajeIncapacidad: { id: 0, label: '' },
  radioEncuestaNOM035: '',
  radioAmeritaSeguimiento: '',
  tipoRiesgo: { id: 0, label: '' },
  radioDiscapacidad: undefined,
  diasIncapacidad: undefined,
  comentarios: '',
};

export interface IObjLaboralAccidente {
  idLaboralAccidente: number;
  radioLaboral: string | undefined;
  arrayAccidentesLaborales: IObjArrayAccidentesLaborales[];
}

export const laboralAccidenteInitial: IObjLaboralAccidente = {
  idLaboralAccidente: -1,
  radioLaboral: undefined,
  arrayAccidentesLaborales: [],
};

export interface IAntecedenteLaboral {
  objTabEmpresa: IObjTabEmpresa;
  objExposicionRiesgo: IGroupSelectorsRiesgo;
  objLaboral: IObjLaboralAccidente;
}

export interface IObjLaboral {
  id: number;
  sinAntecedentes: boolean;
  guardadoOk: boolean;
}

export const laboralInitial: IObjLaboral = {
  id: -1,
  sinAntecedentes: false,
  guardadoOk: false,
};
