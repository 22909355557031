import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInputProps } from './type';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { CircleX, CircleCheckBig } from 'lucide-react';

export const NumberInput: FC<NumberInputProps> = ({
  index,
  name,
  containerClass = '',
  label: externalLabel,
  floatingLabel,
  isRequired: isRequiredExternal = false,
  placeholder: externalPlaceholder,
  helperText,
  fixedValue,
  step = 1,
  min = 0,
  max,
  strictMax = false,
  decimals = 3,
  isCurrency,
  isInteger = false,
  addDot = false,
  labelAligmet = 'top',
  setValue,
  value: externalValue,
  disableOption,
  validacion,
  readOnly,
  // modal,
  // onClick,
  // action,
  disabled = false,
  onValidationChange,
  hideValidationIcon = false,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [value, setInternalValue] = useState<string>('');
  const [isRequired, setIsRequired] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [label, setLabel] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [isString, setIsString] = useState(false);
  const isFloating = isFocused || value !== null;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isWideScreen = useBreakPoint();
  const decimalRegex = new RegExp(`^\\d*(\\.\\d{0,${decimals}})?$`);

  useEffect(() => {
    setIsRequired(isRequiredExternal);
  }, [isRequiredExternal]);

  useEffect(() => {
    setIsString(typeof externalValue === 'string');
    setInternalValue(
      externalValue !== null && externalValue !== undefined ? String(externalValue) : '',
    );
  }, [externalValue]);

  useEffect(() => {
    onValidationChange?.({
      name,
      hasError: (isRequired && value === null) || Number(value) < min || errorMessage.length > 0,
      isMissing: isRequired && value === null,
      index,
    });
  }, [isRequired, value, errorMessage]);

  useEffect(() => {
    if (floatingLabel) {
      setLabel('');
    } else {
      setLabel(externalLabel === undefined ? t(name) : externalLabel);
    }
  }, [floatingLabel, externalLabel]);

  useEffect(() => {
    if (externalPlaceholder) {
      setPlaceholder(`${externalPlaceholder}${isRequired ? '*' : ''}`);
    } else if (label.length > 0) {
      setPlaceholder(`${label}${isRequired ? '*' : ''}`);
    }
  }, [label, externalPlaceholder, isRequired]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  // const handleInputClick = () => {
  //   if (modal && onClick && value !== null) {
  //     onClick(value as number);
  //   }
  //   if (action) {
  //     action();
  //   }
  // };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    let numericValue = Number(value);
    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault();
      if (value === '') {
        numericValue = min;
      } else if (
        (e.key === 'ArrowDown' && numericValue > min) ||
        (e.key === 'ArrowUp' && (max === undefined || numericValue < max))
      ) {
        numericValue = numericValue + step * (e.key === 'ArrowDown' ? -1 : 1);
      }
      setValue({
        name,
        value: isString ? String(numericValue) : numericValue,
      });
      setInternalValue(String(numericValue));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;

    let newValue = event.target.value;

    if (newValue.length > 0) {
      if (
        (isInteger && !/^\d*$/.test(newValue)) ||
        (!isInteger && !decimalRegex.test(newValue)) ||
        (strictMax && max !== undefined && Number(newValue) > max)
      ) {
        return;
      }
      if (addDot) {
        if (value.includes('.')) {
          if (value.slice(-1) === '.' && value.length > newValue.length) {
            newValue = newValue.slice(0, -1);
          }
        } else if (newValue === '0' || (max && Number(newValue) * 10 > max)) {
          newValue = `${newValue}.`;
        }
      }
      let error = '';
      if (max !== undefined && Number(newValue) > max) {
        error = `${t('error_valor_maximo')} ${max}`;
      } else if (validacion) {
        error = validacion(newValue);
      }
      setErrorMessage(error);
    }
    if (addDot && newValue[0] === '.') {
      newValue = `0${newValue}`;
    }

    const numericValue = newValue.length > 0 ? Number(newValue) : null;
    setValue({
      name,
      value: isString ? newValue : numericValue,
    });
    setInternalValue(newValue);
    // if (onClick && numericValue !== null) {
    //   onClick(numericValue);
    // }
  };

  const handleBlur = () => {
    if (value.slice(-1) === '.') {
      const newValue = value.replace('.', '');
      setValue({
        name,
        value: isString ? newValue : Number(newValue),
      });
      setInternalValue(newValue);
    }
    if (value.length > 0 && Number(value) < min) {
      setErrorMessage(`${t('error_valor_minimo')} ${min}`);
    }
    setIsFocused(false);
    if (isRequired && value === null) {
      setErrorMessage(t('error_campo_en_blanco'));
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isWideScreen) {
      setTimeout(() => {
        event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300);
    }
    setIsFocused(true);
  };

  const handleDisableChange = () => {
    const newState = !disableOption?.value;
    setIsDisabled(newState);
    setErrorMessage('');
    setInternalValue('');
    setIsRequired(isRequiredExternal && !newState);
    if (disableOption?.name) {
      setValue?.({ name: disableOption.name, value: newState });
    }
    if (newState) {
      setValue({ name, value: '' });
    }
  };

  const renderDisableCheckbox = () => (
    <label className="flex items-center text-sm text-gray-600 whitespace-nowrap ml-4">
      <input
        type="checkbox"
        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
        checked={isDisabled}
        onChange={handleDisableChange}
      />
      {disableOption?.label}
    </label>
  );

  return (
    <div className={`w-full h-fit my-[10px] ${containerClass}`}>
      <div className="">
        {labelAligmet === 'top' ? (
          <div className="flex items-center justify-between mb-2">
            {label && (
              <label
                htmlFor={name}
                className={`block font-medium text-base ${
                  errorMessage ? 'text-red-500' : 'text-gray-700'
                } ${isDisabled ? 'text-gray-500' : ''}`}
              >
                {label} {isRequired && <span className="text-red-500">*</span>}
              </label>
            )}
            {disableOption?.position === 'right' && renderDisableCheckbox()}
          </div>
        ) : null}

        <div className="flex items-center gap-4">
          <div className="flex-1 relative">
            {floatingLabel && (
              <label
                onClick={() => inputRef.current?.focus()}
                className={`absolute left-3 px-1 rounded-3xl cursor-text transition-all ${
                  isFocused || value
                    ? 'text-gray-600 -top-2 text-xs'
                    : 'text-gray-400 top-[15px] text-sm'
                } bg-[linear-gradient(to_top,rgba(255,255,255,1)_60%,rgba(255,255,255,0)_100%)]`}
              >
                {`${floatingLabel}${isRequired ? '*' : ''}`}
              </label>
            )}
            <input
              ref={inputRef}
              type="text"
              inputMode={isInteger ? 'numeric' : 'decimal'}
              id={name}
              name={name}
              pattern="[0-9]*(.[0-9]*)?"
              placeholder={labelAligmet === 'border' && !isFloating ? '' : placeholder}
              value={value}
              onChange={handleChange}
              // onClick={handleInputClick}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              disabled={isDisabled}
              readOnly={readOnly}
              step={step}
              min={min}
              max={max}
              // pattern={decimals ? `^\d*(\.\d{0,${decimals}})?$` : undefined}
              className={`w-full h-[48px] px-3 text-base ${fixedValue ? 'pr-16' : 'pr-3'} ${
                isCurrency ? 'pl-7' : ''
              } rounded-md border focus:outline-none transition
                ${
                  isDisabled
                    ? 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed'
                    : readOnly
                      ? 'bg-gray-50 cursor-pointer hover:bg-gray-100 border-gray-300'
                      : errorMessage
                        ? 'border-red-500 ring-2 ring-red-200'
                        : `${
                            esPaciente && value.length > 0 ? 'border-green-300' : 'border-gray-300'
                          } focus:ring-2 focus:ring-blue-500`
                }`}
            />
            {labelAligmet === 'border' && label && (
              <label
                htmlFor={name}
                className={`absolute text-sm transition-all duration-200
                  ${
                    isFloating
                      ? `-top-2.5 left-2 bg-white px-1 ${
                          errorMessage ? 'text-red-500' : 'text-blue-500'
                        }`
                      : 'top-[50%] -translate-y-[50%] left-3 text-gray-500'
                  }
                  ${isDisabled ? 'text-gray-400' : ''}`}
              >
                {label} {isRequired && <span className="text-red-500">*</span>}
              </label>
            )}
            {isCurrency && (
              <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">$</span>
            )}
            {fixedValue && (
              <span
                className={`absolute right-3 top-1/2 -translate-y-1/2 text-sm ${
                  isDisabled ? 'text-gray-400' : 'text-gray-500'
                }`}
              >
                {fixedValue}
              </span>
            )}
            {esPaciente && !hideValidationIcon && (
              <div className="w-5 h-5 absolute bg-white -right-[6px] -top-[6px] rounded-xl">
                {value.length > 0 ? (
                  <CircleCheckBig size={20} color="green" />
                ) : (
                  <CircleX size={20} color="gray" />
                )}
              </div>
            )}
          </div>
          {disableOption?.position === 'after' && renderDisableCheckbox()}
        </div>

        {(errorMessage || helperText) && (
          <p className={`text-sm mt-1 ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
            {errorMessage || helperText}
          </p>
        )}
      </div>
    </div>
  );
};

export default NumberInput;
