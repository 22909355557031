export function getUser() {
  const userStr = sessionStorage.getItem('user');
  if (userStr) {
    return userStr;
  }
  return null;
}

export function getSessionKeyBack() {
  return sessionStorage.getItem('keyBack') || '';
}

export function setSessionKeyBack(keyBack: string) {
  sessionStorage.setItem('keyBack', keyBack);
}

export function removeSessionKeyBack() {
  sessionStorage.removeItem('keyBack');
}

export function getSessionIP() {
  return sessionStorage.getItem('ip') || '';
}

export function setSessionIP(ip: string) {
  sessionStorage.setItem('ip', ip);
}

export function setUser(user: string) {
  sessionStorage.setItem('user', user);
}

export function getToken() {
  return sessionStorage.getItem('token') || '';
}

export function setToken(token: string) {
  sessionStorage.setItem('token', token);
}

export function getIdConsultorio() {
  return parseInt(sessionStorage.getItem('idConsultorio') || '0', 10);
}

export function setIdConsultorio(idConsultorio: number) {
  sessionStorage.setItem('idConsultorio', idConsultorio.toString());
}

export function getIdOrganizacion() {
  return parseInt(sessionStorage.getItem('idOrganizacion') || '0', 10);
}

export function setIdOrganizacion(idOrganizacion: number) {
  sessionStorage.setItem('idOrganizacion', idOrganizacion.toString());
}

export function getEmailMe() {
  return sessionStorage.getItem('emailMe') || '';
}

export function setEmailMe(email: string) {
  sessionStorage.setItem('emailMe', email);
}

export function setPaciente(idPaciente: number) {
  sessionStorage.setItem('idPaciente', idPaciente.toString());
}

export function getPaciente() {
  return parseInt(sessionStorage.getItem('idPaciente') || '0', 10);
}

export function setUserSession(user: string, token: string, rol: string) {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', user);
  sessionStorage.setItem('rol', rol);
}

export function getRol() {
  return sessionStorage.getItem('rol') || '';
}

export function setRol(rol: string) {
  sessionStorage.setItem('rol', rol);
}

export function removePaciente() {
  sessionStorage.removeItem('nombre');
  sessionStorage.removeItem('sexo');
  sessionStorage.removeItem('dia');
  sessionStorage.removeItem('mes');
  sessionStorage.removeItem('anio');
  sessionStorage.removeItem('idPaciente');
}

export function removeUserSession() {
  sessionStorage.removeItem('keyBack');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('rol');
  sessionStorage.removeItem('emailMe');
  sessionStorage.removeItem('nombre');
  sessionStorage.removeItem('sexo');
  sessionStorage.removeItem('dia');
  sessionStorage.removeItem('mes');
  sessionStorage.removeItem('anio');
  sessionStorage.removeItem('idPaciente');
  sessionStorage.removeItem('idConsultorio');
  sessionStorage.removeItem('idOrganizacion');
}
