import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, Button, DialogTitle, Slide, SlideProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import ViewRespuestaBreve from '@containers/Builder/Scaffold/RespuestaBreve/view';
import ViewParrafo from '@containers/Builder/Scaffold/Parrafo/view';
import ViewOpcionMultiple from '@containers/Builder/Scaffold/OpcionMultiple/view';
import ViewCasillasVerificacion from '@containers/Builder/Scaffold/CasillasVerificacion/view';
import ViewListaDesplegable from '@containers/Builder/Scaffold/ListaDesplegable/view';
import ViewEscalaLineal from '@containers/Builder/Scaffold/EscalaLineal/view';
import ViewFecha from '@containers/Builder/Scaffold/Fecha/view';
import ViewHora from '@containers/Builder/Scaffold/Hora/view';
import ViewTabla from '@containers/Builder/Scaffold/Tabla/view';
import { capitalize } from '@common/functions';
import IAlertDialogProps from './types';

const Transition = React.forwardRef<HTMLDivElement, SlideProps>(({ children, ...props }, ref) => (
  <Slide direction="up" ref={ref} {...props}>
    {children as React.ReactElement}
  </Slide>
));

/** FORMATO:
 * 1 - RESPUESTA BREVE
 * 2 - PARRAFO
 * 3 - OPCION MULTIPLE
 * 4 - CASILLAS DE VERIFICACIÓN
 * 5 - LISTA DESPLEGABLE
 * 6 - ESCALA LINEAL
 * 7 - TABLA
 * 8 - FECHA
 * 9 - HORA
 */

function ModalComponet({
  open,
  titulo,
  constructorComponente,
  formato,
  callBackClose,
}: IAlertDialogProps) {
  const { t } = useTranslation();

  return titulo ? (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="alert-modal-componente"
      aria-describedby="alert-dialog-componente"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <div className="flex justify-between items-center">
          <div className="font-bold text-sm">
            {`${capitalize(titulo)} - Tipo: ${t(constructorComponente.componente.toLowerCase())}`}
          </div>
          <Button onClick={callBackClose} endIcon={<CloseIcon />}>
            {t('cerrar')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        {formato === 1 && <ViewRespuestaBreve componenteRespuesta={constructorComponente} />}
        {formato === 2 && <ViewParrafo componenteParrafo={constructorComponente} />}
        {formato === 3 && <ViewOpcionMultiple componenteCasillasOpciones={constructorComponente} />}
        {formato === 4 && (
          <ViewCasillasVerificacion componenteCasillasOpciones={constructorComponente} />
        )}
        {formato === 5 && (
          <ViewListaDesplegable componenteListaDesplegable={constructorComponente} />
        )}
        {formato === 6 && <ViewEscalaLineal componenteEscalaLineal={constructorComponente} />}
        {formato === 7 && <ViewTabla componenteTabla={constructorComponente} />}
        {formato === 8 && <ViewFecha componenteFecha={constructorComponente} />}
        {formato === 9 && <ViewHora componenteHora={constructorComponente} />}
      </DialogContent>
    </Dialog>
  ) : null;
}

export default ModalComponet;
