/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { edadCalculada } from '@common/functions';
import { setExploracionFisica, setNuevoUsuario } from '@actions/paciente/actions';
import { setRutaPerfil, setRutaPrincipal } from '@actions/rutasExpediente/actions';
import { sendExploracionFisica, sendExploracionFisicaPaciente } from '@utils/sendInfo';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { RootState } from 'src/store';
import { getSitioMedicion } from '@utils/getCatalogos';
import { edadInitial, ICatalogosEstandar } from '@common/types';
import { IExploracionFisica, arrAreas, exploracionFisicaInitial } from './types';
import TablaExploracion from './TablaExploracion';
import { ModalForm } from '@components/modals/ModalForm';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import TextField from '@components/FormElemntsLibrary/TextField';
import FormSections from '@components/FormSections';
import { IFormSectionComponent, IFormSectionGroup } from '@components/FormSections/type';

function ExploracionFisica() {
  const dispatch = useDispatch();
  const { idMedico, idUsuario, esPaciente } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    nuevo,
    ocultarCampos,
    expediente: {
      perfil: { datosPersonales },
      exploracionFisica,
    },
  } = useSelector((state: RootState) => state.Paciente);
  const { idPaciente, sexo } = useSelector((state: RootState) => state.BasicosPaciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [imc, setImc] = useState<string>('');
  const [presion, setPresion] = useState<{ pMedia: string; pPulso: string }>({
    pMedia: '',
    pPulso: '',
  });
  const [catSitioMedicion, setCatSitioMedicion] = useState<ICatalogosEstandar[]>([]);
  const [exploracionFisicaTemp, setExploracionFisicaTemp] =
    useState<IExploracionFisica>(exploracionFisicaInitial);

  const edad =
    datosPersonales.fechaNac.dia && datosPersonales.fechaNac.mes && datosPersonales.fechaNac.anio
      ? edadCalculada(
          datosPersonales.fechaNac.anio,
          datosPersonales.fechaNac.mes,
          datosPersonales.fechaNac.dia,
        )
      : edadInitial;

  useEffect(() => {
    if (catSitioMedicion.length === 0) {
      getSitioMedicion().then((result: ICatalogosEstandar[]) => {
        setCatSitioMedicion(result);
      });
    }
    if (exploracionFisica.loaded && !exploracionFisicaTemp.loaded) {
      setExploracionFisicaTemp(exploracionFisica);
      setPresion({
        pMedia: exploracionFisica.pMedia,
        pPulso: exploracionFisica.pPulso,
      });
      setImc(exploracionFisica.imc);
    }
  }, [exploracionFisica]);
  // calculo de presiones
  useEffect(() => {
    if (
      exploracionFisicaTemp.sistolica?.length !== 0 &&
      exploracionFisicaTemp.diastolica?.length !== 0
    ) {
      const pMedia =
        (parseInt(exploracionFisicaTemp.sistolica, 10) +
          parseInt(exploracionFisicaTemp.diastolica, 10) * 2) /
        3;
      const pPulso =
        parseInt(exploracionFisicaTemp.sistolica, 10) -
        parseInt(exploracionFisicaTemp.diastolica, 10);
      setPresion({ pMedia: pMedia.toFixed(1), pPulso: pPulso > 0 ? pPulso.toFixed(1) : '' });
    }
  }, [exploracionFisicaTemp.sistolica, exploracionFisicaTemp.diastolica]);
  // calculo de IMC
  useEffect(() => {
    let imc = -1;
    if (
      exploracionFisicaTemp.peso.length > 0 &&
      exploracionFisicaTemp.altura.length > 0 &&
      edad.edad >= 5 &&
      edad.periodo === 'anios'
    ) {
      let peso = parseFloat(exploracionFisicaTemp.peso);
      let altura = parseFloat(exploracionFisicaTemp.altura);
      imc = esPaciente ? peso / (altura * altura) : peso / ((altura / 100) * (altura / 100));
    }
    setImc(imc >= 0 ? imc.toFixed(1) : '');
  }, [
    esPaciente,
    edad.edad,
    edad.periodo,
    exploracionFisicaTemp.peso,
    exploracionFisicaTemp.altura,
  ]);

  const handleInputChange = (e: { name: string; value: any }) =>
    setExploracionFisicaTemp((prevData) => ({ ...prevData, [e.name]: e.value }));

  const handleSubmitForm = () => {
    const datosAEnviar = {
      PESO:
        exploracionFisicaTemp.peso && exploracionFisicaTemp.peso.length === 0
          ? 999
          : exploracionFisicaTemp.peso,
      ALTURA:
        exploracionFisicaTemp.altura && exploracionFisicaTemp.altura.length === 0
          ? 999
          : exploracionFisicaTemp.altura,
      IMC:
        exploracionFisicaTemp.imc && exploracionFisicaTemp.imc.length === 0
          ? '-1'
          : exploracionFisicaTemp.imc,
      PARTERIAL_SISTOLICA:
        exploracionFisicaTemp.sistolica && exploracionFisicaTemp.sistolica.length === 0
          ? 0
          : exploracionFisicaTemp.sistolica,
      PARTERIAL_MEDIA: exploracionFisicaTemp.pMedia,
      PARTERIAL_PULSO: exploracionFisicaTemp.pPulso,
      PARTERIAL_SEL: exploracionFisicaTemp.pArterialRadio,
      PARTERIAL_DIASTOLICA:
        exploracionFisicaTemp.diastolica && exploracionFisicaTemp.diastolica.length === 0
          ? 0
          : exploracionFisicaTemp.diastolica,
      PER_CINTURA:
        exploracionFisicaTemp.perCintura && exploracionFisicaTemp.perCintura.length === 0
          ? 999
          : exploracionFisicaTemp.perCintura,
    };
    dispatch(setLoading(true));
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    const funcSendExploracionFisica = esPaciente
      ? sendExploracionFisicaPaciente({ ...datosAEnviar, ID_USUARIO: idUsuario, NUEVO: nuevo })
      : sendExploracionFisica({
          ...datosAEnviar,
          ID_PACIENTE: idPaciente,
          ID_MEDICO: idMedico,
          ID_CONSULTA: idConsulta,
          F_CARDIACA:
            exploracionFisicaTemp.fCardiaca && exploracionFisicaTemp.fCardiaca.length === 0
              ? 0
              : exploracionFisicaTemp.fCardiaca,
          F_CARDIACASEL: exploracionFisicaTemp.fCardiacaRadio,
          F_RESPIRATORIA:
            exploracionFisicaTemp.fRespiratoria && exploracionFisicaTemp.fRespiratoria.length === 0
              ? 0
              : exploracionFisicaTemp.fRespiratoria,
          SAT_OXIGENO:
            exploracionFisicaTemp.satOxigeno && exploracionFisicaTemp.satOxigeno.length === 0
              ? 0
              : exploracionFisicaTemp.satOxigeno,
          TEMPERATURA:
            exploracionFisicaTemp.temperatura && exploracionFisicaTemp.temperatura.length === 0
              ? 0
              : exploracionFisicaTemp.temperatura,
          TEMPERATURASEL_ID: exploracionFisicaTemp.sitioMedicion.id,
          CONTENIDO_TABLA: exploracionFisicaTemp.contenidoTabla,
          idMedico,
          idConsultorio,
        });
    funcSendExploracionFisica
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setExploracionFisica({
              ...exploracionFisicaTemp,
              pMedia: presion.pMedia,
              pPulso: presion.pPulso,
              imc,
              loaded: true,
            }),
          );
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          if (esPaciente) {
            dispatch(setNuevoUsuario(false));
            dispatch(setRutaPerfil('main'));
            dispatch(setRutaPrincipal('resumen-de-salud'));
          }
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = 'Campo Repetido';
          } else {
            mensaje = result.msg;
          }
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${error.toString()}`,
          }),
        );
        dispatch(setLoading(false));
      });
  };

  const groups: IFormSectionGroup[] = [
    { name: 'antecedentes-ef-signos', hide: ocultarCampos },
    { name: 'antecedentes-ef-soma' },
  ];

  const components: IFormSectionComponent[] = [
    {
      group: 'antecedentes-ef-signos',
      title: t('antecedentes-ef-temperatura'),
      hide: esPaciente,
      component: (
        <div className="grid grid-cols-1 md:grid-cols-3 md:w-1/2 gap-x-4">
          <SelectInput
            containerClass="md:col-span-2 mt-0"
            name="sitioMedicion"
            label=""
            floatingLabel={t('antecedentes-ef-sitio')}
            options={catSitioMedicion}
            value={exploracionFisicaTemp.sitioMedicion.id}
            setValue={handleInputChange}
            isNumeric={false}
            sendObject
          />
          <NumberInput
            containerClass="mt-0"
            name="temperatura"
            label=""
            floatingLabel={esPaciente ? t('antecedentes-ef-temperatura') : 'T'}
            value={exploracionFisicaTemp.temperatura}
            fixedValue={t('antecedentes-ef-gc')}
            helperText="Entre 30 y 44"
            isString
            min={30}
            max={44}
            decimals={2}
            setValue={handleInputChange}
          />
        </div>
      ),
    },
    {
      group: 'antecedentes-ef-signos',
      title: t('antecedentes-ef-frecuenciac'),
      hide: esPaciente,
      component: (
        <div className="grid grid-cols-1 md:grid-cols-3 md:w-1/2 gap-x-4">
          <CheckboxGroup
            containerClass="md:col-span-2"
            name="fCardiacaRadio"
            label=""
            options={[
              { value: 'rRegular', label: t('antecedentes-ef-ritmor') },
              { value: 'rIrregular', label: t('antecedentes-ef-ritmoi') },
            ]}
            distribution="vertical-flex"
            value={exploracionFisicaTemp.fCardiacaRadio}
            setValue={handleInputChange}
          />
          <NumberInput
            containerClass="mt-0"
            name="fCardiaca"
            label=""
            value={exploracionFisicaTemp.fCardiaca}
            isInteger
            isString
            fixedValue={t('antecedentes-ef-l_m')}
            helperText={t('antecedentes-ef-frecuenciac-err')}
            min={40}
            max={220}
            setValue={handleInputChange}
          />
        </div>
      ),
    },
    {
      group: 'antecedentes-ef-signos',
      component: (
        <div className="grid grid-cols-1 md:grid-cols-6 gap-x-4">
          <CheckboxGroup
            containerClass="md:col-span-2"
            name="pArterialRadio"
            label={t('antecedentes-ef-presion')}
            options={['sentado', 'decubito', 'pie'].map((op) => ({
              value: op,
              label: t(`antecedentes-ef-${op}`),
            }))}
            distribution="vertical-flex"
            value={exploracionFisicaTemp.pArterialRadio}
            setValue={handleInputChange}
          />
          <NumberInput
            containerClass="md:mt-8"
            name="sistolica"
            label=""
            value={exploracionFisicaTemp.sistolica}
            floatingLabel={t('antecedentes-ef-sistolica')}
            isInteger
            isString
            fixedValue={t('antecedentes-ef-mmhg')}
            helperText={t('antecedentes-ef-sistolica-err')}
            min={50}
            max={300}
            setValue={handleInputChange}
          />
          <NumberInput
            containerClass="md:mt-8"
            name="diastolica"
            label=""
            value={exploracionFisicaTemp.diastolica}
            floatingLabel={t('antecedentes-ef-diastolica')}
            isInteger
            isString
            fixedValue={t('antecedentes-ef-mmhg')}
            helperText={t('antecedentes-ef-diastolica-err')}
            min={20}
            max={200}
            setValue={handleInputChange}
          />
          {!esPaciente && (
            <>
              <TextField
                containerClass="pt-0 md:pt-7"
                name="pMedia"
                label=""
                floatingLabel={t('antecedentes-ef-presiona')}
                value={presion.pMedia}
                adornment={{
                  text: t('antecedentes-ef-mmhg'),
                  position: 'end',
                }}
              />
              <TextField
                containerClass="pt-0 md:pt-7"
                name="pMedia"
                label=""
                floatingLabel={t('antecedentes-ef-presionp')}
                value={presion.pPulso}
                adornment={{
                  text: t('antecedentes-ef-mmhg'),
                  position: 'end',
                }}
              />
            </>
          )}
        </div>
      ),
    },
    {
      group: 'antecedentes-ef-signos',
      title: t('antecedentes-ef-frecuenciar'),
      hide: esPaciente,
      component: (
        <div className="md:w-2/6">
          <NumberInput
            containerClass="mt-0"
            name="fRespiratoria"
            label=""
            value={exploracionFisicaTemp.fRespiratoria}
            isInteger
            isString
            fixedValue={t('antecedentes-ef-respiraciones')}
            helperText={t('antecedentes-ef-frecuenciar-err')}
            min={10}
            max={99}
            setValue={handleInputChange}
          />
        </div>
      ),
    },
    {
      group: 'antecedentes-ef-signos',
      title: t('antecedentes-ef-sp02-label'),
      hide: esPaciente,
      component: (
        <div className="md:w-2/6">
          <NumberInput
            containerClass="mt-0"
            name="satOxigeno"
            label=""
            value={exploracionFisicaTemp.satOxigeno}
            placeholder={t('antecedentes-ef-sp02')}
            isInteger
            isString
            fixedValue={t('antecedentes-ef-%')}
            helperText={t('antecedentes-ef-sp02-err')}
            min={10}
            max={100}
            setValue={handleInputChange}
          />
        </div>
      ),
    },
    {
      group: 'antecedentes-ef-soma',
      component: (
        <div className="grid grid-cols-1 md:grid-cols-6 gap-x-4">
          <NumberInput
            containerClass="mt-0"
            name="peso"
            label=""
            value={exploracionFisica.peso}
            floatingLabel={t('antecedentes-ef-peso')}
            fixedValue={t('antecedentes-ef-kg')}
            helperText={t('antecedentes-ef-peso-err')}
            isString
            min={1}
            max={400}
            decimals={3}
            setValue={handleInputChange}
          />
          <NumberInput
            containerClass="mt-0"
            name="altura"
            label=""
            value={exploracionFisica.altura}
            floatingLabel={t('antecedentes-ef-altura')}
            isInteger={!esPaciente}
            isString
            fixedValue={t('antecedentes-ef-cm')}
            helperText={t('antecedentes-ef-altura-err')}
            step={esPaciente ? 0.1 : 1}
            min={esPaciente ? 0.3 : 30}
            max={esPaciente ? 2.2 : 220}
            addDot={esPaciente}
            decimals={esPaciente ? 2 : undefined}
            setValue={handleInputChange}
          />
          {!esPaciente && (
            <TextField
              containerClass="mt-2"
              name="imc"
              label=""
              floatingLabel={t('antecedentes-ef-imc')}
              value={imc}
              adornment={{
                text: t('antecedentes-ef-km'),
                position: 'end',
              }}
            />
          )}
          {!ocultarCampos && (
            <NumberInput
              containerClass="md:col-span-2 mt-0"
              name="perCintura"
              label=""
              value={exploracionFisicaTemp.perCintura}
              floatingLabel={t('antecedentes-ef-perimetro')}
              isInteger
              isString
              fixedValue={t('antecedentes-ef-cm')}
              helperText={t('antecedentes-ef-perimetro-err')}
              min={20}
              max={300}
              setValue={handleInputChange}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <ModalForm
      title={t('exploracion_fisica')}
      id="exploracion-fisica-form"
      submitForm={handleSubmitForm}
      backAction={() => dispatch(setRutaPrincipal('main'))}
      noXMargin
    >
      <FormSections groups={groups} components={components} />
      {/* Tabla "Detalles por área" */}
      {!esPaciente && (
        <div className="px-4">
          {!!arrAreas.length && (
            <>
              <div className="text-left">
                <h2 className="text-blue-800 font-normal m-0 py-4">
                  {t('antecedentes-ef-detalle')}
                </h2>
              </div>
              <TablaExploracion
                contenidoTabla={exploracionFisicaTemp.contenidoTabla}
                setExploracionFisica={setExploracionFisicaTemp}
                sexo={sexo}
              />
            </>
          )}
        </div>
      )}
    </ModalForm>
  );
}

export default ExploracionFisica;
