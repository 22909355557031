import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/bootstrap.css';
import {
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import dayjs from 'dayjs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
// import LockIcon from '@material-ui/icons/Lock';
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import {
  docIdLongitudMax,
  validacionDocId,
  validacionDocIdOnChange,
  validacionCualquierLetraSinNumero,
  validacionSoloNumeros,
  validacionTelefono,
  validacionEmail,
} from '@constants/validaciones';
import { RootState } from 'src/store';
import { getMiOrganizacionAltasBajasUsuario } from '@utils/getCatalogos';
import { obtenerURLArchivo } from '@utils/getFiles';
import { setSnackComplete } from '@actions/snackbar/types';
import { setLoading } from '@actions/loading/actions';
import { validarFecha, validarFechaEsAnterior } from '@common/functions';
import {
  IFechaField,
  ITextFieldEstandar,
  ICountryPhoneInput,
  ICatStrEstandar,
} from '@common/types';
import { constDia, constMes, constAnio } from '@constants/fechas';
import DialogPassword from '@components/ModalDialogs/DialogPassword/index';
import TablaErrores from './TablaErrores';
import ModalUltimosCambios from './ModalUltimosCambios';
import { IObjError } from '../../types';
import { IPropsEquipoMedico, IPropsUltimosCambios, IRoleSelector } from './types';
import useStyles from './styles';

dayjs.locale('es');

function EquipoMedico(props: IPropsEquipoMedico) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    licenciasDisponibles,
    catNacionalidad,
    rolesPermisos,
    // disabledSave,
    setDisabledSave,
    setDisabledNext,
    csvArray,
    setCsvArray,
    errorArray,
    setErrorArray,
    stringInformacionCambios,
    setStringInformacionCambios,
  } = props;
  const { idMedico, pais } = useSelector((state: RootState) => state.Me);
  const { organizacion } = useSelector((state: RootState) => state.Organizaciones);
  const { idOrganizacion } = organizacion;
  const columns = [
    {
      Header: t('administrador_equipo_tabla_id'),
      accessor: 'posicion',
    },
    {
      Header: t('administrador_equipo_tabla_error'),
      accessor: 'error',
    },
  ];
  const [rolSelector, setRolSelector] = useState<IRoleSelector[]>([]);
  const [anyoFur] = useState<number[]>(constAnio);
  const [selObjArchivo, setSelObjArchivo] = useState<{ archivo: File } | null>(null);
  const [lastChanges, setLastChanges] = useState<IPropsUltimosCambios[]>([]);
  const [idRolesArray, setIdRolesArray] = useState<number[]>([1, 2, 6, 8]);
  const [selectorCarga, setSelectorCarga] = useState<'masiva' | 'individual'>('masiva');
  const [plataforma, setPlataforma] = useState<'' | 'windows' | 'otra'>('');
  const [showPlataformaDialog, setShowPlataformaDialog] = useState<boolean>(false);
  const [errorDataColumn, setErrorDataColumn] = useState<boolean>(false);
  const [readyToSave, setReadyToSave] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<any>({ helperText: '', error: false });
  const [docIdField, setDocIdField] = useState<ITextFieldEstandar>({
    helperText: '',
    error: false,
  });
  const [errorPass, setErrorPass] = useState({
    password: { error: false, helperText: '' },
    passwordRepetido: { error: false, helperText: '' },
  });
  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });
  const [objDatId, setObjDatId] = useState<any>({
    nombre: '',
    apellidoP: '',
    apellidoS: '',
    docId: '',
    sexo: '',
    fechaNac: {
      dia: '',
      mes: '',
      anio: '',
    },
    celular: {
      numero: '',
      codigo: '+52',
    },
    telefono: {
      numero: '',
      codigo: '+52',
    },
    email: '',
    emailLog: '',
    nacionalidad: {
      id: '',
      label: '',
    },
    id: 0,
    password: '',
    rol: {
      id: 0,
      label: '',
    },
  });
  const [contrasenia, setContrasenia] = useState<string>('');
  const [contraseniaRepetida, setContraseniaRepetida] = useState<string>('');
  const [verPassword, setVerPassword] = useState<boolean>(false);
  const [verPasswordRepetido, setVerPasswordRepetido] = useState<boolean>(false);

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setContrasenia(val);
  };
  const handleChangePasswordRepetido = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setContraseniaRepetida(val);
  };
  const handleClickVerPasswordRepetido = () => {
    setVerPasswordRepetido((prev) => !prev);
  };
  const handleClickVerPassword = () => {
    setVerPassword((prev) => !prev);
  };
  const handleFocusPassword = () => {
    setErrorPass({
      password: { error: false, helperText: '' },
      passwordRepetido: { error: false, helperText: '' },
    });
  };
  const handleMouseDownPasswordRepetido = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleBlurEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    if (!val.length || validacionEmail(val)) {
      setEmailError({ helperText: '', error: false });
    } else {
      setEmailError({ helperText: t('email_incorrecto'), error: true });
    }
  };
  const handleChangeSexo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as string;
    setObjDatId({ ...objDatId, sexo: val });
  };
  const handleChangeDate = (event: React.ChangeEvent<{ value: unknown }>, tipo: string) => {
    const dataUpd = { ...objDatId };
    const valAdd = event.target.value as string;
    if (tipo === 'dia') {
      dataUpd.fechaNac.dia = valAdd;
    } else if (tipo === 'mes') {
      dataUpd.fechaNac.mes = valAdd;
    } else if (tipo === 'anio') {
      dataUpd.fechaNac.anio = valAdd;
    }
    setObjDatId(dataUpd);
  };
  const handleChangeTxt = (event: React.ChangeEvent<HTMLInputElement>, tipo: string) => {
    const val = event.target.value as string;
    const dataUpd = { ...objDatId };
    if (tipo === 'nombre') {
      dataUpd.nombre = val;
    } else if (tipo === 'apellidoP') {
      dataUpd.apellidoP = val;
    } else if (tipo === 'apellidoS') {
      dataUpd.apellidoS = val;
    } else if (tipo === 'docId') {
      if (validacionDocIdOnChange(val, pais)) {
        setDocIdField({ helperText: '', error: false });
        dataUpd.docId = val;
      } else {
        setDocIdField({ helperText: t(`doc_id_incorrecto_${pais}`), error: true });
      }
    } else if (tipo === 'email') {
      dataUpd.email = val;
    }
    setObjDatId(dataUpd);
  };
  const handleChangeNacionalidad = (_event: any, newValue: ICatStrEstandar | null) => {
    if (newValue) {
      setObjDatId({ ...objDatId, nacionalidad: newValue });
    } else {
      setObjDatId({ ...objDatId, nacionalidad: { id: '', label: '' } });
    }
  };
  const handleChangeRol = (_event: any, newValue: IRoleSelector | null) => {
    if (newValue) {
      setObjDatId({ ...objDatId, rol: newValue });
    } else {
      setObjDatId({ ...objDatId, rol: { id: 0, label: '' } });
    }
  };
  const closePlataformaDialog = () => setShowPlataformaDialog(false);
  const processCSV = (str: any) => {
    // para romper la cadena es coma o una línea vertical
    const headers = str.slice(0, str.indexOf('\n')).split(/[,|;]/);
    let index = -1;
    if (pais === 'mx') {
      index = headers.indexOf('CURP');
    } else if (pais === 'uy') {
      index = headers.indexOf('CI');
    }
    if (index !== -1) {
      headers[index] = 'DOC_ID';
    }
    const rows = str.slice(str.indexOf('\n') + 1).split(/[\r\n|\n]/);
    const newArray = rows
      .map((rowValue: string) => {
        if (rowValue) {
          const valuesRow = rowValue.split(/[,|;]/);
          const eachObject = headers.reduce((obj: any, header: string, i: number) => {
            /* eslint-disable no-param-reassign */
            obj[`${header}`] = valuesRow[i];
            return obj;
          }, {});
          return eachObject;
        }
        return false;
      })
      .filter(Boolean);
    setCsvArray(newArray);
  };
  const readCsvFile = (archivo: File) => {
    setErrorArray([]);
    const file: any = archivo;
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e && e.target && e.target.result) {
        const text = e.target.result;
        processCSV(text);
      }
    };
    if (file) {
      reader.readAsText(file, plataforma === 'windows' ? 'windows-1252' : undefined);
    }
  };
  const seleccionarArchivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      setSelObjArchivo({ archivo: event.target.files[0] });
      readCsvFile(event.target.files[0]);
      event.target.value = '';
    } else {
      setSelObjArchivo(null);
    }
  };
  const downloadArchivo = (pathArchivo: string, registro: string) => {
    obtenerURLArchivo(null, null, null, null, pathArchivo, registro)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.url) {
          window.open(result.data.url, '_blank')?.focus();
        }
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `Ocurrio un error al obtener el archivo: ${err}`,
          }),
        );
      });
  };
  useEffect(() => {
    setPlataforma('');
  }, [organizacion]);
  useEffect(() => {
    const arrayRoles = [
      { id: 1, label: 'Médico (Medipraxi)' },
      { id: 2, label: 'Enfermer@ (Medipraxi)' },
      // { id: 3, label: 'Administrador (Medipraxi)' },
    ];
    if (rolesPermisos.length > 0) {
      const arrayIdValorRol: number[] = [1, 2, 6];
      rolesPermisos.forEach((valorRol) => {
        arrayIdValorRol.push(valorRol.id);
        arrayRoles.push({
          id: valorRol.id,
          label: valorRol.nombre,
        });
      });
      setIdRolesArray([...arrayIdValorRol]);
      setRolSelector([...arrayRoles]);
    }
  }, [rolesPermisos, organizacion]);
  useEffect(() => {
    if (csvArray.length) {
      const objError: IObjError = {
        posicion: 0,
        error: '',
      };
      const errorNewArray = [...errorArray];
      const propertyName = [
        'NOMBRE',
        'PRIMER_APELLIDO',
        'SEGUNDO_APELLIDO',
        'NACIMIENTO_DIA',
        'NACIMIENTO_MES',
        'NACIMIENTO_ANIO',
        'LADA_CELULAR',
        'CELULAR',
        'LADA_TELEFONO',
        'TELEFONO',
        'CORREO_ELECTRONICO',
        'DOC_ID',
        'ROL',
        'SEXO',
        'CONTRASENIA',
      ];
      const checkNameProperties = csvArray[0] || {};
      if (
        propertyName.every((elem) => Object.getOwnPropertyNames(checkNameProperties).includes(elem))
      ) {
        setErrorDataColumn(false);
        csvArray.forEach((valorCsv, inx) => {
          const errores = [];
          try {
            if (!valorCsv.NOMBRE.length || validacionCualquierLetraSinNumero(valorCsv.NOMBRE)) {
              errores.push('NOMBRE');
            }
            if (
              !valorCsv.PRIMER_APELLIDO.length ||
              validacionCualquierLetraSinNumero(valorCsv.PRIMER_APELLIDO)
            ) {
              errores.push('PRIMER APELLIDO');
            }
            if (
              valorCsv.SEGUNDO_APELLIDO.length &&
              validacionCualquierLetraSinNumero(valorCsv.SEGUNDO_APELLIDO)
            ) {
              errores.push('SEGUNDO APELLIDO');
            }
            if (!valorCsv.LADA_CELULAR.length || !validacionSoloNumeros(valorCsv.LADA_CELULAR)) {
              errores.push('LADA CELULAR');
            }
            if (!valorCsv.CELULAR.length || !validacionTelefono(valorCsv.CELULAR, pais)) {
              errores.push('CELULAR');
            }
            if (valorCsv.LADA_TELEFONO.length && !validacionSoloNumeros(valorCsv.LADA_TELEFONO)) {
              errores.push('LADA TELEFONO');
            }
            if (valorCsv.TELEFONO.length && !validacionTelefono(valorCsv.TELEFONO, pais)) {
              errores.push('TELEFONO');
            }
            if (
              !valorCsv.CORREO_ELECTRONICO.length ||
              !validacionEmail(valorCsv.CORREO_ELECTRONICO)
            ) {
              errores.push('CORREO ELECTRONICO');
            }
            if (!valorCsv.DOC_ID.length || !validacionDocId(valorCsv.DOC_ID, pais)) {
              errores.push(t(`doc_id_av_${pais}`));
            }
            if (
              !valorCsv.ROL.length ||
              idRolesArray.findIndex((idRol) => idRol === parseInt(valorCsv.ROL, 10)) === -1
            ) {
              errores.push('ROL');
            }
            if (!valorCsv.SEXO.length || !['H', 'M'].includes(valorCsv.SEXO.toUpperCase())) {
              errores.push('SEXO');
            }
            if (valorCsv.CONTRASENIA.length < 8) {
              errores.push('CONTRASEÑA');
            }
            if (
              !valorCsv.NACIMIENTO_DIA.length ||
              !valorCsv.NACIMIENTO_MES.length ||
              !valorCsv.NACIMIENTO_ANIO.length ||
              !validarFecha(
                valorCsv.NACIMIENTO_ANIO,
                valorCsv.NACIMIENTO_MES,
                valorCsv.NACIMIENTO_DIA,
              )
            ) {
              errores.push('FECHA NACIMIENTO');
            }
            if (!valorCsv.ALTAS_BAJAS.length || !['A', 'B'].includes(valorCsv.ALTAS_BAJAS)) {
              errores.push('ALTAS/BAJAS');
            }
            if (errores.length > 0) {
              objError.posicion = inx + 2;
              objError.error = errores.join(', ');
              errorNewArray.push({ ...objError });
            }
          } catch (e) {
            setErrorDataColumn(true);
          }
        });
        if (errorNewArray.length > 0) {
          setErrorArray(errorNewArray);
        }
      } else {
        setErrorDataColumn(true);
      }
    }
  }, [csvArray]);
  useEffect(() => {
    if (
      plataforma === '' ||
      errorDataColumn ||
      !selObjArchivo ||
      (selObjArchivo && errorArray.length > 0)
    ) {
      setDisabledSave(true);
      setReadyToSave(false);
    } else if (
      plataforma.length > 0 &&
      !errorDataColumn &&
      selObjArchivo &&
      errorArray.length === 0
    ) {
      setDisabledSave(false);
      setReadyToSave(true);
    }
    setDisabledNext(false);
  }, [plataforma, errorArray, selObjArchivo, errorDataColumn]);
  useEffect(() => {
    const rdia = objDatId.fechaNac.dia;
    const rmes = objDatId.fechaNac.mes;
    const ranio = objDatId.fechaNac.anio;
    if (rdia !== '' && rmes !== '' && ranio !== '') {
      const objF = { ...fechaField };
      objF.error = false;
      objF.helperText = '';
      if (!validarFecha(ranio, rmes, rdia) || !validarFechaEsAnterior(ranio, rmes, rdia)) {
        objF.error = true;
        objF.helperText = t('fecha_invalida');
      }
      setFechaField(objF);
    }
  }, [objDatId.fechaNac.dia, objDatId.fechaNac.mes, objDatId.fechaNac.anio]);

  return (
    <div className="p-2">
      <div className="border-solid border-2 border-gray-300 rounded-lg p-4">
        <ModalUltimosCambios
          open={openModal}
          callBackClose={() => setOpenModal(false)}
          lastChanges={lastChanges}
        />
        <div className="flex flex-col justify-center">
          <h1 className="text-blue-500 font-normal m-2 text-center">{t('administrador_equipo')}</h1>
        </div>
        <div className="grid grid-cols-6 gap-4">
          <div className="flex items-center">
            <span className="text-gray-500">{`- ${t('administrador_roles_medico_m')} `}</span>
          </div>
          <div className="flex items-start">
            <span className="text-gray-500">{`${t('administrador_equipo_idrol')}: 1`}</span>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-4">
          <div className="flex items-center">
            <span className="text-gray-500">{`- ${t('administrador_roles_enfermero_m')} `}</span>
          </div>
          <div className="flex items-start">
            <span className="text-gray-500">{`${t('administrador_equipo_idrol')}: 2`}</span>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-4">
          <div className="flex items-center">
            <span className="text-gray-500">
              {`- ${t('administrador_roles_administrativo_m')} `}
            </span>
          </div>
          <div className="flex items-start">
            <span className="text-gray-500">{`${t('administrador_equipo_idrol')}: 6`}</span>
          </div>
        </div>
        {rolesPermisos.length > 0 && (
          <>
            <hr />
            <h3 className="text-gray-500 font-normal my-2">
              {t('administrador_roles_perfiles_creados')}
            </h3>
            {rolesPermisos.map((valorRol) => (
              <>
                <div className="grid grid-cols-4 gap-4">
                  <div className="flex items-center">
                    <span className="text-gray-500">
                      {`- ${t('administrador_roles_nombre_rol')}: ${valorRol.nombre}`}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="text-gray-500">
                      {`${t('administrador_roles_tipo_rol')}: ${
                        valorRol.tipoRol.grupo === 'salud'
                          ? t('administrador_roles_salud')
                          : t('administrador_roles_administrativo')
                      }`}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="text-gray-500">
                      {`${t('administrador_roles_id_rol')}: ${valorRol.id}`}
                    </span>
                  </div>
                </div>
              </>
            ))}
          </>
        )}
        <hr />
        <h2 className="text-blue-800 font-normal m-0 my-4">
          {t('administrador_equipo_carga_medicos')}
        </h2>
        <div className="flex justify-between">
          <div className="flex items-center col-span-2">
            <FormControl component="fieldset">
              <FormLabel>{t('administrador_equipo_tipo_carga')}</FormLabel>
              <RadioGroup
                value={selectorCarga}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectorCarga(event.target.value as 'masiva' | 'individual');
                }}
                aria-label="administrador_roles_tipo"
                name="administrador_roles_tipo"
                row
              >
                <FormControlLabel
                  value="masiva"
                  control={<Radio color="primary" />}
                  label={t('administrador_equipo_carga_masiva')}
                />
                {/* <FormControlLabel
                  value="individual"
                  control={<Radio color="primary" />}
                  label={t('administrador_equipo_carga_individual')}
                /> */}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="min-w-max self-right">
            <h3 className="text-green-500 font-normal m-0 p-0">
              {`${t('administrador_equipo_suscripciones')}: ${licenciasDisponibles}`}
            </h3>
            <div className="flex justify-end">
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  dispatch(setLoading(true));
                  // para obtener los últimos movimientos
                  getMiOrganizacionAltasBajasUsuario(idMedico, idOrganizacion)
                    .then((response) => response.json())
                    .then((result) => {
                      setLastChanges(result.code === 200 && result.data ? result.data : []);
                      dispatch(setLoading(false));
                      setOpenModal(true);
                    })
                    .catch(() => {
                      setLastChanges([]);
                      dispatch(setLoading(false));
                    });
                }}
                className={classes.noTextTranform}
              >
                {t('administrador_equipo_ultimos')}
              </Button>
            </div>
          </div>
        </div>
        {selectorCarga === 'masiva' && (
          <>
            <h3 className="text-gray-500 font-normal my-2">
              {t('administrador_equipo_carga_instrucciones')}
            </h3>
            <div className="flex items-center">
              <span className="text-gray-500">1. </span>
              <Button
                color="primary"
                // size="small"
                onClick={() => setShowPlataformaDialog(true)}
                className={classes.noTextTranform}
              >
                {`${t('administrador_equipo_definir_plataforma')}:`}
              </Button>
              <span className={plataforma === '' ? 'text-red-500' : 'text-gray-500'}>
                {plataforma === ''
                  ? t('administrador_equipo_plataforma_requerido')
                  : t(`administrador_equipo_plataforma_${plataforma}`)}
              </span>
              <Dialog
                open={showPlataformaDialog}
                onClose={closePlataformaDialog}
                aria-labelledby="alert-dialog-titulo"
                aria-describedby="alert-dialo-descripcion"
                fullWidth
                maxWidth="md"
              >
                <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
                  <h2 className="font-normal">{t('administrador_equipo_plataforma')}</h2>
                  <IconButton aria-label="cerrar" color="inherit" onClick={closePlataformaDialog}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <DialogContent>
                  <DialogContentText id="alert-dialog-descripcion">
                    <div className="pb-2 pt-2">
                      <h4 className="text-gray-500 font-normal m-0 pt-4 pb-2">
                        {`${t('administrador_equipo_plataforma_texto')}`}
                      </h4>
                      <div className="flex items-center">
                        <RadioGroup
                          value={plataforma}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPlataforma(event.target.value as 'windows' | 'otra');
                          }}
                          aria-label="administrador_roles_tipo"
                          name="administrador_roles_tipo"
                        >
                          <FormControlLabel
                            value="windows"
                            control={<Radio color="primary" />}
                            label={t('administrador_equipo_plataforma_windows')}
                          />
                          <FormControlLabel
                            value="otra"
                            control={<Radio color="primary" />}
                            label={t('administrador_equipo_plataforma_otra')}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </DialogContentText>
                  <div className="text-right">
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      size="large"
                      onClick={() => closePlataformaDialog()}
                    >
                      {t('aceptar')}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
            <div className="flex items-center">
              <span className="text-gray-500">2. </span>
              <Button
                color="primary"
                // size="small"
                onClick={() =>
                  downloadArchivo(
                    `Medipraxi-documentos/${
                      pais.charAt(0).toUpperCase() + pais.charAt(1)
                    }_medicos_plantilla.csv`,
                    'medicosPlantilla',
                  )
                }
                className={classes.noTextTranform}
              >
                {t('administrador_equipo_descarga_plantilla')}
              </Button>
              <div className="pl-2">
                <Button
                  color="primary"
                  size="small"
                  onClick={() =>
                    downloadArchivo(
                      `Medipraxi-documentos/${
                        pais.charAt(0).toUpperCase() + pais.charAt(1)
                      }_Reglas_Carga_Masiva_Medico.pdf`,
                      'reglasCargaMasivaMedico',
                    )
                  }
                  className={classes.noTextTranform}
                  startIcon={<InfoIcon />}
                >
                  {t('administrador_equipo_descarga_guia')}
                </Button>
              </div>
            </div>
            <label className="flex items-center" htmlFor="upload-file">
              {plataforma.length > 0 && (
                <input
                  id="upload-file"
                  name="upload-file"
                  style={{ display: 'none' }}
                  type="file"
                  accept=".csv"
                  // onClick={(event) => {
                  //   setStringInformacionCambios('');
                  //   setCsvArray([]);
                  //   setErrorArray([]);
                  //   seleccionarArchivo(event);
                  // }}
                  onChange={(event) => {
                    setStringInformacionCambios('');
                    setCsvArray([]);
                    setErrorArray([]);
                    seleccionarArchivo(event);
                  }}
                />
              )}
              <span className="text-gray-500 flex items-center">
                3.
                <Button
                  disabled={plataforma === ''}
                  component="span"
                  color="primary"
                  // size="small"
                  // onClick={() => setShowTable(true)}
                  // className={classes.noTextTranform}
                >
                  {t('administrador_equipo_carga_plantilla')}
                </Button>
              </span>
              <span className="text-red-500">
                {`(${t('administrador_equipo_recuerda_asignar')})`}
              </span>
            </label>
            <div className="flex items-center pt-2">
              <span className="text-gray-500">{`4. ${t('administrador_equipo_verifica')}`}</span>
            </div>
            <div className="flex items-center pt-4">
              <span className="text-gray-500">{`5. ${t('guardar')}`}</span>
            </div>
            {errorArray.length ? (
              <div className="pt-2">
                <TablaErrores
                  columns={columns}
                  data={errorArray}
                  pageCount={errorArray.length / 10}
                />
              </div>
            ) : null}
            {readyToSave && (
              <div className="flex justify-center">
                <span className="text-green-500">{t('administrador_equipo_tabla_ready')}</span>
              </div>
            )}
            {stringInformacionCambios && (
              <div className="flex justify-center">
                <span className="text-green-500">{stringInformacionCambios}</span>
              </div>
            )}
            {errorDataColumn && (
              <div className="flex justify-center">
                <span className="text-red-500">{t('administrador_equipo_tabla_sin_columnas')}</span>
              </div>
            )}
          </>
        )}
        {selectorCarga === 'individual' && (
          <div className="p-4 bg-white">
            <DialogPassword
              open={showDialog}
              callBackClose={() => {
                setShowDialog(!showDialog);
              }}
            />
            <div className="">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h4 className="text-gray-500 font-normal mt-0 mb-2">
                    {`${t('selecciona_rol')}*`}
                  </h4>
                  <Autocomplete
                    options={rolSelector}
                    getOptionLabel={(option) => option.label}
                    noOptionsText={t('sin_resultados')}
                    value={objDatId.rol}
                    onChange={(e, nV) => handleChangeRol(e, nV)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                    )}
                    // disabled={!editionMode && admonObjDat.id !== 0}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('nombre(s)')}*`}</h4>
                  <TextField
                    variant="outlined"
                    type="nombre"
                    value={objDatId.nombre}
                    onChange={(e: any) => handleChangeTxt(e, 'nombre')}
                    fullWidth
                    inputProps={{
                      maxLength: 100,
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }}
                    placeholder={t('especifica')}
                    // disabled={!editionMode && admonObjDat.id !== 0}
                  />
                </div>
                <div>
                  <h4 className="text-gray-500 font-normal mt-0 mb-2">
                    {`${t('primer_apellido')}*`}
                  </h4>
                  <TextField
                    variant="outlined"
                    type="apellidoP"
                    value={objDatId.apellidoP}
                    onChange={(e: any) => handleChangeTxt(e, 'apellidoP')}
                    fullWidth
                    inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                    placeholder={t('especifica')}
                    // disabled={!editionMode && admonObjDat.id !== 0}
                  />
                </div>
                <div>
                  <h4 className="text-gray-500 font-normal mt-0 mb-2">{t('segundo_apellido')}</h4>
                  <TextField
                    variant="outlined"
                    type="apellidoS"
                    value={objDatId.apellidoS}
                    onChange={(e: any) => handleChangeTxt(e, 'apellidoS')}
                    fullWidth
                    inputProps={{ maxLength: 50, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                    placeholder={t('especifica')}
                    // disabled={!editionMode && admonObjDat.id !== 0}
                  />
                </div>
                <div>
                  <h4 className="text-gray-500 font-normal mt-0 mb-2">
                    {`${t(`doc_id_${pais}`)}*`}
                  </h4>
                  <TextField
                    variant="outlined"
                    type="docId"
                    value={objDatId.docId}
                    onChange={(e: any) => handleChangeTxt(e, 'docId')}
                    fullWidth
                    inputProps={{
                      maxLength: docIdLongitudMax(pais),
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      style: { textTransform: objDatId.docId.length ? 'uppercase' : 'none' },
                    }}
                    placeholder={t(`especifica_debe_contener_n_caracteres_${pais}`)}
                    error={docIdField.error}
                    helperText={docIdField.helperText}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    // disabled={disableDocId || objDatId.id !== 0 || (!editionMode && admonObjDat.id !== 0)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4 mt-4">
                <div>
                  <h4 className="m-0 mb-3 text-gray-500 font-normal">{`${t('sexo')}*`}</h4>
                  <RadioGroup row name="sexo" value={objDatId.sexo} onChange={handleChangeSexo}>
                    <FormControlLabel
                      value="H"
                      control={<Radio color="primary" />}
                      label={t('hombre')}
                      labelPlacement="end"
                      // disabled={!editionMode && admonObjDat.id !== 0}
                    />
                    <FormControlLabel
                      value="M"
                      control={<Radio color="primary" />}
                      label={t('mujer')}
                      labelPlacement="end"
                      // disabled={!editionMode && admonObjDat.id !== 0}
                    />
                  </RadioGroup>
                </div>
                <div className="w-full grid grid-cols-3 gap-2">
                  <h4 className="text-gray-500 font-normal m-0 col-span-3">
                    {`${t('fecha_nacimiento')}*`}
                  </h4>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="label-dia">{t('dia')}</InputLabel>
                    <Select
                      labelId="label-dia"
                      id="select-dia"
                      value={objDatId.fechaNac.dia}
                      onChange={(e) => handleChangeDate(e, 'dia')}
                      label={t('dia')}
                      error={fechaField.error}
                      // disabled={!editionMode && admonObjDat.id !== 0}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 375,
                          },
                        },
                      }}
                    >
                      {constDia.map((diaSingle) => (
                        <MenuItem value={diaSingle.toString()} key={diaSingle}>
                          {diaSingle}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="label-mes">{t('mes')}</InputLabel>
                    <Select
                      labelId="label-mes"
                      id="select-mes"
                      value={objDatId.fechaNac.mes}
                      onChange={(e) => handleChangeDate(e, 'mes')}
                      label={t('mes')}
                      error={fechaField.error}
                      // disabled={!editionMode && admonObjDat.id !== 0}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 375,
                          },
                        },
                      }}
                    >
                      {constMes.map((mesSingle, indx) => (
                        <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                          {mesSingle.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className={classes.absoluteBottom}>
                      {fechaField.helperText}
                    </FormHelperText>
                  </FormControl>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="label-año">{t('anio')}</InputLabel>
                    <Select
                      labelId="label-año"
                      id="select-año"
                      value={objDatId.fechaNac.anio}
                      onChange={(e) => handleChangeDate(e, 'anio')}
                      label={t('anio')}
                      error={fechaField.error}
                      // disabled={!editionMode && admonObjDat.id !== 0}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 375,
                          },
                        },
                      }}
                    >
                      {anyoFur.map((anioSingle) => (
                        <MenuItem key={anioSingle} value={anioSingle.toString()}>
                          {anioSingle}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <h4 className="text-gray-500 font-normal m-0 mb-2">
                    {`${t('telefono_celular')}*`}
                  </h4>
                  <PhoneInput
                    preferredCountries={[pais, 'us']}
                    country={pais}
                    localization={es}
                    enableSearch
                    searchPlaceholder={t('buscar')}
                    searchNotFound={t('sin_resultados')}
                    inputStyle={{ width: '100%' }}
                    value={`${objDatId.celular.codigo}${objDatId.celular.numero}`}
                    onChange={(value, country: ICountryPhoneInput) => {
                      const { dialCode } = country;
                      const nuevoCel = value.slice(dialCode.length);
                      const dataUpd = { ...objDatId };
                      dataUpd.celular.numero = nuevoCel;
                      dataUpd.celular.codigo = country.dialCode;
                      setObjDatId(dataUpd);
                    }}
                    // disabled={!editionMode && admonObjDat.id !== 0}
                  />
                </div>
                <div>
                  <h4 className="text-gray-500 font-normal m-0 mb-2">{t('telefono_fijo')}</h4>
                  <PhoneInput
                    preferredCountries={[pais, 'us']}
                    country={pais}
                    localization={es}
                    enableSearch
                    searchPlaceholder={t('buscar')}
                    searchNotFound={t('sin_resultados')}
                    inputStyle={{ width: '100%' }}
                    value={`${objDatId.telefono.codigo}${objDatId.telefono.numero}`}
                    onChange={(value, country: ICountryPhoneInput) => {
                      const { dialCode } = country;
                      const nuevoCel = value.slice(dialCode.length);
                      const dataUpd = { ...objDatId };
                      dataUpd.telefono.numero = nuevoCel;
                      dataUpd.telefono.codigo = country.dialCode;
                      setObjDatId(dataUpd);
                    }}
                    // disabled={!editionMode && admonObjDat.id !== 0}
                  />
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4 pt-2">
                <div className="col-span-2">
                  <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('email')}*`}</h4>
                  <TextField
                    variant="outlined"
                    type="email"
                    value={objDatId.email}
                    placeholder={t('especifica')}
                    onChange={(e: any) => handleChangeTxt(e, 'email')}
                    fullWidth
                    error={emailError.error}
                    helperText={emailError.helperText}
                    FormHelperTextProps={{ className: classes.absoluteBottom }}
                    inputProps={{
                      maxLength: 100,
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                    }}
                    onBlur={handleBlurEmail}
                    // disabled={
                    //   activeStep !== 2 || objDatId.id !== 0 || (!editionMode && admonObjDat.id !== 0)
                    // }
                  />
                </div>
                {/* {activeStep === 0 && (
                  <div className="flex items-end">
                    <Button
                      color="primary"
                      onClick={() => {
                        setShowDialog(!showDialog);
                      }}
                      className={classes.noTextTranform}
                      startIcon={<LockIcon />}
                    >
                      {t('medico_perfil_identificacion_cambiar')}
                    </Button>
                  </div>
                )} */}
                <div>
                  <h4 className="text-gray-500 font-normal mt-0 mb-2">{`${t('nacionalidad')}*`}</h4>
                  <Autocomplete
                    options={catNacionalidad}
                    getOptionLabel={(option) => option.label}
                    noOptionsText={t('sin_resultados')}
                    value={objDatId.nacionalidad}
                    onChange={(e, nV) => handleChangeNacionalidad(e, nV)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                    )}
                    // disabled={!editionMode && admonObjDat.id !== 0}
                  />
                </div>
              </div>
              {objDatId.id === 0 && (
                <div className="grid grid-cols-2 gap-4 pt-2">
                  <div>
                    <h4 className="text-gray-500 font-normal mt-0 mb-2">
                      {`${t('contrasenia')}*`}
                    </h4>
                    <TextField
                      // disabled={!editionMode && admonObjDat.id !== 0}
                      error={errorPass.password.error}
                      variant="outlined"
                      type={verPassword ? 'text' : 'password'}
                      fullWidth
                      value={contrasenia}
                      onChange={handleChangePassword}
                      onFocus={handleFocusPassword}
                      onCopy={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onCut={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      helperText={errorPass.password.helperText}
                      FormHelperTextProps={{ className: classes.absoluteBottom }}
                      InputProps={{
                        endAdornment: (
                          <div className="text-blue-500">
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickVerPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                color="inherit"
                              >
                                {verPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          </div>
                        ),
                        inputProps: {
                          autoComplete: 'off',
                          'aria-autocomplete': 'none',
                          maxLength: '50',
                        },
                      }}
                    />
                  </div>
                  <div>
                    <h4 className="text-gray-500 font-normal mt-0 mb-2">
                      {`${t('repetir_contrasenia')}*`}
                    </h4>
                    <TextField
                      // disabled={!editionMode && admonObjDat.id !== 0}
                      error={errorPass.passwordRepetido.error}
                      variant="outlined"
                      type={verPasswordRepetido ? 'text' : 'password'}
                      onFocus={handleFocusPassword}
                      fullWidth
                      value={contraseniaRepetida}
                      onChange={handleChangePasswordRepetido}
                      onCopy={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onCut={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      helperText={errorPass.passwordRepetido.helperText}
                      FormHelperTextProps={{ className: classes.absoluteBottom }}
                      InputProps={{
                        endAdornment: (
                          <div className="text-blue-500">
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickVerPasswordRepetido}
                                onMouseDown={handleMouseDownPasswordRepetido}
                                edge="end"
                                color="inherit"
                              >
                                {verPasswordRepetido ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          </div>
                        ),
                        inputProps: {
                          autoComplete: 'off',
                          'aria-autocomplete': 'none',
                          maxLength: '50',
                        },
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="pt-4">
                <h4 className="text-gray-500 font-normal m-0">{t('campo-obligatorio')}</h4>
              </div>
            </div>
            {/* {activeStep === 2 && admonObjDat.id !== 0 && (
              <>
                <div className="p-2" />
                <hr />
                <h2 className="text-blue-500 font-normal m-0 pt-2">{t('administrador_invitado')}</h2>
                <div className="shadow-md border border-solid border-gray-100 rounded mt-4 flex justify-between relative">
                  <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 pr-5 w-full">
                    <h3 className="m-0 overflow-ellipsis overflow-hidden font-normal text-gray-800">
                      {`${admonObjDat.nombre} ${admonObjDat.apellidoP} ${admonObjDat.apellidoS}`}
                    </h3>
                    <h4 className="font-normal text-gray-500 m-0 mr-4">
                      {`${t(`doc_id_${pais}`)}: ${admonObjDat.docId.toUpperCase()}`}
                    </h4>
                    <h4 className="font-normal text-gray-500 m-0 mr-4">
                      {`${t('sexo')}: ${admonObjDat.sexo === 'H' ? 'Hombre' : 'Mujer'}`}
                    </h4>
                    <h4 className="font-normal text-gray-500 m-0 mr-4">
                      {`${t('fecha_nacimiento')}: ${
                        admonObjDat.fechaNac.dia
                        && admonObjDat.fechaNac.mes
                        && admonObjDat.fechaNac.anio
                        && dayjs(
                          `${admonObjDat.fechaNac.anio}-${admonObjDat.fechaNac.mes}-${admonObjDat.fechaNac.dia}`,
                        ).format('DD-MMM-YYYY')
                      }`}
                    </h4>
                    <h4 className="font-normal text-gray-500 m-0 mr-4">
                      {`${t('telefono_celular')}: ${
                        admonObjDat.celular.codigo + admonObjDat.celular.numero || ''
                      } - ${t('telefono_fijo')}: ${
                        admonObjDat.telefono.codigo + admonObjDat.telefono.numero || ''
                      }`}
                    </h4>
                    <div className="flex items-center m-0">
                      <h4 className="font-normal m-0 text-gray-500 mr-4 pr-6">
                        {`${t('email')}: ${admonObjDat.email}`}
                      </h4>
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => {
                          sendEnviaAdministradorBienvenida(admonObjDat.email, Me.email, idOrganizacion)
                            .then((response) => {
                              if (response.status === 200) {
                                dispatch(
                                  setSnackComplete({
                                    open: true,
                                    severity: 'success',
                                    mensaje: t('email_enviado_correcto'),
                                  }),
                                );
                              } else {
                                dispatch(
                                  setSnackComplete({
                                    open: true,
                                    severity: 'error',
                                    mensaje: t('email_enviado_error'),
                                  }),
                                );
                              }
                            })
                            .catch((err) => {
                              dispatch(
                                setSnackComplete({
                                  open: true,
                                  severity: 'error',
                                  mensaje: `${t('medico_perfil_error_400')} ${err.toString()}`,
                                }),
                              );
                            });
                        }}
                        className={classes.noTextTranform}
                      >
                        {t('reenviar_correo_invitacion')}
                      </Button>
                    </div>
                    <h4 className="font-normal text-gray-500 m-0 mr-4">
                      {`${t('nacionalidad')}: ${admonObjDat.nacionalidad.label}`}
                    </h4>
                  </div>
                  <div className="min-w-max self-center">
                    <IconButton
                      aria-label="edit"
                      color="primary"
                      onClick={() => {
                        setObjDatId({ ...admonObjDat, password: '*' });
                        setEditionMode(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => openAndDeleteAdmon(admonObjDat.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
}

export default EquipoMedico;
