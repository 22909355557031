import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import dayjs from 'dayjs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import { useDispatch, useSelector } from 'react-redux';
import { constDia, constMes, constAnio } from '@constants/fechas';
import { getCatCie9 } from '@utils/getCatalogos';
import { ICatalogosEstandar, ICatStrEstandar, IFechaField, IFechaObj } from '@common/types';
import { validarFecha, valFechaToSave, sortArrayByDate, validarFechaEsIgualOAnterior } from '@common/functions';
import { RootState } from 'src/store';
import { sendProcedimientos } from '@utils/sendInfo';
import ComentariosComponente from '@components/Comentarios';
import { setSnackComplete } from '@actions/snackbar/types';
import { useApiRequest } from '@hooks/useApiRequest';
import useStyles from './styles';
import { IProcedimientoFormularioProps } from './types';
import { IProcedimiento, procedimientoFormularioInitial } from '../types';
import { IFormatoMCProceso } from '@actions/paciente/types';

function ProcedimientoFormulario({
  activoFecha,
  tipo,
  procedimientos,
  setProcedimientos,
  indSelect = null,
  setIndSelect,
  formulario,
  setFormulario,
}: IProcedimientoFormularioProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { apiRequest } = useApiRequest();
  const { idPaciente, sexo } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    expediente: {
      antPatologicos: { catDiagnostico },
    },
    proceso: { formatosMC },
  } = useSelector((state: RootState) => state.Paciente);
  /** Catalogos */
  const [catCie9, setCatCie9] = useState<Array<ICatStrEstandar>>([]);

  const [fechaField, setFechaField] = useState<IFechaField>({
    error: false,
    helperText: '',
  });

  const isEMO = !(procedimientos && setProcedimientos && setIndSelect);

  /** Handlers */
  const handleChangeDiagnostico = (event: any, newValue: ICatalogosEstandar | null) => {
    setFormulario((prev) => ({
      ...prev,
      diagnostico: newValue || { id: 0, label: '' },
      diagEspecifica: '',
    }));
  };
  const handleChangeMotivo = (event: any, newValue: ICatStrEstandar | null) => {
    setFormulario((prev) => ({
      ...prev,
      motivo: newValue || { id: '', label: '' },
    }));
  };
  const handleChangeDate = (event: React.ChangeEvent<{ value: unknown }>, sel: string) => {
    const val = event.target.value as string;
    setFormulario((prev) => ({
      ...prev,
      fecha: { ...prev.fecha, [sel]: val },
    }));
  };
  const setData = (data: RawDraftContentState) => {
    setFormulario((prev) => ({
      ...prev,
      comentarios: data,
    }));
  };
  // Si el registro es nuevo 'idx' es undefined
  const saveMotivo = () => {
    if (!isEMO) {
      const idProcedimiento = indSelect ? procedimientos[indSelect].idProcedimiento : -1;
      const valDate: IFechaObj = valFechaToSave(formulario.fecha);
      if (formulario.motivo) {
        const newProcedimiento: IProcedimiento = {
          idPaciente,
          idConsulta,
          idProceso: -1,
          idProcedimiento,
          enfermedad: formulario.diagnostico,
          txtDiagnostico: formulario.diagEspecifica || '',
          txtMotivo: formulario.motivoEspecifica || '',
          cie9: formulario.motivo,
          dia: valDate.dia,
          mes: valDate.mes,
          anio: valDate.anio,
          comentarios: formulario.comentarios,
          documentos: indSelect && procedimientos[indSelect] && procedimientos[indSelect].documentos
            ? procedimientos[indSelect].documentos
            : [],
          archivos: indSelect && procedimientos[indSelect] && procedimientos[indSelect].archivos
            ? procedimientos[indSelect].archivos
            : [],
        };
        if (valDate.anio === '') {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'warning',
              mensaje: t('antecedentes-pp-proc-snack'),
            }),
          );
        } else {
          apiRequest({
            type: 'send',
            requestFunction: sendProcedimientos({
              ...newProcedimiento,
              idMedico,
              idConsultorio,
              tipo,
            }),
            successFunction: (result: any) => {
              setFormulario(procedimientoFormularioInitial);
              const newProcedimientos = [...procedimientos];
              if (indSelect) { // Si el registro ya existe, se actualiza
                setIndSelect(null);
                newProcedimientos[indSelect] = newProcedimiento;
                setProcedimientos(newProcedimientos);
              } else { // Si el registro es nuevo se le asigna el id y se agrega a la lista
                newProcedimiento.idProcedimiento = result.datos;
                newProcedimientos.push(newProcedimiento);
                setProcedimientos(sortArrayByDate(newProcedimientos));
              }
            },
          });
        }
      }
    }
  };
  const disabledButton = () =>
    fechaField.error ||
    !formulario.fecha.anio ||
    (formulario.radioSel === 'cie' && formulario.motivo && formulario.motivo.id === '') ||
    (formulario.radioSel === 'txt' && formulario.motivoEspecifica.length === 0);
  const getTipoProcedimiento = () => {
    switch (tipo) {
      case 'quirurgico':
        return 1;
      case 'diagnostico':
        return 2;
      case 'terapeutico':
        return 3;
      default:
        return 0;
    }
  };
  useEffect(() => {
    if (!isEMO) {
      getCatCie9(getTipoProcedimiento().toString(), sexo).then((result: ICatStrEstandar[]) => {
        if (tipo === 'diagnostico' && formatosMC.length > 0) {
          const exclude = formatosMC.map((formato: IFormatoMCProceso) => formato.cie9.id);
          setCatCie9(result.filter((cie9) => !exclude.includes(cie9.id)));
        } else {
          setCatCie9(result);
        }
      });
    }
  }, [isEMO, formatosMC]);
  useEffect(() => {
    if (
      formulario.fecha.mes &&
      formulario.fecha.anio &&
      (!validarFecha(formulario.fecha.anio, formulario.fecha.mes, formulario.fecha.dia || '1') ||
        !validarFechaEsIgualOAnterior(
          formulario.fecha.anio,
          formulario.fecha.mes,
          formulario.fecha.dia || '1',
        ))
    ) {
      setFechaField({
        error: true,
        helperText: t('fecha_invalida'),
      });
    } else {
      setFechaField({
        error: false,
        helperText: '',
      });
    }
  }, [formulario.fecha]);
  useEffect(() => {
    if (activoFecha && formulario.fecha.dia.length === 0) {
      setFormulario((prev) => ({
        ...prev,
        fecha: {
          dia: dayjs().format('D'),
          mes: dayjs().format('M'),
          anio: dayjs().format('YYYY'),
        },
      }));
    }
  }, [activoFecha]);

  return (
    <div className="my-4 border-solid border border-gray-400 rounded p-3">
      {!isEMO && (
        <>
          <div className="mb-2">
            <div className="grid grid-cols-2">
              <h3 className="mt-0 mb-3 text-gray-600 font-medium">
                {`${t('antecedentes-pp-proc-diag')}:`}
              </h3>
              <div className="flex justify-end">
                <h4 className="mt-0 mb-2 font-normal text-red-600">
                  <Trans i18nKey="antecedentes-pp-proc-atxt">
                    index0
                    <strong title="index1">index1</strong>
                    <strong title="index2">index2</strong>
                  </Trans>
                </h4>
              </div>
            </div>
            <Autocomplete
              options={catDiagnostico}
              getOptionLabel={(option) => option.label}
              noOptionsText={t('sin_resultados')}
              value={formulario.diagnostico}
              onChange={(e, nV) => handleChangeDiagnostico(e, nV)}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          </div>
          <div className="mb-4">
            <div className="flex justify-left items-center mb-2">
              <h3 className="m-0 mr-8 text-gray-600 font-medium">
                {`${t('antecedentes-pp-proc-tipo')}*`}
              </h3>
              <FormControl>
                <RadioGroup
                  aria-label="tipo"
                  name="tipo"
                  value={formulario.radioSel}
                  onChange={(event) => {
                    setFormulario((prev) => ({
                      ...prev,
                      radioSel: (event.target as HTMLInputElement).value,
                      motivo: { id: '', label: '' },
                      motivoEspecifica: '',
                    }));
                  }}
                  row
                >
                  <FormControlLabel
                    value="cie"
                    control={<Radio color="primary" />}
                    label={t('cie-9')}
                  />
                  <FormControlLabel
                    value="txt"
                    control={<Radio color="primary" />}
                    label={t('texto-libre')}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {formulario.radioSel && formulario.radioSel === 'cie' ? (
              <Autocomplete
                id="cat-cie-9"
                options={catCie9}
                getOptionLabel={(option) => option.label}
                noOptionsText={t('sin_resultados')}
                value={formulario.motivo}
                onChange={(e, nV) => handleChangeMotivo(e, nV)}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
                )}
              />
            ) : (
              <TextField
                variant="outlined"
                value={formulario.motivoEspecifica}
                placeholder={t('especifica')}
                fullWidth
                InputProps={{
                  inputProps: {
                    autoComplete: 'off',
                    'aria-autocomplete': 'none',
                    maxLength: '150',
                  },
                }}
                onChange={(event) => {
                  setFormulario((prev) => ({ ...prev, motivoEspecifica: event.target.value }));
                }}
              />
            )}
          </div>
        </>
      )}
      {!activoFecha && (
        <div className="grid grid-cols-12 mb-4 gab-4">
          <div className="col-span-4">
            <h3 className="mt-0 mb-2 text-gray-600 font-medium">{`${t('fecha')}${isEMO ? '' : '*'}`}</h3>
            <div className="grid grid-cols-4">
              <div className="mr-4">
                <FormControl variant="outlined" fullWidth>
                  <Select
                    displayEmpty
                    labelId="label-dia"
                    id="select-dia"
                    value={formulario.fecha.dia}
                    onChange={(e) => handleChangeDate(e, 'dia')}
                    error={fechaField.error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                    renderValue={
                      formulario.fecha.dia !== ''
                        ? undefined
                        : () => <div className={classes.placeholder}>{t('dia')}</div>
                    }
                  >
                    {constDia.map((diaSingle) => (
                      <MenuItem value={diaSingle.toString()} key={diaSingle}>
                        {diaSingle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mr-4 col-span-2">
                <FormControl variant="outlined" fullWidth>
                  <Select
                    displayEmpty
                    labelId="label-mes"
                    id="select-mes"
                    value={formulario.fecha.mes}
                    onChange={(e) => handleChangeDate(e, 'mes')}
                    error={fechaField.error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                    renderValue={
                      formulario.fecha.mes !== ''
                        ? undefined
                        : () => <div className={classes.placeholder}>{t('mes')}</div>
                    }
                  >
                    {constMes.map((mesSingle, indx) => (
                      <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                        {mesSingle.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText className={classes.absoluteBottom}>
                    {fechaField.helperText}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="mr-4">
                <FormControl variant="outlined" fullWidth>
                  <Select
                    displayEmpty
                    labelId="label-año"
                    id="select-año"
                    value={formulario.fecha.anio}
                    onChange={(e) => handleChangeDate(e, 'anio')}
                    error={fechaField.error}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 375,
                        },
                      },
                    }}
                    renderValue={
                      formulario.fecha.anio !== ''
                        ? undefined
                        : () => <div className={classes.placeholder}>{t('anio')}</div>
                    }
                  >
                    {constAnio().map((anioSingle) => (
                      <MenuItem key={anioSingle} value={anioSingle.toString()}>
                        {anioSingle}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isEMO && (
        <>
          <ComentariosComponente
            comentarios={formulario.comentarios}
            setComentarios={setData}
            rows={4}
            maxLength={500}
            placeholder={t('comentarios')}
            lang="es"
            disabled={false}
            key={formulario.clearComentario}
          />
          <div className="flex justify-end mt-2 mb-3">
            <Button
              variant="contained"
              color="primary"
              startIcon={indSelect === null ? <SaveIcon /> : <SyncIcon />}
              onClick={saveMotivo}
              disabled={disabledButton()}
            >
              {t(indSelect === null ? 'guardar' : 'actualizar')}
            </Button>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-600">*{t('campos_obligatorios')}</span>
            <h4 className="mt-0 mb-2 font-normal">
              <Trans i18nKey="antecedentes-pp-proc-gtxt">
                index0
                <strong title="index1">index1</strong>
                index2
              </Trans>
            </h4>
          </div>
        </>
      )}
    </div>
  );
}

export default ProcedimientoFormulario;
