import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import DeleteDialog from '@components/DeleteDialog';
import { procedimientoEliminarProcedimiento } from '@utils/deleteInfo';
import { editProcedimientos } from '@utils/editInfo';
import { setSnackComplete } from '@actions/snackbar/types';
import { crearArchivo } from '@utils/sendFiles';
import { deleteArchivo } from '@utils/deleteFiles';
import { useApiRequest } from '@hooks/useApiRequest';
import {
  setProcDiagnosticos,
  setProcQuirurgicos,
  setProcTerapeuticos,
  setSinProcQuirurgicos,
} from '@actions/paciente/actions';
import {
  IProcedimiento,
  IProcedimientoFormulario,
  IProcedimientoProps,
  procedimientoFormularioInitial,
} from './types';
import ProcedimientosComponente from './Procedimientos/index';
import ProcedimientoFormulario from './ProcedimientoFormulario';

function Procedimiento({
  modulo,
  activoFecha,
  tipo,
  sinProcedimientos,
  procedimientos,
}: IProcedimientoProps) {
  const { t } = useTranslation();
  const { apiRequest, apiRequestFile } = useApiRequest();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const dispatch = useDispatch();
  const [labelGen, setLabelGen] = useState<string>('');
  const [labelLista, setLabelLista] = useState<string>('');
  const [formulario, setFormulario] = useState<IProcedimientoFormulario>(
    procedimientoFormularioInitial,
  );
  const [indSelect, setIndSelect] = useState<number | null>(null);
  // cierre del modal
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [cambioAlert, setCambioAlert] = useState<boolean>(false);
  const [sinProc, setSinProc] = useState<boolean>(sinProcedimientos);
  const [idSelect, setIdSelect] = useState<number>(0);
  const [openArchivoBorrar, setOpenArchivoBorrar] = useState<boolean>(false);
  /* archivo */
  const [selObjArchivo, setSelObjArchivo] = useState<{ archivo: File; idLista: number } | null>(
    null,
  );
  /* FUNCIONES DE LAS ALERTAS */
  const [objArchivoBorrar, setObjArchivoBorrar] = useState<{
    idLista: number;
    idArchivo: number;
    nombreArchivo: string;
  } | null>(null);
  /* funcion para cerrar la alerta de borrado */
  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  /* funcion para cerrar la alerta de edición */
  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setIndSelect(null);
    setEditAlert(false);
  };
  /* funcion para cerrar la alerta de activar sin antecedentes de alergias */
  const handleCambioAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCambioAlert(false);
  };
  // sin antecedentes procedimientos
  const handleSinProcedimiento = (sinProcedimiento: boolean) => {
    let tipoProcedimiento = 1;
    if (tipo === 'diagnostico') {
      tipoProcedimiento = 2;
    } else if (tipo === 'terapeutico') {
      tipoProcedimiento = 3;
    }
    apiRequest({
      type: 'send',
      requestFunction: editProcedimientos({
        idPaciente,
        tipo: tipoProcedimiento,
        idMedico,
        idConsultorio,
        sinProcedimiento,
      }),
      successFunction: () => {
        setSinProc(sinProcedimiento);
        dispatch(setSinProcQuirurgicos(sinProcedimiento));
      },
    });
    setCambioAlert(false);
  };
  /* funcion para cerrar la alerta de borrado de archivos */
  const handleDeleteArchivoBorrar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setObjArchivoBorrar(null);
    setOpenArchivoBorrar(false);
  };
  const setProcedimientos = (proc: IProcedimiento[]) => {
    if (tipo === 'quirurgico') {
      dispatch(setProcQuirurgicos(proc));
    } else if (tipo === 'terapeutico') {
      dispatch(setProcTerapeuticos(proc));
    } else if (tipo === 'diagnostico') {
      dispatch(setProcDiagnosticos(proc));
    }
  };
  const deleteFirstStepProcedimiento = (inx: number) => {
    setIdSelect(inx);
    setDeleteAlert(true);
  };
  const deleteSecondStepProcedimiento = () => {
    apiRequest({
      type: 'delete',
      requestFunction: procedimientoEliminarProcedimiento({
        idPaciente,
        idBD: procedimientos[idSelect].idProcedimiento,
        idMedico,
        idConsultorio,
      }),
      successFunction: () => {
        const newArray = [...procedimientos];
        newArray.splice(idSelect, 1);
        setIdSelect(0);
        setProcedimientos(newArray);
      },
    });
    setDeleteAlert(false);
  };
  const editSecondStepProcedimiento = (idx: number | null) => {
    if (idx !== null) {
      setFormulario((prev) => ({
        ...prev,
        radioSel:
          procedimientos[idx].txtMotivo && procedimientos[idx].txtMotivo.length > 0 ? 'txt' : 'cie',
        diagnostico: procedimientos[idx].enfermedad,
        diagEspecifica: procedimientos[idx].txtDiagnostico,
        motivo: procedimientos[idx].cie9,
        motivoEspecifica: procedimientos[idx].txtMotivo,
        fecha: {
          dia: procedimientos[idx].dia || '',
          mes: procedimientos[idx].mes || '',
          anio: procedimientos[idx].anio,
        },
        comentarios: procedimientos[idx].comentarios,
        clearComentario: Math.floor(Math.random() * 1000) + 1,
      }));
      window.scrollTo({ top: tipo === 'quirurgico' ? 480 : 440, left: 0, behavior: 'smooth' });  
    }
  };
  const editFirstStepProcedimiento = (idx: number) => {
    setIndSelect(idx);
    if (
      formulario.diagnostico.id !== 0 ||
      formulario.diagEspecifica ||
      formulario.motivoEspecifica ||
      formulario.motivo.id !== '' ||
      formulario.fecha.dia ||
      formulario.fecha.mes ||
      formulario.fecha.dia ||
      (formulario.comentarios &&
        formulario.comentarios.blocks &&
        formulario.comentarios.blocks.length > 0)
    ) {
      setEditAlert(true);
    } else {
      editSecondStepProcedimiento(idx);
    }
  };
  const verArchivo = (
    _idProcedimiento: number,
    idArchivo: number,
    nombreArchivo: string,
    pathArchivo: string,
  ) => {
    let registro = '';
    if (tipo === 'quirurgico') {
      registro = 'procedimientosQuirurgicos';
    } else if (tipo === 'terapeutico') {
      registro = 'procedimientosTerapeuticos';
    } else if (tipo === 'diagnostico') {
      registro = 'procedimientosDiagnosticos';
    }
    apiRequestFile({
      type: 'get',
      idMedico: null,
      idPaciente,
      idArchivo,
      nombreArchivo,
      pathArchivo,
      tipoArchivo: registro,
    });
  };
  const deleteArchivoBorrar = () => {
    if (objArchivoBorrar) {
      const { idLista, idArchivo } = objArchivoBorrar;
      const arch = procedimientos
        .find((lt) => lt.idProcedimiento === idLista)
        ?.archivos.find((ar) => ar.idArchivo === idArchivo);
      if (arch) {
        let registro = '';
        if (tipo === 'quirurgico') {
          registro = 'procedimientosQuirurgicos';
        } else if (tipo === 'terapeutico') {
          registro = 'procedimientosTerapeuticos';
        } else if (tipo === 'diagnostico') {
          registro = 'procedimientosDiagnosticos';
        }
        apiRequest({
          type: 'delete',
          requestFunction: deleteArchivo({
            idLista,
            idArchivo,
            idMedico,
            idPaciente,
            idConsultorio,
            pathArchivo: arch.pathArchivo,
            registro,
          }),
          successFunction: () => {
            const listaArchivos = procedimientos.find(
              (lt) => lt.idProcedimiento === idLista,
            )?.archivos;
            // se verifica que sea un array y no undefined
            if (Array.isArray(listaArchivos)) {
              // le añadimos el objeto que acabamos de subir a la BD
              const laf = listaArchivos.filter((ar) => ar.idArchivo !== idArchivo);
              // actualizamos la lista de transfusiones para evitar hacer un refresh y evitar
              // peticiones extra a la BD
              setProcedimientos(
                procedimientos.map((tra) => {
                  if (tra.idProcedimiento === idLista) {
                    return { ...tra, archivos: laf };
                  }
                  return tra;
                }),
              );
            }
          },
        });
      }
    } else {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: 'Ocurrio un error intentar nuevamente',
        }),
      );
    }
  };
  const eliminarArchivo = (idLista: number, idArchivo: number, nombreArchivo: string) => {
    setObjArchivoBorrar({ idLista, idArchivo, nombreArchivo });
    setOpenArchivoBorrar(true);
  };
  useEffect(() => {
    if (selObjArchivo && selObjArchivo.archivo.size > 10485760) {
      setSelObjArchivo(null);
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('el_archivo_selecionado_pesa_mas_de_10_megas'),
        }),
      );
      return;
    }
    if (selObjArchivo) {
      // despues en un futuro debemos crear un XMLHttpRequest en vez de un fetch para obtener el progreso de la carga
      // ese progreso se dejará maqueteado para usarlo en un futuro, asi que los comentarios de dejan a proposito
      // obtenemos un objeto form data que nos servira para enviar nuestra peticion como si se hubiera generado en
      // un formulario
      const data = new FormData();
      // añadimos la informacion importante y OJO el archivo debe ir siempre al último o el back no parseara correctamente
      // los parametros que se le adjunta
      data.append('idLista', String(selObjArchivo.idLista));
      data.append('idMedico', String(idMedico));
      data.append('idPaciente', String(idPaciente));
      data.append('idConsulta', String(idConsulta));
      data.append('nombre', String(selObjArchivo.archivo?.name));
      data.append('tipo', selObjArchivo.archivo?.type);
      data.append('peso', String(selObjArchivo.archivo?.size));
      if (tipo === 'quirurgico') {
        data.append('registro', 'procedimientosQuirurgicos');
      } else if (tipo === 'terapeutico') {
        data.append('registro', 'procedimientosTerapeuticos');
      } else if (tipo === 'diagnostico') {
        data.append('registro', 'procedimientosDiagnosticos');
      }
      data.append('file', selObjArchivo?.archivo);
      apiRequest({
        type: 'send',
        requestFunction: crearArchivo(data),
        successFunction: (result: any) => {
          // si el resultado es ok procedemos a obtener la lista de archivos
          const listaArchivos = procedimientos.find(
            (lt) => lt.idProcedimiento === selObjArchivo.idLista,
          )?.archivos;
          // se verifica que sea un array y no undefined
          if (Array.isArray(listaArchivos)) {
            // le añadimos el objeto que acabamos de subir a la BD
            listaArchivos.push({
              idArchivo: result.datos.idArchivo,
              idMedico,
              nombreArchivo: selObjArchivo.archivo.name,
              pathArchivo: result.datos.url,
            });
            // actualizamos la lista de transfusiones para evitar hacer un refresh y evitar
            // peticiones extra a la BD
            setProcedimientos(
              procedimientos.map((tra) => {
                if (tra.idProcedimiento === selObjArchivo.idLista) {
                  return { ...tra, archivos: listaArchivos };
                }
                return tra;
              }),
            );
          }
          // reseteamos el objeto
          setSelObjArchivo(null);
        },
        errorFunction: () => {
          // reseteamos el objeto
          setSelObjArchivo(null);
        },
      });
    }
  }, [selObjArchivo]);
  useEffect(() => {
    if (tipo === 'quirurgico') {
      setLabelGen(t('antecedentes-pp-pquirurgico'));
      setLabelLista(t('antecedentes-pp-pquirurgico-l'));
    } else if (tipo === 'terapeutico') {
      setLabelGen(t('antecedentes-pp-pterapeutico'));
      setLabelLista(t('antecedentes-pp-pterapeutico-l'));
    } else if (tipo === 'diagnostico') {
      setLabelGen(t('antecedentes-pp-pdiagnosticos'));
      setLabelLista(t('antecedentes-pp-pdiagnosticos-l'));
    }
  }, []);
  return (
    <div className="relative">
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pp-hos-eliminar')}
        callBackAceptar={deleteSecondStepProcedimiento}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={openArchivoBorrar}
        titulo={t('_atencion_')}
        descripcion={`${t('_estas_seguro_de_eliminar')}: ${objArchivoBorrar?.nombreArchivo}?`}
        callBackAceptar={deleteArchivoBorrar}
        callBackClose={handleDeleteArchivoBorrar}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pp-hos-edicion')}
        callBackAceptar={() => editSecondStepProcedimiento(indSelect)}
        callBackClose={handleEditAlertClose}
      />
      <DeleteDialog
        open={cambioAlert}
        titulo={t('_atencion_')}
        descripcion={t('_estas_seguro__si_existe_registros_anteriores_se_eliminaran')}
        callBackAceptar={() => handleSinProcedimiento(true)}
        callBackClose={handleCambioAlertClose}
      />
      {!activoFecha && <h2 className="text-blue-800 font-normal m-0">{labelGen}</h2>}
      {tipo === 'quirurgico' && !activoFecha && (
        <FormControlLabel
          control={
            <Checkbox
              checked={sinProc}
              onChange={() => {
                if (sinProc) {
                  handleSinProcedimiento(false);
                } else {
                  setCambioAlert(true);
                }
              }}
              name="sinProc"
              color="primary"
            />
          }
          label={t('antecedentes-pp-proc-sin-ant')}
          disabled={!!procedimientos.length}
        />
      )}
      {!sinProc && (
        <>
          <ProcedimientoFormulario
            activoFecha={activoFecha}
            tipo={tipo}
            procedimientos={procedimientos}
            setProcedimientos={setProcedimientos}
            indSelect={indSelect}
            setIndSelect={setIndSelect}
            formulario={formulario}
            setFormulario={setFormulario}
          />
          {procedimientos.length > 0 && (
            <>
              <hr />
              <div className="w-full mt-4">
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="lista-datos"
                    id="lista-datos"
                  >
                    <h2 className="text-blue-500 font-normal m-0">{labelLista}</h2>
                  </AccordionSummary>
                  {procedimientos.map((val, inx) =>
                    modulo === 'antecedentes' || val.idConsulta === idConsulta ? (
                      // eslint-disable-next-line react/no-array-index-key
                      <AccordionDetails key={`accordion-${inx}`}>
                        <ProcedimientosComponente
                          {...val}
                          deleteProcedimiento={() => deleteFirstStepProcedimiento(inx)}
                          editProcedimiento={() => editFirstStepProcedimiento(inx)}
                          indSelect={indSelect}
                          inx={inx}
                          eliminarArchivo={eliminarArchivo}
                          setSelArchivo={setSelObjArchivo}
                          verArchivo={verArchivo}
                        />
                      </AccordionDetails>
                    ) : null,
                  )}
                </Accordion>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Procedimiento;
