import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { TransitionProps } from '@material-ui/core/transitions';
import IAlertDialogProps from './types';

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  ),
);

function DocIdCorrectoPaciente(props: IAlertDialogProps) {
  const { open, usuario, pin, callBackAceptar, callBackClose } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="permiso-acceso-titulo"
      aria-describedby="permiso-acceso-descripcion"
    >
      <div className="flex justify-between items-center bg-blue-500 text-white pl-4">
        <span className="text-lg">{t('acceso_expediente')}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <WarningIcon className="text-red-500" color="inherit" />
        <DialogContentText>
          <p className="text-justify text-red-500 m-0">{t('acceso_expediente_txt')}</p>
          <div className="flex justify-center">
            <p className="pr-6">{`${t('usuario')}: ${usuario.toUpperCase()}`}</p>
            <p>{`${t('contrasena')}: ${pin.toUpperCase()}`}</p>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex justify-center">
          {/* <div className="mr-4">
            <Button onClick={callBackClose} color="primary" variant="outlined">
              {t('cancelar')}
            </Button>
          </div> */}
          <div>
            <Button
              onClick={(e) => {
                callBackAceptar();
                callBackClose(e);
              }}
              color="primary"
              variant="contained"
            >
              {t('aceptar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default DocIdCorrectoPaciente;
