import {
  SET_PACIENTE,
  REMOVE_PACIENTE,
  PACIENTE_ERROR,
  PacienteActionTypes,
  IPacienteState,
  PACIENTE_DIA,
  PACIENTE_MES,
  PACIENTE_ANIO,
  PACIENTE_NOMBRE,
  PACIENTE_SEXO,
  PACIENTE_USUARIO,
  PACIENTE_ID,
  PACIENTE_ID_USUARIO,
  PACIENTE_EDAD,
  PACIENTE_GESTACION,
  PACIENTE_TIPO_EMPLEADO,
  PACIENTE_NUMERO_EMPLEADO,
  PACIENTE_ALERGIAS,
  PACIENTE_TELEFONOS,
  PACIENTE_DOC_ID,
  PACIENTE_SIN_DOC_ID,
  PACIENTE_SHOWCERVICOUTERINO,
} from '@actions/basicosPaciente/types';
import { edadInitial } from '@common/types';
import { getPaciente } from '@utils/commonStore';

const initialState = {
  idPaciente: getPaciente(),
  idUsuario: -1,
  docId: '',
  sinDocId: null,
  usuario: '',
  nombre: '',
  sexo: '',
  dia: '',
  mes: '',
  anio: '',
  mensaje: '',
  error: false,
  edad: edadInitial,
  gestacion: '',
  tipoEmpleado: null,
  numeroEmpleado: '',
  alergias: [],
  telefonos: [],
  showCervicoUterino: false,
  existsEstudios: {
    existsEstudios: false,
    fecha: '',
  },
} as IPacienteState;

// eslint-disable-next-line @typescript-eslint/default-param-last
const BasicosPacienteReducer = (state = initialState, action: PacienteActionTypes) => {
  switch (action.type) {
    case SET_PACIENTE:
      return {
        idPaciente: action.payload.idPaciente,
        idUsuario: action.payload.idUsuario,
        docId: action.payload.docId,
        sinDocId: action.payload.sinDocId,
        usuario: action.payload.usuario,
        nombre: action.payload.nombre,
        sexo: action.payload.sexo,
        dia: action.payload.dia.toString(),
        mes: action.payload.mes.toString(),
        anio: action.payload.anio.toString(),
        mensaje: '',
        error: false,
        edad: action.payload.edad,
        gestacion: action.payload.gestacion,
        tipoEmpleado: action.payload.tipoEmpleado,
        numeroEmpleado: action.payload.numeroEmpleado,
        alergias: action.payload.alergias,
        telefonos: action.payload.telefonos,
        showCervicoUterino: action.payload.showCervicoUterino,
        existsEstudios: action.payload.existsEstudios,
      };
    case REMOVE_PACIENTE:
      return {
        ...initialState,
        idPaciente: 0,
      };
    case PACIENTE_ERROR:
      return {
        ...state,
        error: true,
      };
    case PACIENTE_DIA:
      return {
        ...state,
        dia: action.payload,
      };
    case PACIENTE_MES:
      return {
        ...state,
        mes: action.payload,
      };
    case PACIENTE_ANIO:
      return {
        ...state,
        anio: action.payload,
      };
    case PACIENTE_NOMBRE:
      return {
        ...state,
        nombre: action.payload,
      };
    case PACIENTE_SEXO:
      return {
        ...state,
        sexo: action.payload,
      };
    case PACIENTE_USUARIO:
      return {
        ...state,
        usuario: action.payload,
      };
    case PACIENTE_ID:
      return {
        ...state,
        idPaciente: action.payload,
      };
    case PACIENTE_ID_USUARIO:
      return {
        ...state,
        idUsuario: action.payload,
      };
    case PACIENTE_EDAD:
      return {
        ...state,
        edad: action.payload,
      };
    case PACIENTE_GESTACION:
      return {
        ...state,
        gestacion: action.payload,
      };
    case PACIENTE_TIPO_EMPLEADO:
      return {
        ...state,
        tipoEmpleado: action.payload,
      };
    case PACIENTE_NUMERO_EMPLEADO:
      return {
        ...state,
        numeroEmpleado: action.payload,
      };
    case PACIENTE_ALERGIAS:
      return {
        ...state,
        alergias: action.payload,
      };
    case PACIENTE_TELEFONOS:
      return {
        ...state,
        telefonos: action.payload,
      };
    case PACIENTE_DOC_ID:
      return {
        ...state,
        docId: action.payload,
      };
    case PACIENTE_SIN_DOC_ID:
      return {
        ...state,
        sinDocId: action.payload,
      };
    case PACIENTE_SHOWCERVICOUTERINO:
      return {
        ...state,
        showCervicoUterino: action.payload,
      };
    default:
      return state;
  }
};

export default BasicosPacienteReducer;
