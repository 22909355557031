import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, RadioGroup } from '@material-ui/core';
import DeleteDialog from '@components/DeleteDialog';
import {
  sendConsultoriosListaConsultorios,
  sendConsultoriosSeleccionarConsultorio,
} from '@utils/sendInfo';
import { getConsultorio, resetConsultorio } from '@actions/consultorios/actions';
import { setIdConsultorio, removePaciente } from '@utils/commonStore';
import { limpiarReduxPaciente } from '@utils/reduxCommon';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { getCatalogoAgee, getPerfilMedicoConsultorios } from '@utils/getCatalogos';
import { RootState } from 'src/store';
import { useApiRequest } from '@hooks/useApiRequest';
import Consultorios from './Consultorios';
import ConsultoriosOrganizacion from './ConsultoriosOrganizacion';
import FormularioConsultorios from './FormularioConsultorios';
import { IConsultorio, IPropsConsultorios } from './types';
import { ICatStrEstandar } from '@common/types';

function MisConsultorios(props: IPropsConsultorios) {
  const { t } = useTranslation();
  const { apiRequest } = useApiRequest();
  const { idMedico, enOrganizacion, pais, rol } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { consultorio } = useSelector((state: RootState) => state.Consultorios);
  const { idConsultorio } = consultorio;
  const dispatch = useDispatch();
  const { setActiveStepMedico, activeStepMedico } = props;
  const [selected, setSelected] = useState<string>('');
  const [consultorios, setConsultorios] = useState<IConsultorio[]>([]);
  const [idActualizar, setidActualizar] = useState<number>(-1);
  const [indexToDelete, setIndexToDelete] = useState<number>(-1);
  const [catAGEE, setCatAGEE] = useState<ICatStrEstandar[]>([]);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [consultoriosFilter, setConsultoriosFilter] = useState<IConsultorio[]>([]);

  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
    setDisabledButton(false);
  };

  const handleEditConsultorio = (indice = indexToDelete) => {
    const idCons = consultorios.findIndex(
      (cons) => JSON.stringify(cons) === JSON.stringify(consultoriosFilter[indice]),
    );
    setidActualizar(idCons);
    setDisabledButton(false);
  };

  const handleDeleteConsultorio = () => {
    const nuevaLista = [...consultorios];
    const idCons = consultorios.findIndex(
      (cons) => JSON.stringify(cons) === JSON.stringify(consultoriosFilter[indexToDelete]),
    );
    nuevaLista[idCons].borrado = true;
    setConsultorios(nuevaLista);
  };

  const handleSelectConsultorio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target as HTMLInputElement;
    setSelected(val.value);
  };

  const handleEnviarConsultorios = () => {
    dispatch(setLoading(true));
    const objEnviar = {
      idMedico,
      listaConsultorios: consultorios,
    };
    sendConsultoriosListaConsultorios(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          if (!result.data.listaConsultorios.length) {
            dispatch(resetConsultorio());
          }
          setConsultorios(result.data.listaConsultorios);
          setActiveStepMedico(activeStepMedico + 1);
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${result.code.msg}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  const handleEnviarSeleccion = (moveForward: boolean = true) => {
    apiRequest({
      type: 'send',
      requestFunction: sendConsultoriosSeleccionarConsultorio({
        idMedico,
        idPorDefecto: parseInt(selected, 10),
      }),
      successFunction: () => {
        if (parseInt(selected, 10) !== idConsultorio) {
          if (!idConsulta || !(idPaciente > 0)) {
            setIdConsultorio(parseInt(selected, 10));
            dispatch(getConsultorio(idMedico, parseInt(selected, 10)));
            removePaciente();
            limpiarReduxPaciente();
          }
        }
        if (moveForward) {
          setActiveStepMedico(activeStepMedico + 1);
        }
      },
    });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    Promise.all([getPerfilMedicoConsultorios(idMedico, idOrganizacion), getCatalogoAgee(idMedico)])
      .then((responses) => {
        Promise.all([responses[0].json(), responses[1]])
          .then((results) => {
            if (results[0].code === 200) {
              setConsultorios(results[0].data.listaConsultorios);
              setSelected(
                results[0].data.listaConsultorios
                  .find((cons: any) => cons.porDefecto === true)
                  ?.idConsultorio.toString() || '',
              );
            }
            setCatAGEE(results[1]);
            dispatch(setLoading(false));
          })
          .catch((err) => {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
            dispatch(setLoading(false));
          });
      })
      .catch((err) => {
        if (err.toString() !== 'AbortError: The user aborted a request.') {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        }
        dispatch(setLoading(false));
      });
  }, []);

  useEffect(() => {
    setConsultoriosFilter(consultorios.filter((cons) => !cons.borrado));
  }, [consultorios]);

  return (
    <div>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('_seguro_que_deseas_eliminar_el_registro_')}
        callBackAceptar={handleDeleteConsultorio}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_el_registro_se_borrara_el_que_estas_creando')}
        callBackAceptar={handleEditConsultorio}
        callBackClose={handleEditAlertClose}
      />
      {(() => {
        if (enOrganizacion) {
          if (consultoriosFilter.length > 0) {
            return (
              <>
                <h2 className="text-blue-800 font-normal">{t('lista_de_consultorios')}</h2>
                <RadioGroup
                  value={selected}
                  onChange={handleSelectConsultorio}
                  aria-label="consultorio"
                  name="consultorio-seleccionado"
                >
                  {consultoriosFilter.map((cons) => (
                    <ConsultoriosOrganizacion consultorio={cons} selected={selected} />
                  ))}
                </RadioGroup>
              </>
            );
          }
          return (
            <h2 className="text-blue-800 font-normal">
              No hay consultorios disponibles. Ponte en contacto con el administrador.
            </h2>
          );
        }
        return (
          <>
            <FormularioConsultorios
              idMedico={idMedico}
              pais={pais}
              setConsultorios={setConsultorios}
              idActualizar={idActualizar}
              consultorios={consultorios}
              setIdActualizar={setidActualizar}
              catAGEE={catAGEE}
              setDisabledButton={setDisabledButton}
            />
            <h2 className="text-blue-800 font-normal">{t('lista_de_consultorios')}</h2>
            {consultoriosFilter.map((cons, indx) => (
              <Consultorios
                consultorio={cons}
                setIdActualizar={setidActualizar}
                index={indx}
                setDeleteAlert={setDeleteAlert}
                setEditAlert={setEditAlert}
                setIndexToDelete={setIndexToDelete}
              />
            ))}
          </>
        );
      })()}
      {rol.grupo === 'salud' && (!enOrganizacion || consultoriosFilter.length > 0) && (
        <div className="flex justify-end">
          <Button
            variant="contained"
            color="primary"
            type="button"
            size="large"
            disabled={!consultorios.length || disabledButton}
            onClick={() => {
              if (enOrganizacion) {
                handleEnviarSeleccion();
              } else {
                handleEnviarConsultorios();
              }
            }}
          >
            {t('guardar_y_continuar')}
          </Button>
        </div>
      )}
      {rol.grupo !== 'salud' && consultoriosFilter.length > 0 && (
        <div className="flex justify-end">
          <Button
            variant="contained"
            color="primary"
            type="button"
            size="large"
            onClick={() => handleEnviarSeleccion(false)}
          >
            {t('guardar')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default MisConsultorios;
