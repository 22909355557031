import { Menu } from '@components/Menu';
import { NavigationSection } from '@components/NavigationSection';
import { NavigateButton } from '@components/NavigateButton';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ExploracionFisica from '../ExploracionFisica';
import { IMcpa, mcpaInitial } from '../MCPA/types';
import { RootState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import EditorMCPA from '../MCPA/EditorMCPA';
import ProcedimientoFormulario from '../Antecedentes/PersonalesPatologicos/Procedimiento/ProcedimientoFormulario';
import { IProcedimiento, IProcedimientoFormulario, procedimientoFormularioInitial, procedimientoInitial } from '../Antecedentes/PersonalesPatologicos/Procedimiento/types';
import { setProcDiagnosticos, setProceso } from '@actions/paciente/actions';
import { SubmitButton } from '@components/FormElemntsLibrary/SubmitButton';
import RegistrarLaboratorioComponente from '../DiagnosticoPlan/Plan/Laboratorio/Registrar';
import { mcpaCompToString, sortArrayByDate, valFechaToSave } from '@common/functions';
import { useApiRequest } from '@hooks/useApiRequest';
import { mcpaEditarMCPA } from '@utils/editInfo';
import { mcpaGuardarMCPA, sendProcedimientos } from '@utils/sendInfo';
import { IFechaObj } from '@common/types';

function ExamenesOcupacionales() {
  const { t } = useTranslation('translation');
  const { apiRequests } = useApiRequest();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const {
    expediente: { antPatologicos: { procDiagnosticos } },
    proceso,
  } = useSelector((state: RootState) => state.Paciente);
  interface ISeccionProceso {
    id: string;
    seccion: number;
    idFormatoMC: number;
    label: string;
  }
  const [sections, setSections] = useState<ISeccionProceso[]>([]);
  const [selectedSection, setSelectedSection] = useState<ISeccionProceso>({
    id: '',
    seccion: 0,
    idFormatoMC: 0,
    label: '',
  });
  const [indexFormato, setIndexFormato] = useState<number>(-1);
  const [mcpaTemp, setMcpaTemp] = useState<IMcpa>(mcpaInitial);
  const [procedimiento, setProcedimiento] = useState<IProcedimiento>(procedimientoInitial);
  const [formulario, setFormulario] = useState<IProcedimientoFormulario>(procedimientoFormularioInitial);
  const labels: { [key: number]: string } = {
    20: t('exploracion_fisica'),
    51: t('laboratorio'),
  }

  useEffect(() => {
    if (proceso.secciones.length) {
      const seccionesTemp: ISeccionProceso[] = [];
      proceso.secciones.forEach(sec => {
        if (sec === 46) {
          proceso.formatosMC.forEach(formato => {
            seccionesTemp.push({
              id: `${sec}-${formato.mcpa.formato.id}`,
              seccion: sec,
              idFormatoMC: formato.mcpa.formato.id,
              label: formato.mcpa.formato.nombre,
            });
          });
        } else {
          seccionesTemp.push({
            id: sec.toString(),
            seccion: sec,
            idFormatoMC: 0,
            label: labels[sec] || ''
          });
        }
      })
      setSections(seccionesTemp);
      setSelectedSection(seccionesTemp[0]);
    }
  }, [proceso.secciones]);

  const handleSelectSection = (sec: ISeccionProceso) => {
    setSelectedSection(sec);
    if (sec.seccion === 46) {
      // Formato de motivo de consulta
      const index = proceso.formatosMC.findIndex(f => f.mcpa.formato.id === sec.idFormatoMC);
      setIndexFormato(index);
      setMcpaTemp(proceso.formatosMC[index].mcpa || mcpaInitial);
      // Procedimiento diagnóstico
      const newProcedimiento: IProcedimiento | undefined = procDiagnosticos.find(
        proc => proc.idProceso === proceso.id && proc.cie9.id === proceso.formatosMC[index].cie9.id
      );
      setProcedimiento(newProcedimiento || procedimientoInitial);
      const hoy = new Date();
      setFormulario(newProcedimiento ? {
        radioSel:
          newProcedimiento.txtMotivo && newProcedimiento.txtMotivo.length > 0 ? 'txt' : 'cie',
        diagnostico: newProcedimiento.enfermedad,
        diagEspecifica: newProcedimiento.txtDiagnostico,
        motivo: newProcedimiento.cie9,
        motivoEspecifica: newProcedimiento.txtMotivo,
        fecha: {
          dia: newProcedimiento.dia || '',
          mes: newProcedimiento.mes || '',
          anio: newProcedimiento.anio,
        },
        comentarios: newProcedimiento.comentarios,
        clearComentario: Math.floor(Math.random() * 1000) + 1,
      } : {
        ...procedimientoFormularioInitial,
        motivo: proceso.formatosMC[index].cie9,
        fecha: {
          dia: hoy.getDate().toString(),
          mes: (hoy.getMonth() + 1).toString(),
          anio: hoy.getFullYear().toString(),
        }
      });
    } else {
      setProcedimiento(procedimientoInitial);
      setMcpaTemp(mcpaInitial);
    }
  };

  const handleSaveProcedimiento = () => {
    const valDate: IFechaObj = valFechaToSave(formulario.fecha);
    const objProcedimiento: IProcedimiento = {
      idPaciente,
      idConsulta,
      idProceso: -1,
      idProcedimiento: procedimiento.idProcedimiento,
      enfermedad: formulario.diagnostico,
      txtDiagnostico: formulario.diagEspecifica || '',
      txtMotivo: formulario.motivoEspecifica || '',
      cie9: formulario.motivo,
      dia: valDate.dia,
      mes: valDate.mes,
      anio: valDate.anio,
      comentarios: formulario.comentarios,
      documentos: procedimiento.documentos,
      archivos: procedimiento.archivos,
    };
    const objMCPA = {
      idMedico,
      idMCPAInfo: mcpaTemp.id,
      idConsulta,
      constructor: mcpaTemp.componentes,
      resumen: mcpaTemp.componentes.map((singleComp) => mcpaCompToString(singleComp)).join('\n'),
      idMotivosConsulta: mcpaTemp.formato.id,
      opciones: mcpaTemp.opciones,
      tipo: mcpaTemp.formato.tipo === 'medipraxi',
    };
    apiRequests({
      type: 'send',
      requestFunctions: [
        sendProcedimientos({
          ...objProcedimiento,
          idMedico,
          idConsultorio,
          tipo: 'diagnostico',
        }),
        mcpaTemp.id > 0 ? mcpaEditarMCPA(objMCPA) : mcpaGuardarMCPA(objMCPA),
      ],
      successFunctions: [
        (result) => {
          const newProcedimientos = [...procDiagnosticos];
          if (procedimiento.idProcedimiento > 0) { // Si el registro ya existe, se actualiza
            newProcedimientos[newProcedimientos.findIndex(proc => proc.idProcedimiento === procedimiento.idProcedimiento)] = procedimiento;
            dispatch(setProcDiagnosticos(newProcedimientos));
          } else { // Si el registro es nuevo se le asigna el id y se agrega a la lista
            procedimiento.idProcedimiento = result.datos;
            newProcedimientos.push(procedimiento);
            dispatch(setProcDiagnosticos(sortArrayByDate(newProcedimientos)));
          }
        },
        (result) => {
          if (mcpaTemp.id <= 0) {
            setMcpaTemp({ ...mcpaTemp, id: result.datos });
          }
          const procesosTemp = {...proceso};
          procesosTemp.formatosMC[indexFormato].mcpa = {
            ...mcpaTemp,
            id: mcpaTemp.id > 0 ? mcpaTemp.id : result.datos,
          }
          dispatch(setProceso(procesosTemp));
        },
      ],
    });
  };


  return (
    <>
      <div className="pb-0 bg-white w-full mx-auto md:container rounded-t-md border-solid border border-gray-100 shadow">
        <Menu title={t('perfil')}>
          <NavigationSection key={'perfil'} title="">
            {sections.map((sec, index) => (
              <NavigateButton
                key={sec.id}
                title={sec.label}
                onClick={() => handleSelectSection(sec)}
                position={index === 0 ? 'first' : (index === sections.length ? 'last' : '')}
                isActive={selectedSection.id === sec.id}
                asTabs
              />
            ))}
          </NavigationSection>
        </Menu>
        {selectedSection.seccion === 20 && <ExploracionFisica />}
        {selectedSection.seccion === 46 &&
          <div className="p-4">
            <div>
              <h1 className="w-full font-normal text-center text-blue-800 mb-3">
                {selectedSection.label}
              </h1>
            </div>
            <ProcedimientoFormulario
              tipo="diagnostico"
              formulario={formulario}
              setFormulario={setFormulario}
            />
            <EditorMCPA mcpa={mcpaTemp} setMcpa={setMcpaTemp} emo />
            <SubmitButton
              label={t('guardar')}
              saveCallback={handleSaveProcedimiento}
            />
          </div>
        }
        {selectedSection.seccion === 51 && (
          <div className="p-4">
            <RegistrarLaboratorioComponente />
          </div>
        )}
      </div>
    </>
  );
}

export default ExamenesOcupacionales;
