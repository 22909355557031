import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import WarningIcon from '@material-ui/icons/Warning';
import { TransitionProps } from '@material-ui/core/transitions';
import { withStyles, Theme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
// import useStyles from './styles';
import { validacionSoloNumeros } from '@constants/validaciones';
import IAlertDialogProps from './types';

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  ),
);

function IniciarConsulta(props: IAlertDialogProps) {
  const {
    open,
    pass,
    setPass,
    callBackAceptar,
    callBackClose,
    callBackSendCode,
    showSinPermiso,
    // setShowSinPermiso,
  } = props;
  const ColorRedButton = withStyles((theme: Theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);
  const [viewPass, setViewPass] = useState<boolean>(false);
  const { t } = useTranslation();
  // const classes = useStyles();
  const [verTiempo, setVerTiempo] = useState<boolean>(true);
  const [tiempoRestante, setTiempoRestante] = useState<number>(0);

  const handleChangePIN = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (val === '' || validacionSoloNumeros(val)) {
      setPass(val);
    }
  };

  useEffect(() => {
    if (tiempoRestante > 0) {
      setTimeout(() => setTiempoRestante((tR) => tR - 1), 1000);
    } else {
      setVerTiempo(false);
    }
  }, [tiempoRestante]);

  useEffect(() => {
    if (open) {
      setVerTiempo(true);
      setTiempoRestante(25);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="doc-id-correcto-titulo"
      aria-describedby="doc-id-correcto-descripcion"
    >
      <div className="flex justify-between items-center bg-blue-500 text-white pl-4">
        <span className="text-lg">{t('pacientes-iniciar-existe')}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <WarningIcon className="text-yellow-500" color="inherit" />
        <DialogContentText>
          {!showSinPermiso ? (
            <>
              <p className="text-justify text-gray-500 mt-4">{t('poder_acceder_txt')}</p>
              <div className="p-4 grid grid-cols-3 place-items-center">
                <div />
                <TextField
                  placeholder="XXXX"
                  variant="outlined"
                  type={viewPass ? 'text' : 'password'}
                  fullWidth
                  value={pass}
                  onChange={handleChangePIN}
                  InputProps={{
                    endAdornment: (
                      <div className="text-gray-500">
                        <LockIcon color="inherit" />
                      </div>
                    ),
                    inputProps: {
                      autoComplete: 'off',
                      'aria-autocomplete': 'none',
                      maxLength: 4,
                    },
                  }}
                />
                <div className="text-right">
                  <Button
                    color="primary"
                    startIcon={viewPass ? <Visibility /> : <VisibilityOff />}
                    onClick={() => setViewPass((prevVal) => !prevVal)}
                  >
                    {viewPass ? t('ocultar_contrasenia') : t('mostrar_contrasenia')}
                  </Button>
                </div>
              </div>
              <h5 className="text-right font-normal text-gray-500 m-0">{t('olvido_pass')}</h5>
              <div className="flex justify-between">
                {/* <Button
                  color="primary"
                  size="medium"
                  className={classes.noTextTranform}
                  onClick={() => setShowSinPermiso((evl: boolean) => !evl)}
                >
                  {t('no_tengo_pass')}
                </Button> */}
                {verTiempo ? (
                  <Button disabled size="medium">
                    {`Reintentar en ${tiempoRestante}`}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    size="medium"
                    onClick={() => {
                      callBackSendCode();
                      setVerTiempo(true);
                      setTiempoRestante(20);
                    }}
                  >
                    {t('reenviar_pass')}
                  </Button>
                )}
              </div>
            </>
          ) : (
            <>
              <p className="text-justify text-red-500 m-0">{t('doc_id_error_consulta_l1')}</p>
              <p className="text-justify text-red-500 m-0">
                <Trans i18nKey="doc_id_error_consulta_l2">
                  index0
                  <strong title="index1">index1</strong>
                </Trans>
              </p>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!showSinPermiso ? (
          <div className="w-full flex justify-center">
            <div className="mr-4">
              <Button onClick={callBackClose} color="primary" variant="outlined">
                {t('cancelar')}
              </Button>
            </div>
            <div>
              <Button onClick={callBackAceptar} color="primary" variant="contained">
                {t('aceptar')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex justify-center flex-grow">
            <ColorRedButton onClick={callBackAceptar} color="primary" variant="outlined">
              {t('consulta_sin_contra')}
            </ColorRedButton>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default IniciarConsulta;
