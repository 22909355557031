import { Dispatch, RefObject, SetStateAction } from 'react';
import { IListaRecetas, IMedicamento } from '../types';

export interface IListaMedicamentosProps {
  tratamientosActuales: IMedicamento[];
  setTratamientosActuales: Dispatch<SetStateAction<IMedicamento[]>>;
  indiceReceta: number;
  recetas: IListaRecetas[];
  setRecetas: Dispatch<SetStateAction<IListaRecetas[]>>;
  setIndiceReceta: Dispatch<SetStateAction<number>>;
  setOpenMEMR: Dispatch<SetStateAction<boolean>>;
  setIDMedicamentoEliminar: Dispatch<SetStateAction<number>>;
  setOpenMQMR: Dispatch<SetStateAction<boolean>>;
  setIDMedicamentoQuitar: Dispatch<SetStateAction<number>>;
  setOpenModalEditar: Dispatch<SetStateAction<boolean>>;
  setMedicamentoAEditar: Dispatch<SetStateAction<IMedicamento>>;
  formularioRef: RefObject<HTMLDivElement>;
}

export const ItemTypes = {
  MEDICAMENTO_RECETA: 'medicamento_receta',
  MEDICAMENTO_LISTA: 'medicamento_lista',
};

export interface IItemType {
  type: string;
  index: number;
}
