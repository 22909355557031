import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from './type';
import { CirclePlus, TriangleAlert, Trash, Trash2 } from 'lucide-react';

// eslint-disable-next-line import/prefer-default-export
export const Button: FC<ButtonProps> = ({
  buttonClass = '',
  type = 'button',
  label = null,
  onClick,
  width = 'full',
  color = 'blue',
  size = 'sm',
  icon = null,
  iconSize = 18,
  iconColor,
  disable = false,
  position,
}) => {
  const { t } = useTranslation();

  const getStyle = () => {
    let rounded = 'rounded-md';
    if (position) {
      rounded = position === 'first' ? 'rounded-l-lg' : 'rounded-r-lg';
    }
    const base = `${rounded} shadow-gray-500 shadow-sm`;
    switch (color) {
      case 'outlined':
        return `${base} hover:bg-gray-700`;
      case 'default':
        return `${base} bg-[#e0e0e0] hover:bg-gray-700`;
      case 'primary':
        return `${base} text-white bg-[#3B82F6] hover:bg-blue-700`;
      case 'success':
        return `${base} text-white bg-[#0AE639] hover:bg-green-700`;
      case 'error':
        return `${base} text-white bg-[#F63B44] hover:bg-red-700`;
      case 'warning':
        return `${base} text-white bg-[#F6B43B] hover:bg-orange-700`;
      case 'selected':
        return `${base} text-white bg-[#1E40AF]`;
      case 'deselected':
        return `${base} bg-white hover:bg-[#CFCFCF]`;
      default:
        return `text-${color}-600`;
    }
  };

  const getButtonColor = () => {
    switch (color) {
      case 'blue':
        return '#2563eb';
      case 'green':
        return '#16a34a';
      case 'red':
        return '#dc2626';
      case 'orange':
        return '#ea580c';
      default:
        return 'white';
    }
  };

  const getIcon = () => {
    const iColor = iconColor || getButtonColor();
    switch (icon) {
      case 'add':
        return <CirclePlus size={iconSize} color={iColor} />;
      case 'alert':
        return <TriangleAlert size={iconSize} color={iColor} />;
      case 'trash':
        return <Trash size={iconSize} color={iColor} />;
      case 'trash2':
        return <Trash2 size={iconSize} color={iColor} />;
      default:
        return icon;
    }
  };

  return (
    <button
      type={type}
      color="default"
      className={`flex flex-row gap-x-2 w-${width} h-11 px-4 text-${size} ${getStyle()} cursor-pointer items-center ${icon ? '' : 'justify-center'} ${
        buttonClass
      } transition-all duration-200 disabled:opacity-75 disabled:cursor-not-allowed`}
      disabled={disable}
      onClick={onClick}
    >
      {getIcon()}
      {label}
    </button>
  );
};
