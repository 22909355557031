import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Autocomplete } from '@material-ui/lab';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { RootState } from 'src/store';
import { ICatalogosEstandar } from '@common/types';
import { sendDictamenMedLab } from '@utils/sendInfo';
import { IEducacion } from '@actions/me/types';
import { useApiRequest } from '@hooks/useApiRequest';
import useStyles from './styles';
import { ICatalogosCombinados, dictamenMedLabInitial } from '../../types';
import { IFormularioDictamenMedLabProps } from './types';

function FormularioDictamenMedLab({
  setTipoCatalogo,
  setActiveSubStep,
  dictamenesMedLab,
  setDictamenesMedLab,
  objDictamenMedLab,
  setObjDictamenMedLab,
  listaTipoExamen,
  catTiposExamen,
  setCatTiposExamen,
  catPuestos,
  setCatPuestos,
  catDictamenes,
  catRestricciones,
  setCatRestricciones,
  catCausasNoAptitud,
  setCatCausasNoAptitud,
  enfermedades,
}: IFormularioDictamenMedLabProps) {
  const { t } = useTranslation();
  const { apiRequest } = useApiRequest();
  const classes = useStyles();
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    idMedico,
    especialidades: especialidadesMedico,
    sexo: sexoMedico,
    nombre: nombreMedico,
    primerApellido: primerApellidoMedico,
    segundoApellido: segundoApellidoMedico,
    carrera: carreraMedico,
  } = useSelector((state: RootState) => state.Me);
  const {
    idPaciente,
    nombre: nombrePaciente,
    sexo: sexoPaciente,
    edad: { edad: edadPaciente },
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const clearFormulario = () => {
    setObjDictamenMedLab({ ...dictamenMedLabInitial, idConsulta });
  };
  const handleChangeTipoExamen = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    setObjDictamenMedLab({
      ...objDictamenMedLab,
      tipoExamen: catTiposExamen.find(
        (te: ICatalogosCombinados) => `${te.source}-${te.id}` === val,
      ) || {
        id: 0,
        label: '',
        categoria: { id: 0, label: '' },
        source: 'medipraxi',
        inUse: false,
      },
    });
  };
  const handleChangePuesto = (event: any, newValue: ICatalogosCombinados | null) => {
    setObjDictamenMedLab({
      ...objDictamenMedLab,
      puesto: newValue || {
        id: 0,
        label: '',
        categoria: { id: 0, label: '' },
        source: 'medipraxi',
        inUse: false,
      },
    });
  };
  const handleChangeDictamen = (event: any, newValue: ICatalogosEstandar | null) => {
    setObjDictamenMedLab({
      ...objDictamenMedLab,
      dictamen: newValue || { id: 0, label: '' },
      sinRestricciones: !newValue || [2, 6, 9].includes(newValue.id),
    });
  };
  const handleChangeIndicacion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjDictamenMedLab({
      ...objDictamenMedLab,
      sinRestricciones: (event.target.value as string) === 'sin',
    });
  };
  const handleChangeComentariosRestricciones = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjDictamenMedLab({
      ...objDictamenMedLab,
      comentariosRestricciones: event.target.value as string,
    });
  };
  const handleChangeCertifica = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjDictamenMedLab({
      ...objDictamenMedLab,
      certifica: event.target.value as string,
    });
  };
  const handleChangeRecomendaciones = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjDictamenMedLab({ ...objDictamenMedLab, recomendaciones: event.target.value as string });
  };

  const handleEditCatalogo = (
    tipoCatalogo: 'tipo_examen' | 'puesto' | 'restricciones' | 'causas_no_aptitud',
  ) => {
    setTipoCatalogo(tipoCatalogo);
    setActiveSubStep('catalogos');
  };

  const disableSave = () =>
    objDictamenMedLab.tipoExamen.id === 0 ||
    objDictamenMedLab.puesto.id === 0 ||
    objDictamenMedLab.certifica.length === 0 ||
    ((objDictamenMedLab.tipoExamen.source === 'propio' || objDictamenMedLab.tipoExamen.id !== 2) &&
      objDictamenMedLab.dictamen.id === 0) ||
    (([2, 6, 9].includes(objDictamenMedLab.dictamen.id) ||
      (objDictamenMedLab.tipoExamen.id === 2 && !objDictamenMedLab.sinRestricciones)) &&
      objDictamenMedLab.arrayRestriccionTotal.length === 0 &&
      objDictamenMedLab.arrayRestriccionParcial.length === 0);

  const handleAddDictamen = () => {
    const dataToSend = {
      idPaciente,
      idMedico,
      idOrganizacion,
      idConsultorio,
      idConsulta,
      datos: {
        id: objDictamenMedLab.id,
        tipoExamen: {
          id: objDictamenMedLab.tipoExamen.id,
          source: objDictamenMedLab.tipoExamen.source,
        },
        puesto: {
          id: objDictamenMedLab.puesto.id,
          source: objDictamenMedLab.puesto.source,
        },
        dictamen: objDictamenMedLab.dictamen.id,
        sinRestricciones: objDictamenMedLab.sinRestricciones,
        certifica: objDictamenMedLab.certifica,
        arrayRestriccionTotal: objDictamenMedLab.arrayRestriccionTotal,
        arrayRestriccionParcial: objDictamenMedLab.arrayRestriccionParcial,
        comentariosRestricciones: objDictamenMedLab.comentariosRestricciones,
        arrayCausaNoAptitudEnf: objDictamenMedLab.arrayCausaNoAptitudEnf,
        arrayCausaNoAptitudOtra: objDictamenMedLab.arrayCausaNoAptitudOtra,
        recomendaciones: objDictamenMedLab.recomendaciones,
      },
    };
    apiRequest({
      type: 'send',
      requestFunction: sendDictamenMedLab(dataToSend),
      successFunction: (result: any) => {
        const newDictamenesMedLab = dictamenesMedLab;
        if (objDictamenMedLab.id > 0) {
          newDictamenesMedLab[dictamenesMedLab.findIndex((d) => d.id === objDictamenMedLab.id)] = {
            ...objDictamenMedLab,
          };
        } else {
          newDictamenesMedLab.unshift({
            ...objDictamenMedLab,
            id: result.data,
          });
        }
        setDictamenesMedLab(newDictamenesMedLab);
        const newCatTiposExamen = [...catTiposExamen];
        const indexTe = newCatTiposExamen.findIndex(
          (te) => te.id === objDictamenMedLab.tipoExamen.id,
        );
        newCatTiposExamen[indexTe].inUse = true;
        setCatTiposExamen(newCatTiposExamen);
        const newCatPuestos = [...catPuestos];
        const indexP = newCatPuestos.findIndex((te) => te.id === objDictamenMedLab.puesto.id);
        newCatPuestos[indexP].inUse = true;
        setCatPuestos(newCatPuestos);
        const newCatRestricciones = [...catRestricciones];
        newCatRestricciones.map((restricccion) => ({
          ...restricccion,
          inUse:
            restricccion.inUse === false
              ? objDictamenMedLab.arrayRestriccionTotal.includes(
                  `${restricccion.source}-${restricccion.id}`,
                ) ||
                objDictamenMedLab.arrayRestriccionParcial.includes(
                  `${restricccion.source}-${restricccion.id}`,
                )
              : true,
        }));
        setCatRestricciones(newCatRestricciones);
        const newCatCausasNoAptitud = [...catCausasNoAptitud];
        newCatCausasNoAptitud.map((restricccion) => ({
          ...restricccion,
          inUse:
            restricccion.inUse === false
              ? objDictamenMedLab.arrayRestriccionTotal.includes(
                  `${restricccion.source}-${restricccion.id}`,
                ) ||
                objDictamenMedLab.arrayRestriccionParcial.includes(
                  `${restricccion.source}-${restricccion.id}`,
                )
              : true,
        }));
        setCatCausasNoAptitud(newCatCausasNoAptitud);
        clearFormulario();
      },
    });
  };

  const getTextoCertifica = (tipo: 'dictamen' | 'emp') => {
    const especialidades: string[] = especialidadesMedico.map(
      (esp: IEducacion) => `${esp.titulo} + (cédula profesional No. ${esp.cedula})`,
    );
    const suscrito: string[] = [
      `${
        sexoMedico === 'H' ? 'El suscrito médico' : 'La suscrita médica'
      }, ${nombreMedico} ${primerApellidoMedico} ${segundoApellidoMedico}, `,
      `legalmente autorizada por la Dirección General de Profesiones para ejercer la profesión de ${carreraMedico.titulo} `,
      `(cédula profesional No. ${carreraMedico.cedula})${
        especialidades.length > 0 ? ` ${especialidades.join(', ')}` : ''
      }.\n\n`,
      'Certifica: Que habiendo practicado el reconocimiento médico por interrogatorio, exploración física y los exámenes correspondientes a ',
      `${nombrePaciente}, de sexo ${
        sexoPaciente === 'H' ? 'Hombre' : 'Mujer'
      }, de ${edadPaciente} años de edad, `,
    ];
    switch (tipo) {
      case 'dictamen':
        return suscrito.concat([
          `quien ha sido ${
            sexoPaciente === 'H' ? 'evaluado' : 'evaluada'
          } para ocupar el puesto de ${objDictamenMedLab.puesto.label}, `,
          `encontrando ${
            sexoPaciente === 'H' ? 'al' : 'a la'
          } aspirante ${objDictamenMedLab.dictamen.label.toLocaleLowerCase()} `,
          'para desempeñar sus actividades laborales.',
        ]);
      case 'emp':
        return suscrito.concat([
          `quien ha sido ${
            sexoPaciente === 'H' ? 'evaluado' : 'evaluada'
          } ejerciendo el puesto de ${objDictamenMedLab.puesto.label}, `,
          `encontrando ${sexoPaciente === 'H' ? 'al colaborador' : 'a la colaboradora'} ${
            objDictamenMedLab.sinRestricciones ? 'sin' : 'con'
          } restricciones `,
          'para desempeñar sus actividades laborales.',
        ]);
      default:
        return [];
    }
  };

  const renderRestriccionesTotales = (selected: string[]) => (
    <div className="flex flex-wrap">
      {selected.map((value, inx) => (
        <Chip
          onMouseDown={(event) => event.stopPropagation()}
          onDelete={() => {
            const valChip = [...objDictamenMedLab.arrayRestriccionTotal];
            valChip.splice(inx, 1);
            setObjDictamenMedLab({
              ...objDictamenMedLab,
              arrayRestriccionTotal: valChip,
            });
          }}
          key={value}
          label={catRestricciones.find((r) => `${r.source}-${r.id}` === value)?.label || ''}
          variant="outlined"
          color="primary"
        />
      ))}
    </div>
  );
  const renderRestriccionesParciales = (selected: string[]) => (
    <div className="flex flex-wrap">
      {selected.map((value, inx) => (
        <Chip
          onMouseDown={(event) => event.stopPropagation()}
          onDelete={() => {
            const valChip = [...objDictamenMedLab.arrayRestriccionParcial];
            valChip.splice(inx, 1);
            setObjDictamenMedLab({
              ...objDictamenMedLab,
              arrayRestriccionParcial: valChip,
            });
          }}
          key={value}
          label={catRestricciones.find((r) => `${r.source}-${r.id}` === value)?.label || ''}
          variant="outlined"
          color="primary"
        />
      ))}
    </div>
  );
  const renderCausasNoAptitudEnf = (selected: string[]) => (
    <div className="flex flex-wrap">
      {selected.map((value, inx) => (
        <Chip
          onMouseDown={(event) => event.stopPropagation()}
          onDelete={() => {
            const valChip = [...objDictamenMedLab.arrayCausaNoAptitudEnf];
            valChip.splice(inx, 1);
            setObjDictamenMedLab({
              ...objDictamenMedLab,
              arrayCausaNoAptitudEnf: valChip,
            });
          }}
          key={value}
          label={value}
          variant="outlined"
          color="primary"
        />
      ))}
    </div>
  );
  const renderCausasNoAptitudOtras = (selected: string[]) => (
    <div className="flex flex-wrap">
      {selected.map((value, inx) => (
        <Chip
          onMouseDown={(event) => event.stopPropagation()}
          onDelete={() => {
            const valChip = [...objDictamenMedLab.arrayCausaNoAptitudOtra];
            valChip.splice(inx, 1);
            setObjDictamenMedLab({
              ...objDictamenMedLab,
              arrayCausaNoAptitudOtra: valChip,
            });
          }}
          key={value}
          label={catCausasNoAptitud.find((c) => `${c.source}-${c.id}` === value)?.label || ''}
          variant="outlined"
          color="primary"
        />
      ))}
    </div>
  );

  useEffect(() => {
    if (
      objDictamenMedLab.tipoExamen.source === 'medipraxi' &&
      objDictamenMedLab.tipoExamen.id === 2
    ) {
      setObjDictamenMedLab({
        ...objDictamenMedLab,
        dictamen: { id: 0, label: '' },
      });
    } else {
      setObjDictamenMedLab({
        ...objDictamenMedLab,
        sinRestricciones: true,
        certifica: '',
      });
    }
  }, [objDictamenMedLab.tipoExamen]);
  useEffect(() => {
    if (objDictamenMedLab.tipoExamen.id > 0 && objDictamenMedLab.puesto.id > 0) {
      if (
        objDictamenMedLab.tipoExamen.source === 'medipraxi' &&
        objDictamenMedLab.tipoExamen.id === 2
      ) {
        setObjDictamenMedLab({
          ...objDictamenMedLab,
          certifica: getTextoCertifica('emp').join(''),
        });
      } else if (objDictamenMedLab.dictamen.id > 0) {
        setObjDictamenMedLab({
          ...objDictamenMedLab,
          certifica: getTextoCertifica('dictamen').join(''),
        });
      }
    }
  }, [
    objDictamenMedLab.tipoExamen,
    objDictamenMedLab.puesto,
    objDictamenMedLab.dictamen,
    objDictamenMedLab.sinRestricciones,
  ]);

  return (
    <div className="p-4 bg-white">
      <div className="w-full my-4">
        <div className="flex flex-row">
          <h3 className="text-gray-500 font-normal m-0 mb-2 mr-4 pt-2">{`${t('tipo_examen')}*`}</h3>
          <Button
            color="primary"
            size="small"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => handleEditCatalogo('tipo_examen')}
          >
            {t('crear_editar_opciones')}
          </Button>
        </div>
        <FormControl variant="outlined" fullWidth>
          <Select
            displayEmpty
            labelId="tipoExamen"
            id="tipoExamen"
            value={`${objDictamenMedLab.tipoExamen.source}-${objDictamenMedLab.tipoExamen.id}`}
            onChange={handleChangeTipoExamen}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 375,
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              <span className="text-gray-300">{t('selecciona')}</span>
            </MenuItem>
            {listaTipoExamen}
          </Select>
        </FormControl>
      </div>
      <div className="w-full my-4">
        <div className="flex flex-row">
          <h3 className="text-gray-500 font-normal m-0 mb-2 mr-4 pt-2">
            {`${
              objDictamenMedLab.tipoExamen.source === 'propio' ||
              [2, 3].includes(objDictamenMedLab.tipoExamen.id)
                ? t('puesto_actual')
                : t('puesto_aspirado')
            }*`}
          </h3>
          <Button
            color="primary"
            size="small"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => handleEditCatalogo('puesto')}
          >
            {t('crear_editar_opciones')}
          </Button>
        </div>
        <Autocomplete
          options={catPuestos}
          getOptionLabel={(option) =>
            `${option.label} ${option.source === 'propio' ? '(opción creada)' : ''}`
          }
          fullWidth
          noOptionsText={t('sin_resultados')}
          value={objDictamenMedLab.puesto}
          onChange={(e, nV) => handleChangePuesto(e, nV)}
          renderInput={(params) => (
            <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
          )}
        />
      </div>
      <div className="w-full my-4">
        {objDictamenMedLab.tipoExamen.source === 'medipraxi' &&
        objDictamenMedLab.tipoExamen.id === 2 ? (
          <>
            <h3 className="text-gray-500 font-normal m-0 mb-2">{`${t('indicacion_med_lab')}*`}</h3>
            <RadioGroup
              row
              value={objDictamenMedLab.sinRestricciones ? 'sin' : 'con'}
              onChange={handleChangeIndicacion}
            >
              <FormControlLabel
                value="sin"
                control={<Radio color="primary" />}
                label={t('ninguna')}
              />
              <FormControlLabel
                value="con"
                control={<Radio color="primary" />}
                label={t('con_restricciones')}
              />
            </RadioGroup>
          </>
        ) : (
          <>
            <h3 className="text-gray-500 font-normal m-0 mb-2">{`${t('dictamen')}*`}</h3>
            <Autocomplete
              options={catDictamenes}
              getOptionLabel={(option) => option.label}
              fullWidth
              noOptionsText={t('sin_resultados')}
              value={objDictamenMedLab.dictamen}
              onChange={(e, nV) => handleChangeDictamen(e, nV)}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          </>
        )}
      </div>
      <div className="w-full my-4">
        <h3 className="text-gray-500 font-normal m-0 mb-2">{`${t('certifica')}*`}</h3>
        <TextField
          variant="outlined"
          multiline
          minRows={4}
          fullWidth
          value={objDictamenMedLab.certifica}
          onChange={handleChangeCertifica}
          helperText={`${
            objDictamenMedLab.certifica ? objDictamenMedLab.certifica.length : 0
          }/1000`}
          inputProps={{ maxLength: 1000, autoComplete: 'off', 'aria-autocomplete': 'none' }}
          FormHelperTextProps={{ className: classes.helperText }}
        />
      </div>
      {((objDictamenMedLab.tipoExamen.source === 'medipraxi' &&
        objDictamenMedLab.tipoExamen.id === 2 &&
        objDictamenMedLab.sinRestricciones) ||
        ((objDictamenMedLab.tipoExamen.source === 'propio' ||
          objDictamenMedLab.tipoExamen.id !== 2) &&
          [1, 4, 7].includes(objDictamenMedLab.dictamen.id))) && (
        <>
          <h3 className="text-gray-500 font-normal m-0 mr-4">{`${t('restricciones')}`}</h3>
          <h4 className="font-normal mt-0">{t('sin_restricciones')}</h4>
        </>
      )}
      {((objDictamenMedLab.tipoExamen.source === 'medipraxi' &&
        objDictamenMedLab.tipoExamen.id === 2 &&
        !objDictamenMedLab.sinRestricciones) ||
        ((objDictamenMedLab.tipoExamen.source === 'propio' ||
          objDictamenMedLab.tipoExamen.id !== 2) &&
          [2, 6, 9].includes(objDictamenMedLab.dictamen.id))) && (
        <>
          <h3 className="text-gray-500 font-normal m-0 mb-2 mr-4">{`${t('restricciones')}*`}</h3>
          <div className="border-solid border border-gray-300 rounded p-4 mb-3">
            <div className="flex flex-row">
              <h4 className="text-gray-500 font-normal m-0 mb-2 mr-4 pt-2">
                {`${t('restriccion_total')}:`}
              </h4>
              <Button
                color="primary"
                size="small"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => handleEditCatalogo('restricciones')}
              >
                {t('crear_editar_opciones')}
              </Button>
            </div>
            <div className="flex flex-wrap items-end justify-end">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">{t('selecciona')}</InputLabel>
                <Select
                  fullWidth
                  labelId="label-lista"
                  label={t('selecciona')}
                  id="select-multiple-lista"
                  multiple
                  value={catRestricciones
                    .filter((r) =>
                      objDictamenMedLab.arrayRestriccionTotal.includes(`${r.source}-${r.id}`),
                    )
                    .map((r) => `${r.source}-${r.id}`)}
                  onChange={(event) => {
                    const valXp = { ...objDictamenMedLab };
                    valXp.arrayRestriccionTotal = event.target.value as string[];
                    setObjDictamenMedLab(valXp);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => renderRestriccionesTotales(selected as string[])}
                >
                  {catRestricciones.map((valor, iny) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={String(iny)} value={`${valor.source}-${valor.id}`}>
                      {valor.source === 'propio' ? `${valor.label} (opción creada)` : valor.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="mt-2">
                <Button
                  color="primary"
                  size="small"
                  style={{ color: 'rgb(75, 85, 99)', textTransform: 'none' }}
                  startIcon={<FormatListBulletedIcon />}
                  disabled
                >
                  {t('podras_seleccionar_varias_opciones')}
                </Button>
              </div>
            </div>
            <div className="flex flex-row mt-4">
              <h4 className="text-gray-500 font-normal m-0 mb-2 mr-4 pt-2">
                {`${t('restriccion_parcial')}:`}
              </h4>
              <Button
                color="primary"
                size="small"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => handleEditCatalogo('restricciones')}
              >
                {t('crear_editar_opciones')}
              </Button>
            </div>
            <div className="flex flex-wrap items-end justify-end">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">{t('selecciona')}</InputLabel>
                <Select
                  fullWidth
                  labelId="label-lista"
                  label={t('selecciona')}
                  id="select-multiple-lista"
                  multiple
                  value={catRestricciones
                    .filter((r) =>
                      objDictamenMedLab.arrayRestriccionParcial.includes(`${r.source}-${r.id}`),
                    )
                    .map((r) => `${r.source}-${r.id}`)}
                  onChange={(event) => {
                    const valXp = { ...objDictamenMedLab };
                    valXp.arrayRestriccionParcial = event.target.value as string[];
                    setObjDictamenMedLab(valXp);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => renderRestriccionesParciales(selected as string[])}
                >
                  {catRestricciones.map((valor, iny) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={String(iny)} value={`${valor.source}-${valor.id}`}>
                      {valor.source === 'propio' ? `${valor.label} (opción creada)` : valor.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="mt-2">
                <Button
                  color="primary"
                  size="small"
                  style={{ color: 'rgb(75, 85, 99)', textTransform: 'none' }}
                  startIcon={<FormatListBulletedIcon />}
                  disabled
                >
                  {t('podras_seleccionar_varias_opciones')}
                </Button>
              </div>
            </div>
            <div className="w-full mt-2">
              <h3 className="text-gray-500 font-normal m-0 mb-2">
                {`${t('comentarios_restricciones')}`}
              </h3>
              <TextField
                variant="outlined"
                multiline
                minRows={4}
                fullWidth
                value={objDictamenMedLab.comentariosRestricciones}
                onChange={handleChangeComentariosRestricciones}
                helperText={`${
                  objDictamenMedLab.comentariosRestricciones
                    ? objDictamenMedLab.comentariosRestricciones.length
                    : 0
                }/300`}
                inputProps={{ maxLength: 300, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                FormHelperTextProps={{ className: classes.helperText }}
              />
            </div>
          </div>
        </>
      )}
      {objDictamenMedLab.dictamen.id === 3 && (
        <>
          <h3 className="text-gray-500 font-normal m-0 mb-2 mr-4">
            {`${t('causa_no_aptitud')}* `}
            <span className="text-red-500">{t('causa_no_aptitud_nota')}</span>
          </h3>
          <div className="border-solid border border-gray-300 rounded p-4 mb-3">
            <div className="flex flex-row">
              <h4 className="text-gray-500 font-normal m-0 mb-2 mr-4 pt-2">
                {`${t('enfermedad_preexistente')}:`}
              </h4>
              <Button
                color="primary"
                size="small"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
              >
                {t('agregar_otra_enfermedad')}
              </Button>
            </div>
            <div className="flex flex-wrap items-end justify-end">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">{t('selecciona')}</InputLabel>
                <Select
                  fullWidth
                  labelId="label-lista"
                  label={t('selecciona')}
                  id="select-multiple-lista"
                  multiple
                  value={objDictamenMedLab.arrayCausaNoAptitudEnf}
                  onChange={(event) => {
                    const valXp = { ...objDictamenMedLab };
                    valXp.arrayCausaNoAptitudEnf = event.target.value as string[];
                    setObjDictamenMedLab(valXp);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => renderCausasNoAptitudEnf(selected as string[])}
                >
                  {enfermedades.map((valor, iny) => (
                    <MenuItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={String(iny)}
                      value={
                        valor.diagnosticoCie10.id !== ''
                          ? valor.diagnosticoCie10.label
                          : valor.diagnosticoMedico
                      }
                    >
                      {valor.diagnosticoCie10.id !== ''
                        ? valor.diagnosticoCie10.label
                        : valor.diagnosticoMedico}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="mt-2">
                <Button
                  color="primary"
                  size="small"
                  style={{ color: 'rgb(75, 85, 99)', textTransform: 'none' }}
                  startIcon={<FormatListBulletedIcon />}
                  disabled
                >
                  {t('podras_seleccionar_varias_opciones')}
                </Button>
              </div>
            </div>
            <div className="flex flex-row mt-4">
              <h4 className="text-gray-500 font-normal m-0 mb-2 mr-4 pt-2">{`${t('otros')}:`}</h4>
              <Button
                color="primary"
                size="small"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => handleEditCatalogo('causas_no_aptitud')}
              >
                {t('crear_editar_opciones')}
              </Button>
            </div>
            <div className="flex flex-wrap items-end justify-end">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">{t('selecciona')}</InputLabel>
                <Select
                  fullWidth
                  labelId="label-lista"
                  label={t('selecciona')}
                  id="select-multiple-lista"
                  multiple
                  value={catCausasNoAptitud
                    .filter((c) =>
                      objDictamenMedLab.arrayCausaNoAptitudOtra.includes(`${c.source}-${c.id}`),
                    )
                    .map((c) => `${c.source}-${c.id}`)}
                  onChange={(event) => {
                    const valXp = { ...objDictamenMedLab };
                    valXp.arrayCausaNoAptitudOtra = event.target.value as string[];
                    setObjDictamenMedLab(valXp);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => renderCausasNoAptitudOtras(selected as string[])}
                >
                  {catCausasNoAptitud.map((valor, iny) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={String(iny)} value={`${valor.source}-${valor.id}`}>
                      {valor.source === 'propio' ? `${valor.label} (opción creada)` : valor.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="mt-2">
                <Button
                  color="primary"
                  size="small"
                  style={{ color: 'rgb(75, 85, 99)', textTransform: 'none' }}
                  startIcon={<FormatListBulletedIcon />}
                  disabled
                >
                  {t('podras_seleccionar_varias_opciones')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="w-full my-4">
        <h3 className="text-gray-500 font-normal m-0 mb-2">{`${t('recomendaciones')}*`}</h3>
        <TextField
          variant="outlined"
          multiline
          minRows={4}
          fullWidth
          value={objDictamenMedLab.recomendaciones}
          onChange={handleChangeRecomendaciones}
          helperText={`${
            objDictamenMedLab.recomendaciones ? objDictamenMedLab.recomendaciones.length : 0
          }/300`}
          inputProps={{ maxLength: 300, autoComplete: 'off', 'aria-autocomplete': 'none' }}
          FormHelperTextProps={{ className: classes.helperText }}
        />
      </div>
      <div className="flex justify-between my-4 items-center">
        <span className="text-gray-500">*{t('campos_obligatorios')}</span>
        <Button
          color="primary"
          variant="contained"
          startIcon={objDictamenMedLab.id === -1 ? <SaveIcon /> : <SyncIcon />}
          onClick={handleAddDictamen}
          disabled={disableSave()}
        >
          {objDictamenMedLab.id === -1 ? t('guardar') : t('actualizar')}
        </Button>
      </div>
    </div>
  );
}

export default FormularioDictamenMedLab;
