import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TriangleAlertIcon } from 'lucide-react';
import { sendLogin } from '@utils/sendInfo';
import { getIPAddress, getPublicKey } from '@utils/getCatalogos';
import {
  getSessionIP,
  getSessionKeyBack,
  removeSessionKeyBack,
  removeUserSession,
  setEmailMe,
  setSessionIP,
  setSessionKeyBack,
  setToken,
  setUser,
} from '@utils/commonStore';
import setLoading from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { setAuth } from '@actions/auth/actions';
import { getMeMedico } from '@actions/me/actions';
import { str2AB } from '@common/base64Convert';
import { ILoginProps } from './types';
import { IPhone } from '@common/types';
import PhoneInput from '@components/FormElemntsLibrary/PhoneInput';
import EmailInput from '@components/FormElemntsLibrary/EmailInput';
import PasswordInput from '@components/FormElemntsLibrary/PasswordInput';
import { SmartphoneIcon, MailIcon, StethoscopeIcon, HeartPulseIcon } from 'lucide-react';
import { Button } from '@components/FormElemntsLibrary/Button';
import { RootState } from 'src/store';
import { useApiRequest } from '@hooks/useApiRequest';

function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function Login({ esPaciente = false }: ILoginProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const { apiRequest } = useApiRequest();
  const dispatch = useDispatch();
  const { width } = useSelector((state: RootState) => state.ScreenSize);
  const [ip, setIp] = useState<string>('');
  const [keyBack, setKeyBack] = useState<string>('');
  const [backError, setBackError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [cel, setCel] = useState<IPhone>({ codigo: '', numero: '' });
  const [contrasenia, setContrasenia] = useState<string>('');
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const tiposUsuario = ['correo', 'celular'] as const;
  type tipos = (typeof tiposUsuario)[number];
  const [tipo, setTipo] = useState<tipos>('correo');

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const keyBack = getSessionKeyBack();
    if (keyBack.length > 0) {
      setKeyBack(keyBack);
    } else {
      dispatch(setLoading(true));
      getPublicKey(signal)
        .then((result: string) => {
          const pemHeader = '-----BEGIN PUBLIC KEY-----';
          const pemFooter = '-----END PUBLIC KEY-----';
          const pemContents = result.substring(pemHeader.length, result.length - pemFooter.length);
          setKeyBack(pemContents);
          setSessionKeyBack(pemContents);
        })
        .catch(() => setBackError(true))
        .finally(() => dispatch(setLoading(false)));
    }
    const ip = getSessionIP();
    if (ip.length > 0) {
      setIp(ip);
    } else {
      getIPAddress(signal).then((result: string) => {
        setIp(result);
        setSessionIP(result);
      });
    }
    return () => abortController.abort();
  }, []);

  const handleClickIniciarSesion = async () => {
    // creadas el par de llaves del front (usuario)
    if ((email === '' && cel.numero === '') || contrasenia === '') {
      return;
    }
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'decrypt'],
    );
    // exportadas a JWK (Json Web Key)
    let publicKeyJwk;
    if (keyPair.publicKey) {
      publicKeyJwk = await window.crypto.subtle.exportKey('jwk', keyPair.publicKey);
    } else {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('error_inesperado'),
        }),
      );
      return;
    }
    // const privateKeyJwk = await window.crypto.subtle.exportKey('jwk', keyPair.privateKey); // a proposito
    const binaryDerString = window.atob(keyBack);
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
    // email encriptado
    const emailC =
      email.length > 0
        ? await window.crypto.subtle.encrypt(
            {
              name: 'RSA-OAEP',
            },
            pemPublicKey,
            str2AB(email),
          )
        : null;
    // celular encriptado
    const celularC =
      cel.numero.length > 0
        ? await window.crypto.subtle.encrypt(
            {
              name: 'RSA-OAEP',
            },
            pemPublicKey,
            str2AB(`${cel.codigo}-${cel.numero}`),
          )
        : null;
    // contraseña encriptada
    const contraseniaC = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      pemPublicKey,
      str2AB(contrasenia),
    );
    apiRequest({
      type: 'send',
      requestFunction: sendLogin({
        password: arrayBufferToBase64(contraseniaC),
        email: emailC ? arrayBufferToBase64(emailC) : '',
        celular: celularC ? arrayBufferToBase64(celularC) : '',
        ip,
        llave: publicKeyJwk,
      }),
      successFunction: (result) => {
        setEmailMe(result.datos.userSend);
        setToken(result.datos.Bearer);
        setUser(result.datos.userSend);
        dispatch(
          setAuth({
            id: result.datos.userSend,
            acreditado: true,
            suscrito: result.datos.suscrito,
          }),
        );
        dispatch(getMeMedico(result.datos.userSend));
        if (result.datos.suscrito) {
          if (result.datos.tipoRol === 'superadmin') {
            history.push(
              result.datos.tipoRol === 'superadmin' && result.datos.numOrgs > 0
                ? '/home'
                : '/administracion',
            );
          } else {
            history.push(result.datos.tipoRol === 'admin' ? '/administracion' : '/home');
          }
        } else if (result.datos.tipoRol === 'paciente') {
          history.push('/ece');
        } else {
          history.push('/subscripcion');
        }
      },
      errorFunction: (result) => {
        switch (result.code) {
          case 408:
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('error_contrasenia_incorrecta'),
              }),
            );
            break;
          case 409:
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('error_email_no_encontrado'),
              }),
            );
            break;
          case 410:
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('error_celular_no_encontrado'),
              }),
            );
            break;
          case 500:
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('error_ocurrio_un_error_en_el_servidor'),
              }),
            );
            break;
          case 401:
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('error_logueado_anteriormente'),
              }),
            );
            break;
          default:
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: t('error_ocurrio_un_error_intentar_mas_tarde'),
              }),
            );
            break;
        }
        removeUserSession();
        setToken('');
      },
      catchFunction: () => removeSessionKeyBack(),
      showMsgLoading: false,
      showMsgSuccess: false,
    });
  };

  return backError ? (
    <div className="place-self-center flex flex-row gap-x-2 mx-auto shadow-lg bg-gray-100 rounded-xl mt-16 lg:mt-0 p-6 border border-solid border-gray-200">
      <TriangleAlertIcon color="red" size={20} />
      <span className="text-base">{t('error_back')}</span>
    </div>
  ) : (
    <div className="relative mx-2 md:mx-auto w-full md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 z-20">
      <div className="absolute top-0 right-0 mt-3 mr-3">
        {/* <IconButton
          aria-label="delete"
          onClick={() => {
            history.push(esPaciente ? '/signup-patient' : '/signup');
          }}
        >
          <CloseIcon />
        </IconButton> */}
      </div>
      {['soy_profesional_salud', 'soy_paciente'].map((tp, index) => (
        <button
          key={`button-${index}`}
          type="button"
          value={tp}
          className={`px-2 py-1 ${width > 400 ? '' : 'h-12'} text-white w-1/2 ${
            (tp === 'soy_paciente' && esPaciente) || (tp === 'soy_profesional_salud' && !esPaciente)
              ? 'bg-blue-900'
              : 'bg-gray-400'
          } hover:bg-blue-600 text-sm ${index === 0 ? 'rounded-tl-md' : 'rounded-tr-md'}`}
          onClick={() => {
            setTipo('correo');
            history.push(tp === 'soy_paciente' ? '/login-patient' : 'login');
          }}
        >
          <div className="flex flex-row justify-center items-center my-0">
            {tp === 'soy_paciente' ? <HeartPulseIcon size={14} /> : <StethoscopeIcon size={14} />}
            <span className="pl-2">{t(tp)}</span>
          </div>
        </button>
      ))}
      <div className="shadow-lg bg-white rounded-b-xl py-6 px-10 border border-solid border-gray-200">
        {/* <div
          className="absolute right-4 cursor-pointer"
          onClick={() => {
            history.push(esPaciente ? '/signup-patient' : '/signup');
          }}
        >
          <XIcon size={20} className="text-gray-600" />
        </div> */}
        <div className="w-full text-center py-2">
          <img src="/img/Logotipo_Color.png" alt="logotipo" className="w-5/12" />
        </div>
        <form className="pt-8">
          <div className={`mb-4 ${esPaciente ? '' : 'pt-5'}`}>
            {esPaciente && (
              <>
                {tiposUsuario.map((tp, index) => (
                  <button
                    key={`button-${index}`}
                    type="button"
                    className={`px-2 py-1 text-white w-1/2 ${
                      tipo === tp ? 'bg-blue-900' : 'bg-gray-400'
                    } hover:bg-blue-600 text-sm ${index === 0 ? 'rounded-tl-md' : 'rounded-tr-md'}`}
                    onClick={() => {
                      setEmail('');
                      setCel({ codigo: '', numero: '' });
                      setTipo(tp);
                    }}
                  >
                    <div className="flex flex-row justify-center items-center my-0">
                      {tp === 'correo' ? <MailIcon size={14} /> : <SmartphoneIcon size={14} />}
                      <span className="pl-2">{t(tp)}</span>
                    </div>
                  </button>
                ))}
              </>
            )}
            {tipo === 'correo' ? (
              <EmailInput
                containerClass="mt-[0px]"
                name="email"
                floatingLabel={t('email')}
                value={email}
                setValue={(e: { value: string }) => setEmail(e.value)}
                showIcon
                loginInput={esPaciente}
                onValidationChange={(e) => setEmailError(e.hasError)}
              />
            ) : (
              <PhoneInput
                containerClass="mt-[0px]"
                name="telefono"
                label=""
                placeholder={t('telefono_celular')}
                value={cel}
                setValue={(e: { value: IPhone }) => setCel(e.value)}
                loginInput={esPaciente}
                onValidationChange={(e) => setPhoneError(e.hasError)}
              />
            )}
          </div>
          <div className="mb-4 mt-6">
            <PasswordInput
              name="contrasenia"
              floatingLabel={t('contrasenia')}
              value={contrasenia}
              setValue={(e: { value: string }) => setContrasenia(e.value)}
              requirements={[]}
              isRequired
            />
          </div>
          <div className="text-center py-2">
            <Button
              label={t('iniciar_sesion')}
              onClick={handleClickIniciarSesion}
              disable={
                (tipo === 'correo' && emailError) ||
                (tipo === 'celular' && phoneError) ||
                !contrasenia.length
              }
              color="primary"
              size="base"
            />
            <div className="py-2">
              <Button
                label={t('_has_olvidado_tu_contrasenia_')}
                onClick={() => history.push('/password')}
                color="blue"
                size="base"
              />
            </div>
          </div>
        </form>
        <hr />
        <div className="flex items-center pt-2 gap-4 w-">
          <span className="text-base">{t('_no_tienes_una_cuenta_')}</span>
          <Button
            label={t('registrate')}
            onClick={() => history.push(esPaciente ? '/signup-patient' : '/signup')}
            width="0"
            color="blue"
            size="base"
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
