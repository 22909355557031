import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from '@components/DeleteDialog';
import { edadCalculada, iEdadMenorOIgual } from '@common/functions';
import { RootState } from 'src/store';
import { ITipoDrogasProps } from './types';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import TextInput from '@components/FormElemntsLibrary/TextInput';

function TipoDeDrogas(props: ITipoDrogasProps) {
  const { hayCambios, index, toxicomania, setToxicomania, handleDeleteRow } = props;
  const { dia, mes, anio } = useSelector((state: RootState) => state.BasicosPaciente);
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const edadPaciente = edadCalculada(anio, mes, dia);

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);

  const estatus = ['activo', 'abandonado'].map((es) => ({ value: es, label: t(es) }));
  const tipo = [
    'otra',
    'ayahuasca',
    'depresores_nerviosos',
    'cocaina',
    'dmt',
    'ghb',
    'alucinogenos',
    'heroina',
    'inhalantes',
    'ketamina',
    'khat',
    'kratom',
    'lsd',
    'marihuana',
    'mdma',
    'mescalina',
    'metanfetamienta',
    'venta_libre_dextrometorfano',
    'venta_libre_loperamida',
    'pcp',
    'opioides_con_receta',
    'estimulantes_con_receta',
    'psilocibina',
    'rohypnol',
    'salvia',
    'esteroides',
    'cannabinoides_sinteticos',
    'catinonas_sinteticas',
  ].map((tp) => ({ id: tp, label: t(tp) }));
  const frecuencia = [
    'diario',
    '1_vez_por_semanas',
    '2_veces_por_semana',
    '3_veces_por_semana',
    '4_veces_por_semana',
    '5_veces_por_semana',
    '6_veces_por_semana',
    'mensual_o_menos',
  ].map((frec) => ({ id: frec, label: t(frec) }));

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  const handleInputChange = (e: { name: string; value: any }) => {
    let newArrTipoDrogas = { ...toxicomania.otrasDrogas.arrTipoDrogas };
    switch (e.name) {
      case 'estatus':
        newArrTipoDrogas.estatus[index] = e.value;
        break;
      case 'tipoDroga':
        newArrTipoDrogas.tipoDroga[index] = e.value;
        break;
      case 'especifica':
        newArrTipoDrogas.especifica[index] = e.value;
        break;
      case 'cantidadDroga':
        newArrTipoDrogas.cantidadDroga[index] = e.value;
        break;
      case 'edadInicio':
        newArrTipoDrogas.edadInicio[index] = e.value;
        break;
      case 'edadTermino':
        newArrTipoDrogas.edadTermino[index] = e.value;
        break;
      case 'frecuencia':
        newArrTipoDrogas.frecuencia[index] = e.value;
        break;
    }
    setToxicomania({
      ...toxicomania,
      otrasDrogas: {
        ...toxicomania.otrasDrogas,
        arrTipoDrogas: newArrTipoDrogas,
      },
    });
  };

  return (
    <>
      <DeleteDialog
        key={`dialog-otras-drogas-${index}`}
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={`${t('_esta_seguro_que_desea_eliminar_el_tipo_de_droga')}${
          toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga[index]
            ? ` ${t(toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga[index])}`
            : ''
        }${t('_?')}`}
        callBackAceptar={() => handleDeleteRow(index)}
        callBackClose={handleAlertClose}
      />
      <div
        key={`div-otras-drogas-${index}`}
        className="pb-4 mb-2 border-solid border border-gray-300 rounded flex"
      >
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-2 gap-x-4 px-4 w-full">
          <CheckboxGroup
            containerClass="md:col-span-3"
            key={`estatus-droga-${index}`}
            name="estatus"
            label=""
            options={estatus}
            value={toxicomania.otrasDrogas.arrTipoDrogas.estatus[index]}
            setValue={handleInputChange}
            disabled={hayCambios}
            distribution="vertical-flex"
          />
          <SelectInput
            key={`tipo-droga-${index}`}
            name="tipoDroga"
            label={t('tipo')}
            options={tipo}
            value={toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga[index]}
            setValue={handleInputChange}
            isNumeric={false}
            isDisabled={hayCambios}
          />
          {toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga[index] === 'otra' && (
            <TextInput
              key={`especifica-droga-${index}`}
              name="especifica"
              value={toxicomania.otrasDrogas.arrTipoDrogas.especifica[index]}
              setValue={handleInputChange}
              disabled={hayCambios}
              maxLength={30}
            />
          )}
          <TextInput
            key={`cantidad-droga-${index}`}
            name="cantidadDroga"
            label={t('cantidad/presentacion')}
            value={toxicomania.otrasDrogas.arrTipoDrogas.cantidadDroga[index]}
            setValue={handleInputChange}
            disabled={hayCambios}
          />
          <NumberInput
            key={`edad-inicio-droga-${index}`}
            name="edadInicio"
            label={t('edad_de_inicio')}
            value={toxicomania.otrasDrogas.arrTipoDrogas.edadInicio[index]}
            setValue={handleInputChange}
            isInteger
            isString
            validacion={(val) =>
              val.length > 0 &&
              !iEdadMenorOIgual({ edad: Number(val), claveEdad: 5, periodo: 'anios' }, edadPaciente)
                ? t('la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente')
                : ''
            }
            min={0}
            max={99}
            disabled={hayCambios}
          />
          {toxicomania.otrasDrogas.arrTipoDrogas.estatus[index] === 'abandonado' && (
            <NumberInput
              key={`edad-termino-droga-${index}`}
              name="edadTermino"
              label={t('edad_de_termino')}
              value={toxicomania.otrasDrogas.arrTipoDrogas.edadTermino[index]}
              setValue={handleInputChange}
              isInteger
              isString
              validacion={(val) => {
                if (val.length > 0) {
                  if (
                    !iEdadMenorOIgual(
                      { edad: Number(val), claveEdad: 5, periodo: 'anios' },
                      edadPaciente,
                    )
                  ) {
                    return t(
                      'la_edad_al_momento_del_diagnostico_es_mayor_a_la_edad_actual_del_paciente',
                    );
                  }
                  if (
                    iEdadMenorOIgual(
                      { edad: Number(val), claveEdad: 5, periodo: 'anios' },
                      {
                        edad: parseInt(toxicomania.tabaco.edadInicio, 10),
                        claveEdad: 5,
                        periodo: 'anios',
                      },
                    )
                  ) {
                    return t('la_edad_de_termino_es_menor_a_la_edad_actual_de_inicio');
                  }
                }
                return '';
              }}
              min={0}
              max={99}
              disabled={hayCambios}
            />
          )}
          <SelectInput
            key={`frecuencia-droga-${index}`}
            name="frecuencia"
            options={frecuencia}
            value={toxicomania.otrasDrogas.arrTipoDrogas.frecuencia[index]}
            setValue={handleInputChange}
            isNumeric={false}
            isDisabled={hayCambios}
          />
        </div>
        <IconButton
          color="primary"
          aria-label="delete"
          onClick={() => setDeleteAlert(true)}
          disabled={hayCambios}
          className="h-12 self-center"
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </>
  );
}

export default TipoDeDrogas;
