/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '@common/styles';
import DeleteDialog from '@components/DeleteDialog';
import { RootState } from 'src/store';
import { deleteTerapiaFarmacologicaReceta } from '@utils/deleteInfo';
import { ITabsRecetas } from './types';
import { setTratamientos } from '@actions/paciente/actions';
import setLoading from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';

function TabRecetas({ recetas, setRecetas, indiceReceta, setIndiceReceta }: ITabsRecetas) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { tratamientos } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  // const [selArray, setSelArray] = useState<number>(0);
  const [delSel, setDelSel] = useState<number>(-1);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);

  const verificarDisableAgregarReceta = () => {
    // return false;
    const len = recetas.length;
    return !(recetas[len - 1]?.idBD > 0);
  };

  const handleAgregarReceta = () => {
    const len = recetas.length;
    if (recetas[len - 1].idBD > 0 && len < 4) {
      // if (len < 4) {
      const valArray = [...recetas];
      valArray.push({
        idBD: -1,
        actualizado: true,
      });
      setRecetas(valArray);
      setIndiceReceta(valArray.length - 1);
    }
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  const limpiarRecetas = (idDel: number) => {
    if (recetas.length === 1) {
      setIndiceReceta(0);
      setRecetas([{ idBD: -1, actualizado: true }]);
    } else {
      if (indiceReceta >= delSel) {
        setIndiceReceta((prev) => prev - 1);
      }
      setRecetas((prev) => prev.filter((receta) => receta.idBD !== idDel));
    }
    dispatch(
      setTratamientos(tratamientos.map((t) => (t.idReceta === idDel ? { ...t, idReceta: 0 } : t))),
    );
    setDelSel(-1);
  };

  const eliminarReceta = () => {
    const idReceta = recetas[delSel].idBD;
    if (idReceta > 0) {
      dispatch(setLoading(true));
      deleteTerapiaFarmacologicaReceta({
        idReceta,
        idPaciente,
        idMedico,
        idConsultorio,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            limpiarRecetas(idReceta);
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')}: ${result.msg}`,
              }),
            );
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')}: ${err}`,
          });
          dispatch(setLoading(false));
        });
    } else {
      limpiarRecetas(idReceta);
    }
  };

  return (
    <div className="border-solid border-0 border-b border-gray-600 flex items-center">
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('d-p-plan-lab-del')}
        callBackAceptar={() => eliminarReceta()}
        callBackClose={handleAlertClose}
      />
      <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
        {recetas.map((_val, inx) => (
          <Button
            // eslint-disable-next-line react/no-array-index-key
            key={String(inx)}
            className={indiceReceta === inx ? classes.buttonLeftselected : classes.buttonLeft}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setIndiceReceta(inx)}
            endIcon={
              <CloseIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setDelSel(inx);
                  setDeleteAlert(true);
                }}
              />
            }
          >
            {`${t('receta')} ${inx + 1}`}
          </Button>
        ))}
      </ButtonGroup>
      {recetas.length < 4 && (
        <div className="pl-2">
          <Button
            color="primary"
            size="small"
            onClick={handleAgregarReceta}
            disabled={verificarDisableAgregarReceta()}
            startIcon={<AddCircleOutlineIcon />}
          >
            {t('agregar_receta')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default TabRecetas;
