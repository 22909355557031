import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { TriangleAlert } from 'lucide-react';
import { setRutaAntecedentes } from '@actions/rutasExpediente/actions';
import { RootState } from 'src/store';
import useStyles from './styles';
import PersonalesNoPatologicos from './PersonalesNoPatologicos';
import PersonalesPatologicos from './PersonalesPatologicos';
import Familiares from './Familiares';
import Laborales from './Laborales';
import { IDiagnosticoGeneral } from './PersonalesPatologicos/DiagnosticosGenerales/types';

function Antecedentes() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { rutaAntecedentes } = useSelector((state: RootState) => state.RutasExpediente);
  const { secciones, esPaciente } = useSelector((state: RootState) => state.Me);
  const { idUsuario, sexo } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    usuario: {
      antPatologicos: { diagnosticosGenerales },
    },
    cambios: { antNoPatologicos: cambios },
  } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  return (
    <>
      {!esPaciente && (
        <div className="mt-4 mb-4 bg-blue-200 container mx-auto flex content-center items-center p-4">
          <h3 className="text-blue-500 font-medium m-0 pr-4">{`${t('antecedentes-label')}:`}</h3>
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 14 && valor.idSeccion <= 22) && (
              <div className="pr-4">
                <Button
                  variant="outlined"
                  color="primary"
                  className={
                    rutaAntecedentes === 'personales-patologicos'
                      ? classes.selected
                      : classes.buttonColor
                  }
                  onClick={() => dispatch(setRutaAntecedentes('personales-patologicos'))}
                  startIcon={
                    !esPaciente &&
                    idUsuario > 0 &&
                    diagnosticosGenerales.contenidoTabla.some(
                      (diagnostico: IDiagnosticoGeneral) =>
                        diagnostico.posee === 'si' && !diagnostico.validado,
                    ) ? (
                      <TriangleAlert color="orange" />
                    ) : null
                  }
                >
                  {t('antecedentes-pp')}
                </Button>
              </div>
            )}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 23 && valor.idSeccion <= 30) && (
              <div className="pr-4">
                <Button
                  variant="outlined"
                  color="primary"
                  className={
                    rutaAntecedentes === 'personales-no-patologicos'
                      ? classes.selected
                      : classes.buttonColor
                  }
                  onClick={() => dispatch(setRutaAntecedentes('personales-no-patologicos'))}
                  startIcon={
                    !esPaciente &&
                    idUsuario > 0 &&
                    (cambios.vivienda.some((c: string) => c.length > 0) ||
                      cambios.higiene.some((c: string) => c.length > 0) ||
                      cambios.alimentacion.some((c: string) => c.length > 0) ||
                      cambios.actividadFisica.some((c: string) => c.length > 0) ||
                      cambios.toxicomania.some((c: string) => c.length > 0) ||
                      cambios.ginecoObstetrico.some((c: string) => c.length > 0)) ? (
                      <TriangleAlert color="orange" />
                    ) : null
                  }
                >
                  {t('antecedentes-pnp')}
                </Button>
              </div>
            )}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 31 && valor.idSeccion <= 32) && (
              <div className="pr-4">
                <Button
                  variant="outlined"
                  color="primary"
                  className={
                    rutaAntecedentes === 'familiares' ? classes.selected : classes.buttonColor
                  }
                  onClick={() => dispatch(setRutaAntecedentes('familiares'))}
                >
                  {t('antecedentes-familiares')}
                </Button>
              </div>
            )}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion >= 31 && valor.idSeccion <= 32) && (
              <div className="pr-4">
                <Button
                  variant="outlined"
                  color="primary"
                  className={
                    rutaAntecedentes === 'laborales' ? classes.selected : classes.buttonColor
                  }
                  onClick={() => dispatch(setRutaAntecedentes('laborales'))}
                >
                  {t('antecedentes_laborales')}
                </Button>
              </div>
            )}
        </div>
      )}
      {rutaAntecedentes === 'personales-patologicos' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion >= 14 && valor.idSeccion <= 22) && (
          <PersonalesPatologicos />
        )}
      {rutaAntecedentes === 'personales-no-patologicos' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion >= 23 && valor.idSeccion <= 30) && (
          <PersonalesNoPatologicos />
        )}
      {rutaAntecedentes === 'familiares' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion >= 31 && valor.idSeccion <= 32) && <Familiares />}
      {rutaAntecedentes === 'laborales' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion >= 31 && valor.idSeccion <= 32) && <Laborales />}
    </>
  );
}

export default Antecedentes;
