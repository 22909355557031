export interface IHigiene {
  loaded: boolean;
  banio: string;
  banioComentario: string;
  bucal: string;
  ropa: string;
  ropaComentario: string;
  suenio: number | null;
  comentarios: string;
}

export const higieneInitial: IHigiene = {
  loaded: false,
  banio: '',
  banioComentario: '',
  bucal: '',
  ropa: '',
  ropaComentario: '',
  suenio: null,
  comentarios: '',
};
