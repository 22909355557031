import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { setDiscapacidades, setDiscapacidadesUsuario } from '@actions/paciente/actions';
import { setRutaPerfil } from '@actions/rutasExpediente/actions';
import { useApiRequest } from '@hooks/useApiRequest';
import { ICatalogosEstandar } from '@common/types';
import { ModalForm } from '@components/modals/ModalForm';
import { RootState } from 'src/store';
import {
  getCatalogoDiscapacidadGrado,
  getCatalogoDiscapacidadOrigen,
  getCatalogoDiscapacidadTipo,
} from '@utils/getCatalogos';
import {
  updateDiscapacidades,
  updateDiscapacidadesPaciente,
  updateDiscapacidadesUsuario,
} from '@utils/sendInfo';
import useStyles from './styles';
import {
  discapacidadesInitial,
  discapacidadInitial,
  discapacidadSimpleInitial,
  IDiscapacidad,
  IDiscapacidades,
  IDiscapacidadSimple,
} from './types';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckboxInput from '@components/FormElemntsLibrary/CheckboxInput';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';

function Discapacidades() {
  const dispatch = useDispatch();
  const { apiRequest, apiRequests } = useApiRequest();
  const classes = useStyles();
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    expediente: {
      perfil: { discapacidades },
    },
    usuario: {
      perfil: { discapacidades: discapacidadesUsuario },
    },
    cambios: {
      perfil: { discapacidades: cambios },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [discapacidadesTemp, setDiscapacidadesTemp] =
    useState<IDiscapacidades>(discapacidadesInitial);
  /** catalogos */
  const [catTipoDiscapacidad, setCatTipoDiscapacidad] = useState<Array<ICatalogosEstandar>>([]);
  const [catGradoDiscapacidad, setCatGradoDiscapacidad] = useState<Array<ICatalogosEstandar>>([]);
  const [catOrigenDiscapacidad, setCatOrigenDiscapacidad] = useState<Array<ICatalogosEstandar>>([]);

  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);

  /** Logica */
  // const verificarErrorValidacionGeneral = false;
  const agregarDiscapacidad = () => {
    if (discapacidadesTemp.arrayDiscapacidades.length <= 6) {
      setDiscapacidadesTemp({
        ...discapacidadesTemp,
        arrayDiscapacidades: [...discapacidadesTemp.arrayDiscapacidades, discapacidadInitial],
      });
    }
  };

  const eliminarDiscapacidad = (indx: number) => {
    if (discapacidadesTemp.arrayDiscapacidades.length === 1) {
      setDiscapacidadesTemp({
        ...discapacidadesTemp,
        arrayDiscapacidades: [discapacidadInitial],
      });
    } else {
      setDiscapacidadesTemp({
        ...discapacidadesTemp,
        arrayDiscapacidades: [
          ...discapacidadesTemp.arrayDiscapacidades.slice(0, indx),
          ...discapacidadesTemp.arrayDiscapacidades.slice(indx + 1),
        ],
      });
    }
  };

  const handleChangeDiscapacidad = (value: { name: string; value: any; index?: number }) =>
    setDiscapacidadesTemp((prevData) => ({
      ...prevData,
      arrayDiscapacidades: prevData.arrayDiscapacidades.map((discapacidad, indx) =>
        indx === value.index ? { ...discapacidad, [value.name]: value.value } : discapacidad,
      ),
    }));

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCatalogoDiscapacidadTipo().then((result: ICatalogosEstandar[]) => {
      setCatTipoDiscapacidad(result);
    });
    getCatalogoDiscapacidadGrado().then((result: ICatalogosEstandar[]) => {
      setCatGradoDiscapacidad(result);
    });
    getCatalogoDiscapacidadOrigen().then((result: ICatalogosEstandar[]) => {
      setCatOrigenDiscapacidad(result);
    });
  }, []);
  useEffect(() => {
    if (discapacidades.loaded && !discapacidadesTemp.loaded) {
      setDiscapacidadesTemp(discapacidades);
    }
  }, [discapacidades]);
  useEffect(() => {
    if (discapacidadesTemp.sinDiscapacidad) {
      setDiscapacidadesTemp({
        ...discapacidadesTemp,
        arrayDiscapacidades: [discapacidadInitial],
        certificado: '',
      });
    }
  }, [discapacidadesTemp.sinDiscapacidad]);

  const handleInputChange = (e: { name: string; value: any }) =>
    setDiscapacidadesTemp((prevData) => ({ ...prevData, [e.name]: e.value }));

  const getData = (datos: IDiscapacidades, idUser?: number) => {
    const arrD: IDiscapacidadSimple[] = datos.arrayDiscapacidades
      .filter((e) => e.tipoId !== '')
      .map((d: IDiscapacidad) => ({
        tipoId: d.tipoId,
        gradoId: d.gradoId,
        origenId: d.origenId,
      })) || [discapacidadSimpleInitial];
    return {
      idMedico,
      idConsultorio,
      idPaciente,
      idUsuario: idUser,
      sinDiscapacidad: datos.sinDiscapacidad,
      certificado: datos.certificado,
      arrayDiscapacidades: arrD,
      comentarios: datos.comentarios,
    };
  };

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosDiscapacidades = getData(discapacidadesTemp, idUsuario);
    const sendFunctions = [updateDiscapacidades(datosDiscapacidades)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateDiscapacidadesPaciente(datosDiscapacidades));
    }
    apiRequests({
      type: 'send',
      requestFunctions: sendFunctions,
      successFunctions: [
        () => dispatch(setDiscapacidades({ ...discapacidadesTemp, loaded: true })),
        () => dispatch(setDiscapacidadesUsuario({ ...discapacidadesTemp, loaded: true })),
      ],
    });
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: updateDiscapacidadesUsuario(getData(discapacidadesTemp, idMiUsuario)),
      successFunction: () => {
        dispatch(setDiscapacidades({ ...discapacidadesTemp, loaded: true }));
        dispatch(setRutaPerfil('info-emergencia'));
      },
    });
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    apiRequest({
      type: 'send',
      requestFunction: updateDiscapacidades(getData(discapacidadesUsuario)),
      successFunction: () => {
        dispatch(setDiscapacidades(discapacidadesUsuario));
        setDiscapacidadesTemp(discapacidadesUsuario);
      },
    });
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: updateDiscapacidadesPaciente(getData(discapacidades, idUsuario)),
      successFunction: () => dispatch(setDiscapacidadesUsuario(discapacidades)),
    });
  };

  return (
    <>
      <ModalForm
        // currentStep={3}
        // totalSteps={ocultarCampos ? 4 : 5}
        title={t('discapacidades')}
        id="discapacidades-form"
        submitForm={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
        aceptarCambios={guardarDatosDeUsuarioAExpediente}
        rechazarCambios={guardarDatosDeExpedienteAUsuario}
        hayCambios={hayCambios}
        cambios={cambios}
        backAction={() => dispatch(setRutaPerfil('main'))}
      >
        <div className="flex flex-col">
          <CheckboxInput
            name="sinDiscapacidad"
            label={t('sin_discapacidades')}
            value={discapacidadesTemp.sinDiscapacidad}
            setValue={handleInputChange}
            disabled={hayCambios}
          />
          {!discapacidadesTemp.sinDiscapacidad && (
            <>
              {discapacidadesTemp.arrayDiscapacidades &&
                discapacidadesTemp.arrayDiscapacidades.map((discapacidad, indx) => (
                  <div
                    key={`div-${indx}`}
                    className=" border-solid border border-gray-400 rounded p-4 mb-3 flex"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-y-2 gap-x-4 w-full">
                      <SelectInput
                        index={indx}
                        name="tipoId"
                        label={t('tipo_discapacidad')}
                        options={catTipoDiscapacidad}
                        value={discapacidad.tipoId}
                        setValue={handleChangeDiscapacidad}
                        isDisabled={hayCambios || discapacidadesTemp.sinDiscapacidad}
                      />
                      <SelectInput
                        index={indx}
                        name="gradoId"
                        label={t('grado_discapacidad')}
                        options={catGradoDiscapacidad}
                        value={discapacidad.gradoId}
                        setValue={handleChangeDiscapacidad}
                        isDisabled={
                          hayCambios ||
                          discapacidadesTemp.sinDiscapacidad ||
                          discapacidad.tipoId.length === 0
                        }
                      />
                      <SelectInput
                        index={indx}
                        name="origenId"
                        label={t('origen_discapacidad')}
                        options={catOrigenDiscapacidad}
                        value={discapacidad.origenId}
                        setValue={handleChangeDiscapacidad}
                        isDisabled={
                          hayCambios ||
                          discapacidadesTemp.sinDiscapacidad ||
                          discapacidad.gradoId.length === 0
                        }
                      />
                    </div>
                    <IconButton
                      aria-label="delete"
                      onClick={() => eliminarDiscapacidad(indx)}
                      disabled={hayCambios || discapacidadesTemp.sinDiscapacidad}
                      className="h-12 self-center"
                      style={{ marginTop: '32px' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
              <div className="flex justify-end mb-2">
                <Button
                  color="primary"
                  size="small"
                  onClick={agregarDiscapacidad}
                  className={classes.noTextTranform}
                  startIcon={<AddCircleOutlineIcon />}
                  disabled={hayCambios || discapacidadesTemp.sinDiscapacidad}
                >
                  {t('agregar_discapacidad')}
                </Button>
              </div>
              <CheckboxGroup
                name="certificado"
                label={t('tiene_certificado')}
                options={['si', 'no'].map((opcion) => ({
                  value: opcion,
                  label: t(opcion),
                }))}
                value={discapacidadesTemp.certificado}
                setValue={handleInputChange}
                distribution="vertical-flex"
                disabled={hayCambios || discapacidadesTemp.sinDiscapacidad}
              />
              {!esPaciente && (
                <TextArea
                  label={t('comentario')}
                  name="comentarios"
                  value={discapacidadesTemp.comentarios}
                  setValue={handleInputChange}
                  disabled={hayCambios}
                />
              )}
            </>
          )}
        </div>
      </ModalForm>
    </>
  );
}

export default Discapacidades;
