import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxInputProps } from './type';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export const CheckboxInput: FC<CheckboxInputProps> = ({
  index,
  name,
  containerClass = '',
  label: externalLabel,
  isRequired = false,
  helperText,
  setValue,
  disabled,
  value,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const label = externalLabel === undefined ? t(name) : externalLabel;

  return (
    <div className={`w-full h-auto my-[10px] ${containerClass}`}>
      <input
        type="checkbox"
        name={name}
        checked={value}
        onChange={() => setValue({ name, value: !value, index })}
        required={isRequired}
        disabled={disabled}
        className="w-4 h-4 text-blue-500 border-gray-300 focus:ring-blue-500"
      />

      <span className="ml-2 font-normal text-base text-gray-700">{label}</span>
      {helperText && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
    </div>
  );
};

export default CheckboxInput;
