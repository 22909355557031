import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { ICatalogosEstandar } from '@common/types';
import { getAnimales } from '@utils/getCatalogos';
import {
  antecedentesNoPatologicosGuardarVivienda,
  antecedentesNoPatologicosUsuarioPacienteGuardarVivienda,
  antecedentesNoPatologicosUsuarioGuardarVivienda,
} from '@utils/sendInfo';
import { useApiRequest } from '@hooks/useApiRequest';
import { setVivienda, setViviendaUsuario } from '@actions/paciente/actions';
import { setRutaAntNoPatologicos, setRutaPrincipal } from '@actions/rutasExpediente/actions';
// import MascotasComponente from './Mascotas/index';
import { ITipoMascota, IVivienda, viviendaInitial } from './types';
import { ModalForm } from '@components/modals/ModalForm';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import TextField from '@components/FormElemntsLibrary/TextField';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import AgregarMascotas from '@components/ModalDialogs/AgregarMascotas';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { IFormSectionComponent } from '@components/FormSections/type';
import FormSections from '@components/FormSections';

function Vivienda() {
  const dispatch = useDispatch();
  const { apiRequest, apiRequests } = useApiRequest();
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    ocultarCampos,
    expediente: {
      antNoPatologicos: { vivienda },
    },
    usuario: {
      antNoPatologicos: { vivienda: viviendaUsuario },
    },
    cambios: {
      antNoPatologicos: { vivienda: cambios },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const isWideScreen = useBreakPoint();
  const [viviendaTemp, setViviendaTemp] = useState<IVivienda>(viviendaInitial);
  const [hacinamiento, setHacinamiento] = useState<{ indice: string; txt: string }>({
    indice: '',
    txt: '',
  });
  /** Catalogos */
  const [catAnimales, setCatAnimales] = useState<Array<ICatalogosEstandar>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);

  const tipoMascotaInitial: ITipoMascota = {
    idAnimal: { id: 0, label: '' },
    especifica: '',
    cantidad: '',
  };

  const handleInputChange = (e: { name: string; value: any }) => {
    if (e.name === 'animales') {
      if (e.value === 'T') {
        setOpenModal(true);
      }
      setViviendaTemp({
        ...viviendaTemp,
        animales: e.value,
        tipo: e.value === 'F' ? [] : [tipoMascotaInitial],
      });
    } else {
      setViviendaTemp((prevData) => ({ ...prevData, [e.name]: e.value }));
    }
  };

  const handleServicesChange = (eventOrValue: { name: string; value: string[] }) => {
    const { value } = eventOrValue;
    const newServiciosBasicos = {
      check1: false,
      check2: false,
      check3: false,
      check4: false,
    };

    if (value.length > 0 && value[value.length - 1] === 'todos') {
      // Si ya estaba seleccionado "todos", lo deseleccionamos
      if (viviendaTemp.serviciosBasicos.check1) {
        newServiciosBasicos.check1 = false;
      } else {
        // Si no estaba seleccionado, lo seleccionamos y desactivamos los demás
        newServiciosBasicos.check1 = true;
        newServiciosBasicos.check2 = false;
        newServiciosBasicos.check3 = false;
        newServiciosBasicos.check4 = false;
      }
    } else {
      // Manejo de servicios individuales
      newServiciosBasicos.check1 = false; // "todosServicios" siempre falso si hay individuales
      newServiciosBasicos.check2 = value.includes('sinAgua');
      newServiciosBasicos.check3 = value.includes('sinElectricidad');
      newServiciosBasicos.check4 = value.includes('sinDrenaje');
    }
    setViviendaTemp((prev) => ({
      ...prev,
      serviciosBasicos: newServiciosBasicos,
    }));
  };

  const getDatosVivienda = (datos: IVivienda) => ({
    serviciosBasicos: datos.serviciosBasicos || {
      check1: false,
      check2: false,
      check3: false,
      check4: false,
    },
    numeroHabitantes: datos.numeroHabitantes,
    numeroHabitaciones: datos.numeroHabitaciones,
    animales: datos.animales || '',
    trayectoTrabajo: datos.trayectoTrabajo,
    riesgoInundacion: datos.riesgoInundacion,
    zonaDeslaves: datos.zonaDeslaves,
    tipo: datos.tipo || [],
    comentarios: datos.comentarios || '',
  });
  const getDatosExpediente = (datos: IVivienda) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    vivienda: getDatosVivienda(datos),
  });
  const getDatosUsuario = (datos: IVivienda) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    vivienda: getDatosVivienda(datos),
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(viviendaTemp);
    const sendFunctions = [antecedentesNoPatologicosGuardarVivienda(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarVivienda(datosToSend));
    }
    apiRequests({
      type: 'send',
      requestFunctions: sendFunctions,
      successFunctions: [
        () => dispatch(setVivienda({ ...viviendaTemp, loaded: true })),
        () => dispatch(setViviendaUsuario({ ...viviendaTemp, loaded: true })),
      ],
    });
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: antecedentesNoPatologicosUsuarioGuardarVivienda(
        getDatosUsuario(viviendaTemp),
      ),
      successFunction: () => {
        dispatch(setVivienda({ ...viviendaTemp, loaded: true }));
        dispatch(
          ocultarCampos
            ? setRutaAntNoPatologicos('alimentacion')
            : setRutaAntNoPatologicos('higiene'),
        );
      },
    });
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    apiRequest({
      type: 'send',
      requestFunction: antecedentesNoPatologicosGuardarVivienda(
        getDatosExpediente(viviendaUsuario),
      ),
      successFunction: () => {
        dispatch(setVivienda(viviendaUsuario));
        setViviendaTemp(viviendaUsuario);
      },
    });
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarVivienda(
        getDatosExpediente(vivienda),
      ),
      successFunction: () => dispatch(setViviendaUsuario(vivienda)),
    });
  };

  useEffect(() => {
    if (vivienda.loaded && !viviendaTemp.loaded) {
      setViviendaTemp(vivienda);
    }
  }, [vivienda]);
  useEffect(() => {
    if (
      viviendaTemp.numeroHabitaciones &&
      viviendaTemp.numeroHabitaciones !== 0 &&
      viviendaTemp.numeroHabitantes &&
      viviendaTemp.numeroHabitantes !== 0
    ) {
      const indiceHac = viviendaTemp.numeroHabitantes / viviendaTemp.numeroHabitaciones;
      setHacinamiento({
        indice: indiceHac > 0 ? indiceHac.toFixed(1) : '',
        txt: `${t(`antecedentes-pnp-vivienda-${indiceHac > 2.5 ? 'conih' : 'sinih'}`)}*`,
      });
    }
  }, [viviendaTemp.numeroHabitaciones, viviendaTemp.numeroHabitantes]);
  useEffect(() => {
    if (viviendaTemp.loaded) {
      setViviendaTemp((prev) => ({
        ...prev,
        indiceHac: hacinamiento.indice,
      }));
    }
  }, [hacinamiento]);
  useEffect(() => {
    getAnimales().then((result: ICatalogosEstandar[]) => {
      setCatAnimales(result);
    });
  }, []);

  const components: IFormSectionComponent[] = [
    {
      hide: ocultarCampos,
      component: (
        <div className="grid grid-cols-1 md:grid-cols-4 px-4 md:px-0 gap-y-2 gap-x-4">
          <CheckboxGroup
            name="serviciosVivienda"
            label={t('antecedentes-pnp-vivienda-sv')}
            options={[
              { value: 'todos', label: t('antecedentes-pnp-vivienda-tt') },
              { value: 'sinAgua', label: t('antecedentes-pnp-vivienda-sa') },
              { value: 'sinElectricidad', label: t('antecedentes-pnp-vivienda-se') },
              { value: 'sinDrenaje', label: t('antecedentes-pnp-vivienda-sd') },
            ]}
            setValue={(e) => handleServicesChange(e)}
            value={[
              ...(viviendaTemp.serviciosBasicos.check1 ? ['todos'] : []),
              ...(viviendaTemp.serviciosBasicos.check2 ? ['sinAgua'] : []),
              ...(viviendaTemp.serviciosBasicos.check3 ? ['sinElectricidad'] : []),
              ...(viviendaTemp.serviciosBasicos.check4 ? ['sinDrenaje'] : []),
            ]}
            allowMultiple
            disabled={hayCambios}
          />
          <NumberInput
            name="numeroHabitantes"
            label={t('antecedentes-pnp-vivienda-nhab')}
            value={viviendaTemp.numeroHabitantes}
            setValue={handleInputChange}
            min={0}
            disabled={hayCambios}
          />
          <NumberInput
            name="numeroHabitaciones"
            label={t('antecedentes-pnp-vivienda-ncua')}
            value={viviendaTemp.numeroHabitaciones}
            setValue={handleInputChange}
            min={0}
            disabled={hayCambios}
          />
          {!esPaciente && (
            <TextField
              name="indiceHac"
              label={t('antecedentes-pnp-vivienda-ihac')}
              value={hacinamiento.indice}
              adornment={{
                text: hacinamiento.txt,
                position: 'end',
                alert: parseFloat(hacinamiento.indice) > 2.5,
              }}
              helperText={hacinamiento.txt.length > 0 ? t('antecedentes-pnp-vivienda-notaih') : ''}
              disabled={hayCambios}
            />
          )}
        </div>
      ),
    },
    {
      hide: ocultarCampos,
      component: (
        <CheckboxGroup
          containerClass="md:mt-2 px-4 md:px-0"
          name="animales"
          label={t('antecedentes-pnp-vivienda-mascotas')}
          options={[
            { value: 'T', label: t('si') },
            { value: 'F', label: t('no') },
          ]}
          edit={{
            show: viviendaTemp.animales === 'T',
            onClick: () => setOpenModal((prev) => !prev),
          }}
          distribution="vertical-flex"
          value={viviendaTemp.animales}
          setValue={handleInputChange}
          helperText={
            viviendaTemp.animales === 'T' && viviendaTemp.animales.length > 0
              ? viviendaTemp.tipo
                  .filter((mascota) => mascota.idAnimal.id > 0)
                  .map(
                    (mascota) =>
                      `${mascota.idAnimal.label}${mascota.cantidad ? ` (${mascota.cantidad})` : ''}`,
                  )
                  .join(', ')
              : ''
          }
          disabled={hayCambios}
        />
      ),
    },
    {
      hide: !esPaciente || !ocultarCampos,
      component: (
        <CheckboxGroup
          containerClass="md:py-0"
          name="trayectoTrabajo"
          label={t('antecedentes-pnp-vivienda-trayecto-trabajo')}
          options={[
            { value: 'si', label: t('si') },
            { value: 'no', label: t('no') },
          ]}
          distribution={isWideScreen ? 'horizontal-inline' : 'vertical-flex'}
          value={viviendaTemp.trayectoTrabajo}
          setValue={handleInputChange}
          disabled={hayCambios}
        />
      ),
    },
    {
      hide: !esPaciente || !ocultarCampos,
      component: (
        <CheckboxGroup
          containerClass="md:py-0"
          name="riesgoInundacion"
          label={t('antecedentes-pnp-vivienda-riesgo-inundacion')}
          options={[
            { value: 'si', label: t('si') },
            { value: 'no', label: t('no') },
          ]}
          distribution={isWideScreen ? 'horizontal-inline' : 'vertical-flex'}
          value={viviendaTemp.riesgoInundacion}
          setValue={handleInputChange}
          disabled={hayCambios}
        />
      ),
    },
    {
      hide: !esPaciente || !ocultarCampos,
      component: (
        <CheckboxGroup
          containerClass="md:py-0"
          name="zonaDeslaves"
          label={t('antecedentes-pnp-vivienda-zona-deslaves')}
          options={[
            { value: 'si', label: t('si') },
            { value: 'no', label: t('no') },
          ]}
          distribution={isWideScreen ? 'horizontal-inline' : 'vertical-flex'}
          value={viviendaTemp.zonaDeslaves}
          setValue={handleInputChange}
          disabled={hayCambios}
        />
      ),
    },
    {
      hide: esPaciente,
      component: (
        <TextArea
          containerClass="mt-4"
          name="comentarios"
          value={viviendaTemp.comentarios}
          setValue={handleInputChange}
          disabled={hayCambios}
        />
      ),
    },
  ];

  return (
    <ModalForm
      title={t('antecedentes-pnp-vivienda')}
      id="antecedentes-pnp-vivienda-form"
      aceptarCambios={guardarDatosDeUsuarioAExpediente}
      rechazarCambios={guardarDatosDeExpedienteAUsuario}
      submitForm={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
      hayCambios={hayCambios}
      cambios={cambios}
      backAction={() => dispatch(setRutaPrincipal('main'))}
      noXMargin
    >
      <FormSections components={components} />
      <AgregarMascotas
        open={openModal}
        hayCambios={hayCambios}
        viviendaTemp={viviendaTemp}
        setViviendaTemp={setViviendaTemp}
        catAnimales={catAnimales}
        callBackAceptar={() => setOpenModal(false)}
        callBackClose={() => setOpenModal(false)}
      />
    </ModalForm>
  );
}

export default Vivienda;
