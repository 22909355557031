import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  IconButton,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import DateDayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { setInmunizaciones } from '@actions/paciente/actions';
import { IAlertDialogProps, IVacuna } from './types';

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  ),
);

function ModalVacuna(props: IAlertDialogProps) {
  const { open, callBackClose, catVacunaNombre, catVacunaDosis, inmunizaciones } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataIngresar, setDataIngresar] = useState<IVacuna>({
    id: 0,
    aplicada: '',
    dosis: 0,
    fecha: null,
    otra: '',
  });
  const handleDateChange = (date: any) => {
    const update = { ...dataIngresar };
    update.fecha = dayjs(date).toISOString();
    setDataIngresar(update);
  };
  const callBackAceptar = () => {
    const update = [...inmunizaciones];
    update.push({
      id: dataIngresar.id,
      otraVacuna: true,
      tipo:
        dataIngresar.id === 1000
          ? dataIngresar.otra
          : catVacunaNombre.find((x) => x.id === dataIngresar.id)?.label || '',
      aplicada: dataIngresar.aplicada === 'true',
      dosis: catVacunaDosis.find((x) => x.id === dataIngresar.dosis)?.label || '',
      edad: '',
      fecha: dataIngresar.fecha,
      desactivar: false,
      error: false,
      totalGrupo: 1,
      lugarGrupo: 1,
      esquema: inmunizaciones && inmunizaciones[0].esquema ? inmunizaciones[0].esquema : 0,
      otras: 1,
      grupo: 1,
      redaccion: '',
    });
    dispatch(setInmunizaciones(update));
    setDataIngresar({
      id: 0,
      aplicada: '',
      dosis: 0,
      fecha: null,
      otra: '',
    });
  };
  const evalDisabled = () => {
    if (
      dataIngresar.id === 0 ||
      dataIngresar.aplicada.length === 0 ||
      dataIngresar.dosis === 0 ||
      (dataIngresar.id === 1000 && dataIngresar.otra?.length === 0)
    ) {
      return true;
    }
    return false;
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="delete-dialog-titulo"
      aria-describedby="delete-dialog-descripcion"
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-between items-center pl-4">
        <h2 className="font-medium text-gray-600">
          {t('antecedentes-pnp-inmunizaciones-esquema-modal-agregar')}
        </h2>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <div className="border-solid border border-gray-400 rounded p-4 mb-3 grid grid-cols-4 gap-4">
          <div>
            <h3 className="text-gray-600 font-medium m-0 mb-2 text-left">
              {t('antecedentes-pnp-inmunizaciones-esquema-modal-nombre')}
            </h3>
            <Autocomplete
              id="vacunaNombre"
              options={catVacunaNombre}
              getOptionLabel={(option) => option.label.toUpperCase()}
              noOptionsText={t('sin_resultados')}
              value={
                dataIngresar && dataIngresar.id
                  ? catVacunaNombre.find((x) => x.id === dataIngresar.id)
                  : null
              }
              onChange={(e, nV) => {
                const update = { ...dataIngresar };
                update.id = nV && nV.id ? nV.id : 0;
                update.otra = '';
                setDataIngresar(update);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          </div>
          <div className="grid">
            <h3 className="text-gray-600 font-medium m-0 mb-2 text-left">
              {t('antecedentes-pnp-inmunizaciones-aplicada')}
            </h3>
            <RadioGroup
              aria-label="realiza"
              name="realiza"
              value={dataIngresar && dataIngresar.aplicada ? dataIngresar.aplicada : null}
              onChange={(e) => {
                const update = { ...dataIngresar };
                update.aplicada = e.target.value;
                setDataIngresar(update);
              }}
              row
            >
              <FormControlLabel value="true" control={<Radio color="primary" />} label={t('si')} />
              <FormControlLabel value="false" control={<Radio color="primary" />} label={t('no')} />
            </RadioGroup>
          </div>
          <div>
            <h3 className="text-gray-600 font-medium m-0 mb-2 text-left">
              {t('antecedentes-pnp-inmunizaciones-dosis')}
            </h3>
            <Autocomplete
              id="vacunaDosis"
              options={catVacunaDosis}
              getOptionLabel={(option) => option.label.toUpperCase()}
              noOptionsText={t('sin_resultados')}
              value={
                dataIngresar && dataIngresar.dosis
                  ? catVacunaDosis.find((x) => x.id === dataIngresar.dosis)
                  : null
              }
              onChange={(e, nV) => {
                const update = { ...dataIngresar };
                update.dosis = nV && nV.id ? nV.id : 0;
                setDataIngresar(update);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} placeholder={t('selecciona')} variant="outlined" />
              )}
            />
          </div>
          <div>
            <h3 className="text-gray-600 font-medium m-0 mb-2 text-left">
              {t('antecedentes-pnp-inmunizaciones-fecha')}
            </h3>
            <MuiPickersUtilsProvider locale="es" utils={DateDayjsUtils}>
              <KeyboardDatePicker
                format="MM/DD/YYYY"
                margin="normal"
                id="date-picker-inline"
                value={dataIngresar.fecha}
                onChange={(e) => handleDateChange(e)}
                maxDate={dayjs()}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                // helperText="error"
              />
            </MuiPickersUtilsProvider>
          </div>
          {dataIngresar && dataIngresar.id && dataIngresar.id === 1000 ? (
            <div>
              <TextField
                id="especifica"
                label={t('especifica')}
                value={dataIngresar && dataIngresar.otra ? dataIngresar.otra : ''}
                onChange={(e) => {
                  const update = { ...dataIngresar };
                  update.otra = e.target.value;
                  setDataIngresar(update);
                }}
                inputProps={{
                  maxLength: 25,
                  autoComplete: 'off',
                }}
                variant="outlined"
                fullWidth
              />
            </div>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            callBackAceptar();
            callBackClose(e);
          }}
          color="primary"
          variant="contained"
          disabled={evalDisabled()}
        >
          {t('guardar')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalVacuna;
