import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRutaAntecedentes,
  setRutaAntNoPatologicos,
  setRutaPrincipal,
} from '@actions/rutasExpediente/actions';
import { setDiagnosticosGenerales } from '@actions/paciente/actions';
import { ModalForm } from '@components/modals/ModalForm';
import { RootState } from 'src/store';
import { enfermedadesGuardarDiagnosticosUsuario } from '@utils/sendInfo';
import { useApiRequest } from '@hooks/useApiRequest';
import { IDiagnosticoGeneral, IDiagnosticosGenerales, diagnosticosGeneralesInitial } from './types';
import TablaDiagnosticos from './TablaDiagnosticos';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { FormProgressSteps } from '@components/FormProgressSteps';

function DiagnosticosGenerales() {
  const dispatch = useDispatch();
  const { apiRequest } = useApiRequest();
  const { idUsuario, sexo } = useSelector((state: RootState) => state.Me);
  const { diagnosticosGenerales } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const { t } = useTranslation('translation-patient');

  const [diagnosticosGeneralesTemp, setDiagnosticosGeneralesTemp] =
    useState<IDiagnosticosGenerales>(diagnosticosGeneralesInitial);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(0);

  const isWideScreen = useBreakPoint();
  const formRef = useRef<HTMLDivElement | null>(null);
  const steps: string[] = ['corazon-cronico', 'nervioso-dolor', 'respiratorio-corporal'];

  const handleSubmitForm = () => {
    const contenidoTabla = diagnosticosGeneralesTemp.contenidoTabla.map(
      (diagnostico: IDiagnosticoGeneral, index: number) => {
        if (
          diagnostico.posee !== diagnosticosGenerales.contenidoTabla[index].posee ||
          diagnostico.medicamentos !== diagnosticosGenerales.contenidoTabla[index].medicamentos ||
          diagnostico.noMedicamento !== diagnosticosGenerales.contenidoTabla[index].noMedicamento
        ) {
          return {
            ...diagnostico,
            validado: false,
          };
        }
        return diagnostico;
      },
    );
    apiRequest({
      type: 'send',
      requestFunction: enfermedadesGuardarDiagnosticosUsuario({
        idUsuario,
        contenidoTabla,
      }),
      successFunction: (result) => {
        setDiagnosticosGeneralesTemp({
          loaded: true,
          contenidoTabla: result.datos.contenidoTabla,
        });
        dispatch(
          setDiagnosticosGenerales({
            loaded: true,
            contenidoTabla: result.datos.contenidoTabla,
          }),
        );
        if (currentIndex < 2) {
          setCurrentIndex(currentIndex + 1);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          dispatch(setRutaAntecedentes('personales-no-patologicos'));
          dispatch(setRutaAntNoPatologicos('vivienda'));
        }
      },
    });
  };

  useEffect(() => {
    if (!isWideScreen) {
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [currentIndex]);

  useEffect(() => {
    if (diagnosticosGenerales.loaded && !diagnosticosGeneralesTemp.loaded) {
      setDiagnosticosGeneralesTemp(diagnosticosGenerales);
      setLength(diagnosticosGenerales.contenidoTabla.length);
    }
  }, [diagnosticosGenerales]);

  const hasMissings = steps.map(
    (step) =>
      diagnosticosGeneralesTemp.contenidoTabla.filter(
        (diagnostico) =>
          diagnostico.categoria === step &&
          ['A', sexo].includes(diagnostico.sexo) &&
          diagnostico.posee === '',
      ).length > 0,
  );

  return (
    <div ref={formRef}>
      <ModalForm
        title={t('mi_salud')}
        // progressSteps={steps}
        id="mi-salud-form"
        submitForm={handleSubmitForm}
        disableButton={hasMissings[currentIndex]}
        backAction={() => dispatch(setRutaPrincipal('main'))}
        noXMargin
      >
        {/* <div className="text-gray-500 mt-4">{t('antecedentes-pp-diagnosticos-g-responde-1')}</div>
        <div className="text-gray-500 mb-2">{t('antecedentes-pp-diagnosticos-g-responde-2')}</div> */}
        {/* <div className="text-gray-500 my-2 font-bold">
          {t('antecedentes-pp-diagnosticos-g-presenta')}
        </div> */}
        <FormProgressSteps
          steps={steps.map((st) => t(`antecedentes-pp-categoria-${st}`))}
          currentIndex={currentIndex}
          setIndex={(index) => setCurrentIndex(index)}
          disabled={hasMissings}
        />
        <TablaDiagnosticos
          currentStep={steps[currentIndex]}
          diagnosticos={diagnosticosGeneralesTemp}
          setDiagnosticos={setDiagnosticosGeneralesTemp}
          length={length}
        />
      </ModalForm>
    </div>
  );
}

export default DiagnosticosGenerales;
