import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { IHBarProps } from './types';
import { useEffect, useRef, useState } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const BarChart = (props: IHBarProps) => {
  const {
    id,
    orientation = 'horizontal',
    titulo,
    tipos,
    etiquetas,
    colores,
    datos,
    totales,
    valorCentro,
    etiquetaCentro,
    // callbackDetalle,
    items: numItems,
    maxItems,
    adjustHeight = false,
    autoMax = false,
    join = false,
  } = props;
  const { t } = useTranslation();
  const [items, setItems] = useState<number | undefined>(undefined);
  const chartRef = useRef<ChartJSOrUndefined<'bar'>>(null);

  useEffect(() => {
    const max = maxItems === undefined ? datos[0].length : maxItems;
    setItems((numItems || 0) < max ? numItems : max);
  }, [numItems, maxItems]);
  useEffect(() => {
    chartRef.current?.resize();
  }, [datos]);

  const getDatasets = (data: number[], index: number) => ({
    data: items ? data.slice(0, items) : data,
    backgroundColor: colores[index],
    borderColor: colores[index],
    borderWidth: 1,
  });

  const getGraph = (data?: number[], index?: number) => {
    let height = undefined;
    // console.log(`bar-${id}-${index || 0}`);
    if (adjustHeight) {
      if (items) {
        height = items;
      } else {
        height = data ? data.length * 5 : datos[0].length * 7;
      }
    }
    let max = undefined;
    if (!autoMax) {
      max = index ? totales[index] : Math.max.apply(null, totales);
    }
    return (
      <Bar
        key={index ? `bar-${id}-${index}` : undefined}
        ref={chartRef}
        height={height}
        data={{
          labels: items ? etiquetas.slice(0, items) : etiquetas,
          datasets:
            data && index
              ? [getDatasets(data, index)]
              : datos.map((data, indx) => getDatasets(data, indx)),
        }}
        options={{
          indexAxis: orientation === 'horizontal' ? 'y' : 'x',
          responsive: true,
          scales: {
            screenX: {
              type: 'linear',
              display: true,
              position: 'top',
              max,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (d: any) => {
                  if ([t('sin_datos'), t('riesgo_nulo')].includes(d.label)) {
                    return d.raw;
                  }
                  const p = (d.raw * 100) / (index ? totales[index] : totales[d.datasetIndex]);
                  return `${d.raw} (${p % 1 === 0 ? p : p.toFixed(2)}%)`;
                },
              },
            },
          },
        }}
      />
    );
  };

  return (
    <div>
      {join ? (
        <div className="border-solid border border-gray-200 shadow-md p-4 pt-2 mb-4 ml-2 mr-2">
          {titulo && <h3 className="text-gray-700 text-center mb-2">{titulo}</h3>}
          <div className="flex flex-row justify-center items-center mb-4">
            {tipos?.map((label: string, index: number) => (
              <>
                <div
                  className={`w-9 h-3 mr-1 ${index === 0 ? 'ml-0' : 'ml-6'}`}
                  style={{ backgroundColor: colores[index] }}
                />
                <h4 className="text-gray-700">{`${t(label)} (${totales[index]})`}</h4>
              </>
            ))}
          </div>
          {datos.length > 0 && datos.some((data) => data.length > 0 && data.some((d) => d > 0)) ? (
            <>
              {(valorCentro || totales.length > 0) && (
                <span className="text-gray-600">
                  {`${t('total')}: ${totales.reduce((a, b) => a + b, 0)}`}
                </span>
              )}
              <div>{getGraph()}</div>
            </>
          ) : (
            <div className="pt-4 text-center">
              <span>{t('sin_datos_alarmas')}</span>
            </div>
          )}
        </div>
      ) : (
        <>
          {datos.map((data: number[], index: number) => (
            <div
              key={`div-${id}-${index}`}
              className="border-solid border border-gray-200 shadow-md p-4 pt-2 mb-4 ml-2 mr-2"
            >
              {tipos && (
                <div className="text-center mb-0 h-14">
                  <h3 className="mb-0 pb-4 text-gray-700">{t(tipos[index])}</h3>
                </div>
              )}
              {(valorCentro || totales.length > 0) && (
                <span className="text-gray-600">
                  {valorCentro
                    ? `${t(etiquetaCentro || '')}: ${valorCentro}`
                    : `${t('total')}: ${totales[index]}`}
                </span>
              )}
              <div>
                {data.some((value) => value > 0) ? (
                  <>{getGraph(data, index)}</>
                ) : (
                  <div className="pt-4 text-center">
                    <span>{t('sin_datos_alarmas')}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default BarChart;
