import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { RawDraftContentState } from 'draft-js';
import { Button, Collapse } from '@material-ui/core';
import { RootState } from 'src/store';
import { esMismoDiaFechaISO, getFechaActualISO } from '@common/functions';
import Plan from './Plan/index';
import Diagnostico from './Diagnostico/Formulario';
import ListaDiagnosticos from './Diagnostico/ListaDiagnosticos';
import {
  IEnfermedad,
  IEnfermedadesForm,
  enfermedadesFormInitial,
} from '../Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';

function DiagnosticoPlan() {
  const { t } = useTranslation();
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { secciones, esPaciente } = useSelector((state: RootState) => state.Me);
  const {
    antPatologicos: { enfReferidas },
  } = useSelector((state: RootState) => state.Paciente.expediente);
  // const [selector, setSelector] = useState<number>(1);
  const [referidasActivasExpand, setReferidasActivasExpand] = useState(true);
  const [referidasResueltasExpand, setReferidasResueltasExpand] = useState(false);
  const [enfActivas, setEnfActivas] = useState<IEnfermedad[]>([]);
  const [enfActivasConsulta, setEnfActivasConsulta] = useState<IEnfermedad[]>([]);
  const [enfResueltasConsulta, setEnfResueltasConsulta] = useState<IEnfermedad[]>([]);
  const [idDiagnostico, setIdDiagnostico] = useState<number>(-1);
  const [diagnosticoSeleccionado, setDiagnosticoSeleccionado] =
    useState<IEnfermedadesForm>(enfermedadesFormInitial);

  useEffect(() => {
    const enfermedadesActivas = [] as IEnfermedad[];
    const enfermedadesActivasConsulta = [] as IEnfermedad[];
    const enfermedadesResueltasConsulta = [] as IEnfermedad[];
    enfReferidas.forEach((e) => {
      if (e.idConsulta === idConsulta) {
        if (e.estatusDelDiagnostico === 'activo') {
          enfermedadesActivasConsulta.push(e);
        } else if (e.estatusDelDiagnostico === 'resuelto') {
          enfermedadesResueltasConsulta.push(e);
        }
      }
      if (e.estatusDelDiagnostico === 'activo') {
        enfermedadesActivas.push(e);
      }
    });
    setEnfActivasConsulta(enfermedadesActivasConsulta);
    setEnfActivas(enfermedadesActivas);
    setEnfResueltasConsulta(enfermedadesResueltasConsulta);
  }, [enfReferidas]);

  useEffect(() => {
    if (idDiagnostico !== -1) {
      const diagnosticoSel = enfReferidas.find((e) => e.idBD === idDiagnostico);
      if (diagnosticoSel) {
        const comentario =
          diagnosticoSel.comentarios.length > 0 &&
          esMismoDiaFechaISO(diagnosticoSel.comentarios[0].fecha, getFechaActualISO())
            ? diagnosticoSel.comentarios[0].comentario
            : ({ blocks: [], entityMap: {} } as RawDraftContentState);
        setDiagnosticoSeleccionado({
          ...diagnosticoSel,
          comentario,
        });
      }
    }
  }, [idDiagnostico]);

  return (
    <div className="bg-white w-full">
      {!esPaciente && (
        <div className="container mx-auto border border-solid border-gray-200 shadow">
          {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 47) && (
            <Diagnostico
              idDiagnostico={idDiagnostico}
              setIdDiagnostico={setIdDiagnostico}
              diagnostico={diagnosticoSeleccionado}
              setDiagnostico={setDiagnosticoSeleccionado}
            />
          )}
          <div className="mt-4 bg-white rounded p-4 shadow">
            {enfActivasConsulta.length > 0 ? (
              <h3 className="text-blue-500 font-medium m-0">{t('lista_de_enfermedades')}</h3>
            ) : (
              <h3 className="text-blue-500 font-medium m-0">
                {t('no_hay_registros_existentes_para_esta_consulta')}
              </h3>
            )}
            <div className="mt-4">
              {enfActivasConsulta.length > 0 && (
                <>
                  <h3 className="text-blue-500 font-light m-0 mb-4">
                    {`${t('diagnosticos_activos')}: ${enfActivasConsulta.length}`}
                  </h3>
                  <Collapse in={referidasActivasExpand}>
                    {enfActivasConsulta.map((diagnostico) => (
                      <ListaDiagnosticos
                        diagnostico={diagnostico}
                        idDiagnostico={idDiagnostico}
                        setIdDiagnostico={setIdDiagnostico}
                      />
                    ))}
                  </Collapse>
                  <div className="text-center">
                    <Button
                      onClick={() => setReferidasActivasExpand((prevVal: boolean) => !prevVal)}
                      color="primary"
                    >
                      {referidasActivasExpand ? (
                        <span>{t('ver_menos')}</span>
                      ) : (
                        <span>{t('ver_mas')}</span>
                      )}
                      {referidasActivasExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Button>
                  </div>
                </>
              )}
              {enfResueltasConsulta.length > 0 && (
                <>
                  <h3 className="text-blue-500 font-light m-0 mb-4">
                    {`${t('diagnosticos_resueltos')}: ${enfResueltasConsulta.length}`}
                  </h3>
                  <Collapse in={referidasResueltasExpand}>
                    {enfResueltasConsulta.map((diagnostico) => (
                      <ListaDiagnosticos
                        diagnostico={diagnostico}
                        idDiagnostico={idDiagnostico}
                        setIdDiagnostico={setIdDiagnostico}
                      />
                    ))}
                  </Collapse>
                  <div className="text-center">
                    <Button
                      onClick={() => setReferidasResueltasExpand((prevVal: boolean) => !prevVal)}
                      color="primary"
                    >
                      {referidasResueltasExpand ? (
                        <span>{t('ver_menos')}</span>
                      ) : (
                        <span>{t('ver_mas')}</span>
                      )}
                      {referidasResueltasExpand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="pt-2 pb-4">
        <div className="container mx-auto shadow-lg">
          <div className="container mx-auto shadow flex content-center items-center p-4">
            <Plan enfermedadesActivas={enfActivas} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiagnosticoPlan;
