import { ICatalogosEstandar } from '@common/types';

export interface ISintomaEF {
  nombreSintoma: string;
  comentarioSintoma: string;
  normal: string;
}

export interface IExploracionFisica {
  loaded: boolean;
  sitioMedicion: ICatalogosEstandar;
  temperatura: string;
  fCardiaca: string;
  fCardiacaRadio: string;
  diastolica: string;
  sistolica: string;
  pMedia: string;
  pPulso: string;
  pArterialRadio: string;
  fRespiratoria: string;
  satOxigeno: string;
  peso: string;
  altura: string;
  imc: string;
  perCintura: string;
  contenidoTabla: ISintomaEF[];
}

export const arrAreas = [
  'antecedentes-ef-ag',
  'antecedentes-ef-pa',
  'antecedentes-ef-craneo',
  'antecedentes-ef-cara',
  'antecedentes-ef-ojos',
  'antecedentes-ef-oidos',
  'antecedentes-ef-nariz',
  'antecedentes-ef-bg',
  'antecedentes-ef-cuello',
  'antecedentes-ef-torax',
  'antecedentes-ef-rp',
  'antecedentes-ef-gm',
  'antecedentes-ef-axila',
  'antecedentes-ef-abdomen',
  'antecedentes-ef-ric',
  'antecedentes-ef-gen',
  'antecedentes-ef-recto',
  'antecedentes-ef-extremidades',
  'antecedentes-ef-col',
  'antecedentes-ef-neurologico',
  'antecedentes-ef-otro',
];

export const exploracionFisicaInitial: IExploracionFisica = {
  loaded: false,
  sitioMedicion: { id: 0, label: '' },
  temperatura: '',
  fCardiaca: '',
  fCardiacaRadio: '',
  diastolica: '',
  sistolica: '',
  pMedia: '',
  pPulso: '',
  pArterialRadio: '',
  fRespiratoria: '',
  satOxigeno: '',
  peso: '',
  altura: '',
  imc: '',
  perCintura: '',
  contenidoTabla: arrAreas.map((aA) => ({
    comentarioSintoma: '',
    nombreSintoma: aA,
    normal: '',
  })),
};

export interface IControlErrores {
  peso: boolean;
  altura: boolean;
  cintura: boolean;
  sistolica: boolean;
  diastolica: boolean;
  fcardiaca: boolean;
  frespiratoria: boolean;
  temperatura: boolean;
  sp: boolean;
}
