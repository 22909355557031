import React from 'react';
import { createStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogContentText, Slide } from '@material-ui/core';
/** importacion de validaciones */
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { IModalRecomendacionProps } from './types';

require('dayjs/locale/es');

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#111827',
    },
  });

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  ),
);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: (event: React.SyntheticEvent | React.MouseEvent, reason?: string | undefined) => void;
}

function ModalPrevencion(props: IModalRecomendacionProps) {
  const { t } = useTranslation();
  const { open, callBackClose, referencia } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="alert-dialog-titulo"
      aria-describedby="alert-dialo-descripcion"
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-between pl-4 bg-blue-800 text-white items-center">
        <span className="text-lg">{t('recomendacion')}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <DialogContentText id="alert-dialog-descripcion">
          <h4 className="font-normal mt-4 mr-4 text-gray-500">{referencia.recomendacion}</h4>
          <h4 className="font-normal mt-4 mr-4 text-gray-900">{t('referencia')}</h4>
          <h4 className="font-normal mt-4 mr-4 text-gray-500">{referencia.referencia}</h4>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default ModalPrevencion;
