import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { RadioGroup, FormControlLabel, Radio, TextField, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useStyles from '@common/styles';
import TipoDeDrogas from './TipoDrogas';
import { IOtrasDrogasProps } from './types';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import { AddButton } from '@components/AddButton';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import { toxicomaniaInitial } from '../types';

function OtrasDrogas(props: IOtrasDrogasProps) {
  const { hayCambios, toxicomania, setToxicomania } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const estatus = ['si', 'no'].map((es) => ({ value: es, label: t(es) }));

  const handleInputChange = (e: { name: string; value: any }) => {
    setToxicomania({
      ...toxicomania,
      otrasDrogas: {
        ...toxicomania.otrasDrogas,
        [e.name]: e.value,
      },
    });
  };

  const handleChangeConsumoDrogas = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value as 'si' | 'no';
    const newOtrasDrogas = { ...toxicomania.otrasDrogas };
    newOtrasDrogas.consumoOtrasDrogas = val;
    if (val === 'no') {
      newOtrasDrogas.arrTipoDrogas = {
        arrTabla: ['I'],
        tipoDroga: [''],
        especifica: [''],
        cantidadDroga: [''],
        edadInicio: [''],
        edadTermino: [''],
        frecuencia: [''],
        estatus: ['activo'],
      };
      newOtrasDrogas.comentarios = '';
    }
    setToxicomania({
      ...toxicomania,
      otrasDrogas: newOtrasDrogas,
    });
  };

  const handleChangeComentarios = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToxicomania({
      ...toxicomania,
      otrasDrogas: {
        ...toxicomania.otrasDrogas,
        comentarios: event.target.value as string,
      },
    });
  };

  const handleDeleteRow = (indx: number) => {
    if (toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.length > 1) {
      setToxicomania({
        ...toxicomania,
        otrasDrogas: {
          ...toxicomania.otrasDrogas,
          arrTipoDrogas: {
            arrTabla: toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.filter(
              (_item, j) => indx !== j,
            ),
            tipoDroga: toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga.filter(
              (_item, j) => indx !== j,
            ),
            especifica: toxicomania.otrasDrogas.arrTipoDrogas.especifica.filter(
              (_item, j) => indx !== j,
            ),
            cantidadDroga: toxicomania.otrasDrogas.arrTipoDrogas.cantidadDroga.filter(
              (_item, j) => indx !== j,
            ),
            edadInicio: toxicomania.otrasDrogas.arrTipoDrogas.edadInicio.filter(
              (_item, j) => indx !== j,
            ),
            edadTermino: toxicomania.otrasDrogas.arrTipoDrogas.edadTermino.filter(
              (_item, j) => indx !== j,
            ),
            frecuencia: toxicomania.otrasDrogas.arrTipoDrogas.frecuencia.filter(
              (_item, j) => indx !== j,
            ),
            estatus: toxicomania.otrasDrogas.arrTipoDrogas.estatus.filter((_item, j) => indx !== j),
          },
        },
      });
    } else {
      setToxicomania({
        ...toxicomania,
        otrasDrogas: {
          ...toxicomania.otrasDrogas,
          arrTipoDrogas: {
            arrTabla: ['I'],
            tipoDroga: [''],
            especifica: [''],
            cantidadDroga: [''],
            edadInicio: [''],
            edadTermino: [''],
            frecuencia: [''],
            estatus: ['activo'],
          },
        },
      });
    }
  };

  const handleClickAgregarOtroTipo = () => {
    if (toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.length < 10) {
      setToxicomania({
        ...toxicomania,
        otrasDrogas: {
          ...toxicomania.otrasDrogas,
          arrTipoDrogas: {
            arrTabla: toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.concat(
              `${
                toxicomania.otrasDrogas.arrTipoDrogas.arrTabla[
                  toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.length - 1
                ]
              }I`,
            ),
            tipoDroga: toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga.concat(''),
            especifica: toxicomania.otrasDrogas.arrTipoDrogas.especifica.concat(''),
            cantidadDroga: toxicomania.otrasDrogas.arrTipoDrogas.cantidadDroga.concat(''),
            edadInicio: toxicomania.otrasDrogas.arrTipoDrogas.edadInicio.concat(''),
            edadTermino: toxicomania.otrasDrogas.arrTipoDrogas.edadTermino.concat(''),
            frecuencia: toxicomania.otrasDrogas.arrTipoDrogas.frecuencia.concat(''),
            estatus: toxicomania.otrasDrogas.arrTipoDrogas.estatus.concat('activo'),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (toxicomania.otrasDrogas.consumoOtrasDrogas === 'no') {
      setToxicomania({
        ...toxicomania,
        otrasDrogas: {
          ...toxicomania.otrasDrogas,
          arrTipoDrogas: toxicomaniaInitial.otrasDrogas.arrTipoDrogas,
        },
      });
    }
  }, [toxicomania.otrasDrogas.consumoOtrasDrogas]);

  return (
    <>
      <CheckboxGroup
        name="consumoOtrasDrogas"
        label={t('otras_drogas')}
        options={estatus}
        value={toxicomania.otrasDrogas.consumoOtrasDrogas}
        setValue={handleInputChange}
        disabled={hayCambios}
        distribution="vertical-flex"
      />
      {toxicomania.otrasDrogas.consumoOtrasDrogas === 'si' && (
        <>
          {toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.map((el, indx) => (
            <TipoDeDrogas
              hayCambios={hayCambios}
              key={el}
              index={indx}
              toxicomania={toxicomania}
              setToxicomania={setToxicomania}
              handleDeleteRow={handleDeleteRow}
            />
          ))}
          <AddButton
            variant="enabled"
            label={t('agregar_droga')}
            onClick={handleClickAgregarOtroTipo}
            disabled={
              hayCambios ||
              toxicomania.otrasDrogas.arrTipoDrogas.arrTabla.length >= 10 ||
              toxicomania.otrasDrogas.arrTipoDrogas.tipoDroga.some((droga: string) => !droga)
            }
          />
          {!esPaciente && (
            <TextArea
              name="comentarios"
              value={toxicomania.otrasDrogas.comentarios}
              setValue={handleInputChange}
              maxLength={300}
              disabled={hayCambios}
            />
          )}
        </>
      )}
    </>
  );
}

export default OtrasDrogas;
