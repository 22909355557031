import { useEffect, useState } from 'react';
import { Stethoscope } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@material-ui/core';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { ITablaDiagnosticosProps } from './types';
import { diagnosticoGeneralInitial, IDiagnosticoGeneral } from '../types';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import { AddButton } from '@components/AddButton';
import ControlDiagnostico from '@components/ModalDialogs/ControlDiagnostico';
import CheckboxInput from '@components/FormElemntsLibrary/CheckboxInput';

function TablaDiagnosticos(props: ITablaDiagnosticosProps) {
  const { currentStep, diagnosticos, setDiagnosticos, length } = props;
  const { t } = useTranslation();
  const { sexo } = useSelector((state: RootState) => state.Me);
  const [medicamentos, setMedicamentos] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  let i = 0;

  const handlePoseeChange = (e: { name: string; value: 'si' | 'no'; index?: number }) => {
    let newContenidoTabla = [...diagnosticos.contenidoTabla];
    if (e.index !== undefined) {
      if (e.value === 'si' && newContenidoTabla[e.index].control) {
        setSelectedIndex(e.index);
      }
      newContenidoTabla[e.index] = {
        ...newContenidoTabla[e.index],
        posee: e.value,
        medicamentos: e.value === 'no' ? [] : newContenidoTabla[e.index].medicamentos,
        validado: false,
      };
    }
    setDiagnosticos((prevData) => ({
      ...prevData,
      contenidoTabla: newContenidoTabla,
    }));
  };

  const handleMedicamentosChange = (e: { name: string; value: any; index?: number }) => {
    setMedicamentos(
      medicamentos.map((medicamento, indx) => (indx === e.index ? e.value : medicamento)),
    );
  };

  const handleNoMedicamento = (e: { name: string; value: any; index?: number }, index: number) => {
    setDiagnosticos((prevData) => ({
      ...prevData,
      contenidoTabla: prevData.contenidoTabla.map((diagnostico, indx) =>
        indx === index
          ? {
              ...diagnostico,
              noMedicamento: e.value,
              medicamentos: e.value ? [] : diagnostico.medicamentos,
              validado: false,
            }
          : diagnostico,
      ),
    }));
  };

  const handleAddMedicamento = (index: number) => {
    setDiagnosticos((prevData) => ({
      ...prevData,
      contenidoTabla: prevData.contenidoTabla.map((diagnostico, indx) =>
        indx === index
          ? {
              ...diagnostico,
              medicamentos: [...diagnostico.medicamentos, medicamentos[index]],
              validado: false,
            }
          : diagnostico,
      ),
    }));
    setMedicamentos(medicamentos.map((medicamento, indx) => (indx === index ? '' : medicamento)));
  };

  const handleEditMedicamento = (index: number, indexMed: number) => {
    setMedicamentos(
      medicamentos.map((medicamento, indx) =>
        indx === index ? diagnosticos.contenidoTabla[index].medicamentos[indexMed] : medicamento,
      ),
    );
    setDiagnosticos((prevData) => ({
      ...prevData,
      contenidoTabla: prevData.contenidoTabla.map((diagnostico, indx) =>
        indx === index
          ? {
              ...diagnostico,
              medicamentos: diagnostico.medicamentos.filter((m, i) => i !== indexMed),
              validado: false,
            }
          : diagnostico,
      ),
    }));
  };

  const handleDeleteMedicamento = (index: number, indexMed: number) => {
    setDiagnosticos((prevData) => ({
      ...prevData,
      contenidoTabla: prevData.contenidoTabla.map((diagnostico, indx) =>
        indx === index
          ? {
              ...diagnostico,
              medicamentos: diagnostico.medicamentos.filter((m, i) => i !== indexMed),
              validado: false,
            }
          : diagnostico,
      ),
    }));
  };

  useEffect(() => setMedicamentos(new Array(length).fill('')), [length]);

  return (
    <>
      {diagnosticos.contenidoTabla.map((diagnostico: IDiagnosticoGeneral, index: number) => {
        const show =
          ['A', sexo].includes(diagnostico.sexo) && diagnostico.categoria === currentStep;
        if (show) {
          i += 1;
        }
        return show ? (
          <div
            key={diagnostico.idDiagnostico}
            id={`${diagnostico.idDiagnostico.toString()}-${diagnostico.posee}`}
            className={`${
              i % 2 !== 0 ? 'bg-[#F3F4F6] border-b-[1px]' : 'border-[#D1D5DB] border-b-[1px]'
            } pt-4 pb-2 px-4`}
          >
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="flex flex-col">
                <CheckboxGroup
                  index={index}
                  name="posee"
                  label={t(`antecedentes-pp-diagnosticos-g-${diagnostico.nombre}`)}
                  options={[
                    { value: 'si', label: t('si') },
                    { value: 'no', label: t('no') },
                  ]}
                  distribution="vertical-flex"
                  margin="mb-2"
                  // numberPosition={i}
                  value={diagnostico.posee}
                  setValue={handlePoseeChange}
                />
                {diagnostico.control && diagnostico.posee === 'si' && (
                  <div className="text-left text-blue-600 hover:text-blue-900">
                    <button type="button" onClick={() => setSelectedIndex(index)}>
                      <div className="flex flex-row">
                        <Stethoscope size={15} className="mr-1" />
                        {t('antecedentes-pp-diagnosticos-g-control')}
                      </div>
                    </button>
                  </div>
                )}
              </div>
              {diagnostico.posee === 'si' &&
                ![25, 26, 27, 29, 30, 31].includes(diagnostico.idDiagnostico) && (
                  <div className="grid grid-cols-3">
                    <TextInput
                      containerClass="col-span-2 pr-2"
                      index={index}
                      name="medicamento"
                      label={t('antecedentes-pp-diagnosticos-g-medicamento')}
                      placeholder={t('medicamento')}
                      isRequired={false}
                      maxLength={50}
                      value={medicamentos[index]}
                      setValue={handleMedicamentosChange}
                      disabled={diagnostico.noMedicamento}
                      validacion={(val) => {
                        return diagnostico.medicamentos.includes(val.trim())
                          ? t('antecedentes-pp-diagnosticos-g-repetido')
                          : '';
                      }}
                      hideValidationIcon
                    />
                    <div className="flex flex-col mt-1 mr-4">
                      <CheckboxInput
                        name="noMedicamento"
                        label={t('antecedentes-pp-diagnosticos-g-no-medicamento')}
                        value={diagnostico.noMedicamento}
                        setValue={(e) => handleNoMedicamento(e, index)}
                      />
                      <div className="mt-1">
                        <AddButton
                          variant="enabled"
                          label={t('agregar')}
                          onClick={() => handleAddMedicamento(index)}
                          disabled={
                            medicamentos[index]?.trim() === '' ||
                            diagnostico.medicamentos.includes(medicamentos[index]?.trim())
                          }
                          position="start"
                          xPadding={0}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className="mx-4">
              {diagnostico.medicamentos.map((label, indexMed) => (
                <Chip
                  variant="outlined"
                  label={label}
                  onClick={() => handleEditMedicamento(index, indexMed)}
                  onDelete={() => handleDeleteMedicamento(index, indexMed)}
                />
              ))}
            </div>
          </div>
        ) : null;
      })}
      <ControlDiagnostico
        open={selectedIndex > 0}
        onClose={() => setSelectedIndex(-1)}
        diagnostico={
          selectedIndex > 0 ? diagnosticos.contenidoTabla[selectedIndex] : diagnosticoGeneralInitial
        }
        setDiagnosticos={setDiagnosticos}
      />
      {/* {selectedIndex > 0 &&
        <ControlModal
          diagnostico={diagnosticos.contenidoTabla[selectedIndex]}
          setDiagnosticos={setDiagnosticos}
          handleClose={() => setTimeout(() => setSelectedIndex(-1), 300)}
        />
      } */}
    </>
  );
}

export default TablaDiagnosticos;
