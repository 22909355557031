import { useTranslation } from 'react-i18next';
import { Select, TextField, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { constDia, constMes, constAnioBuilder } from '@constants/fechas';
import { capitalize } from '@common/functions';
import { IViewFechaProps } from './types';

require('dayjs/locale/es');

function ViewFecha({ componenteFecha }: IViewFechaProps) {
  const { t } = useTranslation();
  return (
    <div className="border-solid border border-gray-400 rounded p-4 mb-3">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="flex mb-2 items-end">
            <h3 className="pr-4 m-0 text-gray-600 font-medium">
              {capitalize(componenteFecha.datosComponente.titulo)}
            </h3>
            <div className="text-gray-500">
              {componenteFecha.datosComponente.descripcion
                ? `* ${componenteFecha.datosComponente.descripcion}`
                : ''}
            </div>
          </div>
          <div className="grid grid-cols-4 gap-2 mb-3">
            <FormControl variant="outlined" fullWidth disabled>
              <InputLabel>{t('dia')}</InputLabel>
              <Select
                labelId="label-dia"
                id="select-dia"
                value={componenteFecha.constructor.fecha!.dia}
                label={t('dia')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constDia.map((diaSingle) => (
                  <MenuItem value={diaSingle.toString()} key={diaSingle}>
                    {diaSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth className="col-span-2" disabled>
              <InputLabel>{t('mes')}</InputLabel>
              <Select
                labelId="label-mes"
                id="select-mes"
                value={componenteFecha.constructor.fecha!.mes}
                label={t('mes')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constMes.map((mesSingle, indx) => (
                  <MenuItem value={(indx + 1).toString()} key={mesSingle}>
                    {capitalize(mesSingle)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth disabled>
              <InputLabel>{t('anio')}</InputLabel>
              <Select
                labelId="label-año"
                id="select-año"
                value={componenteFecha.constructor.fecha!.anio}
                label={t('anio')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 375,
                    },
                  },
                }}
              >
                {constAnioBuilder().map((anioSingle) => (
                  <MenuItem key={anioSingle} value={anioSingle.toString()}>
                    {anioSingle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="pt-[31px]">
          <TextField
            variant="outlined"
            rows={4}
            multiline
            fullWidth
            disabled
            label={t('comentarios')}
            inputProps={{ autoComplete: 'off', maxLength: 300 }}
            value={componenteFecha.constructor.comentarios}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewFecha;
