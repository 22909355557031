/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { RootState } from 'src/store';
// import { OrangeButton } from '@utils/ComponentesJSX';
import { ReactComponent as LaboratorioIcon } from '@assets/icons/Laboratorio.svg';
import { ReactComponent as ProcedimientosQuirurgicosIcon } from '@assets/icons/ProcedimientosQuirurgicos.svg';
import { ReactComponent as ProcedimientosTerapeuticosIcon } from '@assets/icons/ProcedimientosTerapeuticos.svg';
import { ReactComponent as ProcedimientosDiagnosticosIcon } from '@assets/icons/ProcedimientosDiagnosticos.svg';
import { ReactComponent as TerapiaFarmacologicaIcon } from '@assets/icons/TerapiaFarmacologica.svg';
import { ReactComponent as InmunizacionIcon } from '@assets/icons/Inmunizacion.svg';
import { ReactComponent as GeneralIcon } from '@assets/icons/general.svg';
import { ReactComponent as MedicinaPreventivaIcon } from '@assets/icons/MedicinaPreventiva.svg';
import { ReactComponent as PronosticoIcon } from '@assets/icons/Pronostico.svg';
// import { ReactComponent as ProximaCitaIcon } from '@assets/icons/ProximaCita.svg';
// import { ReactComponent as InterconsultaIcon } from '@assets/icons/Interconsulta.svg';
import { IBotonPlan, IPlanProps } from './types';
import useStyles from './styles';
import Procedimientos from './Procedimientos/index';
import Laboratorio from './Laboratorio/index';
import TerapiaFarmacologica from './TerapiaFarmacologica';
import MedicinaPreventiva from './MedicinaPreventiva';
import Inmunizacion from '../../Antecedentes/PersonalesNoPatologicos/Inmunizaciones/index';
import Pronostico from './Pronostico/index';
import DictamenMedicoLaboral from './DictamenMedicoLaboral';

function Plan(props: IPlanProps) {
  const { enfermedadesActivas } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { secciones, enOrganizacion, esPaciente } = useSelector((state: RootState) => state.Me);
  const {
    antPatologicos: { procDiagnosticos, procTerapeuticos, procQuirurgicos },
    plan: { solProcDiagnosticos, solProcTerapeuticos, solProcQuirurgicos },
  } = useSelector((state: RootState) => state.Paciente.expediente);
  const [activeStep, setActiveStep] = useState<
    | 'laboratorio'
    | 'procedimientos_diagnosticos'
    | 'procedimientos_terapeuticos'
    | 'procedimientos_quirurgicos'
    | 'terapia_farmacologica'
    | 'inmunizacion'
    | 'dictamen_med_lab'
    | 'medicina_preventiva'
    | 'pronostico'
  >(esPaciente ? 'medicina_preventiva' : 'laboratorio');

  const botones: IBotonPlan[] = [
    { secciones: [50, 51], titulo: 'laboratorio', icono: <LaboratorioIcon /> },
    {
      secciones: [52, 53],
      titulo: 'procedimientos_diagnosticos',
      icono: <ProcedimientosDiagnosticosIcon />,
    },
    {
      secciones: [54, 55],
      titulo: 'procedimientos_terapeuticos',
      icono: <ProcedimientosTerapeuticosIcon />,
    },
    {
      secciones: [56],
      titulo: 'procedimientos_quirurgicos',
      icono: <ProcedimientosQuirurgicosIcon />,
    },
    { secciones: [48], titulo: 'terapia_farmacologica', icono: <TerapiaFarmacologicaIcon /> },
    { secciones: [57], titulo: 'inmunizacion', icono: <InmunizacionIcon /> },
    { secciones: [76], titulo: 'dictamen_med_lab', icono: <GeneralIcon /> },
    { secciones: [49], titulo: 'medicina_preventiva', icono: <MedicinaPreventivaIcon /> },
    { secciones: [58], titulo: 'pronostico', icono: <PronosticoIcon /> },
    // { secciones: [], titulo: 'proximas_citas', icono: <ProximaCitaIcon /> },
    // { secciones: [], titulo: 'interconsulta', icono: <InterconsultaIcon /> },
  ];

  return (
    <div className="w-full">
      {!esPaciente && (
        <>
          <h1 className="text-blue-800 font-normal m-0 mb-4">{t('d-p-plan')}</h1>
          <div className="bg-white grid grid-cols-3 xl:grid-cols-4 gap-2 border-solid border-0 border-b border-gray-600 pb-6">
            {botones.map(
              (boton: IBotonPlan) =>
                (enOrganizacion || boton.titulo !== 'dictamen_med_lab') &&
                secciones.length > 0 &&
                secciones.some((valor) => boton.secciones.includes(valor.idSeccion)) && (
                  <Button
                    className={activeStep === boton.titulo ? classes.selected : classes.button}
                    size="large"
                    variant="contained"
                    color="primary"
                    startIcon={boton.icono}
                    fullWidth
                    onClick={() => setActiveStep(boton.titulo)}
                  >
                    {t(boton.titulo)}
                  </Button>
                ),
            )}
          </div>
        </>
      )}
      {activeStep === 'laboratorio' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion === 50 || valor.idSeccion === 51) && (
          <Laboratorio />
        )}
      {activeStep === 'procedimientos_diagnosticos' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion === 52 || valor.idSeccion === 53) && (
          <Procedimientos
            tipo="diagnostico"
            solProcedimientos={solProcDiagnosticos}
            procedimientos={procDiagnosticos}
          />
        )}
      {activeStep === 'procedimientos_terapeuticos' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion === 54 || valor.idSeccion === 55) && (
          <Procedimientos
            tipo="terapeutico"
            solProcedimientos={solProcTerapeuticos}
            procedimientos={procTerapeuticos}
          />
        )}
      {activeStep === 'procedimientos_quirurgicos' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion === 56) && (
          <Procedimientos
            tipo="quirurgico"
            solProcedimientos={solProcQuirurgicos}
            procedimientos={procQuirurgicos}
          />
        )}
      {activeStep === 'terapia_farmacologica' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion === 48) && (
          <TerapiaFarmacologica enfermedadesActivas={enfermedadesActivas} />
        )}
      {activeStep === 'inmunizacion' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion === 57) && <Inmunizacion isPlan />}
      {activeStep === 'dictamen_med_lab' && enOrganizacion && (
        <DictamenMedicoLaboral enfermedadesActivas={enfermedadesActivas} />
      )}
      {activeStep === 'medicina_preventiva' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion === 49) && <MedicinaPreventiva />}
      {activeStep === 'pronostico' &&
        secciones.length > 0 &&
        secciones.find((valor) => valor.idSeccion === 58) && <Pronostico />}
    </div>
  );
}

export default Plan;
