import { Frown, Laugh, Meh } from 'lucide-react';
import { FC } from 'react';
import { SatisfactionIndicatorProps } from './type';

export const SatisfactionIndicator: FC<SatisfactionIndicatorProps> = ({ showEmojis = true }) => {
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto mb-4">
        <div className="w-full mx-auto">
          {showEmojis && (
            <div className="flex justify-between mb-2">
              <Laugh className="w-5 h-5 text-gray-500" />
              <Meh className="w-5 h-5 text-gray-500" />
              <Frown className="w-5 h-5 text-gray-500" />
            </div>
          )}
          <div className="relative h-2 rounded-full overflow-hidden bg-gradient-to-r from-green-400 via-yellow-400 to-red-500" />
        </div>
      </div>
    </div>
  );
};

export default SatisfactionIndicator;
