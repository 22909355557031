import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { SubmitButtonProps } from './type';

// eslint-disable-next-line import/prefer-default-export
export const SubmitButton: FC<SubmitButtonProps> = ({
  containerClass = '',
  label: externalLabel,
  acceptCallback,
  rejectCallback,
  saveCallback,
  disabled = false,
  hayCambios,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const label = externalLabel || t(esPaciente ? 'guardar_y_continuar' : 'guardar');
  const baseStyles = `w-full h-11 px-6 rounded-md text-base text-white shadow-gray-500
  shadow-sm cursor-pointer ${disabled ? '' : 'hover:bg-blue-700'} transition-all duration-200
  disabled:opacity-75 disabled:cursor-not-allowed`;

  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    saveCallback();
  };

  return (
    <div className="flex flex-col justify-center w-full mb-4">
      <hr className="w-full h-[1px] bg-[#C4C4C4] mb-4" />
      <div className={`md:flex flex-row justify-end px-4 ${containerClass}`}>
        {hayCambios ? (
          <div className="flex justify-between">
            <button type="button" className={`${baseStyles} bg-[#3B82F6]`} onClick={acceptCallback}>
              {t('aceptar')}
            </button>
            <button
              type="button"
              className={`${baseStyles} bg-[#EF4444] hover:bg-[#BA1818] ml-2`}
              onClick={rejectCallback}
            >
              {t('rechazar')}
            </button>
          </div>
        ) : (
          <div className="text-right">
            <button
              type="button"
              className={`${baseStyles} ${disabled ? 'bg-gray-400' : 'bg-[#3B82F6]'}`}
              disabled={disabled}
              onClick={handleClick}
            >
              {label}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
