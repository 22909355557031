import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { IMenstruacionHabitualProps } from './types';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import ScaleInput from '@components/FormElemntsLibrary/ScaleInput';
import SatisfactionIndicator from '@components/FormElemntsLibrary/SatisfactionIndicator';

function MenstruacionHabitual(props: IMenstruacionHabitualProps) {
  const { hayCambios, ginecoObstetrico, setGinecoObstetrico } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { ocultarCampos } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  const handleInputChange = (e: { name: string; value: any }) =>
    setGinecoObstetrico((prevData) => ({
      ...prevData,
      menstruacionHabitual: {
        ...ginecoObstetrico.menstruacionHabitual,
        [e.name]: e.value,
      },
    }));

  const components: { show: boolean; component: JSX.Element }[] = [
    {
      show: !ocultarCampos,
      component: (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <NumberInput
                name="ritmoCada"
                label={t('ritmo')}
                placeholder={t('cada')}
                fixedValue={t('dias')}
                value={ginecoObstetrico.menstruacionHabitual.ritmoCada}
                setValue={handleInputChange}
                disabled={hayCambios}
              />
              <span className="px-2 pt-14">X</span>
              <NumberInput
                name="ritmoDuracion"
                label={t('duracion')}
                fixedValue={t('dias')}
                value={ginecoObstetrico.menstruacionHabitual.ritmoDuracion}
                setValue={handleInputChange}
                disabled={hayCambios}
              />
            </div>
          </div>
          <NumberInput
            name="cantidad"
            fixedValue={t('aposito-dia')}
            value={ginecoObstetrico.menstruacionHabitual.cantidad}
            setValue={handleInputChange}
            disabled={hayCambios}
          />
        </div>
      ),
    },
    {
      show: true,
      component: (
        <div className={`flex flex-col ${ocultarCampos ? 'md:w-1/2' : ''}`}>
          <ScaleInput
            name="dolor"
            label={t('dolor(intensidad)')}
            value={ginecoObstetrico.menstruacionHabitual.dolor}
            options={[...Array(esPaciente ? 5 : 10)].map((x, i) => ({ value: String(i + 1) }))}
            scaleLabels={{
              start: t('menstruacion_leve').toUpperCase(),
              middle: t('menstruacion_moderada').toUpperCase(),
              end: t('menstruacion_intensa').toUpperCase(),
            }}
            setValue={handleInputChange}
          />
          <SatisfactionIndicator />
        </div>
      ),
    },
  ].filter((component) => component.show);

  return (
    <>
      <h2 className="font-normal text-blue-800 mb-0">{t('menstruacion_habitual')}</h2>
      <div className={`grid grid-cols-1 md:grid-cols-${components.length} mt-2 gap-x-6`}>
        {components.map((component) => (
          <>{component.component}</>
        ))}
      </div>
      {!esPaciente && (
        <TextArea
          name="comentarios"
          value={ginecoObstetrico.menstruacionHabitual.comentarios}
          setValue={handleInputChange}
          maxLength={500}
          disabled={hayCambios}
        />
      )}
    </>
  );
}

export default MenstruacionHabitual;
