import React, { useState, useEffect, useRef } from 'react';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PrintIcon from '@material-ui/icons/Print';
// import EmailIcon from '@material-ui/icons/Email';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Input,
  ButtonGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormControl,
  FormGroup,
  Select,
  Chip,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { sendPlan } from '@utils/sendInfo';
import TxtEnriquecido from '@components/Comentarios/index';
import { ICatalogosEstandar, ICatStrEstandar } from '@common/types';
import { getCatCie9 } from '@utils/getCatalogos';
import DeleteDialog from '@components/DeleteDialog';
import { setSnackComplete } from '@actions/snackbar/types';
import loading from '@actions/loading/actions';
import { solicitudProcedimientoEliminarSolicitud } from '@utils/deleteInfo';
import {
  setEnGestacion,
  setSolProcDiagnosticos,
  setSolProcQuirurgicos,
  setSolProcTerapeuticos,
} from '@actions/paciente/actions';
import { RootState } from 'src/store';
import useStyles from './styles';
import { IProcedimientoSolicitado, IProcedimientosProps, ISolicitudProcedimientos } from './types';
import ProcedimientosComponente from './Procedimientos/index';
import PrintStyle from './PrintHtml/index';
import RegistrarProcedimiento from '../../../Antecedentes/PersonalesPatologicos/Procedimiento/index';

const Procedimientos = (props: IProcedimientosProps) => {
  const { tipo, solProcedimientos, procedimientos } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const procedimientosRef = useRef(null);
  const { idPaciente, sexo: sexoPaci } = useSelector((state: RootState) => state.BasicosPaciente);
  const { secciones, idMedico } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    antPatologicos: { enfReferidas, sinProcQuirurgicos },
    antNoPatologicos: {
      ginecoObstetrico: {
        generalidades: { enGestacion },
      },
    },
    exploracionFisica,
  } = useSelector((state: RootState) => state.Paciente.expediente);
  const [enGestacionTemp, setEnGestacionTemp] = useState<'' | 'si' | 'no'>(enGestacion);
  const solicitudProcedimientoInitial = {
    idSolicitud: -1,
    diagnosticoArray: [],
    procedimientosArray: [
      {
        idBd: -1,
        selector: '',
        cie9: { id: '', label: '' },
        txtLibre: '',
        actualizado: false,
      },
    ],
    datosClinicos: { entityMap: {}, blocks: [] },
    checkAll: { check1: false, check2: false, check3: false },
    txtResumen: '',
  };
  const [datosSolProcedimientos, setDatosSolProcedimientos] = useState<ISolicitudProcedimientos[]>([
    solicitudProcedimientoInitial,
  ]);
  const [label, setLabel] = useState<string>('');
  const [labelPdf, setLabelPdf] = useState<string>('');
  const [ejemplo, setEjemplo] = useState<string>('');
  const [arrayDiagnostico, setArrayDiagnostico] = useState<ICatalogosEstandar[]>([]);
  const [selProc, setSelProc] = useState<number>(0);
  const [radioProcedimiento, setRadioProcedimiento] = useState<string>('solicitar');
  const [catCie9, setCatCie9] = useState<Array<ICatStrEstandar>>([]);
  const [delSel, setDelSel] = useState<number>(0);
  const [showSolicitar, setShowSolicitar] = useState<boolean>(true);
  const [showRegistrar, setShowRegistrar] = useState<boolean>(true);
  const [saveClick, setSaveClick] = useState<boolean>(solProcedimientos.length === 0);
  // cierre del modal
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const getTipoProcedimiento = () => {
    switch (tipo) {
      case 'quirurgico':
        return 1;
      case 'diagnostico':
        return 2;
      case 'terapeutico':
        return 3;
      default:
        return 0;
    }
  };
  const setSolProcedimientos = (procs: ISolicitudProcedimientos[]) => {
    switch (tipo) {
      case 'quirurgico':
        dispatch(setSolProcQuirurgicos(procs));
        break;
      case 'diagnostico':
        dispatch(setSolProcDiagnosticos(procs));
        break;
      case 'terapeutico':
        dispatch(setSolProcTerapeuticos(procs));
        break;
      default:
        break;
    }
  };
  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };
  /** Handlers para enviar la informacion */
  const enviarInformacion = () => {
    const solVacia = datosSolProcedimientos.some((proC) =>
      proC.procedimientosArray.some((procA) => {
        if (procA.selector === 'cie9') {
          return !procA.cie9.id.length;
        }
        if (procA.selector === 'txtLibre') {
          return !procA.txtLibre.length;
        }
        return procA.selector.length === 0;
      }),
    );
    if (solVacia) {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('error-msg-proc-solicitudes-vacias'),
        }),
      );
      return;
    }
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    if (
      datosSolProcedimientos &&
      datosSolProcedimientos &&
      datosSolProcedimientos[0] &&
      Object.prototype.hasOwnProperty.call(datosSolProcedimientos[0], 'checkAll')
    ) {
      dispatch(loading(true));
      const dataToSend = {
        ID_CONSULTA: idConsulta,
        ID_PACIENTE: idPaciente,
        TIPO_PROCEDIMIENTO: getTipoProcedimiento(),
        enGestacion: enGestacionTemp,
        data: datosSolProcedimientos,
        idMedico,
        idConsultorio,
      };
      sendPlan(dataToSend)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setSolProcedimientos(datosSolProcedimientos);
            dispatch(setEnGestacion(enGestacionTemp));
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            // setActualizar((preV) => !preV);
            setSaveClick(false);
          } else {
            let mensaje = '';
            if (/UNIQUE KEY/i.test(result.msg)) {
              mensaje = t('campo_repetido');
            } else {
              mensaje = result.msg;
            }
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error')} ${mensaje}`,
              }),
            );
          }
          dispatch(loading(false));
        })
        .catch((error) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${error.toString()}`,
            }),
          );
          dispatch(loading(false));
        });
    }
  };
  const handleAgregarSolicitud = () => {
    if (datosSolProcedimientos.length < 5) {
      const valArray = [...datosSolProcedimientos];
      valArray.push(solicitudProcedimientoInitial);
      setSelProc(selProc + 1);
      setDatosSolProcedimientos(valArray);
      setSaveClick(true);
    }
  };

  const handleBorrarSolicitud = (inx: number) => {
    // obtenemos el id de la solicitud
    const idBD = solProcedimientos[inx].idSolicitud;
    // mostramos un loading
    dispatch(loading(true));
    // mostramos la galleta de que se esta mando a guardar lo que se pidio
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    // verificar que el id sea positivo
    if (idBD > 0) {
      // si es positivo procedemos a mandar un delete con ese id
      const objEnviar = {
        idMedico,
        idPaciente,
        idConsultorio,
        idConsulta,
        idSolicitud: idBD,
      };
      solicitudProcedimientoEliminarSolicitud(objEnviar)
        .then((response) => response.json())
        .then((result) => {
          // si todo es correcto procedemos a eliminar esa solicitud del array de solProcedimientos
          if (result.code === 200) {
            // vemos si existe mas de una solicitud previamente
            if (solProcedimientos.length > 1) {
              // eliminamos ese id del array data
              setSolProcedimientos(
                solProcedimientos.filter(
                  (sol: ISolicitudProcedimientos) => sol.idSolicitud !== idBD,
                ),
              );
              // cambiamos la seleccion del arryData a la previa;
              if (selProc === inx) {
                setSelProc(inx - 1);
              }
            } else {
              // si solo hay una solicitud reseteamos
              setSolProcedimientos([solicitudProcedimientoInitial]);
            }
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'success',
                mensaje: t('message-success'),
              }),
            );
            setSaveClick(false);
          }
          // dejamos de mostrar el loading
          dispatch(loading(false));
        })
        .catch((err) => {
          dispatch(loading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        });
      return;
    }
    // // si es negativo o 0 el idBd entonces solo lo eliminamos de la solicitud ya que significa que no esta guardado
    // if (solProcedimientos.length > 1) {
    //   // vemos que existan mas de una solProcedimientos para proceder a borrarla del array
    //   setDatosSolProcedimientos(
    //     solProcedimientos.filter((_val: ISolicitudProcedimientos, indx: number) => indx !== inx),
    //   );
    //   // si la seleccion es la misma que se esta borrando seleccionar la anterior
    //   if (selProc === inx) {
    //     setSelProc(delSel - 1);
    //   }
    // } else {
    //   // si solo hay una solicitud reseteamos
    //   setDatosSolProcedimientos([solicitudProcedimientoInitial]);
    // }
    // dispatch(loading(false));
  };
  const handleChangeChip = (event: React.ChangeEvent<{ value: unknown }>) => {
    const valArray = [...datosSolProcedimientos];
    valArray[selProc].diagnosticoArray = event.target.value as string[];
    setDatosSolProcedimientos(valArray);
    setSaveClick(true);
  };
  const deleteChip = (id: number) => {
    const valArray = [...datosSolProcedimientos];
    valArray[selProc].diagnosticoArray.splice(id, 1);
    setDatosSolProcedimientos(valArray);
  };
  const handleAgregarProSol = () => {
    const valArray = [...datosSolProcedimientos];
    const proArray = valArray[selProc].procedimientosArray;
    proArray.push({
      idBd: -1,
      selector: 'cie9',
      cie9: { id: '', label: '' },
      txtLibre: '',
      actualizado: false,
    });
    valArray[selProc].procedimientosArray = proArray;
    setDatosSolProcedimientos(valArray);
    setSaveClick(true);
  };
  const handleAgregarComentario = (contentState: RawDraftContentState) => {
    const valArray = [...datosSolProcedimientos];
    valArray[selProc].datosClinicos = contentState;
    setDatosSolProcedimientos(valArray);
    setSaveClick(true);
  };
  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valArray = [...datosSolProcedimientos];
    valArray[selProc].checkAll = {
      ...valArray[selProc].checkAll,
      [event.target.name]: event.target.checked,
    };
    setDatosSolProcedimientos(valArray);
    setSaveClick(true);
  };
  const deleteProcedimiento = () => {
    handleBorrarSolicitud(delSel);
  };

  useEffect(() => {
    if (tipo === 'diagnostico') {
      if (secciones.find((valor) => valor.idSeccion === 52)) {
        setShowSolicitar(true);
      } else {
        setShowSolicitar(false);
      }
      if (secciones.find((valor) => valor.idSeccion === 53)) {
        setShowRegistrar(true);
      } else {
        setShowRegistrar(false);
      }
      setLabel(t('procedimientos-diagnosticos'));
      setEjemplo(`${t('d-p-plan-pro-ejem-diag')}`);
      setLabelPdf(t('d-p-plan-pro-sol-pd'));
    } else if (tipo === 'terapeutico') {
      if (secciones.find((valor) => valor.idSeccion === 54)) {
        setShowSolicitar(true);
      } else {
        setShowSolicitar(false);
      }
      if (secciones.find((valor) => valor.idSeccion === 55)) {
        setShowRegistrar(true);
      } else {
        setShowRegistrar(false);
      }
      setLabel(t('procedimientos_terapeuticos'));
      setEjemplo(`${t('d-p-plan-pro-ejem-tera')}`);
      setLabelPdf(t('d-p-plan-pro-sol-pt'));
    } else if (tipo === 'quirurgico') {
      if (secciones.find((valor) => valor.idSeccion === 56)) {
        setShowRegistrar(true);
      } else {
        setShowRegistrar(false);
      }
      setLabel(t('procedimientos_quirurgicos'));
      setEjemplo('');
    }
    getCatCie9(getTipoProcedimiento().toString(), sexoPaci).then((result: ICatStrEstandar[]) => {
      setCatCie9(result);
    });
  }, []);

  useEffect(() => {
    if (enfReferidas.length > 0) {
      const arrSave: ICatalogosEstandar[] = [];
      enfReferidas.forEach((e) => {
        // CAMBIAR EL ID DE LA CONSULTA AQUI CUANDO ESTE LISTA
        // OJO
        if (e.estatusDelDiagnostico === 'activo') {
          if (e.seleccionDiagnostico === 'cie10') {
            arrSave.push({ id: e.idBD, label: `${e.diagnosticoCie10.label}` });
          } else {
            arrSave.push({ id: e.idBD, label: `${e.diagnosticoMedico}` });
          }
        }
      });
      setArrayDiagnostico(arrSave);
    }
  }, [enfReferidas]);

  useEffect(() => {
    let txt: string = '';
    if (
      datosSolProcedimientos[selProc].checkAll.check1 &&
      exploracionFisica.temperatura &&
      exploracionFisica.temperatura.length > 0
    ) {
      txt += `T: ${exploracionFisica.temperatura}°C | `;
    }
    if (
      datosSolProcedimientos[selProc].checkAll.check1 &&
      exploracionFisica.fCardiaca &&
      exploracionFisica.fCardiaca.length > 0
    ) {
      txt += `FC: ${exploracionFisica.fCardiaca} Latidos/min | `;
    }
    if (
      datosSolProcedimientos[selProc].checkAll.check1 &&
      exploracionFisica.diastolica &&
      exploracionFisica.diastolica.length > 0 &&
      exploracionFisica.sistolica &&
      exploracionFisica.sistolica.length > 0
    ) {
      txt += `PA: ${parseInt(exploracionFisica.diastolica, 10)}/${parseInt(
        exploracionFisica.sistolica,
        10,
      )} mmHg | `;
    }
    if (
      datosSolProcedimientos[selProc].checkAll.check1 &&
      exploracionFisica.fRespiratoria &&
      exploracionFisica.fRespiratoria.length > 0
    ) {
      txt += `FR: ${exploracionFisica.fRespiratoria} respiraciones/min | `;
    }
    if (
      datosSolProcedimientos[selProc].checkAll.check1 &&
      exploracionFisica.satOxigeno &&
      exploracionFisica.satOxigeno.length > 0
    ) {
      txt += `Spo2: ${exploracionFisica.satOxigeno} % | `;
    }
    if (
      datosSolProcedimientos[selProc].checkAll.check2 &&
      exploracionFisica.peso &&
      exploracionFisica.peso.length > 0
    ) {
      txt += `PESO: ${exploracionFisica.peso} kg | `;
    }
    if (
      datosSolProcedimientos[selProc].checkAll.check3 &&
      exploracionFisica.altura &&
      exploracionFisica.altura.length > 0
    ) {
      txt += `ALTURA: ${exploracionFisica.altura} cm | `;
    }
    if (
      datosSolProcedimientos[selProc].checkAll.check2 &&
      datosSolProcedimientos[selProc].checkAll.check3 &&
      exploracionFisica.imc &&
      exploracionFisica.imc.length > 0
    ) {
      txt += `IMC: ${exploracionFisica.imc} Kg/m2 | `;
    }
    if (enGestacionTemp) {
      txt += 'PACIENTE EN GESTACIÓN';
    }
    if (txt.length !== datosSolProcedimientos[selProc].txtResumen.length) {
      const valArray = [...datosSolProcedimientos];
      valArray[selProc].txtResumen = txt;
      setDatosSolProcedimientos(valArray);
    }
  }, [enGestacionTemp, selProc, datosSolProcedimientos[selProc].checkAll]);

  useEffect(() => {
    if (solProcedimientos.length > 0) {
      setDatosSolProcedimientos(solProcedimientos);
    }
    setSaveClick(solProcedimientos.length === 0);
  }, [solProcedimientos]);

  const antesP = (boole: boolean) => {
    dispatch(loading(boole));
  };
  return (
    <div>
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('d-p-plan-pro-del-sol')}
        callBackAceptar={() => deleteProcedimiento()}
        callBackClose={handleAlertClose}
      />
      <div className="flex py-3 items-center">
        <h2 className="text-blue-800 font-normal m-0 pr-4">{label}</h2>
        <h3 className="font-normal m-0 text-gray-600">{ejemplo}</h3>
      </div>
      {tipo === 'quirurgico' && sinProcQuirurgicos ? (
        <h3 className="font-normal m-0 text-gray-600">{t('antecedentes-pp-proc-sin-ant')}</h3>
      ) : (
        <>
          <div className="pb-3">
            <RadioGroup
              aria-label="realiza"
              name="realiza"
              value={radioProcedimiento}
              onChange={(e) => setRadioProcedimiento((e.target as HTMLInputElement).value)}
              row
            >
              {showSolicitar && ejemplo && (
                <FormControlLabel
                  value="solicitar"
                  control={<Radio color="primary" />}
                  label={t('d-p-plan-pro-solicitar')}
                />
              )}
              {showRegistrar && (
                <FormControlLabel
                  value="registrar"
                  control={<Radio color="primary" />}
                  label={t('d-p-plan-pro-registrar')}
                />
              )}
            </RadioGroup>
          </div>
          {showSolicitar && radioProcedimiento === 'solicitar' && ejemplo ? (
            <>
              <div className="border-solid border-0 border-b border-gray-600 flex items-center">
                <ButtonGroup
                  size="large"
                  color="primary"
                  aria-label="large outlined primary button group"
                >
                  {datosSolProcedimientos.map((val: ISolicitudProcedimientos, inx: number) => {
                    const id = inx + 1;
                    const lab = `${t('d-p-plan-pro-solicitud')} ${id}`;
                    return (
                      <Button
                        // eslint-disable-next-line react/no-array-index-key
                        key={inx}
                        className={selProc === inx ? classes.selected : ''}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => setSelProc(inx)}
                        endIcon={
                          inx !== 0 ? (
                            <CloseIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setDelSel(inx);
                                setDeleteAlert(true);
                              }}
                            />
                          ) : null
                        }
                      >
                        {lab}
                      </Button>
                    );
                  })}
                </ButtonGroup>
                {datosSolProcedimientos.length < 5 ? (
                  <div className="pl-2">
                    <Button
                      color="primary"
                      size="small"
                      onClick={handleAgregarSolicitud}
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      {t('d-p-plan-pro-add-sol')}
                    </Button>
                  </div>
                ) : null}
              </div>
              <div className="border-solid border-0 border-b border-gray-600 py-3">
                {sexoPaci === 'M' ? (
                  <div className="flex items-center">
                    <h3 className="font-normal m-0 text-gray-600 pr-4">{t('en_gestacion')}</h3>
                    <RadioGroup
                      row
                      name="gestacion"
                      value={enGestacionTemp}
                      onChange={(e) => setEnGestacionTemp(e.target.value as 'si' | 'no')}
                    >
                      <FormControlLabel
                        value="si"
                        control={<Radio color="primary" />}
                        label={t('si')}
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label={t('no')}
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </div>
                ) : null}
                <h3 className="font-normal m-0 text-gray-600 pb-3">
                  {t('d-p-plan-pro-add-dia-sol')}
                </h3>
                <div className="flex flex-wrap items-end justify-end">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="label">{t('selecciona')}</InputLabel>
                    <Select
                      labelId="label-lista"
                      label={t('selecciona')}
                      multiple
                      value={
                        datosSolProcedimientos &&
                        datosSolProcedimientos[selProc] &&
                        datosSolProcedimientos[selProc].diagnosticoArray
                          ? datosSolProcedimientos[selProc].diagnosticoArray
                          : []
                      }
                      onChange={handleChangeChip}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div>
                          {(selected as string[]).map((value, inx) => (
                            <Chip
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              onDelete={() => deleteChip(inx)}
                              key={value}
                              label={arrayDiagnostico
                                .find((element) => element.id === parseInt(value, 10))
                                ?.label.toUpperCase()}
                              variant="outlined"
                              color="primary"
                            />
                          ))}
                        </div>
                      )}
                    >
                      {arrayDiagnostico.map((valor, iny) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <MenuItem key={String(iny)} value={valor.id}>
                          {valor.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="mt-2">
                    <Button
                      color="primary"
                      size="small"
                      style={{ color: 'rgb(75, 85, 99)', textTransform: 'none' }}
                      startIcon={<FormatListBulletedIcon />}
                      disabled
                    >
                      {t('podras_seleccionar_varias_opciones')}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="border-solid border-0 border-b border-gray-600 py-6">
                {datosSolProcedimientos &&
                  datosSolProcedimientos[selProc] &&
                  datosSolProcedimientos[selProc].procedimientosArray &&
                  datosSolProcedimientos[selProc].procedimientosArray.map(
                    (val: IProcedimientoSolicitado, inx: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`procedimientos-${inx}`}>
                        <ProcedimientosComponente
                          arrayData={datosSolProcedimientos}
                          setArrayData={setDatosSolProcedimientos}
                          setSaveClick={setSaveClick}
                          cie9={catCie9}
                          selArray={selProc}
                          index={inx}
                        />
                      </div>
                    ),
                  )}
                <div className="flex justify-end">
                  <Button
                    color="primary"
                    size="small"
                    onClick={handleAgregarProSol}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    {t('d-p-plan-pro-add-pro-sol')}
                  </Button>
                </div>
              </div>
              <div className="border-solid border-0 border-b border-gray-600 py-6">
                <h3 className="m-0 text-gray-600 pb-3">{t('d-p-plan-pro-var-opc')}</h3>
                <h3 className="font-normal m-0 text-gray-600 pb-1">{t('d-p-plan-pro-var-dis')}</h3>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={datosSolProcedimientos[selProc].checkAll.check1}
                        color="primary"
                        name="check1"
                        onChange={handleChangeCheck}
                        disabled={
                          !(
                            exploracionFisica.temperatura ||
                            exploracionFisica.fCardiaca ||
                            exploracionFisica.diastolica ||
                            exploracionFisica.sistolica ||
                            exploracionFisica.fRespiratoria ||
                            exploracionFisica.satOxigeno
                          )
                        }
                      />
                    }
                    label={t('antecedentes-ef-signos')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={datosSolProcedimientos[selProc].checkAll.check2}
                        color="primary"
                        name="check2"
                        onChange={handleChangeCheck}
                        disabled={!exploracionFisica.peso}
                      />
                    }
                    label={t('antecedentes-ef-peso')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={datosSolProcedimientos[selProc].checkAll.check3}
                        color="primary"
                        name="check3"
                        onChange={handleChangeCheck}
                        disabled={!exploracionFisica.altura}
                      />
                    }
                    label={t('antecedentes-ef-altura')}
                  />
                </FormGroup>
                <h3 className="m-0 text-gray-600 pb-3">{t('d-p-plan-pro-sol-datos')}</h3>
                {datosSolProcedimientos[selProc].txtResumen ? (
                  <div className="pb-3">
                    <div className="flex border-0 border-solid border-blue-500 rounded border-l-8 p-4">
                      <h4 className="font-normal text-gray-500 m-0">
                        {datosSolProcedimientos[selProc].txtResumen}
                      </h4>
                    </div>
                  </div>
                ) : null}
                <TxtEnriquecido
                  comentarios={datosSolProcedimientos[selProc].datosClinicos}
                  setComentarios={handleAgregarComentario}
                  rows={4}
                  maxLength={1000}
                  placeholder=""
                  lang="es"
                  disabled={false}
                  key={selProc}
                />
              </div>
              <div className="flex justify-end">
                {/* <Button color="primary" size="small" startIcon={<EmailIcon />}>
                    {t('d-p-plan-pro-enviar')}
                  </Button> */}
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      size="small"
                      startIcon={<PrintIcon />}
                      disabled={saveClick}
                    >
                      {t('d-p-plan-pro-imprimir')}
                    </Button>
                  )}
                  content={() => procedimientosRef.current}
                  onBeforeGetContent={() => antesP(true)}
                  onAfterPrint={() => antesP(false)}
                />
              </div>
              {datosSolProcedimientos && datosSolProcedimientos[selProc] ? (
                <div key={`print-${selProc}`} className="hidden">
                  <PrintStyle
                    {...datosSolProcedimientos[selProc]}
                    arrayDiagnostico={arrayDiagnostico}
                    labelPdf={labelPdf}
                    ref={procedimientosRef}
                  />
                </div>
              ) : null}
            </>
          ) : null}
          {showRegistrar && radioProcedimiento === 'registrar' ? (
            <div key="registrar-procedimiento">
              <RegistrarProcedimiento
                modulo="plan"
                activoFecha
                tipo={tipo}
                sinProcedimientos={tipo === 'quirurgico' ? sinProcQuirurgicos : false}
                procedimientos={procedimientos}
              />
            </div>
          ) : (
            <div className="text-right">
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="large"
                onClick={enviarInformacion}
                startIcon={<SaveIcon />}
              >
                {t('guardar')}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Procedimientos;
