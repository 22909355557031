import { FC, FormEvent, useEffect, useRef } from 'react';
import { SubmitButton } from '@components/FormElemntsLibrary/SubmitButton';
import { FormProgressSteps } from '@components/FormProgressSteps';
import { StepHeader } from '@components/StepHeader';
import { useBreakPoint } from '@hooks/useBreakpoint';
import ResumenCambios from '@components/ResumenCambios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { ModalFormProps } from './type';
import { CircleX, CircleCheckBig } from 'lucide-react';

// eslint-disable-next-line import/prefer-default-export
export const ModalForm: FC<ModalFormProps> = ({
  id,
  currentStep,
  totalSteps,
  title,
  children,
  submitButtonLabel,
  aceptarCambios,
  rechazarCambios,
  submitForm,
  hideButton,
  disableButton,
  hayCambios = false,
  cambios = [],
  camposObligatorios,
  noXMargin = false,
}) => {
  const { esPaciente, sexo } = useSelector((state: RootState) => state.Me);
  const { nuevo, ocultarCampos } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  const isWideScreen = useBreakPoint();
  const form = useRef<HTMLFormElement | null>(null);
  const disabledForms = ocultarCampos
    ? ['seguros-planes-form', 'antecedentes-pnp-higiene-form', 'antecedentes-pnp-actividad-form']
    : [];
  const forms = [
    'datos-personales-form',
    'datos-ubicacion-form',
    'discapacidades-form',
    'info-emergencia-form',
    'seguros-planes-form',
    'mi-salud-form',
    'antecedentes-pnp-vivienda-form',
    'antecedentes-pnp-higiene-form',
    'antecedentes-pnp-alimentacion-form',
    'antecedentes-pnp-actividad-form',
    'antecedentes-pnp-toxicomania-form',
    'antecedentes-pnp-gineco-form',
    'exploracion-fisica-form',
  ].filter(
    (form) =>
      !disabledForms.includes(form) && (sexo === 'M' || form !== 'antecedentes-pnp-gineco-form'),
  );

  const handleDynamicHeight = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  };

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await submitForm();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    // Animación de entrada
    setTimeout(() => form.current?.classList.remove('animate-popLeft'), 300);
  }, []);

  useEffect(() => {
    // Ajustar altura del viewport en móviles
    if (!isWideScreen) {
      handleDynamicHeight();
      window.addEventListener('resize', handleDynamicHeight);
    }
    return () => window.removeEventListener('resize', handleDynamicHeight);
  }, [isWideScreen]);

  const getInfoCampos = () =>
    esPaciente || camposObligatorios ? (
      <div
        className={`flex justify-end items-end gap-x-4 bg-gray-100 py-2 px-4 ${
          isWideScreen ? 'mt-4' : 'mb-4'
        } rounded-md`}
      >
        {esPaciente && (
          <>
            <div className="flex items-center gap-2 text-gray-700">
              <CircleX size={20} color="gray" />
              <span className="text-gray-500">{t('campos_faltantes')}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-700">
              <CircleCheckBig size={20} color="green" />
              <span className="text-green-700">{t('campos_completados')}</span>
            </div>
          </>
        )}
        {camposObligatorios && (
          <span className="text-red-500">
            *&nbsp;
            {t('campos_obligatorios')}
          </span>
        )}
      </div>
    ) : null;

  return (
    <form
      onSubmit={handleSubmitForm}
      ref={form}
      className={`md:container ${
        isWideScreen ? '' : ''
      } bg-white mx-auto shadow rounded-b-md border-solid border border-gray-100 border-t-0`}
    >
      <div
        className="flex-1 md:w-full md:normal md:h-fit py-4"
        style={{ WebkitOverflowScrolling: 'touch' }}
      >
        <div
          className="w-full h-[100%] md:h-auto flex flex-col justify-between"
          // style={{ minHeight }}
        >
          <div className="w-full min-h-fit relative">
            <div className="px-3 md:px-0">
              <h1
                className={`w-full font-normal md:text-center ${
                  totalSteps && currentStep ? '' : 'mb-4'
                } md:text-blue-800 md:mb-3`}
              >
                {title}
              </h1>
              {!esPaciente && hayCambios && <ResumenCambios cambios={cambios} />}
            </div>
            {esPaciente && nuevo && (
              <StepHeader currentStep={forms.indexOf(id) + 1} totalSteps={forms.length} />
            )}
            <div className={noXMargin ? '' : 'px-4'}>
              {!isWideScreen && getInfoCampos()}
              {children}
              {isWideScreen && getInfoCampos()}
            </div>
          </div>
        </div>
      </div>
      {!hideButton && (
        <SubmitButton
          label={submitButtonLabel}
          acceptCallback={aceptarCambios}
          rejectCallback={rechazarCambios}
          saveCallback={submitForm}
          disabled={disableButton}
          hayCambios={hayCambios}
        />
      )}
    </form>
  );
};
