import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextAreaProps } from './type';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export const TextArea: FC<TextAreaProps> = ({
  index,
  name,
  containerClass = '',
  placeHolder,
  isRequired = false,
  label: externalLabel,
  maxLength,
  helperText,
  disableOption,
  setValue,
  value: externalValue,
  disabled = false,
  errorMessage = '',
  onValidationChange,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [value, setInternalValue] = useState<string>(externalValue ?? '');
  const [isDisabled, setIsDisabled] = useState(false);

  const label = externalLabel === undefined ? t(name) : externalLabel;

  useEffect(() => {
    onValidationChange?.({
      name,
      hasError: errorMessage.length > 0 || (isRequired && !value.length),
      isMissing: isRequired && !value.length,
      index,
    });
  }, [isRequired, value, errorMessage]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    if (externalValue !== undefined) {
      setInternalValue(externalValue ?? '');
    }
  }, [externalValue]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    if (maxLength && newValue.length > maxLength) return;

    setInternalValue(newValue);

    setValue({
      name,
      value: newValue,
    });
  };

  const handleDisableChange = () => {
    const newDisabledState = !isDisabled;
    setIsDisabled(newDisabledState);

    const newValue = newDisabledState ? 'notiene' : '';
    setInternalValue(newValue);
    setValue({
      name,
      value: newValue,
    });
  };

  const renderDisableCheckbox = () => (
    <label className="flex items-center text-sm text-gray-600 whitespace-nowrap ml-4">
      <input
        type="checkbox"
        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
        checked={isDisabled}
        onChange={handleDisableChange}
      />
      {disableOption?.label}
    </label>
  );

  return (
    <div className={`w-full h-auto my-[10px] ${containerClass}`}>
      <div className="flex items-center justify-between mb-2">
        {label && (
          <label
            htmlFor={name}
            className={`block font-medium text-base ${isDisabled ? 'text-gray-500' : 'text-gray-700'}`}
          >
            {label} {isRequired && <span className="text-red-500">*</span>}
          </label>
        )}
        {disableOption?.position === 'right' && renderDisableCheckbox()}
      </div>

      <div className="flex items-start gap-4">
        <div className="flex-1">
          <textarea
            id={name}
            name={name}
            placeholder={placeHolder}
            required={isRequired}
            maxLength={maxLength}
            value={value}
            onChange={handleChange}
            disabled={isDisabled}
            className={`w-full min-h-[8rem] p-2 text-base rounded-md border transition
              ${
                isDisabled
                  ? 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed'
                  : 'border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500'
              }`}
          />
          {maxLength && (
            <div className="text-right text-sm text-gray-500 mt-1">
              {value.length}/{maxLength}
            </div>
          )}
        </div>
        {disableOption?.position === 'after' && renderDisableCheckbox()}
      </div>

      {helperText && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
    </div>
  );
};

export default TextArea;
