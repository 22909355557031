import { useTranslation } from 'react-i18next';
import { IAnimalesProps } from './types';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Button } from '@components/FormElemntsLibrary/Button';

function MascotasComponente({
  hayCambios,
  eliminarMascota,
  handleChange,
  catAnimales,
  index,
  idAnimal,
  cantidad,
  especifica,
}: IAnimalesProps) {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  const handleMascotaChange = (e: { name: string; value: any }) => handleChange({ ...e, index });

  return (
    <div key={`div-${index}`} className="border-solid border border-gray-400 rounded p-4 mb-3 flex">
      <div
        className={`grid grid-cols-1 md:grid-cols-${
          idAnimal && idAnimal.id && idAnimal.id === 28 ? '3' : '2'
        } gap-x-4 w-full`}
      >
        <div>
          <SelectInput
            name="idAnimal"
            label={t('antecedentes-pnp-vivienda-tipo')}
            variant="search"
            options={catAnimales}
            value={idAnimal}
            setValue={handleMascotaChange}
            sendObject
          />
        </div>
        {idAnimal && idAnimal.id && idAnimal.id === 28 ? (
          <TextInput
            name="especifica"
            value={especifica}
            setValue={handleMascotaChange}
            disabled={hayCambios}
          />
        ) : null}
        <div>
          <NumberInput
            name="cantidad"
            value={cantidad}
            setValue={handleMascotaChange}
            isString
            disabled={hayCambios}
          />
        </div>
      </div>
      <Button
        buttonClass="pt-8 self-center"
        onClick={() => eliminarMascota(index)}
        disable={hayCambios}
        width="0"
        icon="trash2"
        iconSize={28}
        iconColor="gray"
      />
    </div>
  );
}

export default MascotasComponente;
