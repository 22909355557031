import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputProps } from './type';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export const TextField: FC<TextInputProps> = ({
  name,
  containerClass = '',
  placeholder,
  label: externalLabel,
  floatingLabel,
  helperText,
  adornment,
  labelAligmet = 'top',
  value,
  disabled = false,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [label, setLabel] = useState('');
  const isFloating = value && value.length > 0;

  useEffect(() => {
    if (floatingLabel) {
      setLabel('');
    } else {
      setLabel(externalLabel === undefined ? t(name) : externalLabel);
    }
  }, [floatingLabel, externalLabel]);

  const getAdornment = (txt: string) => (
    <div className="content-center bg-white">
      <label
        htmlFor={name}
        className={`text-base ${adornment?.alert ? 'text-red-500' : 'text-gray-500'}`}
      >
        {txt}
      </label>
    </div>
  );

  return (
    <div className={`w-full h-fit my-[10px] ${containerClass}`}>
      {labelAligmet === 'top' && label && (
        <div className="flex items-center justify-between mb-2">
          <label htmlFor={name} className="block font-medium text-base text-gray-600">
            {label}
          </label>
        </div>
      )}

      <div className="flex items-center gap-4">
        <div className="flex flex-row relative border-b border-gray-400 border-dotted">
          {floatingLabel && (
            <label className="absolute left-3 text-gray-600 rounded-3xl px-1 -top-2 text-xs bg-[linear-gradient(to_top,rgba(255,255,255,1)_60%,rgba(255,255,255,0)_100%)]">
              {floatingLabel}
            </label>
          )}
          {adornment && adornment.position === 'start' && getAdornment(adornment.text)}
          <input
            type="text"
            id={name}
            name={name}
            placeholder={labelAligmet === 'border' && !isFloating ? '' : placeholder}
            value={value}
            readOnly
            className="w-full h-[48px] text-base px-3 pr-3 focus:outline-none bg-white"
            disabled={disabled}
          />
          {adornment && adornment.position === 'end' && getAdornment(adornment.text)}
          {labelAligmet === 'border' && label && (
            <label
              htmlFor={name}
              className={`absolute text-sm transition-all duration-200 ${
                isFloating
                  ? '-top-2.5 left-2 bg-white px-1 text-gray-500'
                  : 'top-[50%] -translate-y-[50%] left-3 text-gray-500'
              }`}
            >
              {label}
            </label>
          )}
        </div>
      </div>
      {helperText && <h5 className="mt-1 font-normal text-gray-500">{helperText}</h5>}
    </div>
  );
};

export default TextField;
