import { DialogOpenActionTypes, SET_DIALOG_OPEN } from '../actions/dialogOpen/types';

function Dialog(state = false, action: DialogOpenActionTypes): boolean {
  switch (action.type) {
    case SET_DIALOG_OPEN:
      return action.payload;
    default:
      return state;
  }
}

export default Dialog;
