import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import { FormSectionsProps } from './type';

function FormSections(props: FormSectionsProps) {
  const { groups, components, noBackground, noBorder } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  const titles: string[] = [];

  const getTitle = (title: string) => {
    const ttl = titles.includes(title) ? null : (
      <h2 className="text-blue-800 font-semibold pl-4 mb-2 pt-4 pb-2 border-b-[1px] border-[#E2E3E3]">
        {t(title)}
      </h2>
    );
    titles.push(title);
    return ttl;
  };

  return (
    <>
      {components
        .filter(
          (component) =>
            !component.hide &&
            (!groups ||
              groups
                .filter((group) => !group.hide)
                .map((group) => group.name)
                .includes(component.group)),
        )
        .map((component, index) => (
          <div
            className={`${noBorder ? '' : 'border-b-[1px] border-[#D1D5DB]'} ${
              index % 2 === 0 || noBackground ? '' : 'bg-[#F3F4F6]'
            } py-2`}
          >
            {component.group ? getTitle(component.group) : null}
            <div className={`px-4 pt-2 pb-0`}>
              {component.title && <h3 className="text-gray-600 font-medium">{component.title}</h3>}
              {component.component}
            </div>
          </div>
        ))}
    </>
  );
}

export default FormSections;
