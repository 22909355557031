import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import loading from '@actions/loading/actions';
import { getInfoCustomer } from '@utils/getCatalogos';
import { setSnackComplete } from '@actions/snackbar/types';
import { epochTimeToDiaMesAnioStr } from '@common/functions';
import LlamarPagoModal from '@components/ModalDialogs/LlamarPago';
import { RootState } from 'src/store';
import VisaLogo from '@assets/logo/visa.svg';
import MastercardLogo from '@assets/logo/mastercard.svg';
import AmericanLogo from '@assets/logo/american_express.svg';
import MedipraxiLogo from '@assets/logo/medipraxi.svg';
import { IInformacionDePagoProps } from './types';

function obtenerLogo(brand: string) {
  switch (brand) {
    case 'visa':
      return VisaLogo;
    case 'mastercard':
      return MastercardLogo;
    case 'american_express':
      return AmericanLogo;
    default:
      return MedipraxiLogo;
  }
}

function estatusConekta(estatus: string, t: any) {
  switch (estatus) {
    case 'in_trial':
      return t('estatus_trial');
    case 'active':
      return t('estatus_active');
    case 'past_due':
      return t('estatus_past');
    case 'canceled':
      return t('estatus_canceled');
    default:
      return t('estatus_verificando');
  }
}

function InformacionDePago({
  customer,
  estatusSubscripcion,
  setStatusSubscripcion,
}: IInformacionDePagoProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { id: email } = useSelector((state: RootState) => state.Auth);
  const [tipoPlan, setTipoPlan] = useState<string>('');
  const [proximaFecha, setProximaFecha] = useState<number>(0);
  const [tarjetaPredeterminada, setTarjetaPredeterminada] = useState<{
    brand: string;
    last4: string;
  }>({ brand: '', last4: '' });
  const [modalLlame, setModalLlame] = useState<boolean>(false);

  const handleClickPagarModulo = () => {
    dispatch(loading(true));
    window.location.assign(window.location.href.concat('/checkout'));
  };

  const aceptarModalLlamar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    setModalLlame(false);
  };

  useEffect(() => {
    if (customer.length) {
      dispatch(loading(true));
      // pedir informacion sobre el customer
      // obtenerla de conekta
      // necesito la subscripcion
      // necesito la tarjeta
      // necesito la fecha de pago
      // necesito el estatus del pago
      // subscription => status, subscription_start, billing cycle_end, plan
      // payment_sources => data[0], id, brand, last_4
      getInfoCustomer(customer)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            setTipoPlan(result.data.estatusSubscripcion.plan);
            setStatusSubscripcion(result.data.estatusDelPago.estatus);
            setProximaFecha(result.data.fechaDePago.proximaFecha || Date.now() / 1000);
            setTarjetaPredeterminada(result.data.tarjetaPredeterminada);
          } else if (result.code === 401) {
            setTipoPlan(result.data.estatusSubscripcion.plan);
            setStatusSubscripcion(result.data.estatusDelPago.estatus);
            setProximaFecha(result.data.fechaDePago.proximaFecha || Date.now() / 1000);
            setTarjetaPredeterminada(result.data.tarjetaPredeterminada);
            setModalLlame(true);
          } else {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error')} ${result.msg} ${result.detail}`,
              }),
            );
          }
          dispatch(loading(false));
        })
        .catch((err) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: err,
            }),
          );
          dispatch(loading(false));
        });
    }
  }, [customer]);
  return (
    <>
      <LlamarPagoModal
        open={modalLlame}
        callBackClose={aceptarModalLlamar}
        customer={customer}
        email={email}
      />
      <div className="py-4">
        <h2 className="text-blue-800 m-0 font-normal">{t('informacion_de_pago')}</h2>
        {customer.length ? (
          <div className="">
            <div className="flex justify-between items-center py-1 border-0 border-solid border-gray-400 border-b">
              <h3 className="text-gray-800 font-normal m-0">
                {t('tu_proxima_fecha_de_facturacion_es_el')}: &nbsp;
                <span className="font-medium">
                  {estatusSubscripcion === 'canceled'
                    ? 'No aplica'
                    : epochTimeToDiaMesAnioStr(proximaFecha)}
                </span>
              </h3>
            </div>
            <div className="flex justify-between items-center py-1 border-0 border-solid border-gray-400 border-b">
              <div className="flex items-end">
                <img
                  className="w-10 mr-4"
                  src={obtenerLogo(tarjetaPredeterminada.brand)}
                  alt="logo_tarjeta"
                />
                <h3 className="text-gray-800 font-medium m-0">
                  {tarjetaPredeterminada.last4.length
                    ? tarjetaPredeterminada.last4.padStart(
                        tarjetaPredeterminada.brand === 'american_express' ? 15 : 16,
                        '*',
                      )
                    : t('ninguno')}
                </h3>
              </div>
              {!!tarjetaPredeterminada.last4.length && (
                <div>
                  <Button
                    color="primary"
                    onClick={() => history.push(`${path}/ver-metodos-de-pago`)}
                  >
                    {t('ver_tus_metodos_de_pago')}
                  </Button>
                </div>
              )}
            </div>
            <div
              className={`flex justify-between items-center py-1 border-0 border-solid border-gray-400 border-b ${
                estatusSubscripcion !== 'active' ? 'py-1' : 'py-2'
              }`}
            >
              <h3 className="text-gray-800 font-normal m-0">
                {t('estatus_de_tu_subscripcion')}: &nbsp;
                <span className="font-medium">{estatusConekta(estatusSubscripcion, t)}</span>
              </h3>
              {estatusSubscripcion !== 'active' && (
                <div>
                  <Button color="primary" onClick={handleClickPagarModulo}>
                    {estatusSubscripcion === 'canceled'
                      ? t('reanuda_tu_suscripcion')
                      : t('ingresa_tus_datos_bancarios')}
                  </Button>
                </div>
              )}
            </div>
            <div className="flex justify-between items-center py-1 border-0 border-solid border-gray-400 border-b">
              <h3 className="text-gray-800 font-normal m-0">
                {t('tipo_de_subscripcion')}: &nbsp;
                <span className="font-medium">{t(tipoPlan)}</span>
              </h3>
              <div>
                <Button color="primary" onClick={() => history.push(`${path}/ver-suscripcion`)}>
                  {t('ver_suscripcion')}
                </Button>
              </div>
            </div>
            {estatusSubscripcion === 'active' && (
              <div className="flex justify-end">
                <div className="text-red-500">
                  <Button
                    color="inherit"
                    onClick={() => history.push(`${path}/cancelar-suscripcion`)}
                  >
                    {t('cancelar_suscripcion')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="pt-4 text-center w-full">
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={handleClickPagarModulo}
            >
              {t('btn_proceder_a_pago')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default InformacionDePago;
