import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { getRecomendacionReferencia } from '@utils/commonUrl';
import { getToken } from '@utils/commonStore';
import { RootState } from 'src/store';
import ModalRecomendacion from '../../ModalRecomendacion';
import { IDeteccionCancerCervicalProps } from './types';

dayjs.locale('es');

function DeteccionCancerCervical({
  edad,
  sendShowCervicoUterino,
  sendFecha,
}: IDeteccionCancerCervicalProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { secciones } = useSelector((state: RootState) => state.Me);
  const {
    showCervicoUterino,
    existsEstudios: { existsEstudios, fecha: fechaEstudios },
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [ref, setRef] = useState<any>({});

  const revRangoEdad = () => {
    if (edad > 65) {
      return t('det_tem_can_cervical_66');
    }
    if (edad >= 30) {
      return t('det_tem_can_cervical_30_65');
    }
    if (edad >= 21) {
      return t('det_tem_can_cervical_21_29');
    }
    return '';
  };

  const revRangoEdadTxt = () => {
    if (edad > 65) {
      return t('det_tem_can_cervical_citologia3');
    }
    if (edad >= 30) {
      return t('det_tem_can_cervical_citologia2');
    }
    if (edad >= 21) {
      return t('det_tem_can_cervical_citologia1');
    }
    return '';
  };

  const revDivShow = () => {
    if (sendShowCervicoUterino !== undefined) {
      return false;
    }
    if (!existsEstudios) {
      return true;
    }
    return false;
  };

  const revFecha = () => sendFecha || fechaEstudios;

  return (
    <div className="flex-1">
      <ModalRecomendacion
        open={openModal}
        callBackClose={() => {
          setOpenModal(false);
          setRef({});
        }}
        referencia={ref}
      />
      {(sendShowCervicoUterino || (showCervicoUterino && existsEstudios)) && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
          <div className="border-0 border-solid border-red-600 rounded border-l-8 p-4 pr-5 w-full">
            <h3 className="m-0 text-left text-blue-500 font-normal">{t('det_tem_can_cervical')}</h3>
            <div className="flex pt-2">
              <h4 className="font-normal m-0 text-gray-900 pr-1">
                {`${t('det_tem_can_analisis')}: `}
              </h4>
            </div>
            <div className="flex pt-2">
              <h4 className="font-normal m-0 text-gray-500 pr-4">
                {`${t('edad')}: ${revRangoEdad()}`}
              </h4>
              <h4 className="font-normal m-0 text-gray-500 pr-4">
                {`${t('sexo')}: ${t('mujer')}`}
              </h4>
              <h4 className="font-normal m-0 text-gray-500 pr-4">
                {`${t('det_tem_can_estatus')}: ${t('det_tem_can_cervical_status')}`}
              </h4>
              {(fechaEstudios || sendFecha) && (
                <h4 className="font-normal m-0 text-gray-500 pr-4">
                  {`${t('det_tem_can_cervical_fecha')}: ${dayjs(`${revFecha()}`, 'YYYY M D').format(
                    'DD-MMM-YYYY',
                  )}`}
                </h4>
              )}
            </div>
            <h4 className="font-normal m-0 text-gray-900 pt-2">
              {`${t('det_tem_can_cervical_usptf')}:`}
            </h4>
            <h4 className="font-normal m-0 text-gray-500 py-2">{revRangoEdadTxt()}</h4>
          </div>
          <div className="min-w-max self-end">
            <Button
              color="primary"
              onClick={() => {
                const token = getToken();
                fetch(getRecomendacionReferencia('7'), {
                  method: 'GET',
                  headers: { Authorization: `Bearer ${token}` },
                })
                  .then((response) => response.json())
                  .then((result) => {
                    if (result.code === 200 && result.datos && result.datos.length > 0) {
                      setRef(result.datos[0]);
                      setOpenModal(true);
                    }
                  })
                  .catch(() => {
                    setRef({});
                  });
              }}
            >
              {t('ver_recomendacion')}
            </Button>
          </div>
        </div>
      )}
      {revDivShow() && (
        <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
          <div className="border-0 border-solid border-red-600 rounded border-l-8 p-4 pr-5 w-full">
            <h3 className="m-0 text-left text-blue-500 font-normal">{t('det_tem_can_cervical')}</h3>
            <ul>
              <li className="text-red-500">
                <span className="text-gray-500">{t('det_tem_can_cervical_txt1')}</span>
                {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 28) && (
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push(
                        '/ece/antecedentes/personales-no-patologicos/gineco-obstetricos',
                      );
                    }}
                  >
                    {`${t('det_tem_can_cervical_txt2')}.`}
                  </Button>
                )}
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeteccionCancerCervical;
