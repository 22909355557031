import { RawDraftContentState } from 'draft-js';
import { IArchivosLista, ICatStrEstandar, ICatalogosEstandar, IFechaObj } from '@common/types';

interface IDocumentos {
  label: string;
}

export interface IProcedimiento {
  idPaciente: number;
  idConsulta: number;
  idProceso: number;
  idProcedimiento: number;
  enfermedad: ICatalogosEstandar;
  txtDiagnostico: string;
  cie9: ICatStrEstandar;
  txtMotivo: string;
  dia: string;
  mes: string;
  anio: string;
  comentarios: RawDraftContentState;
  documentos: IDocumentos[];
  archivos: IArchivosLista[];
}

export const procedimientoInitial: IProcedimiento = {
  idPaciente: 0,
  idConsulta: 0,
  idProceso: 0,
  idProcedimiento: 0,
  enfermedad: { id: 0, label: '' },
  txtDiagnostico: '',
  cie9: { id: '', label: '' },
  txtMotivo: '',
  dia: '',
  mes: '',
  anio: '',
  comentarios: { blocks: [], entityMap: {} },
  documentos: [],
  archivos: [],  
};

export interface IProcedimientoFormulario {
  radioSel: string;
  diagnostico: ICatalogosEstandar;
  diagEspecifica: string;
  motivo: ICatStrEstandar;
  motivoEspecifica: string;
  fecha: IFechaObj;
  comentarios: RawDraftContentState;
  clearComentario: number;
}

export const procedimientoFormularioInitial: IProcedimientoFormulario = {
  radioSel: 'cie',
  diagnostico: { id: 0, label: '' },
  diagEspecifica: '',
  motivo: { id: '', label: '' },
  motivoEspecifica: '',
  fecha: { dia: '', mes: '', anio: '' },
  comentarios: { blocks: [], entityMap: {} },
  clearComentario: Math.floor(Math.random() * 1000) + 1,
};

export interface IProcedimientoProps {
  modulo: string;
  tipo: 'quirurgico' | 'terapeutico' | 'diagnostico';
  activoFecha?: boolean;
  sinProcedimientos: boolean;
  procedimientos: IProcedimiento[];
}
