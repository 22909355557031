export const SET_SIZE = 'SET_SIZE';

export interface IScreenSize {
  width: number;
  height: number;
  size: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}

interface SetScreenSizeAction {
  type: typeof SET_SIZE;
  payload: IScreenSize;
}

export type ScreenSizeActionTypes = SetScreenSizeAction;
