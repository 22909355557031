import { Menu } from '@components/Menu';
import { NavigationSection } from '@components/NavigationSection';
import { NavigateButton } from '@components/NavigateButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setRutaPerfil } from '@actions/rutasExpediente/actions';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { RootState } from 'src/store';
/** Importacion de los componentes de las tabs */
import DatosDeUbicacion from './DatosDeUbicacion';
import DatosPersonales from './DatosPersonales';
import Discapacidades from './Discapacidades';
import InfoEmergencia from './InfoEmergencia';
import SegurosPlanes from './SegurosPlanes';
import { DatosPersonalesProps } from './types';

function PerfilPaciente(props: DatosPersonalesProps) {
  const { camposCompletados, menuRef, sectionsRef, toggleComponents } = props;
  const dispatch = useDispatch();
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { idUsuario, idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { rutaPerfil } = useSelector((state: RootState) => state.RutasExpediente);
  const { nuevo, ocultarCampos, cambios } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const isWideScreen = useBreakPoint();

  const buttons: {
    name: string;
    title?: string;
    position?: string;
    isActive: boolean;
    onClick: () => void;
    changes?: boolean;
    completed: boolean;
    hide?: boolean;
  }[] = [
    {
      name: 'datos_personales',
      position: 'first',
      isActive: rutaPerfil === 'datos-personales',
      onClick: () => {
        dispatch(setRutaPerfil('datos-personales'));
        toggleComponents('profileSections');
      },
      completed: camposCompletados.includes('datos-personales'),
      changes: cambios.perfil.datosPersonales.some((c: string) => c.length > 0),
    },
    {
      name: 'datos_de_ubicacion',
      isActive: rutaPerfil === 'datos-ubicacion',
      onClick: () => {
        dispatch(setRutaPerfil('datos-ubicacion'));
        toggleComponents('profileSections');
      },
      completed: camposCompletados.includes('datos-ubicacion'),
      changes: cambios.perfil.datosUbicacion.some((c: string) => c.length > 0),
    },
    {
      name: 'discapacidades',
      isActive: rutaPerfil === 'discapacidades',
      onClick: () => {
        dispatch(setRutaPerfil('discapacidades'));
        toggleComponents('profileSections');
      },
      completed: camposCompletados.includes('discapacidades'),
      changes: cambios.perfil.discapacidades.some((c: string) => c.length > 0),
    },
    {
      name: 'info_de_emergencia',
      position: ocultarCampos ? 'last' : '',
      isActive: rutaPerfil === 'info-emergencia',
      onClick: () => {
        dispatch(setRutaPerfil('info-emergencia'));
        toggleComponents('profileSections');
      },
      completed: camposCompletados.includes('info-emergencia'),
      changes: cambios.perfil.infoEmergencia.some((c: string) => c.length > 0),
    },
    {
      name: 'seguros_y_planes',
      position: 'last',
      isActive: rutaPerfil === 'seguros-planes',
      onClick: () => {
        dispatch(setRutaPerfil('seguros-planes'));
        toggleComponents('profileSections');
      },
      completed: camposCompletados.includes('seguros-planes'),
      changes: cambios.perfil.segurosPlanes.some((c: string) => c.length > 0),
      hide: ocultarCampos,
    },
  ];

  return (
    <>
      {!nuevo && (
        <div
          className={`pb-0 bg-white w-full ${
            isWideScreen ? '' : 'absolute'
          } mx-auto md:container rounded-t-md border-solid border border-gray-100 shadow`}
          ref={menuRef}
        >
          <Menu title={t('perfil')}>
            <NavigationSection key={'perfil'} title="">
              {buttons
                .filter((btn) => !btn.hide)
                .map((btn) => (
                  <NavigateButton
                    key={btn.name}
                    title={btn.title || t(btn.name)}
                    onClick={btn.onClick}
                    position={btn.position}
                    isActive={btn.isActive}
                    alert={!esPaciente && idUsuario > 0 && btn.changes}
                    asTabs
                    completed={btn.completed}
                  />
                ))}
            </NavigationSection>
          </Menu>
        </div>
      )}
      <div ref={sectionsRef}>
        {rutaPerfil === 'datos-personales' && <DatosPersonales />}
        {idPaciente > 0 && rutaPerfil === 'datos-ubicacion' && <DatosDeUbicacion />}
        {idPaciente > 0 && rutaPerfil === 'discapacidades' && <Discapacidades />}
        {idPaciente > 0 && rutaPerfil === 'info-emergencia' && <InfoEmergencia />}
        {idPaciente > 0 && rutaPerfil === 'seguros-planes' && <SegurosPlanes />}
      </div>
    </>
  );
}

export default PerfilPaciente;
