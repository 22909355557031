import {
  perfilPacienteDatosGenerales,
  perfilPacienteDatosUbicacion,
  perfilPacienteInfoEmergencia,
  perfilPacienteSegurosPlanes,
  perfilPacienteDatosFacturacion,
  perfilPacienteDiscapacidades,
  crearTokenUsuarioURL,
  validarTokenUsuarioURL,
  validarEmailUsuarioURL,
  guardarTipoEmpleadoURL,
  perfilUsuarioPacienteDatosGenerales,
  perfilUsuarioPacienteDatosUbicacion,
  perfilUsuarioPacienteInfoEmergencia,
  perfilUsuarioPacienteSegurosPlanes,
  perfilUsuarioPacienteDiscapacidades,
  perfilUsuarioDatosGenerales,
  perfilUsuarioDatosUbicacion,
  perfilUsuarioInfoEmergencia,
  perfilUsuarioSegurosPlanes,
  perfilUsuarioDiscapacidades,
  antecedentesNoPatologicos,
  antecedentesPatologicos,
  aparatosYSistemas,
  saveAgendaConfigurar,
  saveAgenda,
  saveProcedimientos,
  saveInmunizaciones,
  exploracionFisica,
  exploracionFisicaUsuarioPaciente,
  diagnosticoPlan,
  diagnosticPlanSaveLaboratory,
  saveLaboratory,
  pronosticoURL,
  deleteLaboratory,
  transfusionGuardar,
  SecureLoginURL,
  SecureSignUpURL,
  SecureAuthorizationURL,
  SecureLogoutURL,
  SecureUpdatePassURL,
  CheckSessionURL,
  FarmacologicoGuardarMedicamento,
  FarmacologicoGuardarReceta,
  perfilPacienteNotasURL,
  perfilMedicoDatosURL,
  perfilMedicoCarreraURL,
  perfilMedicoMaestriaURL,
  perfilMedicoEspecialidadURL,
  perfilMedicoDoctoradoURL,
  perfilMedicoConsultoriosURL,
  perfilMedicoSeleccionarConsultorioURL,
  enfermedadesListasEnfermedads,
  enfermedadesListaEnfermedad,
  datosCheckout,
  antecedentesFamiliaresGuardarFamiliar,
  antecedentesFamiliarAgregarEnfermedad,
  perfilMedicoExperienciaURL,
  consultasMedicasCrearConsulta,
  AlergiasURLAlergia,
  AlergiasURLSinAntecedentes,
  TransfusionesURLTransfusion,
  TransfusionesURLSinAntecedentes,
  TransfusionesURLUsuarioPacienteTransfusion,
  TransfusionesURLUsuarioPacienteSinAntecedentes,
  HospitalizacionesURLHospitalizacion,
  HospitalizacionesURLSinAntecedentes,
  builderURLBuilderVariable,
  builderURLFCValidacion,
  builderURLCompartirFormatoConsulta,
  MCPAURL,
  CrearTokenPacienteURL,
  enfermedadesEnfermedadReferidaURL,
  enfermedadesEnfermedadNegadaURL,
  enfermedadesGrupoEnfermedadesNegadasURL,
  enfermedadesDiagnosticosUsuarioURL,
  antecedentesNoPatologicosVivienda,
  antecedentesNoPatologicosGinecoObstetricos,
  antecedentesNoPatologicosHistoriaSexual,
  antecedentesNoPatologicosToxicomania,
  antecedentesNoPatologicosActividadFisica,
  antecedentesNoPatologicosAlimentacion,
  antecedentesNoPatologicosHigiene,
  antecedentesNoPatologicosUsuarioPacienteVivienda,
  antecedentesNoPatologicosUsuarioPacienteHigiene,
  antecedentesNoPatologicosUsuarioPacienteAlimentacion,
  antecedentesNoPatologicosUsuarioPacienteActividadFisica,
  antecedentesNoPatologicosUsuarioPacienteToxicomania,
  antecedentesNoPatologicosUsuarioPacienteGinecoObstetricos,
  antecedentesNoPatologicosUsuarioVivienda,
  antecedentesNoPatologicosUsuarioHigiene,
  antecedentesNoPatologicosUsuarioAlimentacion,
  antecedentesNoPatologicosUsuarioActividadFisica,
  antecedentesNoPatologicosUsuarioToxicomania,
  antecedentesNoPatologicosUsuarioGinecoObstetricos,
  guardarDictamenMedLab,
  guardarTipoExamenURL,
  guardarOcupacionURL,
  guardarRestriccionURL,
  guardarCausaNoAptitudURL,
  crearTokenNuevo,
  crearOrdenCompleta,
  crearOrdenSubscripcion,
  cancelarSubscripcion,
  reestablecerEmail,
  reestablecerToken,
  reestablecerPassword,
  antecedentesLaboralesSinAntecedentesURL,
  antecedentesLaboralesEmpresaURL,
  antecedentesLaboralesExposicionesNegadasURL,
  antecedentesLaboralesExposicionListaURL,
  antecedentesEliminarLaboralesExposicionListaURL,
  antecedentesAccidenteLaboralRadioURL,
  antecedentesAccidenteObjLaboralURL,
  antecedentesEliminarAccidenteLaboralURL,
  antecedentesEliminarEmpresaURL,
  incapacidadesGuardarURL,
  incapacidadesEliminarURL,
  perfilSuperAdministradorDatosURL,
  guardarSAMiOrganizacionOrganizacionURL,
  guardarSAMiOrganizacionOrganizacionesURL,
  guardarSAMiOrganizacionAdministradorURL,
  perfilAdministradorDatosURL,
  guardarMiOrganizacionOrganizacionURL,
  guardarMiOrganizacionAdministradorURL,
  guardarMiOrganizacionConsultoriosURL,
  enviaAdministradorBienvenidaURL,
  guardarMiOrganizacionRolesURL,
  guardarMiOrganizacionEquipoMedicoCsvURL,
  guardarMiOrganizacionPacientesCsvURL,
  guardarMiOrganizacionPermitirTokensPacientesURL,
  getAgendaURL,
} from './commonUrl';
import { getToken } from './commonStore';

function sendDatos(obj: any, url: string) {
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

function sendReestablecer(obj: any, url: string) {
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  });
}

function updateDatos(obj: any, url: string) {
  return fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function sendDatosPersonales(obj: any) {
  const url = perfilPacienteDatosGenerales();
  return sendDatos(obj, url);
}

export function sendAntecedentesLaboralesSinAntecedentes(obj: any) {
  const url = antecedentesLaboralesSinAntecedentesURL();
  return sendDatos(obj, url);
}

export function sendAntecedentesLaboralesEmpresa(obj: any) {
  const url = antecedentesLaboralesEmpresaURL();
  return sendDatos(obj, url);
}

export function sendExposicionesNegadas(obj: any) {
  const url = antecedentesLaboralesExposicionesNegadasURL();
  return sendDatos(obj, url);
}

export function sendAccidenteLaboralRadio(obj: any) {
  const url = antecedentesAccidenteLaboralRadioURL();
  return sendDatos(obj, url);
}

export function sendAntecedentesAccidenteObjLaboral(obj: any) {
  const url = antecedentesAccidenteObjLaboralURL();
  return sendDatos(obj, url);
}

export function sendAntecedentesEliminarAccidenteLaboral(obj: any) {
  const url = antecedentesEliminarAccidenteLaboralURL();
  return sendDatos(obj, url);
}

export function sendEnviaAdministradorBienvenida(
  emailAdministrador: string,
  emailSend: string,
  idOrganizacion: number,
) {
  const url = enviaAdministradorBienvenidaURL(emailAdministrador, emailSend, idOrganizacion);
  return sendDatos({}, url);
}

export function sendAntecedentesEliminarEmpresaURL(obj: any) {
  const url = antecedentesEliminarEmpresaURL();
  return sendDatos(obj, url);
}

export function sendAntecedentesLaboralesExposicionLista(obj: any) {
  const url = antecedentesLaboralesExposicionListaURL();
  return sendDatos(obj, url);
}

export function sendEliminarAntecedentesLaboralesExposicionLista(obj: any) {
  const url = antecedentesEliminarLaboralesExposicionListaURL();
  return sendDatos(obj, url);
}

export function sendIncapacidades(obj: any) {
  const url = incapacidadesGuardarURL();
  return sendDatos(obj, url);
}

export function sendEliminarIncapacidades(obj: any) {
  const url = incapacidadesEliminarURL();
  return sendDatos(obj, url);
}

export function updateDatosPersonales(obj: any) {
  const url = perfilPacienteDatosGenerales();
  return updateDatos(obj, url);
}

export function sendDatosUbicacion(obj: any) {
  const url = perfilPacienteDatosUbicacion();
  return sendDatos(obj, url);
}

export function updateDatosUbicacion(obj: any) {
  const url = perfilPacienteDatosUbicacion();
  return updateDatos(obj, url);
}

export function sendInfoEmergencia(obj: any) {
  const url = perfilPacienteInfoEmergencia();
  return sendDatos(obj, url);
}

export function updateInfoEmergencia(obj: any) {
  const url = perfilPacienteInfoEmergencia();
  return updateDatos(obj, url);
}

export function sendSegurosPlanes(obj: any) {
  const url = perfilPacienteSegurosPlanes();
  return sendDatos(obj, url);
}

export function updateSegurosPlanes(obj: any) {
  const url = perfilPacienteSegurosPlanes();
  return updateDatos(obj, url);
}

export function sendDatosFacturacion(obj: any) {
  const url = perfilPacienteDatosFacturacion();
  return sendDatos(obj, url);
}

export function sendDiscapacidades(obj: any) {
  const url = perfilPacienteDiscapacidades();
  return sendDatos(obj, url);
}

export function updateDiscapacidades(obj: any) {
  const url = perfilPacienteDiscapacidades();
  return updateDatos(obj, url);
}

export function sendNotasMedico(obj: any) {
  const url = perfilPacienteNotasURL();
  return sendDatos(obj, url);
}

export function updateNotasMedico(obj: any) {
  const url = perfilPacienteNotasURL();
  return updateDatos(obj, url);
}

export function sendTokenUsuario(obj: any) {
  const url = crearTokenUsuarioURL();
  return sendDatos(obj, url);
}

export function validarTokenUsuario(obj: any) {
  const url = validarTokenUsuarioURL();
  return sendDatos(obj, url);
}

export function validarEmailUsuario(obj: any) {
  const url = validarEmailUsuarioURL();
  return sendDatos(obj, url);
}

export function guardarTipoEmpleado(obj: any) {
  const url = guardarTipoEmpleadoURL();
  return sendDatos(obj, url);
}

export function updateDatosPersonalesPaciente(obj: any) {
  const url = perfilUsuarioPacienteDatosGenerales();
  return updateDatos(obj, url);
}

export function updateDatosPersonalesUsuario(obj: any) {
  const url = perfilUsuarioDatosGenerales();
  return updateDatos(obj, url);
}

export function updateDatosUbicacionPaciente(obj: any) {
  const url = perfilUsuarioPacienteDatosUbicacion();
  return updateDatos(obj, url);
}

export function updateDatosUbicacionUsuario(obj: any) {
  const url = perfilUsuarioDatosUbicacion();
  return updateDatos(obj, url);
}

export function updateInfoEmergenciaPaciente(obj: any) {
  const url = perfilUsuarioPacienteInfoEmergencia();
  return updateDatos(obj, url);
}

export function updateInfoEmergenciaUsuario(obj: any) {
  const url = perfilUsuarioInfoEmergencia();
  return updateDatos(obj, url);
}

export function updateSegurosPlanesPaciente(obj: any) {
  const url = perfilUsuarioPacienteSegurosPlanes();
  return updateDatos(obj, url);
}

export function updateSegurosPlanesUsuario(obj: any) {
  const url = perfilUsuarioSegurosPlanes();
  return updateDatos(obj, url);
}

export function updateDiscapacidadesPaciente(obj: any) {
  const url = perfilUsuarioPacienteDiscapacidades();
  return updateDatos(obj, url);
}

export function updateDiscapacidadesUsuario(obj: any) {
  const url = perfilUsuarioDiscapacidades();
  return updateDatos(obj, url);
}

export function sendAntecedentesNoPatologicos(obj: any) {
  const url = antecedentesNoPatologicos();
  return sendDatos(obj, url);
}

export function sendAntecedentesPatologicos(obj: any) {
  const url = antecedentesPatologicos();
  return sendDatos(obj, url);
}

export function sendProcedimientos(obj: any) {
  const url = saveProcedimientos();
  return sendDatos(obj, url);
}

export function sendAparatosYSistemas(obj: any) {
  const url = aparatosYSistemas;
  return sendDatos(obj, url);
}

export function sendExploracionFisica(obj: any) {
  const url = exploracionFisica;
  return sendDatos(obj, url);
}

export function sendExploracionFisicaPaciente(obj: any) {
  const url = exploracionFisicaUsuarioPaciente;
  return sendDatos(obj, url);
}

export function sendPronosticoGuardar(obj: any) {
  const url = pronosticoURL();
  return sendDatos(obj, url);
}

export function sendPlan(obj: any) {
  const url = diagnosticoPlan();
  return sendDatos(obj, url);
}

export function sendSolicitudLaboratorio(obj: any) {
  const url = diagnosticPlanSaveLaboratory();
  return sendDatos(obj, url);
}

export function sendLaboratory(obj: any) {
  const url = saveLaboratory();
  return sendDatos(obj, url);
}

export function sendAgendaConfigurar(obj: any) {
  const url = saveAgendaConfigurar();
  return sendDatos(obj, url);
}

export function sendAgenda(obj: any) {
  const url = saveAgenda();
  return sendDatos(obj, url);
}

export function sendTransfusionGuardar(obj: any) {
  const url = transfusionGuardar();
  return sendDatos(obj, url);
}

export function eliminarLaboratorio(obj: any) {
  const url = deleteLaboratory();
  return sendDatos(obj, url);
}

export function sendLogin(obj: any) {
  return fetch(SecureLoginURL(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(obj),
  });
}

export function sendSignUp(obj: any) {
  return fetch(SecureSignUpURL(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(obj),
  });
}

export function sendAuthorization(obj: any) {
  return fetch(SecureAuthorizationURL(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(obj),
  });
}

export function changePassword(obj: any) {
  return fetch(SecureUpdatePassURL(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function sendLoguot(obj: any) {
  return fetch(SecureLogoutURL(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function checkSession(obj: any) {
  return fetch(CheckSessionURL(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function farmacologicoGuardarMedicamento(obj: any) {
  const url = FarmacologicoGuardarMedicamento();
  return sendDatos(obj, url);
}

export function farmacologicoGuardarReceta(obj: any) {
  const url = FarmacologicoGuardarReceta();
  return sendDatos(obj, url);
}

export function sendEnfermedadesListasEnfermedades(obj: any) {
  return fetch(enfermedadesListasEnfermedads(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function sendEnfermedadesListaEnfermedade(obj: any) {
  return fetch(enfermedadesListaEnfermedad(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function sendDatosCkeckout(obj: any) {
  const url = datosCheckout();
  return sendDatos(obj, url);
}

export function sendPerfilMedico(obj: any) {
  const url = perfilMedicoDatosURL();
  return sendDatos(obj, url);
}

export function sendPerfilSuperAdministrador(obj: any) {
  const url = perfilSuperAdministradorDatosURL();
  return sendDatos(obj, url);
}

export function sendSAMiOrganizacionOrganizacion(obj: any) {
  const url = guardarSAMiOrganizacionOrganizacionURL();
  return sendDatos(obj, url);
}

export function sendSAMiOrganizacionOrganizaciones(obj: any) {
  const url = guardarSAMiOrganizacionOrganizacionesURL();
  return sendDatos(obj, url);
}

export function sendSAMiOrganizacionAdministrador(obj: any) {
  const url = guardarSAMiOrganizacionAdministradorURL();
  return sendDatos(obj, url);
}

export function sendPerfilAdministrador(obj: any) {
  const url = perfilAdministradorDatosURL();
  return sendDatos(obj, url);
}

export function sendMiOrganizacionAdministrador(obj: any) {
  const url = guardarMiOrganizacionAdministradorURL();
  return sendDatos(obj, url);
}

export function sendMiOrganizacionEquipoMedicoCsv(obj: any) {
  const url = guardarMiOrganizacionEquipoMedicoCsvURL();
  return sendDatos(obj, url);
}

export function sendMiOrganizacionPacientesCsv(obj: any) {
  const url = guardarMiOrganizacionPacientesCsvURL();
  return sendDatos(obj, url);
}

export function sendMiOrganizacionPermitirTokensPacientes(obj: any) {
  const url = guardarMiOrganizacionPermitirTokensPacientesURL();
  return sendDatos(obj, url);
}

export function sendTokenPaciente(obj: any) {
  const url = CrearTokenPacienteURL();
  return sendDatos(obj, url);
}

export function sendCarreraMedico(obj: any) {
  const url = perfilMedicoCarreraURL();
  return sendDatos(obj, url);
}

export function sendEspecialidadMedico(obj: any) {
  const url = perfilMedicoEspecialidadURL();
  return sendDatos(obj, url);
}

export function sendMaestriaMedico(obj: any) {
  const url = perfilMedicoMaestriaURL();
  return sendDatos(obj, url);
}

export function sendDoctoradoMedico(obj: any) {
  const url = perfilMedicoDoctoradoURL();
  return sendDatos(obj, url);
}

export function sendExperienciaMedico(obj: any) {
  const url = perfilMedicoExperienciaURL();
  return sendDatos(obj, url);
}

export function sendMiOrganizacionOrganizacion(obj: any) {
  const url = guardarMiOrganizacionOrganizacionURL();
  return sendDatos(obj, url);
}

export function sendMiOrganizacionConsultorios(obj: any) {
  const url = guardarMiOrganizacionConsultoriosURL();
  return sendDatos(obj, url);
}

export function sendMiOrganizacionRoles(obj: any) {
  const url = guardarMiOrganizacionRolesURL();
  return sendDatos(obj, url);
}

export function sendConsultoriosListaConsultorios(obj: any) {
  return fetch(perfilMedicoConsultoriosURL(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function sendConsultoriosSeleccionarConsultorio(obj: any) {
  return fetch(perfilMedicoSeleccionarConsultorioURL(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function sendFamiliaresGuardarEnfermedadFamiliar(obj: any) {
  return fetch(antecedentesFamiliaresGuardarFamiliar(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function sendFamiliaresAgregarEnfermedadFamiliar(obj: any) {
  return fetch(antecedentesFamiliarAgregarEnfermedad(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` },
    body: JSON.stringify(obj),
  });
}

export function crearConsultaMedica(idMedico: number, idPaciente: number, idConsultorio: number, idOrganizacion: number, tipo: 'consulta' | 'EMA' | 'EMP' = 'consulta', idProceso: number = -1) {
  const obj = {
    idMedico,
    idPaciente,
    idConsultorio,
    idOrganizacion,
    tipo,
    idProceso,
  };
  return sendDatos(obj, consultasMedicasCrearConsulta());
}

export function guardarInmunizaciones(obj: any) {
  return sendDatos(obj, saveInmunizaciones());
}

export function alergiasGuardarAlergia(obj: any) {
  return sendDatos(obj, AlergiasURLAlergia());
}

export function alergiasGuardarSinAntecedentes(obj: any) {
  return sendDatos(obj, AlergiasURLSinAntecedentes());
}

export function transfusionesGuardarTransfusion(obj: any) {
  return sendDatos(obj, TransfusionesURLTransfusion());
}

export function transfusionesGuardarSinAntecedentes(obj: any) {
  return sendDatos(obj, TransfusionesURLSinAntecedentes());
}

export function transfusionesUsuarioPacienteGuardarTransfusion(obj: any) {
  return sendDatos(obj, TransfusionesURLUsuarioPacienteTransfusion());
}

export function transfusionesUsuarioPacienteGuardarSinAntecedentes(obj: any) {
  return sendDatos(obj, TransfusionesURLUsuarioPacienteSinAntecedentes());
}

export function hospitalizacionesGuardarHospitalizacion(obj: any) {
  return sendDatos(obj, HospitalizacionesURLHospitalizacion());
}

export function hospitalizacionesGuardarSinAntecedentes(obj: any) {
  return sendDatos(obj, HospitalizacionesURLSinAntecedentes());
}

export function builderGuardarVariable(obj: any) {
  return sendDatos(obj, builderURLBuilderVariable());
}

export function builderFCGuardar(obj: any) {
  return sendDatos(obj, builderURLFCValidacion());
}

export function mcpaGuardarMCPA(obj: any) {
  return sendDatos(obj, MCPAURL());
}

export function compartirFormatoConsulta(obj: any) {
  return sendDatos(obj, builderURLCompartirFormatoConsulta());
}

export function enfermedadesGuardarReferida(obj: any) {
  return sendDatos(obj, enfermedadesEnfermedadReferidaURL());
}

export function enfermedadesGuardarNegada(obj: any) {
  return sendDatos(obj, enfermedadesEnfermedadNegadaURL());
}

export function enfermedadesGuardarGrupoEnfermedadesNegadas(obj: any) {
  return sendDatos(obj, enfermedadesGrupoEnfermedadesNegadasURL());
}

export function antecedentesNoPatologicosGuardarVivienda(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosVivienda());
}

export function antecedentesNoPatologicosGuardarHigiene(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosHigiene());
}

export function antecedentesNoPatologicosGuardarAlimentacion(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosAlimentacion());
}

export function antecedentesNoPatologicosGuardarActividadFisica(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosActividadFisica());
}

export function antecedentesNoPatologicosGuardarToxicomania(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosToxicomania());
}

export function antecedentesNoPatologicosGuardarGinecoObstetricos(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosGinecoObstetricos());
}

export function antecedentesNoPatologicosGuardarHistoriaSexual(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosHistoriaSexual());
}

export function antecedentesNoPatologicosUsuarioPacienteGuardarVivienda(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioPacienteVivienda());
}

export function antecedentesNoPatologicosUsuarioPacienteGuardarHigiene(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioPacienteHigiene());
}

export function antecedentesNoPatologicosUsuarioPacienteGuardarAlimentacion(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioPacienteAlimentacion());
}

export function antecedentesNoPatologicosUsuarioPacienteGuardarActividadFisica(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioPacienteActividadFisica());
}

export function antecedentesNoPatologicosUsuarioPacienteGuardarToxicomania(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioPacienteToxicomania());
}

export function antecedentesNoPatologicosUsuarioPacienteGuardarGinecoObstetricos(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioPacienteGinecoObstetricos());
}

export function antecedentesNoPatologicosUsuarioGuardarVivienda(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioVivienda());
}

export function antecedentesNoPatologicosUsuarioGuardarHigiene(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioHigiene());
}

export function antecedentesNoPatologicosUsuarioGuardarAlimentacion(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioAlimentacion());
}

export function antecedentesNoPatologicosUsuarioGuardarActividadFisica(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioActividadFisica());
}

export function antecedentesNoPatologicosUsuarioGuardarToxicomania(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioToxicomania());
}

export function antecedentesNoPatologicosUsuarioGuardarGinecoObstetricos(obj: any) {
  return sendDatos(obj, antecedentesNoPatologicosUsuarioGinecoObstetricos());
}

export function enfermedadesGuardarDiagnosticosUsuario(obj: any) {
  return sendDatos(obj, enfermedadesDiagnosticosUsuarioURL());
}

export function sendDictamenMedLab(obj: any) {
  return sendDatos(obj, guardarDictamenMedLab());
}

export function sendTipoExamen(obj: any) {
  return sendDatos(obj, guardarTipoExamenURL());
}

export function sendOcupacion(obj: any) {
  return sendDatos(obj, guardarOcupacionURL());
}

export function sendRestriccion(obj: any) {
  return sendDatos(obj, guardarRestriccionURL());
}

export function sendCausaNoAptitud(obj: any) {
  return sendDatos(obj, guardarCausaNoAptitudURL());
}

export function traerAgenda(idMedico: string, mes: string, anio: string, token: string) {
  return fetch(getAgendaURL(idMedico.toString(), mes, anio), {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function crearTokenConekta() {
  return sendDatos({}, crearTokenNuevo());
}

export function crearCustomerSubscriptionConekta(objDatos: any) {
  return sendDatos(objDatos, crearOrdenCompleta());
}

export function crearSubscripcionConekta(objDatos: any) {
  return sendDatos(objDatos, crearOrdenSubscripcion());
}

export function cancelarSubscripcionConekta(objDatos: any) {
  return sendDatos(objDatos, cancelarSubscripcion());
}

export function enviarEmailReestablecer(objDatos: any) {
  return sendReestablecer(objDatos, reestablecerEmail());
}

export function enviarTokenReestablecer(objDatos: any) {
  return sendReestablecer(objDatos, reestablecerToken());
}

export function enviarPasswordReestablecer(objDatos: any) {
  return sendReestablecer(objDatos, reestablecerPassword());
}
