import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDatosUbicacion, setDatosUbicacionUsuario } from '@actions/paciente/actions';
import { setRutaPerfil } from '@actions/rutasExpediente/actions';
import { useApiRequest } from '@hooks/useApiRequest';
import { ICatStrEstandar } from '@common/types';
import { validacionCp } from '@constants/validaciones';
import { RootState } from 'src/store';
import obtenerDatosGeograficos, { geoDataInitial } from '@utils/geografia';
import {
  getCatalogoAgea,
  getCatalogoAgee,
  getCatalogoAgel,
  getCatalogoAgem,
} from '@utils/getCatalogos';
import {
  updateDatosUbicacion,
  updateDatosUbicacionPaciente,
  updateDatosUbicacionUsuario,
} from '@utils/sendInfo';
import { IDatosUbicacion, IGeoData, datosUbicacionInitial } from './types';
import { ModalForm } from '@components/modals/ModalForm';
import { useBreakPoint } from '@hooks/useBreakpoint';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import Dialog from '@components/ModalDialogs/Dialog';
import FormSections from '@components/FormSections';
import { IFormSectionComponent, IFormSectionGroup } from '@components/FormSections/type';

/** Formulario de los datos de ubicacion del paciente */
function DatosDeUbicacion() {
  const dispatch = useDispatch();
  const { apiRequest, apiRequests } = useApiRequest();
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    pais,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    ocultarCampos,
    expediente: {
      perfil: { datosUbicacion },
    },
    usuario: {
      perfil: { datosUbicacion: datosUbicacionUsuario },
    },
    cambios: {
      perfil: { datosUbicacion: cambios },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [geo, setGeo] = useState<IGeoData>(geoDataInitial);
  const [gentilicio, setGentilicio] = useState<string>('');
  const [territorio, setTerritorio] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [originarioLoaded, setOriginarioLoaded] = useState<boolean>(false);
  const [residenteLoaded, setResidenteLoaded] = useState<boolean>(false);
  const [cpError, setCpError] = useState<boolean>(false);
  /** states del alert */
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  /** catalogos */
  const [catAgee, setCatAgee] = useState<ICatStrEstandar[]>([]);
  const [catAgemOriginario, setCatAgemOriginario] = useState<ICatStrEstandar[]>([]);
  const [catAgelOriginario, setCatAgelOriginario] = useState<ICatStrEstandar[]>([]);
  const [catAgemResidente, setCatAgemResidente] = useState<ICatStrEstandar[]>([]);
  const [catAgelResidente, setCatAgelResidente] = useState<ICatStrEstandar[]>([]);
  const [catAgeaResidente, setCatAgeaResidente] = useState<ICatStrEstandar[]>([]);
  const [datosUbicacionTemp, setDatosUbicacionTemp] =
    useState<IDatosUbicacion>(datosUbicacionInitial);

  const isWideScreen = useBreakPoint();
  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);

  /** logica */

  /** Handlers del componente */

  const handleInputChange = (e: { name: string; value: any }) =>
    setDatosUbicacionTemp((prevData) => ({ ...prevData, [e.name]: e.value }));

  /** handles de disabled components */
  /** Verificar validaciones */
  const verificarObligatorios = () => {
    if (
      ((datosUbicacionTemp.territorioOriginario === gentilicio &&
        (!geo.agee?.required || datosUbicacionTemp.ageeOriginario.length) &&
        (!geo.agem?.required || datosUbicacionTemp.agemOriginario.length) &&
        (!geo.agel?.required || datosUbicacionTemp.agelOriginario.length)) ||
        datosUbicacionTemp.territorioOriginario === 'extranjero' ||
        datosUbicacionTemp.territorioOriginario === 'desconocido') &&
      ((datosUbicacionTemp.territorioResidente === gentilicio &&
        (!geo.agee?.required || datosUbicacionTemp.ageeResidente.length) &&
        (!geo.agem?.required || datosUbicacionTemp.agemResidente.length) &&
        (!geo.agel?.required || datosUbicacionTemp.agelResidente.length) &&
        (!geo.agea?.required || datosUbicacionTemp.ageaResidente.length)) ||
        datosUbicacionTemp.territorioResidente === 'extranjero' ||
        datosUbicacionTemp.territorioResidente === 'desconocido')
    ) {
      return false;
    }
    return true;
  };

  /** hooks como useEffect */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCatalogoAgee(idMedico).then((result: ICatStrEstandar[]) => {
      setCatAgee(result);
    });
  }, [idMedico]);
  useEffect(() => {
    if (pais.length > 0) {
      setGeo(obtenerDatosGeograficos(pais));
      const gent = t(`gentilicio_${pais}`);
      setGentilicio(gent);
      setTerritorio([
        { value: gent, label: gent },
        { value: 'extranjero', label: t('extranjero') },
        { value: 'desconocido', label: t('desconocido') },
      ]);
    }
  }, [pais]);

  useEffect(() => {
    if (datosUbicacion.loaded && !datosUbicacionTemp.loaded && gentilicio.length > 0) {
      setDatosUbicacionTemp(
        esPaciente
          ? {
              ...datosUbicacion,
              territorioOriginario: datosUbicacion.territorioOriginario || gentilicio,
              territorioResidente: datosUbicacion.territorioResidente || gentilicio,
            }
          : datosUbicacion,
      );
      if (
        datosUbicacion.territorioOriginario === gentilicio &&
        datosUbicacion.ageeOriginario.length
      ) {
        getCatalogoAgem(datosUbicacion.ageeOriginario).then((catAgem: ICatStrEstandar[]) => {
          setCatAgemOriginario(catAgem);
          if (datosUbicacion.agemOriginario.length) {
            getCatalogoAgel(datosUbicacion.ageeOriginario, datosUbicacion.agemOriginario).then(
              (catAgel: ICatStrEstandar[]) => {
                setCatAgelOriginario(catAgel);
                setOriginarioLoaded(true);
              },
            );
          } else {
            setOriginarioLoaded(true);
          }
        });
      } else {
        setOriginarioLoaded(true);
      }
      if (
        datosUbicacion.territorioResidente === gentilicio &&
        datosUbicacion.ageeResidente.length
      ) {
        getCatalogoAgem(datosUbicacion.ageeResidente).then((catAgem: ICatStrEstandar[]) => {
          setCatAgemResidente(catAgem);
          if (datosUbicacion.agemResidente.length) {
            getCatalogoAgel(datosUbicacion.ageeResidente, datosUbicacion.agemResidente).then(
              (catAgel: ICatStrEstandar[]) => {
                setCatAgelResidente(catAgel);
                if (datosUbicacion.agelResidente.length) {
                  getCatalogoAgea(
                    datosUbicacion.ageeResidente,
                    datosUbicacion.agemResidente,
                    datosUbicacion.agelResidente,
                  ).then((catAgea: ICatStrEstandar[]) => {
                    setCatAgeaResidente(catAgea);
                    setResidenteLoaded(true);
                  });
                } else {
                  setResidenteLoaded(true);
                }
              },
            );
          } else {
            setResidenteLoaded(true);
          }
        });
      } else {
        setResidenteLoaded(true);
      }
    }
  }, [datosUbicacion.loaded, gentilicio, esPaciente]);

  useEffect(() => {
    if (
      datosUbicacionTemp.loaded &&
      String(datosUbicacionTemp.territorioOriginario) !== gentilicio
    ) {
      setDatosUbicacionTemp((prev) => ({ ...prev, ageeOriginario: '' }));
    }
  }, [datosUbicacionTemp.territorioOriginario]);
  useEffect(() => {
    if (datosUbicacionTemp.ageeOriginario.length) {
      getCatalogoAgem(datosUbicacionTemp.ageeOriginario).then((cat: ICatStrEstandar[]) =>
        setCatAgemOriginario(cat),
      );
    }
    if (datosUbicacionTemp.loaded && originarioLoaded) {
      setDatosUbicacionTemp((prev) => ({ ...prev, agemOriginario: '' }));
    }
  }, [datosUbicacionTemp.ageeOriginario]);
  useEffect(() => {
    if (datosUbicacionTemp.agemOriginario.length) {
      getCatalogoAgel(datosUbicacionTemp.ageeOriginario, datosUbicacionTemp.agemOriginario).then(
        (cat: ICatStrEstandar[]) => setCatAgelOriginario(cat),
      );
    }
    if (datosUbicacionTemp.loaded && originarioLoaded) {
      setDatosUbicacionTemp((prev) => ({ ...prev, agelOriginario: '' }));
    }
  }, [datosUbicacionTemp.agemOriginario]);
  useEffect(() => {
    if (
      datosUbicacionTemp.loaded &&
      String(datosUbicacionTemp.territorioResidente) !== gentilicio
    ) {
      setDatosUbicacionTemp((prev) => ({ ...prev, ageeResidente: '' }));
    }
  }, [datosUbicacionTemp.territorioResidente]);
  useEffect(() => {
    if (datosUbicacionTemp.ageeResidente.length) {
      getCatalogoAgem(datosUbicacionTemp.ageeResidente).then((cat: ICatStrEstandar[]) =>
        setCatAgemResidente(cat),
      );
    }
    if (datosUbicacionTemp.loaded && residenteLoaded) {
      setDatosUbicacionTemp((prev) => ({ ...prev, agemResidente: '' }));
    }
  }, [datosUbicacionTemp.ageeResidente]);
  useEffect(() => {
    if (datosUbicacionTemp.agemResidente.length) {
      getCatalogoAgel(datosUbicacionTemp.ageeResidente, datosUbicacionTemp.agemResidente).then(
        (cat: ICatStrEstandar[]) => setCatAgelResidente(cat),
      );
    }
    if (datosUbicacionTemp.loaded && residenteLoaded) {
      setDatosUbicacionTemp((prev) => ({ ...prev, agelResidente: '' }));
    }
  }, [datosUbicacionTemp.agemResidente]);
  useEffect(() => {
    if (datosUbicacionTemp.agelResidente.length) {
      getCatalogoAgea(
        datosUbicacionTemp.ageeResidente,
        datosUbicacionTemp.agemResidente,
        datosUbicacionTemp.agelResidente,
      ).then((cat: ICatStrEstandar[]) => setCatAgeaResidente(cat));
    }
    if (datosUbicacionTemp.loaded && residenteLoaded) {
      setDatosUbicacionTemp((prev) => ({ ...prev, ageaResidente: '' }));
    }
  }, [datosUbicacionTemp.agelResidente]);

  const getData = (datos: IDatosUbicacion, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsultorio,
    territorioOriginario: datos.territorioOriginario,
    ageeOriginario: datos.ageeOriginario ? datos.ageeOriginario : '',
    agemOriginario: datos.agemOriginario ? datos.agemOriginario : '',
    agelOriginario: datos.agelOriginario ? datos.agelOriginario : '',
    territorioResidente: datos.territorioResidente,
    ageeResidente: datos.ageeResidente ? datos.ageeResidente : '',
    agemResidente: datos.agemResidente ? datos.agemResidente : '',
    agelResidente: datos.agelResidente ? datos.agelResidente : '',
    ageaResidente: datos.ageaResidente ? datos.ageaResidente : '',
    calle: datos.calle,
    num1: datos.num1,
    num2: datos.num2,
    cp: datos.cp,
    referencias: datos.referencias,
    comentario: datos.comentario,
  });
  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const dataUbicacion = getData(datosUbicacionTemp, idUsuario);
    const sendFunctions = [updateDatosUbicacion(dataUbicacion)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateDatosUbicacionPaciente(dataUbicacion));
    }
    apiRequests({
      type: 'send',
      requestFunctions: sendFunctions,
      successFunctions: [
        () => dispatch(setDatosUbicacion({ ...datosUbicacionTemp, loaded: true })),
        () => dispatch(setDatosUbicacionUsuario({ ...datosUbicacionTemp, loaded: true })),
      ],
    });
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: updateDatosUbicacionUsuario(getData(datosUbicacionTemp, idMiUsuario)),
      successFunction: () => {
        dispatch(setDatosUbicacion({ ...datosUbicacionTemp, loaded: true }));
        dispatch(setRutaPerfil('discapacidades'));
      },
    });
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    apiRequest({
      type: 'send',
      requestFunction: updateDatosUbicacion(getData(datosUbicacionUsuario)),
      successFunction: () => {
        dispatch(setDatosUbicacion(datosUbicacionUsuario));
        setDatosUbicacionTemp(datosUbicacionUsuario);
      },
    });
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: updateDatosUbicacionPaciente(getData(datosUbicacion, idUsuario)),
      successFunction: () => dispatch(setDatosUbicacionUsuario(datosUbicacion)),
    });
  };
  /** Handlers para enviar la informacion */
  const handleSubmitForm = () => {
    if (esPaciente) {
      guardarDatosUsuario();
    } else {
      if (verificarObligatorios()) {
        setAlertOpen(true);
        return;
      }
      guardarDatosExpediente();
    }
  };

  const groups: IFormSectionGroup[] = [{ name: 'originario_de' }, { name: 'residente_en' }];

  const components: IFormSectionComponent[] = [
    {
      group: 'originario_de',
      component: (
        <CheckboxGroup
          name="territorioOriginario"
          label={t('territorio')}
          options={territorio}
          value={datosUbicacionTemp.territorioOriginario}
          setValue={handleInputChange}
          distribution={isWideScreen ? 'grid' : 'vertical-flex'}
          disabled={hayCambios}
        />
      ),
    },
    {
      group: 'originario_de',
      hide: !geo.agee,
      component: geo.agee ? (
        <SelectInput
          label={t(geo.agee.label)}
          name="ageeOriginario"
          options={catAgee}
          value={String(datosUbicacionTemp.ageeOriginario)}
          setValue={handleInputChange}
          isRequired={
            geo.agee.required && String(datosUbicacionTemp.territorioOriginario) === gentilicio
          }
          variant={isWideScreen ? 'search' : 'normal'}
          isDisabled={hayCambios || String(datosUbicacionTemp.territorioOriginario) !== gentilicio}
        />
      ) : (
        <></>
      ),
    },
    {
      group: 'originario_de',
      hide: !geo.agem || ocultarCampos,
      component: geo.agem ? (
        <SelectInput
          label={t(geo.agem.label)}
          name="agemOriginario"
          options={catAgemOriginario}
          value={String(datosUbicacionTemp.agemOriginario)}
          setValue={handleInputChange}
          isRequired={
            geo.agem.required && String(datosUbicacionTemp.territorioOriginario) === gentilicio
          }
          variant={isWideScreen ? 'search' : 'normal'}
          isDisabled={hayCambios || !datosUbicacionTemp.ageeOriginario.length}
        />
      ) : (
        <></>
      ),
    },
    {
      group: 'originario_de',
      hide: !geo.agel || ocultarCampos,
      component: geo.agel ? (
        <SelectInput
          label={t(geo.agel.label)}
          name="agelOriginario"
          options={catAgelOriginario}
          value={String(datosUbicacionTemp.agelOriginario)}
          setValue={handleInputChange}
          isRequired={
            geo.agel.required && String(datosUbicacionTemp.territorioOriginario) === gentilicio
          }
          variant={isWideScreen ? 'search' : 'normal'}
          isDisabled={hayCambios || !datosUbicacionTemp.agemOriginario.length}
        />
      ) : (
        <></>
      ),
    },
    {
      group: 'residente_en',
      component: (
        <CheckboxGroup
          name="territorioResidente"
          label={t('territorio')}
          options={territorio}
          value={datosUbicacionTemp.territorioResidente}
          setValue={handleInputChange}
          distribution={isWideScreen ? 'grid' : 'vertical-flex'}
          disabled={hayCambios}
        />
      ),
    },
    {
      group: 'residente_en',
      hide: !geo.agee,
      component: geo.agee ? (
        <SelectInput
          label={t(geo.agee.label)}
          name="ageeResidente"
          options={catAgee}
          value={String(datosUbicacionTemp.ageeResidente)}
          setValue={handleInputChange}
          isRequired={
            geo.agee.required && String(datosUbicacionTemp.territorioResidente) === gentilicio
          }
          variant={isWideScreen ? 'search' : 'normal'}
          isDisabled={hayCambios || String(datosUbicacionTemp.territorioResidente) !== gentilicio}
        />
      ) : (
        <></>
      ),
    },
    {
      group: 'residente_en',
      hide: !geo.agem,
      component: geo.agem ? (
        <SelectInput
          label={t(geo.agem.label)}
          name="agemResidente"
          options={catAgemResidente}
          value={String(datosUbicacionTemp.agemResidente)}
          setValue={handleInputChange}
          isRequired={
            geo.agem.required && String(datosUbicacionTemp.territorioResidente) === gentilicio
          }
          variant={isWideScreen ? 'search' : 'normal'}
          isDisabled={hayCambios || !datosUbicacionTemp.ageeResidente.length}
        />
      ) : (
        <></>
      ),
    },
    {
      group: 'residente_en',
      hide: !geo.agel || ocultarCampos,
      component: geo.agel ? (
        <SelectInput
          label={t(geo.agel.label)}
          name="agelResidente"
          options={catAgelResidente}
          value={String(datosUbicacionTemp.agelResidente)}
          setValue={handleInputChange}
          isRequired={
            geo.agel.required && String(datosUbicacionTemp.territorioResidente) === gentilicio
          }
          variant={isWideScreen ? 'search' : 'normal'}
          isDisabled={hayCambios || !datosUbicacionTemp.agemResidente.length}
        />
      ) : (
        <></>
      ),
    },
    {
      group: 'residente_en',
      hide: !geo.agea || ocultarCampos,
      component: geo.agea ? (
        <SelectInput
          label={t(geo.agea.label)}
          name="ageaResidente"
          options={catAgeaResidente}
          value={String(datosUbicacionTemp.ageaResidente)}
          setValue={handleInputChange}
          isRequired={
            geo.agea.required && String(datosUbicacionTemp.territorioResidente) === gentilicio
          }
          variant={isWideScreen ? 'search' : 'normal'}
          isDisabled={hayCambios || !datosUbicacionTemp.agelResidente.length}
        />
      ) : (
        <></>
      ),
    },
    {
      group: 'residente_en',
      hide: ocultarCampos,
      component: (
        <TextInput
          containerClass="md:col-span-2"
          label={t(geo.calle.label)}
          name="calle"
          value={String(datosUbicacionTemp.calle)}
          setValue={handleInputChange}
          maxLength={100}
          disabled={hayCambios}
        />
      ),
    },
    {
      group: 'residente_en',
      hide: ocultarCampos,
      component: (
        <div className="flex flex-row gap-x-2">
          <TextInput
            label={t(geo.num1.label)}
            name="num1"
            value={String(datosUbicacionTemp.num1)}
            setValue={handleInputChange}
            maxLength={10}
            disabled={hayCambios}
          />
          {geo.num2 && (
            <TextInput
              label={t(geo.num2.label)}
              name="num2"
              value={String(datosUbicacionTemp.num2)}
              setValue={handleInputChange}
              maxLength={10}
              disabled={hayCambios}
            />
          )}
        </div>
      ),
    },
    {
      group: 'residente_en',
      hide: ocultarCampos,
      component: (
        <TextInput
          label={t(geo.cp.label)}
          name="cp"
          value={String(datosUbicacionTemp.cp)}
          setValue={handleInputChange}
          maxLength={6}
          validacion={(val) => {
            const invalido = val.length > 0 && !validacionCp(val, pais);
            setCpError(invalido);
            return invalido ? t('cp_incorrecto') : '';
          }}
          disabled={hayCambios}
        />
      ),
    },
    {
      group: 'residente_en',
      hide: ocultarCampos,
      component: (
        <TextInput
          containerClass="md:col-span-3"
          label={t(geo.referencias.label)}
          name="referencias"
          value={String(datosUbicacionTemp.referencias)}
          setValue={handleInputChange}
          maxLength={100}
          disabled={hayCambios}
        />
      ),
    },
  ];

  return (
    <div id="datos-de-ubicacion" aria-labelledby="datos-de-ubicacion">
      <ModalForm
        title={t('datos_de_ubicacion')}
        id="datos-ubicacion-form"
        submitForm={handleSubmitForm}
        aceptarCambios={guardarDatosDeUsuarioAExpediente}
        rechazarCambios={guardarDatosDeExpedienteAUsuario}
        disableButton={cpError}
        hayCambios={hayCambios}
        cambios={cambios}
        camposObligatorios
        backAction={() => dispatch(setRutaPerfil('main'))}
        noXMargin={!isWideScreen}
      >
        {!isWideScreen ? (
          <FormSections groups={groups} components={components} />
        ) : (
          <div className="flex flex-col">
            <h2 className="text-gray-600 font-medium my-4">{t('originario_de')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
              <CheckboxGroup
                name="territorioOriginario"
                label={t('territorio')}
                options={territorio}
                value={datosUbicacionTemp.territorioOriginario}
                setValue={handleInputChange}
                distribution="grid"
                disabled={hayCambios}
              />
              {geo.agee && (
                <SelectInput
                  label={t(geo.agee.label)}
                  name="ageeOriginario"
                  options={catAgee}
                  value={String(datosUbicacionTemp.ageeOriginario)}
                  setValue={handleInputChange}
                  isRequired={
                    geo.agee.required &&
                    String(datosUbicacionTemp.territorioOriginario) === gentilicio
                  }
                  variant={isWideScreen ? 'search' : 'normal'}
                  isDisabled={
                    hayCambios || String(datosUbicacionTemp.territorioOriginario) !== gentilicio
                  }
                />
              )}
              {geo.agem && !ocultarCampos && (
                <SelectInput
                  label={t(geo.agem.label)}
                  name="agemOriginario"
                  options={catAgemOriginario}
                  value={String(datosUbicacionTemp.agemOriginario)}
                  setValue={handleInputChange}
                  isRequired={
                    geo.agem.required &&
                    String(datosUbicacionTemp.territorioOriginario) === gentilicio
                  }
                  variant={isWideScreen ? 'search' : 'normal'}
                  isDisabled={hayCambios || !datosUbicacionTemp.ageeOriginario.length}
                />
              )}
              {geo.agel && !ocultarCampos && (
                <SelectInput
                  label={t(geo.agel.label)}
                  name="agelOriginario"
                  options={catAgelOriginario}
                  value={String(datosUbicacionTemp.agelOriginario)}
                  setValue={handleInputChange}
                  isRequired={
                    geo.agel.required &&
                    String(datosUbicacionTemp.territorioOriginario) === gentilicio
                  }
                  variant={isWideScreen ? 'search' : 'normal'}
                  isDisabled={hayCambios || !datosUbicacionTemp.agemOriginario.length}
                />
              )}
            </div>
            <h2 className="text-gray-600 font-medium my-4">{t('residente_en')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-x-4">
              <CheckboxGroup
                name="territorioResidente"
                label={t('territorio')}
                options={territorio}
                value={datosUbicacionTemp.territorioResidente}
                setValue={handleInputChange}
                distribution="grid"
                disabled={hayCambios}
              />
              {geo.agee && (
                <SelectInput
                  label={t(geo.agee.label)}
                  name="ageeResidente"
                  options={catAgee}
                  value={String(datosUbicacionTemp.ageeResidente)}
                  setValue={handleInputChange}
                  isRequired={
                    geo.agee.required &&
                    String(datosUbicacionTemp.territorioResidente) === gentilicio
                  }
                  variant={isWideScreen ? 'search' : 'normal'}
                  isDisabled={
                    hayCambios || String(datosUbicacionTemp.territorioResidente) !== gentilicio
                  }
                />
              )}
              {geo.agem && (
                <SelectInput
                  label={t(geo.agem.label)}
                  name="agemResidente"
                  options={catAgemResidente}
                  value={String(datosUbicacionTemp.agemResidente)}
                  setValue={handleInputChange}
                  isRequired={
                    geo.agem.required &&
                    String(datosUbicacionTemp.territorioResidente) === gentilicio
                  }
                  variant={isWideScreen ? 'search' : 'normal'}
                  isDisabled={hayCambios || !datosUbicacionTemp.ageeResidente.length}
                />
              )}
              {geo.agel && !ocultarCampos && (
                <SelectInput
                  label={t(geo.agel.label)}
                  name="agelResidente"
                  options={catAgelResidente}
                  value={String(datosUbicacionTemp.agelResidente)}
                  setValue={handleInputChange}
                  isRequired={
                    geo.agel.required &&
                    String(datosUbicacionTemp.territorioResidente) === gentilicio
                  }
                  variant={isWideScreen ? 'search' : 'normal'}
                  isDisabled={hayCambios || !datosUbicacionTemp.agemResidente.length}
                />
              )}
              {!ocultarCampos && (
                <>
                  {geo.agea && (
                    <SelectInput
                      label={t(geo.agea.label)}
                      name="ageaResidente"
                      options={catAgeaResidente}
                      value={String(datosUbicacionTemp.ageaResidente)}
                      setValue={handleInputChange}
                      isRequired={
                        geo.agea.required &&
                        String(datosUbicacionTemp.territorioResidente) === gentilicio
                      }
                      variant={isWideScreen ? 'search' : 'normal'}
                      isDisabled={hayCambios || !datosUbicacionTemp.agelResidente.length}
                    />
                  )}
                  <TextInput
                    containerClass="md:col-span-2"
                    label={t(geo.calle.label)}
                    name="calle"
                    value={String(datosUbicacionTemp.calle)}
                    setValue={handleInputChange}
                    maxLength={100}
                    disabled={hayCambios}
                  />
                  <div className="flex flex-row gap-x-2">
                    <TextInput
                      label={t(geo.num1.label)}
                      name="num1"
                      value={String(datosUbicacionTemp.num1)}
                      setValue={handleInputChange}
                      maxLength={10}
                      disabled={hayCambios}
                    />
                    {geo.num2 && (
                      <TextInput
                        label={t(geo.num2.label)}
                        name="num2"
                        value={String(datosUbicacionTemp.num2)}
                        setValue={handleInputChange}
                        maxLength={10}
                        disabled={hayCambios}
                      />
                    )}
                  </div>
                  <TextInput
                    label={t(geo.cp.label)}
                    name="cp"
                    value={String(datosUbicacionTemp.cp)}
                    setValue={handleInputChange}
                    maxLength={6}
                    validacion={(val) => {
                      const invalido = val.length > 0 && !validacionCp(val, pais);
                      setCpError(invalido);
                      return invalido ? t('cp_incorrecto') : '';
                    }}
                    disabled={hayCambios}
                  />
                  <TextInput
                    containerClass="md:col-span-3"
                    label={t(geo.referencias.label)}
                    name="referencias"
                    value={String(datosUbicacionTemp.referencias)}
                    setValue={handleInputChange}
                    maxLength={100}
                    disabled={hayCambios}
                  />
                </>
              )}
            </div>
            {!esPaciente && (
              <TextArea
                name="comentario"
                value={datosUbicacionTemp.comentario}
                setValue={handleInputChange}
                disabled={hayCambios}
              />
            )}
          </div>
        )}
      </ModalForm>
      <Dialog
        type="options"
        open={alertOpen}
        title={t('_atencion_')}
        onClose={(anwser) => {
          setAlertOpen(false);
          if (anwser) {
            guardarDatosExpediente();
          }
        }}
        text={t('texto_llenado_norma_oficial')}
        acceptButtonText={t('guardar')}
        cancelButtonText={t('regresar')}
        cancelButtonColor="default"
        alert
      />
    </div>
  );
}

export default DatosDeUbicacion;
