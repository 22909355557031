import {
  SET_NUEVO_USUARIO,
  SET_OCULTAR_CAMPOS,
  SET_DATOS_EXPEDIENTE,
  SET_DATOS_USUARIO,
  SET_DATOS_PERSONALES,
  SET_DATOS_UBICACION,
  SET_DISCAPACIDADES,
  SET_INFO_EMERGENCIA,
  SET_SEGUROS_PLANES,
  SET_MCPA,
  SET_ENF_REFERIDAS,
  SET_ENF_NEGADAS,
  SET_GRUPO_ENF_NEGADAS,
  SET_CAT_DIAGNOSTICO,
  SET_DIAGNOSTICOS_GENERALES,
  SET_TRATAMIENTOS,
  SET_SIN_HOSPITALIZACIONES,
  SET_HOSPITALIZACIONES,
  SET_SIN_ALERGIAS,
  SET_ALERGIAS,
  SET_SIN_TRANSFUSIONES,
  SET_TRANSFUSIONES,
  SET_EN_GESTACION,
  SET_VIVIENDA,
  SET_HIGIENE,
  SET_ALIMENTACION,
  SET_ACTIVIDAD_FISICA,
  SET_INMUNIZACIONES,
  SET_TOXICOMANIA,
  SET_GINECO_OBSTETRICO,
  SET_HISTORIA_SEXUAL,
  SET_SIN_PROC_QUIRURGICOS,
  SET_PROC_DIAGNOSTICOS,
  SET_PROC_TERAPEUTICOS,
  SET_PROC_QUIRURGICOS,
  SET_SOL_PROC_DIAGNOSTICOS,
  SET_SOL_PROC_TERAPEUTICOS,
  SET_SOL_PROC_QUIRURGICOS,
  SET_FAMILIARES,
  SET_OBJ_LABORAL,
  SET_ANTECEDENTES_LABORALES,
  SET_ACCIDENTES_LABORALES,
  SET_APARATOS_Y_SISTEMAS,
  SET_EXPLORACION_FISICA,
  SET_LABORATORIO_SOLICITUD,
  SET_PRONOSTICOS,
  SET_INCAPACIDADES,
  SET_DATOS_EXPEDIENTE_USUARIO,
  SET_DATOS_PERSONALES_USUARIO,
  SET_DATOS_UBICACION_USUARIO,
  SET_DISCAPACIDADES_USUARIO,
  SET_INFO_EMERGENCIA_USUARIO,
  SET_SEGUROS_PLANES_USUARIO,
  SET_DIAGNOSTICOS_GENERALES_USUARIO,
  SET_EN_GESTACION_USUARIO,
  SET_VIVIENDA_USUARIO,
  SET_HIGIENE_USUARIO,
  SET_ALIMENTACION_USUARIO,
  SET_ACTIVIDAD_FISICA_USUARIO,
  SET_TOXICOMANIA_USUARIO,
  SET_GINECO_OBSTETRICO_USUARIO,
  SET_REGISTRO_FINALIZADO,
  SET_CAMBIOS_DATOS_PACIENTE,
  SET_PROCESO,
  RESET_DATOS_PACIENTE,
  IPaciente,
  PacienteActionTypes,
} from '@actions/paciente/types';
import { laboralInitial } from '@containers/TabsPaciente/Antecedentes/Laborales/types';
import { actividadFisicaInitial } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/ActividadFisica/types';
import { alimentacionInitial } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Alimentacion/types';
import { ginecoObstetricoInitial } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/GinecoObstetricos/types';
import { higieneInitial } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Higiene/types';
import { historiaSexualInitial } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/HistoriaSexual/types';
import { toxicomaniaInitial } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Toxicomania/types';
import { viviendaInitial } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Vivienda/types';
import { diagnosticosGeneralesInitial } from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/DiagnosticosGenerales/types';
import {
  grupoEnfermedadesNegadasInitial,
  IEnfermedad,
} from '@containers/TabsPaciente/Antecedentes/PersonalesPatologicos/EnfermedadesTratamientos/Enfermedades/types';
import { aparatosYSistemasInitial } from '@containers/TabsPaciente/AparatosYSistemas/types';
import { laboratorioSolicitudInitial } from '@containers/TabsPaciente/DiagnosticoPlan/Plan/Laboratorio/types';
import { exploracionFisicaInitial } from '@containers/TabsPaciente/ExploracionFisica/types';
import { mcpaInitial } from '@containers/TabsPaciente/MCPA/types';
import {
  datosUbicacionInitial,
  datosUbicacionNombresInitial,
} from '@containers/TabsPaciente/PerfilPaciente/DatosDeUbicacion/types';
import { datosPersonalesInitial } from '@containers/TabsPaciente/PerfilPaciente/DatosPersonales/types';
import { discapacidadesInitial } from '@containers/TabsPaciente/PerfilPaciente/Discapacidades/types';
import { infoEmergenciaInitial } from '@containers/TabsPaciente/PerfilPaciente/InfoEmergencia/types';
import { segurosPlanesInitial } from '@containers/TabsPaciente/PerfilPaciente/SegurosPlanes/types';

const initialState = {
  nuevo: false,
  ocultarCampos: false,
  expediente: {
    perfil: {
      datosPersonales: datosPersonalesInitial,
      datosUbicacion: datosUbicacionInitial,
      discapacidades: discapacidadesInitial,
      infoEmergencia: infoEmergenciaInitial,
      segurosPlanes: segurosPlanesInitial,
    },
    mcpa: mcpaInitial,
    antPatologicos: {
      enfReferidas: [],
      catDiagnostico: [],
      enfNegadas: [],
      grupoEnfNegadas: grupoEnfermedadesNegadasInitial,
      tratamientos: [],
      recetas: [],
      sinHospitalizaciones: false,
      hospitalizaciones: [],
      sinProcQuirurgicos: false,
      procDiagnosticos: [],
      procTerapeuticos: [],
      procQuirurgicos: [],
      sinAlergias: false,
      alergias: [],
      sinTransfusiones: false,
      transfusiones: [],
      diagnosticosGenerales: diagnosticosGeneralesInitial,
    },
    antNoPatologicos: {
      vivienda: viviendaInitial,
      higiene: higieneInitial,
      alimentacion: alimentacionInitial,
      actividadFisica: actividadFisicaInitial,
      inmunizaciones: [],
      toxicomania: toxicomaniaInitial,
      ginecoObstetrico: ginecoObstetricoInitial,
      historiaSexual: historiaSexualInitial,
    },
    familiares: [],
    objLaboral: laboralInitial,
    antecedentesLaborales: [],
    accidentesLaborales: [],
    aparatosYSistemas: aparatosYSistemasInitial,
    exploracionFisica: exploracionFisicaInitial,
    plan: {
      solProcDiagnosticos: [],
      solProcTerapeuticos: [],
      solProcQuirurgicos: [],
      laboratorioSolicitud: [laboratorioSolicitudInitial],
      pronosticos: [],
    },
    incapacidades: [],
  },
  usuario: {
    perfil: {
      datosPersonales: datosPersonalesInitial,
      datosUbicacion: datosUbicacionInitial,
      datosUbicacionNombres: datosUbicacionNombresInitial,
      discapacidades: discapacidadesInitial,
      infoEmergencia: infoEmergenciaInitial,
      segurosPlanes: segurosPlanesInitial,
    },
    antPatologicos: {
      diagnosticosGenerales: diagnosticosGeneralesInitial,
    },
    antNoPatologicos: {
      vivienda: viviendaInitial,
      higiene: higieneInitial,
      alimentacion: alimentacionInitial,
      actividadFisica: actividadFisicaInitial,
      toxicomania: toxicomaniaInitial,
      ginecoObstetrico: ginecoObstetricoInitial,
    },
  },
  cambios: {
    perfil: {
      datosPersonales: [],
      datosUbicacion: [],
      discapacidades: [],
      infoEmergencia: [],
      segurosPlanes: [],
    },
    antNoPatologicos: {
      vivienda: [],
      higiene: [],
      alimentacion: [],
      actividadFisica: [],
      toxicomania: [],
      ginecoObstetrico: [],
    },
  },
  proceso: {
    id: 0,
    tipo: 'EMP',
    secciones: [],
    formatosMC: [],
    formatosCie9: [],
  }
} as IPaciente;

// eslint-disable-next-line @typescript-eslint/default-param-last
function Paciente(state = initialState, action: PacienteActionTypes) {
  switch (action.type) {
    case SET_NUEVO_USUARIO:
      return {
        ...state,
        nuevo: action.payload,
      };
    case SET_OCULTAR_CAMPOS:
      return {
        ...state,
        ocultarCampos: action.payload,
      };
    case SET_DATOS_EXPEDIENTE:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          perfil: {
            datosPersonales: { ...action.payload.perfil.datosPersonales, loaded: true },
            datosUbicacion: action.payload.perfil.datosUbicacion
              ? { ...action.payload.perfil.datosUbicacion, loaded: true }
              : state.expediente.perfil.datosUbicacion,
            discapacidades: action.payload.perfil.discapacidades
              ? { ...action.payload.perfil.discapacidades, loaded: true }
              : state.expediente.perfil.discapacidades,
            infoEmergencia: action.payload.perfil.infoEmergencia
              ? { ...action.payload.perfil.infoEmergencia, loaded: true }
              : state.expediente.perfil.infoEmergencia,
            segurosPlanes: action.payload.perfil.segurosPlanes
              ? { ...action.payload.perfil.segurosPlanes, loaded: true }
              : state.expediente.perfil.segurosPlanes,
          },
          mcpa: action.payload.mcpa || state.expediente.mcpa,
          antPatologicos: {
            enfReferidas:
              action.payload.antPatologicos.enfReferidas ||
              state.expediente.antPatologicos.enfReferidas,
            catDiagnostico: action.payload.antPatologicos.enfReferidas
              ? action.payload.antPatologicos.enfReferidas.map((enf: IEnfermedad) => ({
                  id: enf.idBD,
                  label:
                    enf.seleccionDiagnostico === 'cie10'
                      ? enf.diagnosticoCie10.label
                      : enf.diagnosticoMedico,
                }))
              : [],
            enfNegadas:
              action.payload.antPatologicos.enfNegadas ||
              state.expediente.antPatologicos.enfNegadas,
            grupoEnfNegadas:
              action.payload.antPatologicos.grupoEnfNegadas ||
              state.expediente.antPatologicos.grupoEnfNegadas,
            tratamientos:
              action.payload.antPatologicos.tratamientos ||
              state.expediente.antPatologicos.tratamientos,
            // recetas:
            //   action.payload.antPatologicos.recetas || state.expediente.antPatologicos.recetas,
            sinHospitalizaciones:
              action.payload.antPatologicos.sinHospitalizaciones ||
              state.expediente.antPatologicos.sinHospitalizaciones,
            hospitalizaciones:
              action.payload.antPatologicos.hospitalizaciones ||
              state.expediente.antPatologicos.hospitalizaciones,
            procDiagnosticos:
              action.payload.antPatologicos.procDiagnosticos ||
              state.expediente.antPatologicos.procDiagnosticos,
            procTerapeuticos:
              action.payload.antPatologicos.procTerapeuticos ||
              state.expediente.antPatologicos.procTerapeuticos,
            sinProcQuirurgicos:
              action.payload.antPatologicos.sinProcQuirurgicos ||
              state.expediente.antPatologicos.sinProcQuirurgicos,
            procQuirurgicos:
              action.payload.antPatologicos.procQuirurgicos ||
              state.expediente.antPatologicos.procQuirurgicos,
            sinAlergias:
              action.payload.antPatologicos.sinAlergias ||
              state.expediente.antPatologicos.sinAlergias,
            alergias:
              action.payload.antPatologicos.alergias || state.expediente.antPatologicos.alergias,
            sinTransfusiones:
              action.payload.antPatologicos.sinTransfusiones ||
              state.expediente.antPatologicos.sinTransfusiones,
            transfusiones:
              action.payload.antPatologicos.transfusiones ||
              state.expediente.antPatologicos.transfusiones,
            diagnosticosGenerales: action.payload.antPatologicos.diagnosticosGenerales
              ? { ...action.payload.antPatologicos.diagnosticosGenerales, loaded: true }
              : state.expediente.antPatologicos.diagnosticosGenerales,
          },
          antNoPatologicos: {
            vivienda: action.payload.antNoPatologicos.vivienda
              ? { ...action.payload.antNoPatologicos.vivienda, loaded: true }
              : state.expediente.antNoPatologicos.vivienda,
            higiene: action.payload.antNoPatologicos.higiene
              ? { ...action.payload.antNoPatologicos.higiene, loaded: true }
              : state.expediente.antNoPatologicos.higiene,
            alimentacion: action.payload.antNoPatologicos.alimentacion
              ? { ...action.payload.antNoPatologicos.alimentacion, loaded: true }
              : state.expediente.antNoPatologicos.alimentacion,
            actividadFisica: action.payload.antNoPatologicos.actividadFisica
              ? { ...action.payload.antNoPatologicos.actividadFisica, loaded: true }
              : state.expediente.antNoPatologicos.actividadFisica,
            inmunizaciones:
              action.payload.antNoPatologicos.inmunizaciones ||
              state.expediente.antNoPatologicos.inmunizaciones,
            toxicomania: action.payload.antNoPatologicos.toxicomania
              ? { ...action.payload.antNoPatologicos.toxicomania, loaded: true }
              : state.expediente.antNoPatologicos.toxicomania,
            ginecoObstetrico: action.payload.antNoPatologicos.ginecoObstetrico
              ? { ...action.payload.antNoPatologicos.ginecoObstetrico, loaded: true }
              : state.expediente.antNoPatologicos.ginecoObstetrico,
            historiaSexual: action.payload.antNoPatologicos.historiaSexual
              ? { ...action.payload.antNoPatologicos.historiaSexual, loaded: true }
              : state.expediente.antNoPatologicos.historiaSexual,
          },
          familiares: action.payload.familiares || state.expediente.familiares,
          objLaboral: action.payload.objLaboral || state.expediente.objLaboral,
          antecedentesLaborales:
            action.payload.antecedentesLaborales || state.expediente.antecedentesLaborales,
          accidentesLaborales:
            action.payload.accidentesLaborales || state.expediente.accidentesLaborales,
          aparatosYSistemas: action.payload.aparatosYSistemas || state.expediente.aparatosYSistemas,
          exploracionFisica: action.payload.exploracionFisica
            ? { ...action.payload.exploracionFisica, loaded: true }
            : state.expediente.exploracionFisica,
          plan: {
            solProcDiagnosticos:
              action.payload.plan.solProcDiagnosticos || state.expediente.plan.solProcDiagnosticos,
            solProcTerapeuticos:
              action.payload.plan.solProcTerapeuticos || state.expediente.plan.solProcTerapeuticos,
            solProcQuirurgicos:
              action.payload.plan.solProcQuirurgicos || state.expediente.plan.solProcQuirurgicos,
            laboratorioSolicitud:
              action.payload.plan.laboratorioSolicitud ||
              state.expediente.plan.laboratorioSolicitud,
            pronosticos: action.payload.plan.pronosticos || state.expediente.plan.pronosticos,
          },
          incapacidades: action.payload.incapacidades || state.expediente.incapacidades,
        },
      };
    case SET_DATOS_USUARIO:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          perfil: {
            datosPersonales: { ...action.payload.perfil.datosPersonales, loaded: true },
            datosUbicacion: action.payload.perfil.datosUbicacion
              ? { ...action.payload.perfil.datosUbicacion, loaded: true }
              : state.expediente.perfil.datosUbicacion,
            discapacidades: action.payload.perfil.discapacidades
              ? { ...action.payload.perfil.discapacidades, loaded: true }
              : state.expediente.perfil.discapacidades,
            infoEmergencia: action.payload.perfil.infoEmergencia
              ? { ...action.payload.perfil.infoEmergencia, loaded: true }
              : state.expediente.perfil.infoEmergencia,
            segurosPlanes: action.payload.perfil.segurosPlanes
              ? { ...action.payload.perfil.segurosPlanes, loaded: true }
              : state.expediente.perfil.segurosPlanes,
          },
          antPatologicos: {
            ...state.expediente.antPatologicos,
            diagnosticosGenerales: action.payload.antPatologicos.diagnosticosGenerales
              ? { ...action.payload.antPatologicos.diagnosticosGenerales, loaded: true }
              : state.expediente.antPatologicos.diagnosticosGenerales,
          },
          antNoPatologicos: {
            ...state.expediente.antNoPatologicos,
            vivienda: action.payload.antNoPatologicos.vivienda
              ? { ...action.payload.antNoPatologicos.vivienda, loaded: true }
              : state.expediente.antNoPatologicos.vivienda,
            higiene: action.payload.antNoPatologicos.higiene
              ? { ...action.payload.antNoPatologicos.higiene, loaded: true }
              : state.expediente.antNoPatologicos.higiene,
            alimentacion: action.payload.antNoPatologicos.alimentacion
              ? { ...action.payload.antNoPatologicos.alimentacion, loaded: true }
              : state.expediente.antNoPatologicos.alimentacion,
            actividadFisica: action.payload.antNoPatologicos.actividadFisica
              ? { ...action.payload.antNoPatologicos.actividadFisica, loaded: true }
              : state.expediente.antNoPatologicos.actividadFisica,
            toxicomania: action.payload.antNoPatologicos.toxicomania
              ? { ...action.payload.antNoPatologicos.toxicomania, loaded: true }
              : state.expediente.antNoPatologicos.toxicomania,
            ginecoObstetrico: action.payload.antNoPatologicos.ginecoObstetrico
              ? { ...action.payload.antNoPatologicos.ginecoObstetrico, loaded: true }
              : state.expediente.antNoPatologicos.ginecoObstetrico,
          },
          exploracionFisica: action.payload.exploracionFisica
            ? { ...action.payload.exploracionFisica, loaded: true }
            : state.expediente.exploracionFisica,
        },
      };
    case SET_DATOS_PERSONALES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          perfil: { ...state.expediente.perfil, datosPersonales: action.payload },
        },
      };
    case SET_DATOS_UBICACION:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          perfil: { ...state.expediente.perfil, datosUbicacion: action.payload },
        },
      };
    case SET_DISCAPACIDADES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          perfil: { ...state.expediente.perfil, discapacidades: action.payload },
        },
      };
    case SET_INFO_EMERGENCIA:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          perfil: { ...state.expediente.perfil, infoEmergencia: action.payload },
        },
      };
    case SET_SEGUROS_PLANES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          perfil: { ...state.expediente.perfil, segurosPlanes: action.payload },
        },
      };
    case SET_MCPA:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          mcpa: action.payload,
        },
      };
    case SET_ENF_REFERIDAS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, enfReferidas: action.payload },
        },
      };
    case SET_ENF_NEGADAS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, enfNegadas: action.payload },
        },
      };
    case SET_GRUPO_ENF_NEGADAS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, grupoEnfNegadas: action.payload },
        },
      };
    case SET_CAT_DIAGNOSTICO:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, catDiagnostico: action.payload },
        },
      };
    case SET_DIAGNOSTICOS_GENERALES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: {
            ...state.expediente.antPatologicos,
            diagnosticosGenerales: action.payload,
          },
        },
      };
    case SET_TRATAMIENTOS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, tratamientos: action.payload },
        },
      };
    case SET_SIN_HOSPITALIZACIONES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: {
            ...state.expediente.antPatologicos,
            sinHospitalizaciones: action.payload,
          },
        },
      };
    case SET_HOSPITALIZACIONES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, hospitalizaciones: action.payload },
        },
      };
    case SET_SIN_PROC_QUIRURGICOS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: {
            ...state.expediente.antPatologicos,
            sinProcQuirurgicos: action.payload,
          },
        },
      };
    case SET_PROC_DIAGNOSTICOS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, procDiagnosticos: action.payload },
        },
      };
    case SET_PROC_TERAPEUTICOS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, procTerapeuticos: action.payload },
        },
      };
    case SET_PROC_QUIRURGICOS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, procQuirurgicos: action.payload },
        },
      };
    case SET_SIN_ALERGIAS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, sinAlergias: action.payload },
        },
      };
    case SET_ALERGIAS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, alergias: action.payload },
        },
      };
    case SET_SIN_TRANSFUSIONES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, sinTransfusiones: action.payload },
        },
      };
    case SET_TRANSFUSIONES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antPatologicos: { ...state.expediente.antPatologicos, transfusiones: action.payload },
        },
      };
    case SET_EN_GESTACION:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antNoPatologicos: {
            ...state.expediente.antNoPatologicos,
            ginecoObstetrico: {
              ...state.expediente.antNoPatologicos.ginecoObstetrico,
              generalidades: {
                ...state.expediente.antNoPatologicos.ginecoObstetrico.generalidades,
                enGestacion: action.payload,
              },
            },
          },
        },
      };
    case SET_VIVIENDA:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antNoPatologicos: { ...state.expediente.antNoPatologicos, vivienda: action.payload },
        },
      };
    case SET_HIGIENE:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antNoPatologicos: { ...state.expediente.antNoPatologicos, higiene: action.payload },
        },
      };
    case SET_ALIMENTACION:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antNoPatologicos: { ...state.expediente.antNoPatologicos, alimentacion: action.payload },
        },
      };
    case SET_ACTIVIDAD_FISICA:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antNoPatologicos: {
            ...state.expediente.antNoPatologicos,
            actividadFisica: action.payload,
          },
        },
      };
    case SET_INMUNIZACIONES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antNoPatologicos: {
            ...state.expediente.antNoPatologicos,
            inmunizaciones: action.payload,
          },
        },
      };
    case SET_TOXICOMANIA:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antNoPatologicos: { ...state.expediente.antNoPatologicos, toxicomania: action.payload },
        },
      };
    case SET_GINECO_OBSTETRICO:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antNoPatologicos: {
            ...state.expediente.antNoPatologicos,
            ginecoObstetrico: action.payload,
          },
        },
      };
    case SET_HISTORIA_SEXUAL:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antNoPatologicos: {
            ...state.expediente.antNoPatologicos,
            historiaSexual: action.payload,
          },
        },
      };
    case SET_SOL_PROC_DIAGNOSTICOS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          plan: { ...state.expediente.plan, solProcDiagnosticos: action.payload },
        },
      };
    case SET_SOL_PROC_TERAPEUTICOS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          plan: { ...state.expediente.plan, solProcTerapeuticos: action.payload },
        },
      };
    case SET_SOL_PROC_QUIRURGICOS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          plan: { ...state.expediente.plan, solProcQuirurgicos: action.payload },
        },
      };
    case SET_LABORATORIO_SOLICITUD:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          plan: { ...state.expediente.plan, laboratorioSolicitud: action.payload },
        },
      };
    case SET_PRONOSTICOS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          plan: { ...state.expediente.plan, pronosticos: action.payload },
        },
      };
    case SET_FAMILIARES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          familiares: action.payload,
        },
      };
    case SET_OBJ_LABORAL:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          objLaboral: action.payload,
        },
      };
    case SET_ANTECEDENTES_LABORALES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          antecedentesLaborales: action.payload,
        },
      };
    case SET_ACCIDENTES_LABORALES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          accidentesLaborales: action.payload,
        },
      };
    case SET_APARATOS_Y_SISTEMAS:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          aparatosYSistemas: action.payload,
        },
      };
    case SET_EXPLORACION_FISICA:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          exploracionFisica: action.payload,
        },
      };
    case SET_INCAPACIDADES:
      return {
        ...state,
        expediente: {
          ...state.expediente,
          incapacidades: action.payload,
        },
      };
    case SET_DATOS_EXPEDIENTE_USUARIO:
      return {
        ...state,
        usuario: {
          perfil: {
            datosPersonales: { ...action.payload.perfil.datosPersonales, loaded: true },
            datosUbicacion: action.payload.perfil.datosUbicacion
              ? { ...action.payload.perfil.datosUbicacion, loaded: true }
              : state.usuario.perfil.datosUbicacion,
            datosUbicacionNombres:
              action.payload.perfil.datosUbicacionNombres ||
              state.usuario.perfil.datosUbicacionNombres,
            discapacidades: action.payload.perfil.discapacidades
              ? { ...action.payload.perfil.discapacidades, loaded: true }
              : state.usuario.perfil.discapacidades,
            infoEmergencia: action.payload.perfil.infoEmergencia
              ? { ...action.payload.perfil.infoEmergencia, loaded: true }
              : state.usuario.perfil.infoEmergencia,
            segurosPlanes: action.payload.perfil.segurosPlanes
              ? { ...action.payload.perfil.segurosPlanes, loaded: true }
              : state.usuario.perfil.segurosPlanes,
          },
          antPatologicos: {
            diagnosticosGenerales: action.payload.antPatologicos.diagnosticosGenerales
              ? { ...action.payload.antPatologicos.diagnosticosGenerales, loaded: true }
              : state.usuario.antPatologicos.diagnosticosGenerales,
          },
          antNoPatologicos: {
            vivienda: action.payload.antNoPatologicos.vivienda
              ? { ...action.payload.antNoPatologicos.vivienda, loaded: true }
              : state.usuario.antNoPatologicos.vivienda,
            higiene: action.payload.antNoPatologicos.higiene
              ? { ...action.payload.antNoPatologicos.higiene, loaded: true }
              : state.usuario.antNoPatologicos.higiene,
            alimentacion: action.payload.antNoPatologicos.alimentacion
              ? { ...action.payload.antNoPatologicos.alimentacion, loaded: true }
              : state.usuario.antNoPatologicos.alimentacion,
            actividadFisica: action.payload.antNoPatologicos.actividadFisica
              ? { ...action.payload.antNoPatologicos.actividadFisica, loaded: true }
              : state.usuario.antNoPatologicos.actividadFisica,
            toxicomania: action.payload.antNoPatologicos.toxicomania
              ? { ...action.payload.antNoPatologicos.toxicomania, loaded: true }
              : state.usuario.antNoPatologicos.toxicomania,
            ginecoObstetrico: action.payload.antNoPatologicos.ginecoObstetrico
              ? { ...action.payload.antNoPatologicos.ginecoObstetrico, loaded: true }
              : state.usuario.antNoPatologicos.ginecoObstetrico,
          },
        },
      };
    case SET_DATOS_PERSONALES_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          perfil: { ...state.usuario.perfil, datosPersonales: action.payload },
        },
      };
    case SET_DATOS_UBICACION_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          perfil: { ...state.usuario.perfil, datosUbicacion: action.payload },
        },
      };
    case SET_DISCAPACIDADES_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          perfil: { ...state.usuario.perfil, discapacidades: action.payload },
        },
      };
    case SET_INFO_EMERGENCIA_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          perfil: { ...state.usuario.perfil, infoEmergencia: action.payload },
        },
      };
    case SET_SEGUROS_PLANES_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          perfil: { ...state.usuario.perfil, segurosPlanes: action.payload },
        },
      };
    case SET_DIAGNOSTICOS_GENERALES_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          antPatologicos: {
            ...state.usuario.antPatologicos,
            diagnosticosGenerales: action.payload,
          },
        },
      };
    case SET_EN_GESTACION_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          antNoPatologicos: {
            ...state.usuario.antNoPatologicos,
            ginecoObstetrico: {
              ...state.usuario.antNoPatologicos.ginecoObstetrico,
              generalidades: {
                ...state.usuario.antNoPatologicos.ginecoObstetrico.generalidades,
                enGestacion: action.payload,
              },
            },
          },
        },
      };
    case SET_VIVIENDA_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          antNoPatologicos: { ...state.usuario.antNoPatologicos, vivienda: action.payload },
        },
      };
    case SET_HIGIENE_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          antNoPatologicos: { ...state.usuario.antNoPatologicos, higiene: action.payload },
        },
      };
    case SET_ALIMENTACION_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          antNoPatologicos: { ...state.usuario.antNoPatologicos, alimentacion: action.payload },
        },
      };
    case SET_ACTIVIDAD_FISICA_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          antNoPatologicos: { ...state.usuario.antNoPatologicos, actividadFisica: action.payload },
        },
      };
    case SET_TOXICOMANIA_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          antNoPatologicos: { ...state.usuario.antNoPatologicos, toxicomania: action.payload },
        },
      };
    case SET_GINECO_OBSTETRICO_USUARIO:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          antNoPatologicos: { ...state.usuario.antNoPatologicos, ginecoObstetrico: action.payload },
        },
      };
    case SET_REGISTRO_FINALIZADO:
      return {
        ...state,
        nuevo: false,
      };
    case SET_CAMBIOS_DATOS_PACIENTE:
      return {
        ...state,
        cambios: action.payload || state.cambios,
      };
    case SET_PROCESO:
      return {
        ...state,
        proceso: action.payload || state.proceso,
      };
    case RESET_DATOS_PACIENTE:
      return initialState;
    default:
      return state;
  }
}

export default Paciente;
