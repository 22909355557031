import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import {
  getBusinessDoctorObtenerDatos,
  getCatalogoTipoOcupacion,
  getFethCatalogoAgee,
  getCatalogoAgem,
  getOperationsDoctorObtenerDatos,
  getOperationsDoctorObtenerOperacionesSalud,
  getDictamenesObtenerDatos,
  getCatalogoDistGeografica,
} from '@utils/getCatalogos';
import { setSnackComplete } from '@actions/snackbar/types';
import { ICatalogosCie10, ICatalogosEstandar, ICatStrEstandar } from '@common/types';
import {
  IBusinessDoctor,
  IObjPacienteDia,
  IObjPacienteCategoriaSexo,
  IObjPacienteAccidente,
  IObjPacienteCategoriaPorcentajeSexo,
  // IObjPacientesCumple,
  IObjGloborisk,
  IObjCancerPulmon,
  IObjCancerCervico,
  IObjAudiometria,
  IObjEMP,
  IObjAAA,
  IObjPacienteEpidemiologia,
  IFiltrosReporteEpidemiologia,
  IObjPacienteSexoTotales,
  IObjGraphCardiovascular,
  IObjHabito,
  IObjPacienteSexoHabitos,
  IObjAreasOportunidad,
  IObjPacienteIncapacidad,
  IObjCampania,
  IObjPacienteDictamen,
  IFiltrosSalud,
  IObjPacienteReporteGeneral,
  IObjRiesgoOperativo,
  IObjGradosSalud,
  IObjExamenAlarma,
  IObjGraphGradosSalud,
  IObjGraphRiesgosOperativos,
  ICatDistGeo,
  IDistGeo,
  IObjPacienteFaltante,
  IObjPacienteSexo,
} from './types';
import useStyles from './styles';
import Operaciones from './operaciones';
import Inteligencia from './inteligencia';
import Epidemiologia from './epidemiologia';
import Gestion from './gestion';
import Dictamenes from './Dictamenes';
import { TipoOperacion, TipoTabla } from './operaciones/types';
import { useApiRequest } from '@hooks/useApiRequest';

dayjs.locale('es');

const BusinessDoctor = () => {
  const { t } = useTranslation();
  const { apiRequest } = useApiRequest();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { idMedico, rol, carrera, secciones, enOrganizacion } = useSelector(
    (state: RootState) => state.Me,
  );
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { consultorios, consultorio } = useSelector((state: RootState) => state.Consultorios);
  const { agee, idConsultorio: idConsMaster } = consultorio;
  const [idConsultorio, setIdConsultorio] = useState<number>(idConsMaster);
  const [tipoPacientes, setTipoPacientes] = useState<'n/a' | 'interno' | 'externo'>(
    enOrganizacion ? 'interno' : 'n/a',
  );
  const [verTablaDetalle, setVerTablaDetalle] = useState<boolean>(false);
  const [graphType, setGraphType] = useState<'' | 'table' | 'chart'>('chart');
  const [seleccionPagina, setSeleccionPagina] = useState<
    'operaciones' | 'inteligencia' | 'epidemiologia' | 'gestion' | 'dictamenes'
  >('operaciones');
  const [seleccionOperacion, setSeleccionOperacion] =
    useState<TipoOperacion>('medicina_preventiva');
  const [plantillaConsultorio, setPlantillaConsultorio] = useState<number>(0);
  const [plantillaOrganizacion, setPlantillaOrganizacion] = useState<number>(0);
  const [tablaCardiovascular, setTablaCardiovascular] = useState<IObjPacienteSexoTotales[]>([]);
  const [tablaAAA, setTablaAAA] = useState<IObjPacienteSexoTotales[]>([]);
  const [tablaCancerPulmon, setTablaCancerPulmon] = useState<IObjPacienteSexoTotales[]>([]);
  const [tablaCancerCervical, setTablaCancerCervical] = useState<IObjPacienteSexoTotales[]>([]);
  const [tablaHabitosRiesgo, setTablaHabitosRiesgo] = useState<IObjPacienteSexoHabitos[]>([]);
  const [tablaHacinamiento, setTablaHacinamiento] = useState<IObjPacienteSexoTotales[]>([]);
  const [tablaAudiometrias, setTablaAudiometrias] = useState<IObjExamenAlarma[]>([]);
  const [tablaEMP, setTablaEMP] = useState<IObjExamenAlarma[]>([]);
  const [graficoCardiovascular, setGraficoCardiovascular] = useState<
    IObjGraphCardiovascular[]
  >([]);
  const [tablaCardiovascularDetalle, setTablaCardiovascularDetalle] = useState<
    IObjGloborisk[]
  >([]);
  const [tablaAAADetalle, setTablaAAADetalle] = useState<IObjAAA[]>([]);
  const [tablaCancerPulmonDetalle, setTablaCancerPulmonDetalle] = useState<IObjCancerPulmon[]>(
    [],
  );
  const [tablaCancerCervicoDetalle, setTablaCancerCervicoDetalle] = useState<
    IObjCancerCervico[]
  >([]);
  const [tablaAudiometriasDetalle, setTablaAudiometriasDetalle] = useState<IObjAudiometria[]>(
    [],
  );
  const [tablaEMPDetalle, setTablaEMPDetalle] = useState<IObjEMP[]>([]);
  const [tableGloborisk, setTableGloborisk] = useState<any[]>([]);
  const [tablaTabaquismoDetalle, setTablaTabaquismoDetalle] = useState<IObjHabito[]>([]);
  const [tablaCafeinaDetalle, setTablaCafeinaDetalle] = useState<IObjHabito[]>([]);
  const [tablaAlcoholTrastornoDetalle, setTablaAlcoholTrastornoDetalle] = useState<
    IObjHabito[]
  >([]);
  const [tablaAlcoholIntensivoDetalle, setTablaAlcoholIntensivoDetalle] = useState<
    IObjHabito[]
  >([]);
  const [tablaOtrasDrogasDetalle, setTablaOtrasDrogasDetalle] = useState<IObjHabito[]>([]);
  const [tablaHacinamientoDetalle, setTablaHacinamientoDetalle] = useState<IObjHabito[]>([]);
  const [tablaActividadFisicaDetalle, setTablaActividadFisicaDetalle] = useState<IObjHabito[]>(
    [],
  );
  const [tablaRefrescosDetalle, setTablaRefrescosDetalle] = useState<IObjHabito[]>([]);
  const [tablaReporteGeneral, setTablaReporteGeneral] = useState<IObjPacienteSexo[]>([]);
  const [tablaReporteGeneralDetalle, setTablaReporteGeneralSaludDetalle] = useState<
    IObjPacienteReporteGeneral[]
  >([]);
  const [tablaRiesgoOperativo, setTablaRiesgoOperativo] = useState<IObjRiesgoOperativo[]>([]);
  const [tablaGradoSalud, setTablaGradoSalud] = useState<IObjGradosSalud[]>([]);
  const [tablaAntecedentesImpactoLaboral, setTablaAntecedentesImpactoLaboral] = useState<
    IObjPacienteCategoriaSexo[]
  >([]);
  const [tablaDiscapacidades, setTablaDiscapacidades] = useState<IObjPacienteCategoriaSexo[]>([]);
  const [tablaRegistrosFaltantes, setTablaRegistrosFaltantes] = useState<IObjPacienteFaltante[]>(
    [],
  );
  const [graficoGradoSalud, setGraficoGradoSalud] = useState<IObjGraphGradosSalud[]>([]);
  const [graficoRiesgoOperativo, setGraficoRiesgoOperativo] = useState<
    IObjGraphRiesgosOperativos[]
  >([]);
  // const [arrayCumple, setArrayCumple] = useState<IObjPacientesCumple[]>([]);
  const [fechaSeleccionada, setFechaSeleccionada] = useState<boolean>(false);
  const [fechaSeleccionadaMayor, setFechaSeleccionadaMayor] = useState<boolean>(false);
  const [filtroEdadSexo, setFiltroEdadSexo] = useState<string>('year');
  const [selectorTabla, setSelectorTabla] = useState<TipoTabla>('riesgo_cardiovascular');
  const [datoPeticionGeneral, setDatoPeticionGeneral] = useState<IBusinessDoctor>({
    start: dayjs(new Date()).subtract(1, 'month'),
    end: new Date(),
  });
  const [datosPacienteDia, setDatosPacienteDia] = useState<IObjPacienteDia[]>([]);
  const [datosPacienteEdadSexo, setDatosPacienteEdadSexo] = useState<IObjPacienteCategoriaSexo[]>([]);
  const [datosPacienteDiagnosticoSexo, setDatosPacienteDiagnosticoSexo] = useState<
    IObjPacienteCategoriaPorcentajeSexo[]
  >([]);
  const [datosPacienteAccidentes, setDatosPacienteAccidentes] = useState<IObjPacienteAccidente[]>(
    [],
  );
  const [datosPacienteIncapacidades, setDatosPacienteIncapacidades] = useState<
    IObjPacienteIncapacidad[]
  >([]);
  const [datosPacienteDistribucionGeografica, setDatosPacienteDistribucionGeografica] = useState<
    IObjPacienteCategoriaPorcentajeSexo[]
  >([]);
  const [catAGEE, setCatAGEE] = useState<ICatStrEstandar[]>([]);
  const [catAGEM, setCatAGEM] = useState<ICatStrEstandar[]>([]);
  const [selAGEE, setSelAGEE] = useState<ICatStrEstandar | null>(null);
  const [selAGEM, setSelAGEM] = useState<ICatStrEstandar | null>(null);
  const [distGeo, setDistGeo] = useState<IDistGeo[]>([]);
  const [catDistGeo, setCatDistGeo] = useState<ICatDistGeo>({
    distGeo1: [],
    distGeo2: [],
    distGeo3: [],
    distGeo4: [],
  });
  const [filtrosRepSalud, setFiltrosRepSalud] = useState<IFiltrosSalud>({
    distGeo1: '',
    distGeo2: '',
    distGeo3: '',
    distGeo4: '',
    tipoRol: 'todos',
  });
  const [tablaAreasOportunidad, setTablaAreasOportunidad] = useState<IObjAreasOportunidad[]>(
    [],
  );
  // const [catAgemRep, setCatAgemRep] = useState<ICatStrEstandar[]>([]);
  const [catTipoOcupacionRep, setCatTipoOcupacionRep] = useState<Array<ICatalogosEstandar>>([]);
  const [catCIE10, setCatCIE10] = useState<ICatalogosCie10[]>([]);
  // const [openCIE10, setOpenCIE10] = useState<boolean>(false);
  const [loadingCIE10, setLoadingCIE10] = useState<boolean>(false);
  const [inputCIE10, setInputCIE10] = useState<string>('');
  const [datosReporteEpidemiologia, setDatosReporteEpidemiologia] = useState<
    IObjPacienteEpidemiologia[]
  >([]);
  const [filtrosReporteEpidemiologia, setFiltrosReporteEpidemiologia] =
    useState<IFiltrosReporteEpidemiologia>({
      fecha: {
        start: dayjs(new Date()).subtract(1, 'month'),
        end: new Date(),
      },
      consultorio: idConsMaster,
      // agees: [],
      // agems: [],
      edad: [0, 60],
      sexo: 'ambos',
      diagnosticos: [],
      ocupaciones: [],
    });
  const [resumenFiltros, setResumenFiltros] = useState<string>('');
  const [seleccionarTodo, setSeleccionarTodo] = useState<boolean>(false);
  const [listaCampanias, setListaCampanias] = useState<IObjCampania[]>([]);
  const [datosPacienteDictamenes, setDatosPacienteDictamenes] = useState<IObjPacienteDictamen[]>(
    [],
  );
  const [consultaDictamenes, setConsultaDictamenes] = useState<boolean>(false);

  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);

  const getPestanasWith = () => {
    if (window.innerWidth > 800) {
      if (window.innerWidth > 1500) {
        return 'w-3/5';
      }
      // if (window.innerWidth > 1100) {
      //   return 'w-4/5';
      // }
      return 'w-4/5';
    }
    return '';
  };
  const getObtenerDatosInteligencia = (periodoMax: boolean) =>
    getBusinessDoctorObtenerDatos(
      idMedico,
      idOrganizacion,
      idConsMaster,
      tipoPacientes,
      dayjs(datoPeticionGeneral.start).format('YYYYMMDD'),
      dayjs(datoPeticionGeneral.end).format('YYYYMMDD'),
      filtroEdadSexo,
      agee?.id || '',
      '',
      periodoMax,
    );
  // cambios del reporte de epidemiología
  // const handleChangeAgeeReporte = (event: any, newValue: ICatStrEstandar[] | []) => {
  //   setFiltrosReporteEpidemiologia({
  //     ...filtrosReporteEpidemiologia,
  //     agee: newValue,
  //   });
  //   setSelAGEM(null);
  //   if (newValue.length === 1) {
  //     getCatalogoAgem(newValue[0].id).then((result: ICatStrEstandar[]) => {
  //       setCatAgemRep(result);
  //     });
  //   }
  // };
  // const handleChangeAgemReporte = (event: any, newValue: ICatStrEstandar[] | []) => {
  //   setFiltrosReporteEpidemiologia({
  //     ...filtrosReporteEpidemiologia,
  //     agem: newValue,
  //   });
  // };

  // para llenar el catálogo de agee y agem
  useEffect(() => {
    if (agee) {
      const fetchCatAgee = getFethCatalogoAgee(idMedico);
      const fetchCatAgem = getCatalogoAgem(agee.id);
      Promise.all([fetchCatAgee, fetchCatAgem])
        .then((responses) => {
          setCatAGEE(responses[0]);
          const est = responses[0].find((obj: ICatStrEstandar) => obj.id === agee.id);
          setSelAGEE(est);
          // setFiltrosReporteEpidemiologia({
          //   ...filtrosReporteEpidemiologia,
          //   agee: [est],
          // });
          setCatAGEM(responses[1]);
          // setCatAgemRep(responses[1]);
        })
        .catch((err) => {
          if (err.toString() !== 'AbortError: The user aborted a request.') {
            dispatch(
              setSnackComplete({
                open: true,
                severity: 'error',
                mensaje: `${t('message-error-warning')} ${err.toString()}`,
              }),
            );
          }
        });
    }
  }, [agee]);
  const consultarDatosOperaciones = () => {
    apiRequest({
      type: 'get',
      requestFunction: getOperationsDoctorObtenerDatos(idMedico, idConsMaster, tipoPacientes),
      successFunction: (result: any) => {
        if (result.code === 200) {
          setPlantillaConsultorio(result.plantilla || 0);
          setTablaCardiovascular(result.datos.cardiovascular);
          setTablaAAA(result.datos.aaa);
          setTablaCancerPulmon(result.datos.cancerPulmon);
          setTablaCancerCervical(result.datos.cancerCervical);
          setTablaHacinamiento(result.datos.hacinamiento);
          setTablaAudiometrias(result.datos.audiometrias);
          setTablaEMP(result.datos.emp);
          setGraficoCardiovascular(result.datos.graficosCardiovascular);
          setTablaHabitosRiesgo(
            result.datos.habitosRiesgo.map((habito: IObjPacienteSexoHabitos) => {
              const newHabito = { ...habito };
              newHabito.titulo = t(newHabito.tipo);
              newHabito.verDetalle = newHabito.tipo;
              switch (newHabito.tipo) {
                case 'alarma_tabaquismo':
                  newHabito.comentarios = `${t('cigarrillos_promedio_al_dia')}: ${
                    newHabito.comentarios
                  }`;
                  break;
                default:
                  break;
              }
              return newHabito;
            }),
          );
          setTablaCardiovascularDetalle(result.datos.cardiovascularDetalle);
          setTablaCancerPulmonDetalle(result.datos.cancerPulmonDetalle);
          setTablaAAADetalle(result.datos.aneurismaAortaAbdominalDetalle);
          setTablaCancerCervicoDetalle(result.datos.cancerCervicoDetalle);
          setTablaAudiometriasDetalle(result.datos.audiometriasDetalle);
          setTablaEMPDetalle(result.datos.EMPDetalle);
          setTablaTabaquismoDetalle(result.datos.tabaquismoDetalle);
          setTablaCafeinaDetalle(result.datos.cafeinaDetalle);
          setTablaAlcoholTrastornoDetalle(result.datos.alcoholTrastornoDetalle);
          setTablaAlcoholIntensivoDetalle(result.datos.alcoholIntensivoDetalle);
          setTablaOtrasDrogasDetalle(result.datos.otrasDrogasDetalle);
          setTablaHacinamientoDetalle(result.datos.hacinamientoDetalle);
          setTablaActividadFisicaDetalle(result.datos.actividadFisicaDetalle);
          setTablaRefrescosDetalle(result.datos.refrescosDetalle);
          setTablaAreasOportunidad(
            result.datos.areasOportunidad.map((row: any) => ({ ...row, titulo: t(row.tipo) })),
          );
        }
      },
    });
  };
  const consultarDatosDictamenes = () => {
    apiRequest({
      type: 'get',
      requestFunction: getDictamenesObtenerDatos(idMedico, idConsMaster, tipoPacientes),
      successFunction: (result: any) => {
        setDatosPacienteDictamenes(result.datos);
        setConsultaDictamenes(true);
      },
    });
  };
  useEffect(() => {
    if (idConsMaster > 0) {
      setFechaSeleccionadaMayor(false);
      setFechaSeleccionada(true);
      setDatosPacienteDia([]);
      setDatosPacienteEdadSexo([]);
      setDatosPacienteDiagnosticoSexo([]);
      setDatosPacienteAccidentes([]);
      setDatosPacienteIncapacidades([]);
      setDatosPacienteDistribucionGeografica([]);
      setDatosReporteEpidemiologia([]);
      setListaCampanias([]);
      setDatosPacienteDictamenes([]);
      setConsultaDictamenes(false);
      setIdConsultorio(idConsMaster);
      consultarDatosOperaciones();
      if (seleccionPagina === 'dictamenes') {
        consultarDatosDictamenes();
      }
    }
  }, [idConsMaster, tipoPacientes]);
  useEffect(() => {
    getCatalogoTipoOcupacion().then((result: ICatalogosEstandar[]) => {
      setCatTipoOcupacionRep(result);
    });
  }, []);
  useEffect(() => {
    if (seleccionPagina === 'operaciones') {
      setSeleccionOperacion('medicina_preventiva');
      setSelectorTabla('riesgo_cardiovascular');
      setGraphType('chart');
      setVerTablaDetalle(true);
    } else if (seleccionPagina === 'dictamenes' && enOrganizacion && !consultaDictamenes) {
      consultarDatosDictamenes();
    }
  }, [seleccionPagina]);

  useEffect(() => {
    if (idOrganizacion > 0) {
      getCatalogoDistGeografica(idOrganizacion).then((result: IDistGeo[]) => setDistGeo(result));
    }
    setTablaReporteGeneral([]);
    setTablaReporteGeneralSaludDetalle([]);
    setFiltrosRepSalud((prev) => ({
      ...prev,
      distGeo1: '',
      distGeo2: '',
      distGeo3: '',
      distGeo4: '',
    }));
  }, [idOrganizacion]);

  const uniqueDistGeo = (level: 'distGeo1' | 'distGeo2' | 'distGeo3' | 'distGeo4') => {
    let newArray: IDistGeo[] = [];
    switch (level) {
      case 'distGeo2':
        if (filtrosRepSalud.distGeo1.length > 0) {
          newArray = distGeo.filter((row) => row.distGeo1 === filtrosRepSalud.distGeo1);
        }
        break;
      case 'distGeo3':
        if (filtrosRepSalud.distGeo2.length > 0) {
          newArray = distGeo.filter(
            (row) =>
              row.distGeo1 === filtrosRepSalud.distGeo1 &&
              row.distGeo2 === filtrosRepSalud.distGeo2,
          );
        }
        break;
      case 'distGeo4':
        if (filtrosRepSalud.distGeo3.length > 0) {
          newArray = distGeo.filter(
            (row) =>
              row.distGeo1 === filtrosRepSalud.distGeo1 &&
              row.distGeo2 === filtrosRepSalud.distGeo2 &&
              row.distGeo3 === filtrosRepSalud.distGeo3,
          );
        }
        break;
      default:
        newArray = distGeo;
        break;
    }
    return [...new Set(newArray.map((row) => row[level]))].map((row) => ({ id: row, label: row }));
  };

  useEffect(() => {
    if (distGeo.length > 0) {
      setCatDistGeo((prev) => ({
        ...prev,
        distGeo1: uniqueDistGeo('distGeo1'),
      }));
    }
  }, [distGeo]);
  useEffect(() => {
    setFiltrosRepSalud((prev) => ({ ...prev, distGeo2: '', distGeo3: '', distGeo4: '' }));
    setCatDistGeo((prev) => ({
      ...prev,
      distGeo2: filtrosRepSalud.distGeo1.length > 0 ? uniqueDistGeo('distGeo2') : [],
    }));
  }, [filtrosRepSalud.distGeo1]);
  useEffect(() => {
    setFiltrosRepSalud((prev) => ({ ...prev, distGeo3: '', distGeo4: '' }));
    setCatDistGeo((prev) => ({
      ...prev,
      distGeo3: filtrosRepSalud.distGeo2.length > 0 ? uniqueDistGeo('distGeo3') : [],
    }));
  }, [filtrosRepSalud.distGeo2]);
  useEffect(() => {
    setFiltrosRepSalud((prev) => ({ ...prev, distGeo4: '' }));
    setCatDistGeo((prev) => ({
      ...prev,
      distGeo4: filtrosRepSalud.distGeo3.length > 0 ? uniqueDistGeo('distGeo4') : [],
    }));
  }, [filtrosRepSalud.distGeo3]);
  useEffect(() => {
    setTablaReporteGeneral([]);
    setTablaReporteGeneralSaludDetalle([]);
    setTablaRiesgoOperativo([]);
    setTablaGradoSalud([]);
    setTablaAntecedentesImpactoLaboral([]);
    setTablaDiscapacidades([]);
  }, [filtrosRepSalud]);

  const obtenerDatosOperacionesSalud = () => {
    apiRequest({
      type: 'get',
      requestFunction: getOperationsDoctorObtenerOperacionesSalud(
        idMedico,
        idOrganizacion,
        tipoPacientes,
        filtrosRepSalud.tipoRol,
        filtrosRepSalud.distGeo1,
        filtrosRepSalud.distGeo2,
        filtrosRepSalud.distGeo3,
        filtrosRepSalud.distGeo4,
      ),
      successFunction: (result: any) => {
        setPlantillaOrganizacion(result.datos.plantilla);
        setTablaReporteGeneral(result.datos.operacionesSaludTotales);
        setGraficoRiesgoOperativo(result.datos.graficoRiesgoOperativo);
        setGraficoGradoSalud(result.datos.graficoGradoSalud);
        setTablaReporteGeneralSaludDetalle(
          result.datos.operacionesSaludDetalle.map((row: IObjPacienteReporteGeneral) => ({
            ...row,
            sexo: t(row.sexo),
            ocupacion: row.ocupacion ? row.ocupacion.toUpperCase() : '',
            gradoSalud: row.gradoSalud.toString(),
            riesgoOperativo: row.riesgoOperativo ? t(row.riesgoOperativo) : '',
            distGeo1: row.distGeo1 || '-',
            distGeo2: row.distGeo2 || '-',
            distGeo3: row.distGeo3 || '-',
            distGeo4: row.distGeo4 || '-',
            supervisor: row.supervisor || '-',
          })),
        );
        setTablaRiesgoOperativo(
          result.datos.riesgoOperativo
            .filter((row: IObjRiesgoOperativo) => row.diagnostico !== 'lentes')
            .map((row: IObjRiesgoOperativo) => ({
              ...row,
              diagnostico: t(`resumen-salud-diagnosticos-g-${row.diagnostico}`),
            }))
            .sort((a: IObjRiesgoOperativo, b: IObjRiesgoOperativo) =>
              a.riesgoOperativo > b.riesgoOperativo ? -1 : 1,
            ),
        );
        setTablaGradoSalud(
          result.datos.gradoSalud
            .map((row: IObjGradosSalud) => ({
              ...row,
              diagnostico: t(`resumen-salud-diagnosticos-g-${row.diagnostico}`),
            }))
            .sort((a: IObjGradosSalud, b: IObjGradosSalud) =>
              (a.grado4[0] * 10000, a.grado4[1] * 1000) +
                (a.grado3[0] * 1000, a.grado3[1] * 100) +
                (a.grado2[0] * 100, a.grado2[1] * 100) +
                (a.grado1[0] * 10, a.grado1[1] * 10) +
                (a.grado0[0] * 1, a.grado0[1] * 1) >
              (b.grado4[0] * 10000, b.grado4[1] * 1000) +
                (b.grado3[0] * 1000, b.grado3[1] * 100) +
                (b.grado2[0] * 100, b.grado2[1] * 100) +
                (b.grado1[0] * 10, b.grado1[1] * 10) +
                (b.grado0[0] * 1, b.grado0[1] * 1)
                ? -1
                : 1,
            ),
        );
        setTablaAntecedentesImpactoLaboral(
          result.datos.antecedentesImpactoLaboral
          .map((row: IObjPacienteCategoriaSexo) => ({
            ...row,
            categoria: t(`resumen-salud-diagnosticos-g-${row.categoria}`)
          }))
          .sort((a: IObjPacienteCategoriaSexo, b: IObjPacienteCategoriaSexo) =>
            a.total > b.total ? -1 : 1,
          ),
        );
        setTablaDiscapacidades(
          result.datos.discapacidades
          .sort((a: IObjPacienteCategoriaSexo, b: IObjPacienteCategoriaSexo) =>
            a.total > b.total ? -1 : 1,
          ),
        );
        setTablaRegistrosFaltantes(result.datos.registrosFaltantes);
      },
    });
  };

  const getTabs = () => {
    const tabs: {
      tipo: 'operaciones' | 'inteligencia' | 'epidemiologia' | 'gestion' | 'dictamenes';
      titulo: string;
      seccion: number;
    }[] = [
      { tipo: 'operaciones', titulo: 'business_operaciones', seccion: 72 },
      { tipo: 'inteligencia', titulo: 'business_inteligencia_negocio', seccion: 70 },
      { tipo: 'epidemiologia', titulo: 'business_reportes_mis_reportes', seccion: 75 },
      { tipo: 'gestion', titulo: 'business_gestion', seccion: 78 },
    ];
    if (enOrganizacion) {
      tabs.push({ tipo: 'dictamenes', titulo: 'dictamenes', seccion: 79 });
    }
    return tabs;
  };

  const handleFiltrosSalud = (e: { name: string; value: any }) =>
    setFiltrosRepSalud((prevData) => ({ ...prevData, [e.name]: e.value }));

  return (
    <div className="p-4 bg-white w-full">
      {rol.grupo === 'administrativo' ||
      (carrera.cedula &&
        carrera.escuela &&
        carrera.titulo &&
        consultorios.length &&
        consultorio.idConsultorio > 0 &&
        consultorio.nombre) ? (
        <>
          {/* <div className="text-center p-4 bg-blue-300">
            <h1 className="text-blue-800 font-normal m-0 text-center">
              {`${t('bienvenido')}, ${nombre || ''} ${primerApellido || ''}`}
            </h1>
          </div> */}
          {enOrganizacion && (
            <div className="float-right p-0">
              <div className="grid grid-cols-5 items-center">
                <div className="col-span-2">
                  <h4 className="text-gray-500 font-normal m-0">
                    {`${t('business_tipo_pacientes')}:`}
                  </h4>
                </div>
                <div className="col-span-3">
                  <FormControl variant="outlined" fullWidth>
                    <RadioGroup
                      row
                      aria-label="filtroTipoPacientes"
                      name="filtroTipoPacientes"
                      value={tipoPacientes}
                      onChange={(event) =>
                        setTipoPacientes(event.target.value as 'interno' | 'externo')
                      }
                    >
                      <FormControlLabel
                        value="interno"
                        control={<Radio color="primary" />}
                        label={t('business_pacientes_internos')}
                      />
                      <FormControlLabel
                        value="externo"
                        control={<Radio color="primary" />}
                        label={t('business_pacientes_externos')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          )}
          <div
            className={`${window.innerWidth > 800 ? 'flex' : ''} justify-between items-end w-full`}
          >
            {/* esta es la parte de las pestañas */}
            <div className={`${getPestanasWith()} flex flex-row items-start`}>
              {getTabs().map((tab, index) =>
                secciones.length > 0 &&
                secciones.find((valor) => valor.idSeccion === tab.seccion) ? (
                  <Button
                    key={`button-${index}`}
                    fullWidth
                    className={
                      seleccionPagina === tab.tipo ? classes.buttonSelected : classes.buttonRoot
                    }
                    onClick={() => setSeleccionPagina(tab.tipo)}
                  >
                    {t(tab.titulo)}
                  </Button>
                ) : null,
              )}
            </div>
          </div>
          <hr className="m-0" />
          <div className="py-2" />
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 72) &&
            seleccionPagina === 'operaciones' && (
              <Operaciones
                seleccionOperacion={seleccionOperacion}
                setSeleccionOperacion={setSeleccionOperacion}
                selectorTabla={selectorTabla}
                setSelectorTabla={setSelectorTabla}
                graphType={graphType}
                setGraphType={setGraphType}
                plantillaConsultorio={plantillaConsultorio}
                plantillaOrganizacion={plantillaOrganizacion}
                catDistGeo={catDistGeo}
                tipoPacientes={tipoPacientes}
                filtrosRepSalud={filtrosRepSalud}
                handleFiltrosSalud={handleFiltrosSalud}
                verTablaDetalle={verTablaDetalle}
                setVerTablaDetalle={setVerTablaDetalle}
                tablaCardiovascular={tablaCardiovascular}
                tablaAAA={tablaAAA}
                tablaCancerPulmon={tablaCancerPulmon}
                tablaCancerCervical={tablaCancerCervical}
                tablaHabitosRiesgo={tablaHabitosRiesgo}
                tablaHacinamiento={tablaHacinamiento}
                tablaAudiometrias={tablaAudiometrias}
                tablaEMP={tablaEMP}
                graficoCardiovascular={graficoCardiovascular}
                tablaCardiovascularDetalle={tablaCardiovascularDetalle}
                tablaAAADetalle={tablaAAADetalle}
                tablaCancerPulmonDetalle={tablaCancerPulmonDetalle}
                tablaCancerCervicoDetalle={tablaCancerCervicoDetalle}
                tablaAudiometriasDetalle={tablaAudiometriasDetalle}
                tablaEMPDetalle={tablaEMPDetalle}
                tableGloborisk={tableGloborisk}
                setTableGloborisk={setTableGloborisk}
                tablaTabaquismoDetalle={tablaTabaquismoDetalle}
                tablaCafeinaDetalle={tablaCafeinaDetalle}
                tablaAlcoholTrastornoDetalle={tablaAlcoholTrastornoDetalle}
                tablaAlcoholIntensivoDetalle={tablaAlcoholIntensivoDetalle}
                tablaOtrasDrogasDetalle={tablaOtrasDrogasDetalle}
                tablaHacinamientoDetalle={tablaHacinamientoDetalle}
                tablaActividadFisicaDetalle={tablaActividadFisicaDetalle}
                tablaRefrescosDetalle={tablaRefrescosDetalle}
                tablaReporteGeneral={tablaReporteGeneral}
                tablaReporteGeneralDetalle={tablaReporteGeneralDetalle}
                tablaRiesgoOperativo={tablaRiesgoOperativo}
                tablaGradoSalud={tablaGradoSalud}
                tablaAntecedentesImpactoLaboral={tablaAntecedentesImpactoLaboral}
                tablaDiscapacidades={tablaDiscapacidades}
                tablaRegistrosFaltantes={tablaRegistrosFaltantes}
                graficoRiesgoOperativo={graficoRiesgoOperativo}
                graficoGradoSalud={graficoGradoSalud}
                obtenerDatosOperacionesSalud={obtenerDatosOperacionesSalud}
                // arrayCumple={arrayCumple}
                // setArrayCumple={setArrayCumple}
              />
            )}
          {/* esta es la parte de inteligencia de negocio */}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 70) &&
            seleccionPagina === 'inteligencia' && (
              <Inteligencia
                idConsultorio={idConsultorio}
                setIdConsultorio={setIdConsultorio}
                tipoPacientes={tipoPacientes}
                fechaSeleccionada={fechaSeleccionada}
                setFechaSeleccionada={setFechaSeleccionada}
                fechaSeleccionadaMayor={fechaSeleccionadaMayor}
                setFechaSeleccionadaMayor={setFechaSeleccionadaMayor}
                filtroEdadSexo={filtroEdadSexo}
                setFiltroEdadSexo={setFiltroEdadSexo}
                datoPeticionGeneral={datoPeticionGeneral}
                setDatoPeticionGeneral={setDatoPeticionGeneral}
                datosPacienteDia={datosPacienteDia}
                setDatosPacienteDia={setDatosPacienteDia}
                datosPacienteEdadSexo={datosPacienteEdadSexo}
                setDatosPacienteEdadSexo={setDatosPacienteEdadSexo}
                datosPacienteDiagnosticoSexo={datosPacienteDiagnosticoSexo}
                setDatosPacienteDiagnosticoSexo={setDatosPacienteDiagnosticoSexo}
                datosPacienteAccidentes={datosPacienteAccidentes}
                setDatosPacienteAccidentes={setDatosPacienteAccidentes}
                datosPacienteIncapacidades={datosPacienteIncapacidades}
                setDatosPacienteIncapacidades={setDatosPacienteIncapacidades}
                datosPacienteDistribucionGeografica={datosPacienteDistribucionGeografica}
                setDatosPacienteDistribucionGeografica={setDatosPacienteDistribucionGeografica}
                catAGEE={catAGEE}
                catAGEM={catAGEM}
                setCatAGEM={setCatAGEM}
                selAGEE={selAGEE}
                setSelAGEE={setSelAGEE}
                selAGEM={selAGEM}
                setSelAGEM={setSelAGEM}
                tablaAreasOportunidad={tablaAreasOportunidad}
                getObtenerDatosInteligencia={getObtenerDatosInteligencia}
              />
            )}
          {/* esta es la parte del reporte */}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 75) &&
            seleccionPagina === 'epidemiologia' && (
              <Epidemiologia
                tipoPacientes={tipoPacientes}
                datoPeticionGeneral={datoPeticionGeneral}
                // catAgemRep={catAgemRep}
                // setCatAgemRep={setCatAgemRep}
                catTipoOcupacionRep={catTipoOcupacionRep}
                catCIE10={catCIE10}
                setCatCIE10={setCatCIE10}
                // openCIE10={openCIE10}
                // setOpenCIE10={setOpenCIE10}
                loadingCIE10={loadingCIE10}
                setLoadingCIE10={setLoadingCIE10}
                inputCIE10={inputCIE10}
                setInputCIE10={setInputCIE10}
                datosReporteEpidemiologia={datosReporteEpidemiologia}
                setDatosReporteEpidemiologia={setDatosReporteEpidemiologia}
                filtrosReporteEpidemiologia={filtrosReporteEpidemiologia}
                setFiltrosReporteEpidemiologia={setFiltrosReporteEpidemiologia}
                resumenFiltros={resumenFiltros}
                setResumenFiltros={setResumenFiltros}
                seleccionarTodo={seleccionarTodo}
                setSeleccionarTodo={setSeleccionarTodo}
                listaCampanias={listaCampanias}
              />
            )}
          {/* esta es la parte de gestion */}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 78) &&
            seleccionPagina === 'gestion' && (
              <Gestion
                tipoPacientes={tipoPacientes}
                listaCampanias={listaCampanias}
                setListaCampanias={setListaCampanias}
              />
            )}
          {secciones.length > 0 &&
            secciones.find((valor) => valor.idSeccion === 79) &&
            seleccionPagina === 'dictamenes' && (
              <Dictamenes datosPacienteDictamenes={datosPacienteDictamenes} />
            )}
        </>
      ) : (
        <>
          <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
            <div className="border-0 p-4 pr-5 w-full">
              <h3 className="m-0 text-center text-blue-500 font-normal">{t('bienvenido')}</h3>
              <div className="text-center my-4">
                <WarningIcon className="text-red-500" color="inherit" fontSize="large" />
              </div>
              <p className="text-gray-500 text-center">
                {t(
                  'si_no_llenas_al_menos_tus_datos_de_identificacion_no_podras_acceder_a_las_otras_partes_del_expediente',
                )}
              </p>
              {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 1) && (
                <div className="flex justify-center w-full">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push('/perfil-medico');
                    }}
                  >
                    {`${t('ir_a_perfil_medico')}`}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BusinessDoctor;
