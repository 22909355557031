import { useTranslation } from 'react-i18next';
import IAlertDialogProps from './types';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import Dialog from '../Dialog';
import { useBreakPoint } from '@hooks/useBreakpoint';

function ControlDiagnostico(props: IAlertDialogProps) {
  const { open, onClose, diagnostico, setDiagnosticos } = props;
  const { t } = useTranslation();
  const isWideScreen = useBreakPoint();

  const frecuencia = ['siempre', 'casi_siempre', 'a_veces', 'rara_vez', 'nunca'].map((frec) => ({
    value: frec,
    label: t(frec),
  }));
  const controlesFrecuencia: { name: string; label: string; value?: string }[] = [
    {
      name: 'frecSigueIndicaciones',
      label: 'frec-sigue-indicaciones',
      value: diagnostico.control?.frecSigueIndicaciones,
    },
    {
      name: 'frecAcudeConsultas',
      label: 'frec-acude-consultas',
      value: diagnostico.control?.frecAcudeConsultas,
    },
    {
      name: 'frecSigueDieta',
      label: 'frec-sigue-dieta',
      value: diagnostico.control?.frecSigueDieta,
    },
  ];

  const handleInputChange = (e: { name: string; value: any }) =>
    setDiagnosticos((prevData) => ({
      ...prevData,
      contenidoTabla: prevData.contenidoTabla.map((d) =>
        d.idDiagnostico === diagnostico.idDiagnostico && diagnostico.control
          ? { ...diagnostico, control: { ...diagnostico.control, [e.name]: e.value } }
          : d,
      ),
    }));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('antecedentes-pp-diagnosticos-g-control')}
      description={
        diagnostico.nombre ? t(`antecedentes-pp-diagnosticos-g-${diagnostico.nombre}`) : ''
      }
      fullScreen={!isWideScreen}
      maxWidth="md"
    >
      {controlesFrecuencia.map((control) => (
        <CheckboxGroup
          name={control.name}
          label={control.label ? t(`antecedentes-pp-diagnosticos-g-${control.label}`) : ''}
          options={frecuencia}
          distribution="vertical"
          value={control.value || ''}
          setValue={handleInputChange}
        />
      ))}
      <NumberInput
        name="diasUltimaMedicion"
        label={
          diagnostico.nombre
            ? t(`antecedentes-pp-diagnosticos-g-ult-med-${diagnostico.nombre}`)
            : ''
        }
        placeholder=""
        value={diagnostico.control?.diasUltimaMedicion}
        setValue={handleInputChange}
        isInteger
        fixedValue={t('dias')}
      />
    </Dialog>
  );
}

export default ControlDiagnostico;
