import { ScreenSizeActionTypes, SET_SIZE } from './types';

const getSize = (width: number) => {
  if (width > 1536) {
    return '2xl';
  }
  if (width > 1280) {
    return 'xl';
  }
  if (width > 1024) {
    return 'lg';
  }
  if (width > 768) {
    return 'md';
  }
  return 'sm';
};

export function setScreenSize(width: number, height: number): ScreenSizeActionTypes {
  return {
    type: SET_SIZE,
    payload: {
      width,
      height,
      size: getSize(width),
    },
  };
}
