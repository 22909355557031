export interface IAlimentos {
  alimento: string;
  especifica: string;
}

export interface IAlimentacion {
  loaded: boolean;
  dieta: string;
  alimentos: IAlimentos[];
  botonComidas: number | null;
  botonFruta: number | null;
  botonVegetales: number | null;
  comidas: number | null;
  carne: number | null;
  carneAlt: number | null;
  pollo: number | null;
  pescado: number | null;
  refrescos: number | null;
  postres: number | null;
  arrTorFriPas: number | null;
  dulPapPast: number | null;
  comentarios: string;
  comidasFuera: number | null;
  dulces: number | null;
}

export const alimentacionInitial: IAlimentacion = {
  loaded: false,
  dieta: '',
  alimentos: [{ alimento: '', especifica: '' }],
  botonComidas: null,
  botonFruta: null,
  botonVegetales: null,
  comidas: null,
  carne: null,
  carneAlt: null,
  pollo: null,
  pescado: null,
  refrescos: null,
  postres: null,
  arrTorFriPas: null,
  dulPapPast: null,
  comidasFuera: null,
  dulces: null,
  comentarios: '',
};
