import { FC, useEffect, useState } from 'react';
import { ScaleInputProps } from './type';
import { CircleX, CircleCheckBig } from 'lucide-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export const ScaleInput: FC<ScaleInputProps> = ({
  name,
  containerClass = '',
  label,
  options,
  scaleLabels,
  isRequired = false,
  helperText,
  onChange,
  setValue,
  value: externalValue,
  disabled = false,
  hideValidationIcon = false,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const [selectedValue, setSelectedValue] = useState<string | number | undefined>(externalValue);

  useEffect(() => {
    if (externalValue !== undefined) {
      setSelectedValue(externalValue);
    }
  }, [externalValue]);

  const handleChange = (value: string | number) => {
    setSelectedValue(value);

    setValue({
      name,
      value,
    });

    if (onChange) {
      onChange(value);
    }
  };

  const renderLabel = () => {
    if (!label) return null;
    return (
      <h3 className="font-medium text-gray-900">
        {label}
        {isRequired && <span className="text-red-500 ml-1">*</span>}
      </h3>
    );
  };

  const renderScaleLabels = () => {
    if (!scaleLabels) return null;
    return (
      <div className="flex justify-between text-xs font-medium text-gray-500 mb-3">
        <div className="flex-1 text-left">{scaleLabels.start}</div>
        <div className="flex-1 text-center">{scaleLabels.middle}</div>
        <div className="flex-1 text-right">{scaleLabels.end}</div>
      </div>
    );
  };

  const renderOptions = () => {
    const baseOptionClass = 'h-10 flex-1 border-t border-b border-r first:border-l';
    const selectedClass = 'bg-blue-500 text-white border-blue-500 font-medium';
    const unselectedClass = `bg-white text-gray-700 ${esPaciente && selectedValue !== undefined ? 'border-green-300' : 'border-gray-300'}`;

    return (
      <div className="w-full h-auto">
        <div className="flex relative" role="group" aria-label={label}>
          {options.map((option, index) => (
            <button
              key={option.value}
              type="button"
              onClick={() => handleChange(option.value)}
              className={`
                ${baseOptionClass}
                ${selectedValue === option.value ? selectedClass : unselectedClass}
                ${index === 0 ? 'rounded-l' : ''}
                ${index === options.length - 1 ? 'rounded-r' : ''}
                focus:relative focus:z-10 focus:outline-none ${
                  selectedValue === option.value ? 'hover:bg-blue-600' : ''
                }
                transition-colors duration-150
              `}
              aria-pressed={selectedValue === option.value}
              role="radio"
              aria-checked={selectedValue === option.value}
              disabled={disabled}
            >
              {option.label || option.value}
            </button>
          ))}
          {esPaciente && !hideValidationIcon && (
            <div className="w-5 h-5 absolute bg-white -right-[6px] -top-[6px] rounded-xl">
              {selectedValue !== undefined ? (
                <CircleCheckBig size={20} color="green" />
              ) : (
                <CircleX size={20} color="gray" />
              )}
            </div>
          )}
        </div>
        {renderScaleLabels()}
      </div>
    );
  };

  return (
    <div className={`w-full h-24 mt-2 ${containerClass}`}>
      <div className="mb-2">{renderLabel()}</div>
      {renderOptions()}
      {helperText && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
    </div>
  );
};

export default ScaleInput;
