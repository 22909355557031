import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { TriangleAlert } from 'lucide-react';
import { RootState } from 'src/store';
import { ReactComponent as ViviendaIcon } from '@assets/icons/Vivienda.svg';
import { ReactComponent as HigieneIcon } from '@assets/icons/Higiene.svg';
import { ReactComponent as AlimentacionIcon } from '@assets/icons/Alimentacion.svg';
import { ReactComponent as ActividadFisicaIcon } from '@assets/icons/ActividadFisica.svg';
import { ReactComponent as InmunizacionesIcon } from '@assets/icons/Inmunizaciones.svg';
import { ReactComponent as ToxicomaniaIcon } from '@assets/icons/Toxicomania.svg';
import { ReactComponent as GinecobstetricoIcon } from '@assets/icons/Ginecobstetrico.svg';
import { ReactComponent as HistoriaSexualIcon } from '@assets/icons/HistoriaSexual.svg';
import { setRutaAntNoPatologicos } from '@actions/rutasExpediente/actions';
import useStyles from './styles';
import Vivienda from './Vivienda/index';
import Higiene from './Higiene/index';
import Alimentacion from './Alimentacion/index';
import ActividadFisica from './ActividadFisica/index';
import Inmunizaciones from './Inmunizaciones/index';
import Toxicomania from './Toxicomania';
import GinecoObstetricos from './GinecoObstetricos';
import HistoriaSexual from './HistoriaSexual';

function PersonalesNoPatologicos() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { rutaAntNoPatologicos } = useSelector((state: RootState) => state.RutasExpediente);
  const { secciones, esPaciente } = useSelector((state: RootState) => state.Me);
  const { sexo, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    cambios: { antNoPatologicos: cambios },
  } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  return (
    <div>
      {!esPaciente && (
        <>
          <div className="relative pb-4">
            <div className="text-left">
              <h1 className="text-blue-800 font-normal m-2">{t('antecedentes-pnp-selecciona')}</h1>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-3 px-4 pb-6 mb-4">
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 23) && (
              <Button
                className={rutaAntNoPatologicos === 'vivienda' ? classes.selected : classes.button}
                variant="contained"
                color="primary"
                startIcon={
                  <>
                    {!esPaciente &&
                    idUsuario > 0 &&
                    cambios.vivienda.filter((c: string) => c.length > 0).length > 0 ? (
                      <TriangleAlert color="orange" />
                    ) : null}
                    <ViviendaIcon />
                  </>
                }
                fullWidth
                onClick={() => dispatch(setRutaAntNoPatologicos('vivienda'))}
                size="large"
              >
                {t('antecedentes-pnp-vivienda')}
              </Button>
            )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 24) && (
              <Button
                className={rutaAntNoPatologicos === 'higiene' ? classes.selected : classes.button}
                variant="contained"
                color="primary"
                size="large"
                startIcon={
                  <>
                    {!esPaciente &&
                    idUsuario > 0 &&
                    cambios.higiene.filter((c: string) => c.length > 0).length > 0 ? (
                      <TriangleAlert color="orange" />
                    ) : null}
                    <HigieneIcon />
                  </>
                }
                fullWidth
                onClick={() => dispatch(setRutaAntNoPatologicos('higiene'))}
              >
                {t('antecedentes-pnp-higiene')}
              </Button>
            )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 25) && (
              <Button
                className={
                  rutaAntNoPatologicos === 'alimentacion' ? classes.selected : classes.button
                }
                variant="contained"
                color="primary"
                size="large"
                startIcon={
                  <>
                    {!esPaciente &&
                    idUsuario > 0 &&
                    cambios.alimentacion.filter((c: string) => c.length > 0).length > 0 ? (
                      <TriangleAlert color="orange" />
                    ) : null}
                    <AlimentacionIcon />
                  </>
                }
                fullWidth
                onClick={() => dispatch(setRutaAntNoPatologicos('alimentacion'))}
              >
                {t('antecedentes-pnp-alimentacion')}
              </Button>
            )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 26) && (
              <Button
                className={
                  rutaAntNoPatologicos === 'actividad-fisica' ? classes.selected : classes.button
                }
                variant="contained"
                color="primary"
                size="large"
                startIcon={
                  <>
                    {!esPaciente &&
                    idUsuario > 0 &&
                    cambios.actividadFisica.filter((c: string) => c.length > 0).length > 0 ? (
                      <TriangleAlert color="orange" />
                    ) : null}
                    <ActividadFisicaIcon />
                  </>
                }
                fullWidth
                onClick={() => dispatch(setRutaAntNoPatologicos('actividad-fisica'))}
              >
                {t('antecedentes-pnp-actividad')}
              </Button>
            )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 27) && (
              <Button
                className={
                  rutaAntNoPatologicos === 'inmunizaciones' ? classes.selected : classes.button
                }
                variant="contained"
                color="primary"
                size="large"
                startIcon={<InmunizacionesIcon />}
                fullWidth
                onClick={() => dispatch(setRutaAntNoPatologicos('inmunizaciones'))}
              >
                {t('antecedentes-pnp-inmunizaciones')}
              </Button>
            )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 28) && (
              <Button
                className={
                  rutaAntNoPatologicos === 'toxicomania' ? classes.selected : classes.button
                }
                variant="contained"
                color="primary"
                size="large"
                startIcon={
                  <>
                    {!esPaciente &&
                    idUsuario > 0 &&
                    cambios.toxicomania.filter((c: string) => c.length > 0).length > 0 ? (
                      <TriangleAlert color="orange" />
                    ) : null}
                    <ToxicomaniaIcon />
                  </>
                }
                fullWidth
                onClick={() => dispatch(setRutaAntNoPatologicos('toxicomania'))}
              >
                {t('antecedentes-pnp-toxicomania')}
              </Button>
            )}
            {secciones.length > 0 &&
              secciones.find((valor) => valor.idSeccion === 29) &&
              sexo === 'M' && (
                <Button
                  className={
                    rutaAntNoPatologicos === 'gineco-obstetricos'
                      ? classes.selected
                      : classes.button
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={
                    <>
                      {!esPaciente &&
                      idUsuario > 0 &&
                      cambios.ginecoObstetrico.filter((c: string) => c.length > 0).length > 0 ? (
                        <TriangleAlert color="orange" />
                      ) : null}
                      <GinecobstetricoIcon />
                    </>
                  }
                  fullWidth
                  onClick={() => dispatch(setRutaAntNoPatologicos('gineco-obstetricos'))}
                >
                  {t('antecedentes-pnp-gineco')}
                </Button>
              )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 30) && (
              <Button
                className={
                  rutaAntNoPatologicos === 'historia-sexual' ? classes.selected : classes.button
                }
                variant="contained"
                color="primary"
                startIcon={<HistoriaSexualIcon />}
                fullWidth
                onClick={() => dispatch(setRutaAntNoPatologicos('historia-sexual'))}
              >
                {t('antecedentes-pnp-historias')}
              </Button>
            )}
          </div>
        </>
      )}
      <div>
        {rutaAntNoPatologicos === 'vivienda' &&
          secciones.length > 0 &&
          secciones.find((valor) => valor.idSeccion === 23) && <Vivienda />}
        {rutaAntNoPatologicos === 'higiene' &&
          secciones.length > 0 &&
          secciones.find((valor) => valor.idSeccion === 24) && <Higiene />}
        {rutaAntNoPatologicos === 'alimentacion' &&
          secciones.length > 0 &&
          secciones.find((valor) => valor.idSeccion === 25) && <Alimentacion />}
        {rutaAntNoPatologicos === 'actividad-fisica' &&
          secciones.length > 0 &&
          secciones.find((valor) => valor.idSeccion === 26) && <ActividadFisica />}
        {rutaAntNoPatologicos === 'inmunizaciones' &&
          secciones.length > 0 &&
          secciones.find((valor) => valor.idSeccion === 27) && <Inmunizaciones isPlan={false} />}
        {rutaAntNoPatologicos === 'toxicomania' &&
          secciones.length > 0 &&
          secciones.find((valor) => valor.idSeccion === 28) && <Toxicomania />}
        {rutaAntNoPatologicos === 'gineco-obstetricos' &&
          secciones.length > 0 &&
          secciones.find((valor) => valor.idSeccion === 29) &&
          sexo === 'M' && <GinecoObstetricos />}
        {rutaAntNoPatologicos === 'historia-sexual' &&
          secciones.find((valor) => valor.idSeccion === 30) && <HistoriaSexual />}
      </div>
    </div>
  );
}

export default PersonalesNoPatologicos;
