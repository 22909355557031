import { ThunkDispatch } from 'redux-thunk';
import { ICatalogosEstandar } from '@common/types';
import { IDatosOrganizacion } from '@containers/AdmonInformacion/types';
import {
  getPerfilMedicoOrganizacion,
  getPerfilMedicoOrganizacionesSimple,
} from '@utils/getCatalogos';
import { setLoading } from '../loading/actions';
import {
  OrganizacionesActionTypes,
  RESET_ORGANIZACION,
  RESET_ORGANIZACIONES,
  SET_ORGANIZACION,
  SET_ORGANIZACION_PERMITIR_TOKENS_PACIENTES,
  SET_ORGANIZACIONES,
} from './types';
import { LoadingActionTypes } from '../loading/types';

export function setOrganizacion(organizacion: IDatosOrganizacion): OrganizacionesActionTypes {
  return {
    type: SET_ORGANIZACION,
    payload: organizacion,
  };
}

export function setOrganizacionPermitirTokensPacientes(
  permitir: boolean,
): OrganizacionesActionTypes {
  return {
    type: SET_ORGANIZACION_PERMITIR_TOKENS_PACIENTES,
    payload: permitir,
  };
}

export function setOrganizaciones(organizaciones: ICatalogosEstandar[]): OrganizacionesActionTypes {
  return {
    type: SET_ORGANIZACIONES,
    payload: organizaciones,
  };
}

export function resetOrganizacion(): OrganizacionesActionTypes {
  return {
    type: RESET_ORGANIZACION,
  };
}

export function resetOrganizaciones(): OrganizacionesActionTypes {
  return {
    type: RESET_ORGANIZACIONES,
  };
}

export const getOrganizaciones =
  (idMedico: number) =>
  (dispatch: ThunkDispatch<object, object, OrganizacionesActionTypes | LoadingActionTypes>) => {
    dispatch(setLoading(true));
    getPerfilMedicoOrganizacionesSimple(idMedico)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(setOrganizaciones(result.data));
        } else {
          dispatch(setOrganizaciones([]));
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setOrganizaciones([]));
        dispatch(setLoading(false));
      });
  };

const organizacionInitial = {
  idOrganizacion: -1,
  urlLogotipo: '',
  personalJuridica: '',
  nombreOrganizacion: '',
  razonSocial: '',
  rfc: '',
  cfdi: { id: 0, label: '' },
  email: '',
  telefono: '',
  extension: '',
  agee: null,
  agem: null,
  agel: null,
  agea: null,
  calle: '',
  num1: '',
  num2: '',
  cp: '',
  beneficioBrutoDiario: '',
  diasLaboralesAnio: '',
  relCostoBeneficio: '',
  presentismoTabaquismo: '',
  permitirTokensPacientes: false,
  tokenPacientesInternos: '',
  tokenPacientesExternos: '',
  organizacionSuperior: 0,
  isOxxo: false,
};

export const getOrganizacion =
  (idMedico: number, idOrganizacion: number) =>
  (dispatch: ThunkDispatch<object, object, OrganizacionesActionTypes | LoadingActionTypes>) => {
    dispatch(setLoading(true));
    getPerfilMedicoOrganizacion(idMedico, idOrganizacion)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(setOrganizacion(result.data));
        } else {
          setOrganizacion(organizacionInitial);
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        setOrganizacion(organizacionInitial);
        dispatch(setLoading(false));
      });
  };
