import { useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Badge,
  ListItemText,
} from '@material-ui/core';
import { MoveLeft, DoorOpen } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
// import { ReactComponent as RegresarConsulta } from '@assets/icons/Volver_a_consulta.svg';
// import { ReactComponent as FinalizarConsulta } from '@assets/icons/Finalizar_consulta.svg';
import { RootState } from 'src/store';
import { getConsultasObtenerConsultaAbierta } from '@utils/getCatalogos';
import { setSnackComplete } from '@actions/snackbar/types';
import { getPaciente, setPacienteID } from '@actions/basicosPaciente/actions';
import { setConsulta } from '@actions/consulta/actions';
import useStyles from './styles';

/** Prop recibidas del componente Navbar */
interface ISideBarProps {
  sidebarOpen: boolean;
  handleSidebarClose: () => void;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}

/** Regresa una sidebar (un componente drawer de Material UI) */
function SideBar(props: ISideBarProps) {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { idConsulta, idPaciente: idPacienteConsulta } = useSelector(
    (state: RootState) => state.Consulta,
  );
  const { idMedico, rol, secciones, carrera } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { tipoProceso } = useSelector((state: RootState) => state.Consulta);
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { sidebarOpen, handleSidebarClose, setOpenDialog } = props;
  const classes = useStyles();
  const theme = useTheme();

  const regresarConsulta = () => {
    if (idConsulta && idPacienteConsulta) {
      dispatch(setPacienteID(idPacienteConsulta));
      dispatch(getPaciente(idPacienteConsulta, idMedico, idOrganizacion, idConsultorio));
      history.push('/ece');
    }
  };

  useEffect(() => {
    if (idMedico > 0 && idConsultorio > 0 && rol.grupo === 'salud') {
      getConsultasObtenerConsultaAbierta(idMedico, idConsultorio)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            dispatch(
              setConsulta(result.data.idConsulta, result.data.idPaciente, result.data.tipoProceso, result.data.idProceso),
            );
          }
        })
        .catch((err) => {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        });
    }
  }, [idMedico, idConsultorio]);

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: sidebarOpen,
          [classes.drawerClose]: !sidebarOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: sidebarOpen,
            [classes.drawerClose]: !sidebarOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleSidebarClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <List>
          <ListItem
            button
            classes={{ button: classes.button }}
            className={pathname === '/home' ? classes.listItemHomeSelected : classes.listItemHome}
            onClick={() => history.push('/home')}
          >
            <ListItemIcon className={classes.iconWidth}>
              <HomeIcon className={pathname === '/home' ? 'text-white' : 'text-blue-800'} />
            </ListItemIcon>
            <ListItemText primary={t('home')} />
          </ListItem>
          {carrera.cedula && carrera.escuela && carrera.titulo && idConsultorio >= 1 && (
            <>
              {secciones.length > 0 &&
                secciones.find((valor) => valor.idSeccion >= 2 && valor.idSeccion <= 6) && (
                  <ListItem
                    button
                    classes={{ button: classes.button }}
                    className={
                      pathname === '/agenda'
                        ? classes.listItemAgendaSelected
                        : classes.listItemAgenda
                    }
                    onClick={() => history.push('/agenda')}
                  >
                    <ListItemIcon className={classes.iconWidth}>
                      <Badge badgeContent={0} color="primary">
                        <DateRangeIcon
                          className={pathname === '/agenda' ? 'text-white' : 'text-blue-800'}
                        />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary={t('agenda')} />
                  </ListItem>
                )}
              {secciones.length > 0 &&
                secciones.find((valor) => valor.idSeccion >= 64 && valor.idSeccion <= 66) && (
                  <ListItem
                    button
                    classes={{ button: classes.button }}
                    className={
                      pathname === '/pacientes'
                        ? classes.listItemPacienteSelected
                        : classes.listItemPaciente
                    }
                    onClick={() => history.push('/pacientes')}
                  >
                    <ListItemIcon className={classes.iconWidth}>
                      <PeopleAltIcon
                        className={pathname === '/pacientes' ? 'text-white' : 'text-blue-800'}
                      />
                    </ListItemIcon>
                    <ListItemText primary={t('pacientes')} />
                  </ListItem>
                )}
              {secciones.length > 0 &&
                secciones.find(
                  (valor) =>
                    valor.idSeccion === 61 || (valor.idSeccion >= 67 && valor.idSeccion <= 69),
                ) && (
                  <ListItem
                    classes={{ button: classes.button }}
                    button
                    className={
                      pathname === '/formatos-consulta' ||
                      pathname === '/builder' ||
                      pathname === '/personalizacion-formatos-consulta'
                        ? classes.listItemBuilderSelected
                        : classes.listItemBuilder
                    }
                    onClick={() => history.push('/formatos-consulta')}
                  >
                    <ListItemIcon className={classes.iconWidth}>
                      <DynamicFeedIcon
                        className={
                          pathname === '/formatos-consulta' ||
                          pathname === '/builder' ||
                          pathname === '/personalizacion-formatos-consulta'
                            ? 'text-white'
                            : 'text-blue-800'
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={t('formatos')} />
                  </ListItem>
                )}
              {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 62) && (
                <ListItem
                  classes={{ button: classes.button }}
                  button
                  className={classes.listItemRegresarConsulta}
                  disableGutters
                  onClick={regresarConsulta}
                  disabled={!idConsulta || !idPacienteConsulta}
                >
                  <ListItemIcon className="flex flex-col">
                    <MoveLeft className="ml-3" color="white" />
                    <span className="text-wrap w-12 text-white text-[8pt] font-light text-center">
                      {tipoProceso.length > 0 ? tipoProceso : t('consulta')}
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={t('regresar')} />
                </ListItem>
              )}
              {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 63) && (
                <ListItem
                  classes={{ button: classes.button }}
                  button
                  className={classes.listItemFinalizarConsulta}
                  disableGutters
                  disabled={!idConsulta || !idPacienteConsulta}
                  onClick={() => setOpenDialog(true)}
                >
                  <ListItemIcon className="flex flex-col">
                    <DoorOpen className="ml-3" color="white" />
                    <span className="text-wrap w-12 text-white text-[8pt] font-light text-center">
                      {t(`pacientes-finalizar-${tipoProceso.length > 0 ? tipoProceso : 'consulta'}`)}
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={t('finalizar')} />
                </ListItem>
              )}
            </>
          )}
        </List>
      </Drawer>
    </>
  );
}

export default SideBar;
