import { combineReducers } from 'redux';
import Auth from './Auth/auth';
import BasicosPacienteReducer from './DatosBasicosPaciente';
import RutasExpediente from './rutasExpediente';
import Paciente from './paciente';
import LoadingReducer from './LoadingReducer';
import LoginReducer from './LoginReducer';
import MeReducer from './MeReducer';
import SnackReducer from './SnackBar';
import Consulta from './consulta';
import Consultorios from './consultorios';
import Organizaciones from './organizaciones';
import DialogOpen from './DialogOpen';
import ScreenSize from './screenSize';

export const rootReducer = combineReducers({
  Organizaciones,
  Consultorios,
  Consulta,
  RutasExpediente,
  Me: MeReducer,
  BasicosPaciente: BasicosPacienteReducer,
  Paciente,
  Auth,
  Login: LoginReducer,
  Loading: LoadingReducer,
  SnackBar: SnackReducer,
  DialogOpen,
  ScreenSize,
});

export type RootState = ReturnType<typeof rootReducer>;
