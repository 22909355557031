import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactToPrint from 'react-to-print';
import { RootState } from 'src/store';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import { farmacologicoGuardarReceta } from '@utils/sendInfo';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { deleteTerapiaFarmacologicaMedicamento } from '@utils/deleteInfo';
import { setTratamientos } from '@actions/paciente/actions';
import FormularioReceta from './FormularioReceta';
import ListaMedicamentos from './ListaMedicamentos';
import Receta from './Receta';
import ModalEliminarMedicamentoReceta from './ListaMedicamentos/ModalEliminarMedicamentoReceta';
import {
  IListaRecetas,
  IMedicamento,
  ITerapiaFarmacologicaProps,
  medicamentoInitial,
} from './types';

function TerapiaFarmacologica({ enfermedadesActivas }: ITerapiaFarmacologicaProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formularioRef = useRef<HTMLDivElement>(null);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { tratamientos } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );

  const recetaRef = useRef(null);
  // abrir el modal para eliminar medicamento
  const [openMEMR, setOpenMEMR] = useState<boolean>(false);
  // abrir el modal para quitar medicamento
  const [openMQMR, setOpenMQMR] = useState<boolean>(false);
  // abrir le modal
  const [openModalEditar, setOpenModalEditar] = useState<boolean>(false);
  const [tratamientosActuales, setTratamientosActuales] = useState<IMedicamento[]>([]);
  const [recetas, setRecetas] = useState<IListaRecetas[]>([]);
  const [indiceReceta, setIndiceReceta] = useState<number>(0);
  const [idMedicamentoEliminar, setIDMedicamentoEliminar] = useState<number>(0);
  const [idMedicamentoQuitar, setIDMedicamentoQuitar] = useState<number>(0);
  const [modoEditar, setModoEditar] = useState<boolean>(false);
  const [toggleEditar, setToggleEditar] = useState<boolean>(false);
  const [medicamentoAEditar, setMedicamentoAEditar] = useState<IMedicamento>(medicamentoInitial);

  useEffect(() => {
    if (recetas.length === 0) {
      const tratamientosActuales: IMedicamento[] = [];
      const idsRecetas: number[] = [];
      tratamientos.forEach((tratamiento) => {
        if (tratamiento.actual === true) {
          tratamientosActuales.push(tratamiento);
          if (tratamiento.idReceta > 0 && !idsRecetas.includes(tratamiento.idReceta)) {
            idsRecetas.push(tratamiento.idReceta);
          }
        }
      });
      setTratamientosActuales(tratamientosActuales);
      setRecetas(
        idsRecetas.length > 0
          ? idsRecetas.map((idBD) => ({ idBD, actualizado: false }))
          : [{ idBD: -1, actualizado: true }],
      );
    } else {
      const idsActuales = tratamientosActuales.map((ta) => ta.id);
      setTratamientosActuales(
        tratamientos
          .filter((t) => t.actual === true)
          .map(
            (t) =>
              idsActuales.includes(t.id) ? t : { ...t, idReceta: recetas[indiceReceta].idBD }, // Si es nuevo se agrega automáticamente a la receta actual
          ),
      );
    }
  }, [tratamientos]);

  const handleMEMRDClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMEMR(false);
  };

  const handleMQMRDClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenMQMR(false);
  };

  const handleModalEditarClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenModalEditar(false);
  };

  const handleClickGuardarReceta = () => {
    dispatch(setLoading(true));
    // para guardar la receta, necesito los ids de los listaMedicamentosActuales, guardar la relacion
    const objRecetaGuardar = {
      tratamientosReceta: recetas.map((receta: IListaRecetas) => ({
        idBD: receta.idBD,
        listaMedicamentos: tratamientosActuales
          .filter((med: IMedicamento) => med.idReceta === receta.idBD)
          .map((med: IMedicamento) => med.id),
      })),
      // los de abajo pueden salir del array de recetas que está aqui arriba
      // cuando se tenga las consultas y las acciones paciente de ahi deben salir
      idConsulta,
      idMedico,
      idPaciente,
      idConsultorio,
    };
    farmacologicoGuardarReceta(objRecetaGuardar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          // para actualizar el id de la receta que nos llega en el back, lo unico que hacemos es darle
          // el id que nos regresa el back, antes se envio un array de recetas con listaMedicamentosActuales
          // ahora solo recibimos un arrays con los id de cada receta, entonces reemplazamos
          const newRecetas: IListaRecetas[] = [];
          const newTratamientos: IMedicamento[] = [...tratamientos];
          let newIdBD = -1;
          result.datos.forEach((idBD: number, indx: number) => {
            newRecetas.push({ idBD, actualizado: false });
            if (recetas[indx].idBD === -1) {
              newIdBD = idBD;
            }
          });
          tratamientosActuales.forEach((ta) => {
            newTratamientos[newTratamientos.findIndex((t) => t.id === ta.id)].idReceta =
              ta.idReceta === -1 ? newIdBD : ta.idReceta;
          });
          setRecetas(newRecetas);
          dispatch(setTratamientos(newTratamientos));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${result.msg}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error')} ${err.toString()}`,
          }),
        );
        dispatch(setLoading(true));
      });
  };

  // para eliminar el medicamento
  const eliminarMedicamento = () => {
    dispatch(setLoading(true));
    const objEnviar = {
      idMedicamento: idMedicamentoEliminar,
      idMedico,
    };
    setOpenMEMR(false);
    deleteTerapiaFarmacologicaMedicamento(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          // si recibimos el código correcto del back
          // tenemos que actualizar la receta del medicamento borrado
          setRecetas((prev) =>
            prev.map((receta: IListaRecetas, inx: number) =>
              inx === indiceReceta ? { ...receta, actualizado: true } : receta,
            ),
          );
          // tenemos que filtar de la lista de medicamentos el medicamento borrado
          dispatch(setTratamientos(tratamientos.filter((ma) => ma.id !== idMedicamentoEliminar)));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-delete-success'),
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-delete')} ${result.msg}`,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-delete')} ${err.toString()}`,
          }),
        );
      });
  };

  // para quitar el medicamento de la lista
  const quitarMedicamento = () => {
    setRecetas(
      recetas.map((receta, index) =>
        index === indiceReceta ? { ...receta, actualizado: true } : receta,
      ),
    );
    setTratamientosActuales((prev) =>
      prev.map((med) => (med.id === idMedicamentoQuitar ? { ...med, idReceta: 0 } : med)),
    );
    setOpenMQMR(false);
  };

  return (
    <div>
      <ModalEliminarMedicamentoReceta
        open={openMEMR}
        titulo={t('_atencion_')}
        descripcion={t('_seguro_que_deseas_eliminar_el_tratamiento_')}
        borrarMedicamentoRecetaActual={eliminarMedicamento}
        callBackClose={handleMEMRDClose}
      />
      <ModalEliminarMedicamentoReceta
        open={openMQMR}
        titulo={t('_atencion_')}
        descripcion={t('_seguro_que_deseas_quitar_el_tratamiento_')}
        borrarMedicamentoRecetaActual={quitarMedicamento}
        callBackClose={handleMQMRDClose}
      />
      <ModalEliminarMedicamentoReceta
        open={openModalEditar}
        titulo={t('_atencion_')}
        descripcion={t('si_vas_a_agregar_un_nuevo_medicamento_se_borrara_el_que_estas_creando')}
        borrarMedicamentoRecetaActual={() => {
          setModoEditar(true);
          setOpenModalEditar(false);
          setToggleEditar((prevVal) => !prevVal);
        }}
        callBackClose={handleModalEditarClose}
      />
      <h3 className="text-blue-500 font-medium my-4">{t('terapia-farmacologica')}</h3>
      <FormularioReceta
        tratamientosActuales={tratamientosActuales}
        indiceReceta={indiceReceta}
        recetas={recetas}
        setRecetas={setRecetas}
        listaEnfermedadesActivas={enfermedadesActivas}
        modoEditar={modoEditar}
        setModoEditar={setModoEditar}
        medicamentoAEditar={medicamentoAEditar}
        toggleEditar={toggleEditar}
        formularioRef={formularioRef}
      />
      <DndProvider backend={HTML5Backend}>
        <ListaMedicamentos
          tratamientosActuales={tratamientosActuales}
          setTratamientosActuales={setTratamientosActuales}
          recetas={recetas}
          setRecetas={setRecetas}
          indiceReceta={indiceReceta}
          setIndiceReceta={setIndiceReceta}
          setOpenMEMR={setOpenMEMR}
          setIDMedicamentoEliminar={setIDMedicamentoEliminar}
          setOpenMQMR={setOpenMQMR}
          setIDMedicamentoQuitar={setIDMedicamentoQuitar}
          setOpenModalEditar={setOpenModalEditar}
          setMedicamentoAEditar={setMedicamentoAEditar}
          formularioRef={formularioRef}
        />
      </DndProvider>
      <div className="flex justify-end items-center my-2">
        <div className="mr-4">
          <ReactToPrint
            trigger={() => (
              <Button
                color="primary"
                size="small"
                startIcon={<PrintIcon />}
                disabled={recetas[indiceReceta]?.actualizado}
              >
                {t('imprimir_receta')}
              </Button>
            )}
            content={() => recetaRef.current}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickGuardarReceta}
            disabled={
              !recetas[indiceReceta]?.actualizado ||
              !tratamientosActuales.filter(
                (med: IMedicamento) => med.idReceta === recetas[indiceReceta]?.idBD,
              ).length
            }
            startIcon={<SaveIcon />}
          >
            {t('guardar_recetas')}
          </Button>
        </div>
      </div>
      <div className="hidden">
        <Receta
          listaMedicamentos={tratamientosActuales.filter(
            (med: IMedicamento) => med.idReceta === recetas[indiceReceta]?.idBD,
          )}
          folio={recetas[indiceReceta]?.idBD || 0}
          ref={recetaRef}
        />
      </div>
    </div>
  );
}

export default TerapiaFarmacologica;
