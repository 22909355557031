import {
  IOrganizaciones,
  OrganizacionesActionTypes,
  SET_ORGANIZACIONES,
  SET_ORGANIZACION,
  SET_ORGANIZACION_PERMITIR_TOKENS_PACIENTES,
  RESET_ORGANIZACION,
  RESET_ORGANIZACIONES,
} from '@actions/organizaciones/types';

const initialState = {
  organizaciones: [],
  organizacion: {
    idOrganizacion: -1,
    urlLogotipo: '',
    personalJuridica: '',
    nombreOrganizacion: '',
    razonSocial: '',
    rfc: '',
    cfdi: { id: 0, label: '' },
    email: '',
    telefono: '',
    extension: '',
    agee: null,
    agem: null,
    agel: null,
    agea: null,
    calle: '',
    num1: '',
    num2: '',
    cp: '',
    beneficioBrutoDiario: '',
    diasLaboralesAnio: '',
    relCostoBeneficio: '',
    presentismoTabaquismo: '',
    permitirTokensPacientes: false,
    tokenPacientesInternos: '',
    tokenPacientesExternos: '',
    organizacionSuperior: 0,
  },
} as IOrganizaciones;

// eslint-disable-next-line @typescript-eslint/default-param-last
function Organizaciones(state = initialState, action: OrganizacionesActionTypes) {
  switch (action.type) {
    case SET_ORGANIZACIONES:
      return {
        ...state,
        organizaciones: action.payload,
      };
    case SET_ORGANIZACION:
      return {
        ...state,
        organizacion: action.payload,
      };
    case SET_ORGANIZACION_PERMITIR_TOKENS_PACIENTES:
      return {
        ...state,
        permitirTokensPacientes: action.payload,
      };
    case RESET_ORGANIZACION:
      return {
        ...state,
        organizacion: initialState.organizacion,
      };
    case RESET_ORGANIZACIONES:
      return {
        ...state,
        organizaciones: initialState.organizaciones,
      };
    default:
      return state;
  }
}

export default Organizaciones;
