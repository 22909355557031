/* eslint-disable @typescript-eslint/no-unused-vars */
import { RootState } from 'src/store';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import CallMade from '@material-ui/icons/CallMade';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import { sendFormatoConsultaActualizar } from '@utils/editInfo';
import { ICatalogosEstandar } from '@common/types';
import { getCatalogoCategoriasMC, getCatalogoRangoEdadMC } from '@utils/getCatalogos';
import { setLoading } from '@actions/loading/actions';
import { setSnackComplete } from '@actions/snackbar/types';
import { removePaciente, removeUserSession } from '@utils/commonStore';
import { limpiarReduxSesion, limpiarReduxPaciente } from '@utils/reduxCommon';
import DeleteDialog from '@components/DeleteDialog';
import { ICompUnico } from '../../PersonalizacionFC/SeleccionVariablesMC/TodasLasVariables/types';
import NotasAdicionales from '../../PersonalizacionFC/NotasAdicionales';
import PadecimientoActualMC from '../../PersonalizacionFC/PadecimientoActualMC';
import VisualizacionMC from '../../PersonalizacionFC/VisualizacionMC';
import SeleccionVariablesMC from '../../PersonalizacionFC/SeleccionVariablesMC';
import Builder from '../../Builder';
import PaperInstrucciones from '../../PersonalizacionFC/PaperInstrucciones';
import InfoMotivoConsulta from '../../PersonalizacionFC/InfoMotivoConsulta';
import { IEditarFMCProps } from './types';

function EditarFMC({
  motivoConsulta,
  setModoEditar,
  modoEditar,
  handleCompartir,
}: IEditarFMCProps) {
  const {
    idFormato,
    categoria,
    nombreFormato,
    rangoEdad,
    sexo,
    tipoConsulta,
    componentes,
    opciones,
    compartido,
  } = motivoConsulta;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { idMedico, enOrganizacion } = useSelector((state: RootState) => state.Me);
  /** States del functional component */
  const [idFC, setIdFC] = useState<number>(idFormato);
  const [rangoEdadMC, setRangoEdadMC] = useState<number>(rangoEdad.id);
  const [categoriaMC, setCategoriaMC] = useState<number>(categoria.id);
  const [nombreDelMC, setNombreDelMC] = useState<string>(nombreFormato);
  const [sexoParaElQueAplica, setSexoParaElQueAplica] = useState<number>(sexo.id);
  const [tipoDeMC, setTipoDeMC] = useState<number>(tipoConsulta.id);
  const [comentariosExtra, setComentariosExtra] = useState<string>('');
  const [incluirSeccionComentarios, setIncluirSeccionComentarios] = useState<boolean>(
    opciones.notasAdicionales.incluido,
  );
  const [incluirSeccionPatologiasRelacionadas] = useState<boolean>(false);
  const [arrComponentes, setArrComponentes] = useState<ICompUnico[]>(componentes);
  const [builderShow, setBuilderShow] = useState<boolean>(false);
  const [compartirFormato, setCompartirFormato] = useState<boolean>(false);
  const [disableCompartirFormato, setDisableCompartirFormato] = useState<boolean>(true);
  /** state de los checkbox cuando se seleccionan */
  const [objChecked, setObjChecked] = useState<any>({});
  /** states para expandir el texto del child */
  /** States de comprobacion de fields */
  /** states de componentes que se deshabilitan */
  /** states de la snackbar */
  /** Catalogos */
  const [catCategoriaMC, setCatCategoriaMC] = useState<Array<ICatalogosEstandar>>([]);
  const [catRangoEdadMC, setCatRangoEdadMC] = useState<Array<ICatalogosEstandar>>([]);
  /** Logica */
  const verificarErrorValidacionGeneralFormato = () => {
    if (
      nombreDelMC &&
      categoriaMC &&
      rangoEdadMC &&
      sexoParaElQueAplica &&
      tipoDeMC &&
      arrComponentes.length
    ) {
      return false;
    }
    return true;
  };
  /** Hooks */
  useEffect(() => {
    setDisableCompartirFormato(componentes.length === 0);
  }, [componentes]);
  useEffect(() => {
    getCatalogoCategoriasMC().then((result: ICatalogosEstandar[]) => {
      setCatCategoriaMC(result);
    });
    getCatalogoRangoEdadMC().then((result: ICatalogosEstandar[]) => {
      setCatRangoEdadMC(result);
    });
  }, []);
  /** Efecto al cargar la pagina */
  /** states para mostrar o no diferentes campos */
  /** handlers de desabilitacion o no de otros campos */
  /** Handlers del componente */
  /** handles de disabled components */
  /** handler del evento submit */
  const actualizarFormatoConsulta = () => {
    dispatch(setLoading(true));
    const datosUpdateFormato = {
      idMedico,
      idFormato: idFC,
      motivoNombre: nombreDelMC,
      motivoCategoria: categoriaMC,
      motivoRango: rangoEdadMC,
      motivoSexo: sexoParaElQueAplica,
      motivoConsulta: tipoDeMC,
      publico: true,
      constructor: arrComponentes,
      comentarios: incluirSeccionComentarios,
    };
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('message-info'),
      }),
    );
    sendFormatoConsultaActualizar(datosUpdateFormato)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          setDisableCompartirFormato(false);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          limpiarReduxSesion();
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarReduxSesion();
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarReduxPaciente();
          history.push('/pacientes');
        } else if (result.code === 305) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t(result.msg),
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'info',
              mensaje: t('message-get-error'),
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  /** Handlers para enviar la informacion */
  const crearNuevaVariable = () => {
    setBuilderShow(true);
  };

  if (builderShow) {
    return (
      <Builder
        index={1}
        value={1}
        regresar={setBuilderShow}
        nombreDelMC={nombreDelMC}
        setArrComponentes={setArrComponentes}
      />
    );
  }
  // falta enviar el catalogo de sexo y el catalogo de motivo de consulta
  return (
    <>
      <DeleteDialog
        open={compartirFormato}
        titulo={compartido ? t('descompartir_formato') : t('compartir_formato')}
        descripcion={
          compartido ? t('confirmar_descompartir_formato') : t('confirmar_compartir_formato')
        }
        callBackAceptar={() => handleCompartir(idFC)}
        callBackClose={() => setCompartirFormato(false)}
      />
      <div className="p-4">
        <PaperInstrucciones modoEditar={modoEditar} setModoEditar={setModoEditar} />
        <div className="mb-6" />
        <InfoMotivoConsulta
          nombreDelMC={nombreDelMC}
          rangoEdad={rangoEdadMC}
          categoria={categoriaMC}
          catCategoriaMC={catCategoriaMC}
          catRangoEdadMC={catRangoEdadMC}
          setNombreDelMC={setNombreDelMC}
          setRangoEdad={setRangoEdadMC}
          setCategoria={setCategoriaMC}
          sexoParaElQueAplica={sexoParaElQueAplica}
          setSexoParaElQueAplica={setSexoParaElQueAplica}
          tipoDeMC={tipoDeMC}
          setTipoDeMC={setTipoDeMC}
          paso={0}
        />

        <div className="mb-4" />
        <SeleccionVariablesMC
          nombreDelMC={nombreDelMC}
          arrComponentes={arrComponentes}
          setArrComponentes={setArrComponentes}
          objChecked={objChecked}
          setObjChecked={setObjChecked}
          crearVariable={crearNuevaVariable}
        />
        <div className="mb-4" />
        <VisualizacionMC
          nombreDelMC={nombreDelMC}
          arrComponentes={arrComponentes}
          setArrComponentes={setArrComponentes}
        />
        <div className="mb-4" />
        <NotasAdicionales
          comentariosExtra={comentariosExtra}
          setComentariosExtra={setComentariosExtra}
          incluirSeccionComentarios={incluirSeccionComentarios}
          setIncluirSeccionComentarios={setIncluirSeccionComentarios}
        />
        {/* <PatologiasRelacionadas
              incluirSeccionPatologiasRelacionadas={incluirSeccionPatologiasRelacionadas}
              setIncluirSeccionPatologiasRelacionadas={setIncluirSeccionPAtologiasRelacionadas}
            /> */}
        <div className="mb-4" />
        <PadecimientoActualMC
          nombreDelMC={nombreDelMC}
          arrComponentes={arrComponentes}
          setArrComponentes={setArrComponentes}
        />
        <div className="mb-4" />
        <div className="flex justify-end items-end">
          <div>
            <p className="mb-2">
              *&nbsp;
              {t('campos_obligatorios')}
            </p>
            {enOrganizacion && (
              <HtmlTooltip title={compartido ? t('formato_compartido') : t('compartir_formato')}>
                <Button
                  color="default"
                  disabled={disableCompartirFormato}
                  onClick={() => setCompartirFormato(true)}
                  startIcon={
                    <CallMade className={compartido ? 'text-blue-900' : 'text-gray-400 '} />
                  }
                >
                  {t('compartir_formato')}
                </Button>
              </HtmlTooltip>
            )}
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={verificarErrorValidacionGeneralFormato()}
              size="large"
              onClick={actualizarFormatoConsulta}
              startIcon={<SyncIcon />}
            >
              {t('editar_formato')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditarFMC;
