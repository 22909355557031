import { IScreenSize, SET_SIZE, ScreenSizeActionTypes } from '@actions/screenSize/types';

const initialState: IScreenSize = {
  width: 0,
  height: 0,
  size: 'md',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function ScreenSize(state = initialState, action: ScreenSizeActionTypes) {
  switch (action.type) {
    case SET_SIZE:
      return {
        width: action.payload.width,
        height: action.payload.height,
        size: action.payload.size,
      };
    default:
      return state;
  }
}

export default ScreenSize;
