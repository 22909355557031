import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, XIcon } from 'lucide-react';
import { DateInputProps } from './type';
import { IFechaObj } from '@common/types';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { validarFechaEsAnterior } from '@common/functions';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { CircleX, CircleCheckBig } from 'lucide-react';

export const DateInput: FC<DateInputProps> = ({
  index,
  containerClass = '',
  label: externalLabel,
  isRequired = false,
  name,
  setValue,
  value,
  validacion,
  untilToday = false,
  helperText,
  disabled = false,
  yearsMore = 0,
  onValidationChange,
  hideValidationIcon = false,
}) => {
  const { size } = useSelector((state: RootState) => state.ScreenSize);
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const currentDate = new Date();
  const [openDay, setOpenDay] = useState(false);
  const [openMonth, setOpenMonth] = useState(false);
  const [openYear, setOpenYear] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isWideScreen = useBreakPoint();
  const label = externalLabel === undefined ? t(name) : externalLabel;
  const days = [...Array(31)].map((x, i) => ({ value: String(i + 1), label: String(i + 1) }));
  const months = [...Array(12)].map((x, i) => ({ value: String(i + 1), label: t(`mes${i + 1}`) }));
  const years = [...Array(101)].map((x, i) => ({
    value: String(currentDate.getFullYear() - i + yearsMore),
    label: String(currentDate.getFullYear() - i + yearsMore),
  }));

  useEffect(() => {
    const isMissing = isRequired && (!value.dia.length || !value.mes.length || !value.anio.length);
    onValidationChange?.({
      name,
      hasError: errorMessage.length > 0 || isMissing,
      isMissing,
      index,
    });
  }, [isRequired, value, errorMessage]);

  useEffect(() => {
    // if (isRequired && (!value.dia.length || !value.mes.length || !value.anio.length)) {
    //   setErrorMessage(t('error_campo_en_blanco'));
    // }
    if (
      untilToday &&
      value.anio.length &&
      value.mes.length &&
      value.dia.length &&
      !validarFechaEsAnterior(value.anio, value.mes, value.dia)
    ) {
      setErrorMessage(t('fecha_invalida'));
    } else if (validacion) {
      setErrorMessage(validacion(value.dia, value.mes, value.anio));
    } else {
      setErrorMessage('');
    }
  }, [value.anio, value.mes, value.dia]);

  const handleDateChange = (type: 'day' | 'month' | 'year', newValue: string) => {
    const defaultDate: IFechaObj = { dia: '', mes: '', anio: '' };
    switch (type) {
      case 'day':
        setValue({ name, value: { ...(value || defaultDate), dia: newValue || '' } });
        setOpenDay(false);
        break;
      case 'month':
        setValue({ name, value: { ...(value || defaultDate), mes: newValue || '' } });
        setOpenMonth(false);
        break;
      case 'year':
        setValue({ name, value: { ...(value || defaultDate), anio: newValue || '' } });
        setOpenYear(false);
        break;
      default:
        break;
    }
  };

  const CustomSelect = ({
    value: val,
    onChange,
    options,
    floatingLabel,
    isOpen,
    setIsOpen,
    isDisabled,
    width,
  }: {
    value: string;
    onChange: (value: string) => void;
    options: string[] | typeof months;
    floatingLabel: string;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    isDisabled: boolean;
    width: number;
  }) => {
    const [wasFocused, setWasFocused] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [inputPosition, setInputPosition] = useState({ top: 0, left: 0 });

    const handleToggleDropdown = () => {
      if (!disabled) {
        if (!wasFocused) {
          setWasFocused(true);
        }
        setIsOpen(!isOpen);
      }
    };

    const handleHover = (event: MouseEvent) => {
      setIsHover(!!dropdownRef.current?.contains(event.target as Node));
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current?.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [wasFocused]);

    useEffect(() => {
      document.addEventListener('mouseover', handleHover);
      return () => document.removeEventListener('mouseover', handleHover);
    }, [isHover]);

    const updateDropdownPosition = () => {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        // Ajusta esto según el tamaño del dropdown
        const dropdownHeight = 248;
        const spaceBelow = window.innerHeight - rect.bottom;

        const top = spaceBelow < dropdownHeight ? rect.top - dropdownHeight : rect.bottom;
        setInputPosition({ top, left: rect.left });
      }
    };

    useEffect(() => {
      if (isWideScreen && isOpen) {
        updateDropdownPosition(); // Calcular posición cuando se abre
        window.addEventListener('scroll', updateDropdownPosition);
        window.addEventListener('resize', updateDropdownPosition);
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        window.removeEventListener('scroll', updateDropdownPosition);
        window.removeEventListener('resize', updateDropdownPosition);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen, wasFocused]);

    return (
      <div className={`min-w-${width} w-full relative`} ref={dropdownRef}>
        <label
          onClick={handleToggleDropdown}
          className={`absolute left-3 bg-white rounded-3xl px-1 cursor-pointer ${
            isOpen || !!val?.length
              ? 'text-gray-600 -top-2 text-xs'
              : 'text-gray-400 top-[12px] text-sm'
          } transition-all bg-[linear-gradient(to_top,rgba(255,255,255,1)_60%,rgba(255,255,255,0)_100%)]`}
        >
          {floatingLabel}
        </label>
        <div
          className={`w-full h-[42px] px-2 border rounded-md flex justify-between items-center 
            ${
              isDisabled
                ? 'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed'
                : `${
                    esPaciente && val.length > 0 ? 'border-green-300' : 'border-gray-300'
                  } bg-white cursor-pointer`
            }`}
          onClick={handleToggleDropdown}
        >
          <div className="w-full" onClick={handleToggleDropdown}>
            <span className={`px-1 ${!val?.length ? 'text-base text-gray-400' : ''}`}>
              {
                (options as { value: string; label: string }[]).find(
                  (option) => option.value === val,
                )?.label
              }
            </span>
          </div>
          <div className="flex flex-row">
            {val?.length && !disabled && isHover && size !== 'sm' ? (
              <XIcon
                size={18}
                onClick={() => {
                  if (!isDisabled) {
                    onChange('');
                  }
                }}
                className="hover:bg-gray-100 rounded-full"
              />
            ) : null}
            <ChevronDown
              size={20}
              className={`transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}
              hover:bg-gray-100 rounded-full`}
              onClick={handleToggleDropdown}
            />
          </div>
          {esPaciente && !hideValidationIcon && (
            <div className="w-4 h-4 absolute bg-white -right-[6px] -top-[6px] rounded-xl">
              {val.length > 0 ? (
                <CircleCheckBig size={15} color="green" />
              ) : (
                <CircleX size={15} color="gray" />
              )}
            </div>
          )}
        </div>

        {isOpen && !isDisabled && (
          <div
            className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto"
            style={
              isWideScreen
                ? {
                    position: 'fixed',
                    top: inputPosition.top,
                    left: inputPosition.left,
                    width: dropdownRef.current?.getBoundingClientRect().width,
                  }
                : {
                    position: 'absolute',
                    width: dropdownRef.current?.getBoundingClientRect().width,
                  }
            }
          >
            {(options as { value: string; label: string }[]).map((option) => (
              <div
                key={Number(option.value)}
                className={`py-2 px-4 cursor-pointer hover:bg-gray-100 text-base
                  ${option.value.toString() === val.toString() ? 'bg-blue-50 text-blue-600' : ''}`}
                onClick={() => onChange(option.value.toString() || '')}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`w-full h-auto my-[10px] ${containerClass}`}>
      {label && (
        <label className="block font-medium text-base text-gray-700 mb-4">
          {label}
          {isRequired && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      <div className="flex gap-2">
        <CustomSelect
          value={value.dia || ''}
          onChange={(newValue) => handleDateChange('day', newValue)}
          options={days}
          floatingLabel={t('dia')}
          isDisabled={disabled}
          isOpen={openDay}
          setIsOpen={setOpenDay}
          width={16}
        />
        <CustomSelect
          value={value.mes || ''}
          onChange={(newValue) => handleDateChange('month', newValue)}
          options={months}
          floatingLabel={t('mes')}
          isDisabled={disabled}
          isOpen={openMonth}
          setIsOpen={setOpenMonth}
          width={32}
        />
        <CustomSelect
          value={value.anio || ''}
          onChange={(newValue) => handleDateChange('year', newValue)}
          options={years}
          floatingLabel={t('anio')}
          isDisabled={disabled}
          isOpen={openYear}
          setIsOpen={setOpenYear}
          width={20}
        />
      </div>
      {(errorMessage || helperText) && (
        <span className={`pt-1 text-xs ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
          {errorMessage || helperText}
        </span>
      )}
    </div>
  );
};

export default DateInput;
