import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { Button, Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { RootState } from 'src/store';
import {
  // getTableroPacienteEvaluacionMedidas,
  // getTableroPacienteEvaluacionMedidasUsuario,
  getColesterolUsuarioPaciente,
  getMedicinaPreventivaTableroUsuarioPaciente,
  // getAntecedenteLaboralEmpresa,
  // getAntecedenteLaboralEmpresaUsuarioPaciente,
  getDataTablaGloborisk,
  getDataTablaGloboriskUsuario,
  getAntecedentesLaboralesUsuarioPaciente,
} from '@utils/getCatalogos';
import { useApiRequest } from '@hooks/useApiRequest';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// import EvaluacionMedidas from '../DiagnosticoPlan/Plan/MedicinaPreventiva/EvaluacionMedidas';
// import VerDetalleMedicamento from '../DiagnosticoPlan/Plan/TerapiaFarmacologica/ListaMedicamentos/VerDetalleMedicamento';
import DeteccionTempranaCancer from '../DiagnosticoPlan/Plan/MedicinaPreventiva/DeteccionTempranaCancer';
import AneurismaAortaAbdominal from '../DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionCardiovascular/AneurismaAortaAbdominal';
import PrevencionCardiovascularDiv from '../DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionCardiovascular/PrevencionCardiovascular';
import DeteccionCancerCervicalDiv from '../DiagnosticoPlan/Plan/MedicinaPreventiva/DeteccionTempranaCancer/DeteccionCancerCervical';
import ListaAntecedentesLaborales from '../Antecedentes/Laborales/FormAntecedentesLaborales/ListaAntecedentesLaborales';
import {
  // IObjEvaluacionMedidas,
  // IObjPresionArterial,
  IObjExamenColesterol,
  IObjMedicinaPreventiva,
  IObjAntecedentesLaborales,
  IObjTabla,
} from './types';
import PrevencionAuditiva from '../DiagnosticoPlan/Plan/MedicinaPreventiva/PrevencionRiesgos/PrevencionAuditiva';
import ExamenMedicoPeriodico from '../DiagnosticoPlan/Plan/MedicinaPreventiva/ExamenMedicoPeriodico';
import {
  // objEvaluacionMedidasInitial,
  objExamenColesterolInitial,
  // objPresionArterialInitial,
} from './mockDatosInitial';
import { IAntecedenteLaboral } from '../Antecedentes/Laborales/types';

dayjs.locale('es');

const ResumenDeSalud = () => {
  const { apiRequest, apiRequests } = useApiRequest();
  const { idMedico, esPaciente, validar } = useSelector((state: RootState) => state.Me);
  const { idPaciente, idUsuario, docId } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const { diagnosticosGenerales } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const { t } = useTranslation('translation-patient');
  // const [fuenteMedidas, setFuenteMedidas] = useState<'expediente' | 'usuario'>('expediente');
  // const [medidasExpediente, setMedidasExpediente] = useState<boolean>(false);
  // const [medidasUsuario, setMedidasUsuario] = useState<boolean>(false);
  // const [fechaMedidas, setFechaMedidas] = useState<string>('');
  // const [fechaMedidasExpediente, setFechaMedidasExpediente] = useState<string>('');
  // const [fechaMedidasUsuario, setFechaMedidasUsuario] = useState<string>('');
  // const [evaluacionMedidas, setEvaluacionMedidas] = useState<IObjEvaluacionMedidas>(
  //   objEvaluacionMedidasInitial,
  // );
  // const [evaluacionMedidasExpediente, setEvaluacionMedidasExpediente] =
  //   useState<IObjEvaluacionMedidas>(objEvaluacionMedidasInitial);
  // const [evaluacionMedidasUsuario, setEvaluacionMedidasUsuario] = useState<IObjEvaluacionMedidas>(
  //   objEvaluacionMedidasInitial,
  // );
  // const [presionArterial, setPresionArterial] =
  //   useState<IObjPresionArterial>(objPresionArterialInitial);
  // const [presionArterialExpediente, setPresionArterialExpediente] =
  //   useState<IObjPresionArterial>(objPresionArterialInitial);
  // const [presionArterialUsuario, setPresionArterialUsuario] =
  //   useState<IObjPresionArterial>(objPresionArterialInitial);
  const [examenColesterol, setExamenColesterol] = useState<IObjExamenColesterol>(
    objExamenColesterolInitial,
  );
  const [arrayDiagnosticos, setArrayDiagnosticos] = useState<IObjTabla[]>([]);
  const [arrayMedicinaPreventiva, setArrayMedicinaPreventiva] = useState<IObjMedicinaPreventiva[]>(
    [],
  );
  const [arrayAntecedentesLaborales, setArrayAntecedentesLaborales] = useState<
    IObjAntecedentesLaborales[]
  >([]);
  const [arrayTratamientos, setArrayTratamientos] = useState<IObjTabla[]>([]);
  const [viewTabCardiovascular, setViewTabCardiovascular] = useState<boolean>(false);
  // const [openDetalle, setOpenDetalle] = useState<boolean>(false);
  const [dataToSetMedicina, setDataToSetMedicina] = useState<IObjMedicinaPreventiva | undefined>(
    undefined,
  );
  const [numberDataToSetMedicina, setNumberDataToSetMedicina] = useState<number>(-1);
  const [tableGloborisk, setTableGloborisk] = useState<any[]>([]);
  const [valAntecedenteLaboral, setValAntecedenteLaboral] = useState<
    IAntecedenteLaboral | undefined
  >(undefined);
  const isWideScreen = useBreakPoint();

  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  useEffect(() => {
    // Se evalúa si docId tiene valor para validar si ya se cargaron los datos del paciente
    // No se valida solo el idPaciente porque ese se asigna manualmente, pero el idUsuario viene de la consulta de los datos
    // Luego se valida si no es paciente o si es un paciente ya validado
    if (docId && (!esPaciente || !validar)) {
      apiRequests({
        type: 'get',
        requestFunctions: [
          getMedicinaPreventivaTableroUsuarioPaciente(idMedico, idUsuario), // 1. Medicina preventiva
          getColesterolUsuarioPaciente(idMedico), // 2. Colesterol
          getAntecedentesLaboralesUsuarioPaciente(idMedico), // 3. Antecedentes laborales
        ],
        successFunctions: [
          (result: any) => setArrayMedicinaPreventiva(result.datos), // 1. Medicina preventiva
          (result: any) => {
            // 2. Colesterol
            setExamenColesterol({
              ...examenColesterol,
              anio: result.datos.anio || '',
              dia: result.datos.dia || '',
              mes: result.datos.mes || '',
              valor: result.datos.valor || '',
            });
          },
          (result: any) => setArrayAntecedentesLaborales(result.datos), // 3. Antecedentes laborales
        ],
        showMsgError: false,
      });
    }
  }, [docId]);
  useEffect(() => {
    if (arrayDiagnosticos.length === 0) {
      const diagnosticos: IObjTabla[] = [];
      let tratamientos: IObjTabla[] = [];
      const exclude = [
        'cirugia-abdominal',
        'cirugia-columna',
        'faltan-dedos-ext',
        'pension-enf-acc-ces',
        'incapacidad',
      ];
      diagnosticosGenerales.contenidoTabla.forEach((diagnostico) => {
        if (diagnostico.posee === 'si' && !exclude.includes(diagnostico.nombre)) {
          diagnosticos.push({ nombre: t(`resumen-salud-diagnosticos-g-${diagnostico.nombre}`) });
          if (diagnostico.medicamentos.length > 0) {
            tratamientos = [
              ...tratamientos,
              ...diagnostico.medicamentos.map((nombre) => ({ nombre })),
            ];
          }
        }
      });
      setArrayDiagnosticos(diagnosticos);
      setArrayTratamientos(tratamientos);
    }
  }, [diagnosticosGenerales]);
  // useEffect(() => {
  //   setFechaMedidas(fuenteMedidas === 'expediente' ? fechaMedidasExpediente : fechaMedidasUsuario);
  //   setEvaluacionMedidas(
  //     fuenteMedidas === 'expediente' ? evaluacionMedidasExpediente : evaluacionMedidasUsuario,
  //   );
  //   setPresionArterial(
  //     fuenteMedidas === 'expediente' ? presionArterialExpediente : presionArterialUsuario,
  //   );
  // }, [fuenteMedidas]);
  useEffect(() => {
    if (
      numberDataToSetMedicina === 2 &&
      dataToSetMedicina &&
      [
        'riesgo_cardiovascular',
        'riesgo_cardiovascular_s_colesterol',
        'riesgo_cardiovascular_usuario',
      ].includes(dataToSetMedicina.tipo)
    ) {
      apiRequest({
        type: 'get',
        requestFunction: esPaciente
          ? getDataTablaGloboriskUsuario(
              dataToSetMedicina?.sexo || 0,
              dataToSetMedicina?.consumoTabaco || 0,
              dataToSetMedicina?.rangoEdad || 0,
              idPaciente,
            )
          : getDataTablaGloborisk(
              dataToSetMedicina?.sexo || 0,
              dataToSetMedicina?.esDiabetico || 0,
              dataToSetMedicina?.consumoTabaco || 0,
              dataToSetMedicina?.rangoEdad || 0,
              !['riesgo_cardiovascular_s_colesterol', 'riesgo_cardiovascular_usuario'].includes(
                dataToSetMedicina.tipo,
              ),
              idMedico,
              idPaciente,
              idConsultorio,
            ),
        successFunction: (result: any) => {
          if (result.datos && result.datos.length > 0) {
            setTableGloborisk(result.datos);
          }
        },
        catchFunction: () => setTableGloborisk([]),
        showMsgs: false,
      });
    }
  }, [dataToSetMedicina, numberDataToSetMedicina]);
  // const handleClickMedicionesArrows = () =>
  //   setFuenteMedidas(fuenteMedidas === 'expediente' ? 'usuario' : 'expediente');

  const tables: {
    name:
      | 'medicina_preventiva'
      | 'business_diagnosticos'
      | 'tratamientos'
      | 'antecedentes_laborales_ant';
    data: IObjTabla[];
    tipo: number;
  }[] = [
    {
      name: 'medicina_preventiva',
      data: arrayMedicinaPreventiva.map((riesgo: any) => ({
        nombre: t(riesgo.tipo),
        riesgo: t(riesgo.oportunidad),
      })),
      tipo: 2,
    },
    {
      name: 'business_diagnosticos',
      data: arrayDiagnosticos,
      tipo: 1,
    },
    {
      name: 'tratamientos',
      data: arrayTratamientos,
      tipo: 4,
    },
    {
      name: 'antecedentes_laborales_ant',
      data: arrayAntecedentesLaborales.map((ant: any) => ({
        nombre: ant.empresa,
        riesgo: t(ant.riesgo),
      })),
      tipo: 3,
    },
  ];

  return (
    <div
      className={`p-4 bg-white w-full ${
        isWideScreen ? '' : 'animate-popLeft'
      } mx-auto md:container border-solid border border-gray-100`}
    >
      {/* <VerDetalleMedicamento
        open={openDetalle}
        handleDetalleClose={() => setOpenDetalle(false)}
        medicamentoAVerDetalle={medicamentoAVerDetalle}
      /> */}
      {viewTabCardiovascular ? (
        <>
          <div className="py-4">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              onClick={() => {
                setNumberDataToSetMedicina(-1);
                setViewTabCardiovascular(false);
                setDataToSetMedicina(undefined);
              }}
              startIcon={<ArrowBackIcon />}
            >
              {t('regresar')}
            </Button>
          </div>
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'riesgo_cardiovascular' && (
            <PrevencionCardiovascularDiv
              presion={dataToSetMedicina.rangoPa || 0}
              colesterol={dataToSetMedicina.rangoColesterol || 0}
              valor={dataToSetMedicina.valor?.toString() || ''}
              sexo={dataToSetMedicina.sexo ? 'M' : 'H'}
              toxi={dataToSetMedicina.consumoTabaco ? 'activo' : 'falso'}
              esDiabetico={dataToSetMedicina.esDiabetico || 0}
              colesterolResultado={dataToSetMedicina.valorColesterol || 0}
              fechaColesterol={dataToSetMedicina.fecha || ''}
              tableGloborisk={tableGloborisk}
              edad={dataToSetMedicina.edad || 0}
            />
          )}
          {numberDataToSetMedicina === 2 &&
            dataToSetMedicina &&
            ['riesgo_cardiovascular_s_colesterol', 'riesgo_cardiovascular_usuario'].includes(
              dataToSetMedicina.tipo,
            ) && (
              <PrevencionCardiovascularDiv
                conColesterol={false}
                presion={dataToSetMedicina.rangoPa || 0}
                imc={dataToSetMedicina.rangoImc || 0}
                valor={dataToSetMedicina.valor?.toString() || ''}
                sexo={dataToSetMedicina.sexo ? 'M' : 'H'}
                toxi={dataToSetMedicina.consumoTabaco ? 'activo' : 'falso'}
                imcResultado={dataToSetMedicina.imc || 0}
                tableGloborisk={tableGloborisk}
                edad={dataToSetMedicina.edad || 0}
              />
            )}
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'cancer_pulmon' && (
            <DeteccionTempranaCancer selIdPaciente={idPaciente || 0} />
          )}
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'cancer_cervical' && (
            <DeteccionCancerCervicalDiv
              edad={dataToSetMedicina.edad}
              sendFecha={dataToSetMedicina.fecha}
              sendShowCervicoUterino
            />
          )}
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'aaa' && (
            <AneurismaAortaAbdominal txtToxicomania={dataToSetMedicina.toxicomania || ''} />
          )}
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'audiometria' && (
            <PrevencionAuditiva
              nivel={dataToSetMedicina.nivel || 0}
              fechaEstudio={dataToSetMedicina.fechaEstudio || ''}
            />
          )}
          {numberDataToSetMedicina === 2 && dataToSetMedicina?.tipo === 'emp' && (
            <ExamenMedicoPeriodico fechaEstudio={dataToSetMedicina.fechaEstudio || ''} />
          )}
          {numberDataToSetMedicina === 3 && valAntecedenteLaboral && (
            <ListaAntecedentesLaborales
              objTabEmpresa={valAntecedenteLaboral.objTabEmpresa}
              objExposicionRiesgo={valAntecedenteLaboral.objExposicionRiesgo}
              objLaboral={valAntecedenteLaboral.objLaboral}
              idEmpresa={valAntecedenteLaboral.objTabEmpresa.id}
              inx={-1}
              actualizaListaValores={() => {}}
              eliminaListaValores={() => {}}
            />
          )}
        </>
      ) : (
        <>
          <h1 className="text-blue-800 font-normal m-0 text-center pb-4">
            {t('resumen_de_salud')}
          </h1>
          {tables.some((table) => table.data.length > 0) ? (
            <Grid container spacing={4} className="pb-4">
              {tables
                .filter((table) => table.data.length > 0 || table.name === 'business_diagnosticos')
                .map((table) => (
                  <Grid item xs={12} md={6} className="pb-4">
                    <div>
                      <h2 className="bg-blue-500 text-white text-center p-2 md:p-3 rounded-t-lg text-base md:text-lg">
                        {t(table.name)}
                      </h2>
                      <div className="border rounded-b-lg">
                        {table.data.map((row: IObjTabla, inx) => (
                          <div key={inx} className="p-3 border-b flex justify-between items-center">
                            <span className="md:text-base">{row.nombre}</span>
                            {row.riesgo && <span className="md:text-base">{row.riesgo}</span>}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <div className="place-self-center text-center mx-auto shadow-lg bg-gray-100 rounded-xl mt-16 lg:mt-0 p-6 border border-solid border-gray-200">
              <span className="text-base font-semibold text-gray-600">
                {t('resumen-salud-aparente-sano')}
              </span>
            </div>
          )}
          {/* {(medidasExpediente || medidasUsuario) && (
            <>
              <div className="bg-white border-solid border-0 border-b border-gray-600">
                <h2 className="text-blue-800 font-normal m-0 text-center">
                  {t('ultimas_mediciones')}
                </h2>
                <div className="flex justify-between">
                  <h4 className="mt-0 text-gray-600">
                    {t(
                      fuenteMedidas === 'expediente'
                        ? 'mediciones_expediente'
                        : 'mediciones_usuario_paciente',
                    )}
                  </h4>
                  {((fuenteMedidas === 'expediente' && fechaMedidas) ||
                    (fuenteMedidas === 'usuario' && fechaMedidasUsuario)) && (
                    <span className="text-gray-600">
                      {`${t('fecha')}: ${fechaISOADiaMesAnioStr(
                        fuenteMedidas === 'expediente' ? fechaMedidas : fechaMedidasUsuario,
                        { sep: '-', dia: 'DD', mes: 'MMM' },
                      )}`}
                    </span>
                  )}
                </div>
              </div>
              <div className="py-2" />
              <div className="w-full flex flex-row">
                {medidasExpediente && medidasUsuario && (
                  <Button onClick={handleClickMedicionesArrows}>
                    <ArrowBackIosIcon />
                  </Button>
                )}
                {evaluacionMedidas.talla &&
                  evaluacionMedidas.perCintura &&
                  evaluacionMedidas.peso &&
                  evaluacionMedidas.imc && (
                    <div className="pr-2 w-3/4">
                      <EvaluacionMedidas
                        talla={evaluacionMedidas.talla}
                        perCintura={evaluacionMedidas.perCintura}
                        peso={evaluacionMedidas.peso}
                        imc={evaluacionMedidas.imc}
                      />
                    </div>
                )}
                <div className="pl-2 w-1/4">
                  {fuenteMedidas === 'expediente' &&
                    examenColesterol.anio &&
                    examenColesterol.mes &&
                    examenColesterol.dia &&
                    examenColesterol.valor && (
                      <h3 className="font-normal m-0 text-left pb-4">
                        {`${t('p_e_cardio_er_colesterol')}: ${
                          examenColesterol.valor
                        } mg/dL (${dayjs(
                          `${examenColesterol.anio}-${examenColesterol.mes}-${examenColesterol.dia}`,
                        ).format('DD-MMM-YYYY')})`}
                      </h3>
                  )}
                  {presionArterial.pDiastolica && presionArterial.pSistolica && (
                    <h3 className="font-normal m-0 text-left pb-4">
                      {`${t('pa')}: ${presionArterial.pSistolica}/${
                        presionArterial.pDiastolica
                      } mmHg`}
                    </h3>
                  )}
                </div>
                {medidasExpediente && medidasUsuario && (
                  <Button onClick={handleClickMedicionesArrows}>
                    <ArrowForwardIosIcon />
                  </Button>
                )}
              </div>
            </>
          )} */}
        </>
      )}
    </div>
  );
};

export default ResumenDeSalud;
