import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton, Button } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { stringMes } from '@common/functions';
import { RootState } from 'src/store';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import ViewComentarios from '@components/Comentarios/view';
import DocumentosCargadosListado from '@components/Archivos/DocumentosCargadosListado';
import { IProcedimientosProps } from './types';

function ProcedimientosComponente({
  archivos,
  idProceso,
  idProcedimiento,
  deleteProcedimiento,
  editProcedimiento,
  enfermedad,
  txtDiagnostico,
  cie9,
  txtMotivo,
  dia,
  mes,
  anio,
  comentarios,
  documentos,
  indSelect,
  inx,
  setSelArchivo,
  eliminarArchivo,
  verArchivo,
}: IProcedimientosProps) {
  const { t } = useTranslation();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const mot = txtMotivo ? `${txtMotivo}` : `${cie9.label}`;
  let diag = '';
  if (txtDiagnostico) {
    diag = `${t('antecedentes-pp-hos-diag-res')}: ${txtDiagnostico}`;
  } else if (enfermedad.id > 0) {
    diag = `${t('antecedentes-pp-hos-diag-res')}: ${enfermedad.label}`;
  }
  let fecha = '';
  if (anio && mes && dia) {
    fecha = `${t('fecha')}: ${dia}-${stringMes(mes, t)}-${anio}`;
  } else if (mes && anio) {
    fecha = `${t('fecha')}: ${stringMes(mes, t)}-${anio}`;
  } else if (anio) {
    fecha = `${t('fecha')}: ${anio}`;
  }
  const seleccionarArchivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      setSelArchivo({ archivo: event.target.files[0], idLista: idProcedimiento || 0 });
    } else {
      setSelArchivo(null);
    }
  };
  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative w-full">
      <div className="border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full">
        <h2 className="mb-2 m-0 overflow-ellipsis overflow-hidden text-left font-medium text-gray-500">{`${mot}`}</h2>
        <h3 className="font-normal text-gray-500 m-0 mb-2">{`${diag}`}</h3>
        <h4 className="font-normal text-gray-500 m-0 mb-2">{`${fecha}`}</h4>
        <div className="mb-3">
          {comentarios ? <ViewComentarios comentarios={comentarios} /> : null}
          {documentos && documentos.length > 0 ? (
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<InsertDriveFileIcon />}
            >
              <TreeItem nodeId="1" label={t('antecedentes-pp-proc-vdocumentos')}>
                <TreeItem nodeId="2" label="Prueba" />
              </TreeItem>
            </TreeView>
          ) : null}
        </div>
        {archivos.length > 0 && (
          <DocumentosCargadosListado
            idMedico={idMedico}
            idLista={idProcedimiento || 0}
            archivos={archivos}
            eliminarArchivo={eliminarArchivo}
            verArchivo={verArchivo}
          />
        )}
      </div>
      <div className="min-w-max self-center">
        <label htmlFor={`btn-upload-${inx}`}>
          <input
            id={`btn-upload-${inx}`}
            name={`btn-upload-${inx}`}
            style={{ display: 'none' }}
            type="file"
            accept=".csv, .odt, .ods, odp, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
            onChange={seleccionarArchivo}
          />
          <Button component="span" color="primary" startIcon={<CloudUploadIcon />}>
            {t('cargar_archivo')}
          </Button>
        </label>
        <div className="text-center">
          <IconButton
            aria-label="edit"
            onClick={editProcedimiento}
            color="primary"
            disabled={inx === indSelect || idProceso > 0}
          >
            <EditIcon />
          </IconButton>
          {archivos.length ? (
            <HtmlTooltip title={t('para_eliminar_se_deben_eliminar_los_archivos_primero')}>
              <span>
                <IconButton aria-label="delete" color="primary" disabled>
                  <DeleteIcon />
                </IconButton>
              </span>
            </HtmlTooltip>
          ) : (
            <IconButton
              aria-label="delete"
              onClick={deleteProcedimiento}
              color="primary"
              disabled={inx === indSelect || idProceso > 0}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProcedimientosComponente;
