import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { HtmlTooltip } from '@utils/ComponentesJSX';
import { RootState } from 'src/store';
import { getDataTablaGloborisk } from '@utils/getCatalogos';
import Tabla from '../../Tabla';
import { ITablasDetallesProps } from './types';
import { ReactComponent as Excel2021Icon } from '@assets/icons/Excel2021.svg';
import { setSnackComplete } from '@actions/snackbar/types';
import { generarXlsRegistrosFaltantes } from '@utils/documents';
import { useApiRequest } from '@hooks/useApiRequest';

const TablasDetalles = (props: ITablasDetallesProps) => {
  const {
    operacion,
    seleccionOperacion,
    selectorTabla,
    seleccionTabla,
    tipoPacientes,
    filtrosRepSalud,
    tablaDetalleRef,
    tablaCardiovascularDetalle,
    tablaAAADetalle,
    tablaCancerPulmonDetalle,
    tablaCancerCervicoDetalle,
    tablaAudiometriasDetalle,
    tablaEMPDetalle,
    tablaTabaquismoDetalle,
    tablaCafeinaDetalle,
    tablaAlcoholTrastornoDetalle,
    tablaAlcoholIntensivoDetalle,
    tablaOtrasDrogasDetalle,
    tablaHacinamientoDetalle,
    tablaActividadFisicaDetalle,
    tablaRefrescosDetalle,
    tablaReporteGeneralDetalle,
    tablaRegistrosFaltantes,
    // selectorHabito,
    setTableGloborisk,
    setInxSeleccionado,
    setVerDetalle,
  } = props;
  const { t } = useTranslation();
  const { apiRequest } = useApiRequest();
  const dispatch = useDispatch();
  const { idOrganizacion } = useSelector((state: RootState) => state.Organizaciones.organizacion);
  const { idConsultorio } = useSelector((state: RootState) => state.Consultorios.consultorio);
  const { idMedico, rol } = useSelector((state: RootState) => state.Me);

  const getTituloTooltip = (titulo: string) => (
    <HtmlTooltip title={t(titulo) || ''}>
      <h4 className="font-normal m-0">{t('dias_restantes')}</h4>
    </HtmlTooltip>
  );

  /* COLUMNAS */
  const columnasRiesgoSalud = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('oportunidad_riesgo'), accessor: 'rangoClasificacion' },
    { Header: t('sugerencia'), accessor: 'sugerencia' },
    { Header: t('contacto'), accessor: 'contacto' },
  ];
  const columnasAudiometrias = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: getTituloTooltip('pre_rie_dias_restantes'), accessor: 'estudio' },
    { Header: t('sugerencia'), accessor: 'sugerencia' },
    { Header: t('contacto'), accessor: 'contacto' },
  ];
  const columnasEMP = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: getTituloTooltip('emp_dias_restantes'), accessor: 'estudio' },
    { Header: t('sugerencia'), accessor: 'sugerencia' },
    { Header: t('contacto'), accessor: 'contacto' },
  ];
  const columnasTabaquismo = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('riesgo'), accessor: 'rangoClasificacion' },
    { Header: t('cigarrillos_al_dia'), accessor: 'consumo' },
  ];
  const columnasCafeina = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('consumo_aproximado_de_cafeina_al_dia'), accessor: 'consumo' },
  ];
  const columnasAlcohol = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('frecuencia'), accessor: 'frecuencia' },
    { Header: t('cantidad_tragos_cuando_toma'), accessor: 'consumo' },
  ];
  const columnasOtrasDrogas = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('tipo'), accessor: 'tipo' },
    { Header: t('frecuencia'), accessor: 'frecuencia' },
    { Header: t('cantidad'), accessor: 'consumo' },
  ];
  const columnasActividadFisica = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('antecedentes-pnp-actividad-minimo-semanal'), accessor: 'rangoClasificacion' },
  ];
  const columnasRefrescos = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('tipo_consumo'), accessor: 'rangoClasificacion' },
    { Header: t('consumo_semanal'), accessor: 'consumo' },
  ];
  const columnasOperacionesSalud = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('sexo'), accessor: 'sexo' },
    { Header: t('edad'), accessor: 'edad' },
    { Header: t('ocupacion'), accessor: 'ocupacion' },
    { Header: t('grado_salud'), accessor: 'gradoSalud' },
    { Header: t('riesgo_operativo'), accessor: 'riesgoOperativo' },
    { Header: t('distGeo1'), accessor: 'distGeo1' },
    { Header: t('distGeo2'), accessor: 'distGeo2' },
    { Header: t('distGeo3'), accessor: 'distGeo3' },
    { Header: t('distGeo4'), accessor: 'distGeo4' },
    { Header: t('supervisor'), accessor: 'supervisor' },
  ];
  const columnasRegistrosFaltantes = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('numero_empleado'), accessor: 'numeroEmpleado' },
    { Header: t('ocupacion'), accessor: 'ocupacion' },
    { Header: t('tienda'), accessor: 'distGeo4' },
    { Header: t('supervisor'), accessor: 'supervisor' },
  ];
  const columnasHacinamiento = [
    { Header: t('nombre'), accessor: 'nombre' },
    { Header: t('antecedentes-pnp-vivienda-ihac'), accessor: 'consumo' },
  ];

  /* MOSTRAR GLOBORISK */
  const showDataGloborisk = (inx: number) => {
    apiRequest({
      type: 'get',
      requestFunction: getDataTablaGloborisk(
        tablaCardiovascularDetalle[inx].sexo,
        tablaCardiovascularDetalle[inx].esDiabetico,
        tablaCardiovascularDetalle[inx].consumoTabaco,
        tablaCardiovascularDetalle[inx].rangoEdad,
        true,
        idMedico,
        tablaCardiovascularDetalle[inx].idPaciente,
        idConsultorio,
      ),
      successFunction: (result: any) => {
        if (result.datos && result.datos.length > 0) {
          setInxSeleccionado(inx);
          setTableGloborisk(result.datos);
          setVerDetalle(true);
        }
      },
      errorFunction: () => setTableGloborisk([]),
    });
  };
  const showDataTabla = (inx: number) => {
    setInxSeleccionado(inx);
    setVerDetalle(true);
  };

  /* DETECTAR SI SE TIENE VALORES EN LA TABLA */
  const conDatos =
    (seleccionOperacion === 'otros_riesgos' && tablaHacinamientoDetalle.length > 0) ||
    (selectorTabla === 'riesgo_cardiovascular' && tablaCardiovascularDetalle.length > 0) ||
    (selectorTabla === 'aaa' && tablaAAADetalle.length > 0) ||
    (selectorTabla === 'cancer_pulmon' && tablaCancerPulmonDetalle.length > 0) ||
    (selectorTabla === 'cancer_cervical' && tablaCancerCervicoDetalle.length > 0) ||
    (selectorTabla === 'alarma_tabaquismo' && tablaTabaquismoDetalle.length > 0) ||
    (selectorTabla === 'alarma_cafeina_excesivo' && tablaCafeinaDetalle.length > 0) ||
    (selectorTabla === 'alarma_alcohol_trastorno' && tablaAlcoholTrastornoDetalle.length > 0) ||
    (selectorTabla === 'alarma_alcohol_intensivo' && tablaAlcoholIntensivoDetalle.length > 0) ||
    (selectorTabla === 'alarma_otras_drogas' && tablaOtrasDrogasDetalle.length > 0) ||
    (selectorTabla === 'alarma_actividad_fisica' && tablaActividadFisicaDetalle.length > 0) ||
    (selectorTabla === 'alarma_refrescos' && tablaRefrescosDetalle.length > 0) ||
    (selectorTabla === 'audiometria' && tablaAudiometriasDetalle.length > 0) ||
    (selectorTabla === 'emp' && tablaEMPDetalle.length > 0) ||
    (selectorTabla === 'reporte_general' && tablaReporteGeneralDetalle.length > 0) ||
    (selectorTabla === 'registros_faltantes' && tablaRegistrosFaltantes.length > 0);

  /* OBTENER TABLAS DE DETALLE */
  const getTablaDetalle = () => {
    if (seleccionOperacion === 'otros_riesgos') {
      return (
        <Tabla
          columns={columnasHacinamiento}
          data={tablaHacinamientoDetalle}
          callBackVerDetalle={(_d, index) => showDataTabla(index)}
        />
      );
    } else {
      switch (selectorTabla) {
        case 'riesgo_cardiovascular':
          return (
            <Tabla
              columns={columnasRiesgoSalud}
              data={tablaCardiovascularDetalle}
              callBackVerDetalle={(_d, index) => showDataGloborisk(index)}
            />
          );
        case 'aaa':
          return (
            <Tabla
              columns={columnasRiesgoSalud}
              data={tablaAAADetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'cancer_pulmon':
          return (
            <Tabla
              columns={columnasRiesgoSalud}
              data={tablaCancerPulmonDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'cancer_cervical':
          return (
            <Tabla
              columns={columnasRiesgoSalud}
              data={tablaCancerCervicoDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_tabaquismo':
          return (
            <Tabla
              columns={columnasTabaquismo}
              data={tablaTabaquismoDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_cafeina_excesivo':
          return (
            <Tabla
              columns={columnasCafeina}
              data={tablaCafeinaDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_alcohol_trastorno':
          return (
            <Tabla
              columns={columnasAlcohol}
              data={tablaAlcoholTrastornoDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_alcohol_intensivo':
          return (
            <Tabla
              columns={columnasAlcohol}
              data={tablaAlcoholIntensivoDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_otras_drogas':
          return (
            <Tabla
              columns={columnasOtrasDrogas}
              data={tablaOtrasDrogasDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_actividad_fisica':
          return (
            <Tabla
              columns={columnasActividadFisica}
              data={tablaActividadFisicaDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'alarma_refrescos':
          return (
            <Tabla
              columns={columnasRefrescos}
              data={tablaRefrescosDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'audiometria':
          return (
            <Tabla
              columns={columnasAudiometrias}
              data={tablaAudiometriasDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'emp':
          return (
            <Tabla
              columns={columnasEMP}
              data={tablaEMPDetalle}
              callBackVerDetalle={(_d, index) => showDataTabla(index)}
            />
          );
        case 'reporte_general':
          return (
            <div>
              <div className="flex flex-row items-center justify-end">
                <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
                <IconButton aria-label="edit" onClick={() => {}} color="primary">
                  <Excel2021Icon />
                </IconButton>
              </div>
              <Tabla
                columns={columnasOperacionesSalud}
                data={tablaReporteGeneralDetalle}
                centered
                firstColumnIsTitle
                sort
              />
            </div>
          );
        case 'registros_faltantes':
          return (
            <div>
              <div className="flex flex-row items-center justify-end">
                <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    if (tablaRegistrosFaltantes.length === 0) {
                      dispatch(
                        setSnackComplete({
                          open: true,
                          severity: 'error',
                          mensaje: t('business_exportar_excel_sin_datos'),
                        }),
                      );
                    } else {
                      const sendData = {
                        ...filtrosRepSalud,
                        idOrganizacion,
                        tipoPacientes,
                      };
                      generarXlsRegistrosFaltantes(sendData);
                    }
                  }}
                  color="primary"
                >
                  <Excel2021Icon />
                </IconButton>
              </div>
              <Tabla
                columns={columnasRegistrosFaltantes}
                data={tablaRegistrosFaltantes}
                centered
                firstColumnIsTitle
                sort
              />
            </div>
          );
        default:
          return <></>;
      }
    }
  };

  return conDatos ? (
    <div
      ref={tablaDetalleRef}
      className="flex flex-col relative bg-white mx-auto shadow rounded-b-md border-solid border border-gray-100 p-4 mt-2"
    >
      <label className="absolute bg-white text-blue-400 text-base left-3 px-2 -top-3">
        {t('tabla_detalle')}
      </label>
      {['habitos', 'otros_riesgos'].includes(operacion.tipo) && (
        <h2 className="text-blue-800 font-normal text-center mb-2">
          {t(operacion.tipo === 'habitos' ? selectorTabla : 'alarma_hacinamiento')}
        </h2>
      )}
      {rol.grupo === 'salud' ? getTablaDetalle() : null}
    </div>
  ) : null;
};

export default TablasDetalles;
