import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import ITarjetaRechazaProps from './types';

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  ),
);

function LlamarPagoModal({ open, callBackClose, email, customer }: ITarjetaRechazaProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="pago-fallido-titulo"
      aria-describedby="pago-fallido-descripcion"
    >
      <DialogContent className="text-center">
        <h2 className="font-normal text-red-500">{t('_oh_no_')}</h2>
        <h2 className="font-medium text-gray-600">
          {t('ha_ocurrido_un_error_grave_con_tu_transaccion_comuniquese')}
        </h2>
        <div>
          <h3 className="font-medium text-gray-600">{t('formas_de_contacto')}:</h3>
          <div className="text-gray-600 text-lg">
            <div>
              <span>{t('email')}</span>
              :&nbsp;hola@medipraxi.com
            </div>
            <div>
              <span>{t('telefonos')}</span>
              :&nbsp;55 8936 0678 - 55 8936 0654
            </div>
          </div>
        </div>
        <div>
          <h3 className="font-medium text-gray-600">
            {`${t('por_favor_proporcione_los_siguientes_datos')}:`}
          </h3>
          <div className="text-lg text-gray-600">
            <div>
              <span>Referencia:&nbsp;</span>
              {customer}
            </div>
            <div>
              <span>Email:&nbsp;</span>
              {email}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex justify-center pb-6">
          <Button onClick={callBackClose} color="primary" variant="contained">
            {t('aceptar')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default LlamarPagoModal;
