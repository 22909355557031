import {
  SET_RUTA_PRINCIPAL,
  SET_RUTA_PERFIL,
  SET_RUTA_ANTECEDENTES,
  SET_RUTA_ANT_PATOLOGICOS,
  SET_RUTA_ANT_NO_PATOLOGICOS,
  SET_RUTA_PLAN,
  RESET_RUTAS,
  RutasExpedienteActionTypes,
  TRutaPrincipal,
  TRutaPerfil,
  TRutaAntecedentes,
  TRutaAntPatologicos,
  TRutaAntNoPatologicos,
  TRutaPlan,
} from './types';

export function setRutaPrincipal(ruta: TRutaPrincipal): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_PRINCIPAL,
    payload: ruta,
  };
}

export function setRutaPerfil(ruta: TRutaPerfil): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_PERFIL,
    payload: ruta,
  };
}

export function setRutaAntecedentes(ruta: TRutaAntecedentes): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_ANTECEDENTES,
    payload: ruta,
  };
}

export function setRutaAntPatologicos(ruta: TRutaAntPatologicos): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_ANT_PATOLOGICOS,
    payload: ruta,
  };
}

export function setRutaAntNoPatologicos(ruta: TRutaAntNoPatologicos): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_ANT_NO_PATOLOGICOS,
    payload: ruta,
  };
}

export function setRutaPlan(ruta: TRutaPlan): RutasExpedienteActionTypes {
  return {
    type: SET_RUTA_PLAN,
    payload: ruta,
  };
}

export function resetRutas(): RutasExpedienteActionTypes {
  return {
    type: RESET_RUTAS,
  };
}
