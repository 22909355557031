import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/store';
import useStyles from '@common/styles';
import MetodoAnticonceptivo from './MetodoAnticonceptivo';
import { IAnticonceptivoProps } from './types';

function Anticonceptivo(props: IAnticonceptivoProps) {
  const { hayCambios, ginecoObstetrico, setGinecoObstetrico } = props;
  const classes = useStyles();
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  const handleChangeComentario = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGinecoObstetrico({
      ...ginecoObstetrico,
      anticonceptivo: {
        ...ginecoObstetrico.anticonceptivo,
        comentarios: event.target.value as string,
      },
    });
  };

  const handleClickAgregarOtroMetodo = () => {
    if (ginecoObstetrico.anticonceptivo.arrayTabla.length < 6) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        anticonceptivo: {
          arrayTabla: ginecoObstetrico.anticonceptivo.arrayTabla.concat(
            `${
              ginecoObstetrico.anticonceptivo.arrayTabla[
                ginecoObstetrico.anticonceptivo.arrayTabla.length - 1
              ]
            }I`,
          ),
          metodoEnUso: ginecoObstetrico.anticonceptivo.metodoEnUso.concat(''),
          tiempoEnUso: ginecoObstetrico.anticonceptivo.tiempoEnUso.concat(''),
          comentarios: ginecoObstetrico.anticonceptivo.comentarios,
        },
      });
    }
  };

  const handleDeleteRow = (indx: number) => {
    if (ginecoObstetrico.anticonceptivo.arrayTabla.length > 1) {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        anticonceptivo: {
          ...ginecoObstetrico.anticonceptivo,
          arrayTabla: ginecoObstetrico.anticonceptivo.arrayTabla.filter((_item, j) => indx !== j),
          metodoEnUso: ginecoObstetrico.anticonceptivo.metodoEnUso.filter((_item, j) => indx !== j),
          tiempoEnUso: ginecoObstetrico.anticonceptivo.tiempoEnUso.filter((_item, j) => indx !== j),
        },
      });
    } else {
      setGinecoObstetrico({
        ...ginecoObstetrico,
        anticonceptivo: {
          ...ginecoObstetrico.anticonceptivo,
          arrayTabla: ['I'],
          metodoEnUso: [''],
          tiempoEnUso: [''],
        },
      });
    }
  };

  return (
    <>
      <h2 className="font-normal text-blue-800 mb-0">{t('anticonceptivo')}</h2>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-4">
        {ginecoObstetrico.anticonceptivo.arrayTabla.map((tabla, indx) => (
          <MetodoAnticonceptivo
            ginecoObstetrico={ginecoObstetrico}
            setGinecoObstetrico={setGinecoObstetrico}
            index={indx}
            key={tabla}
            handleDeleteRow={handleDeleteRow}
          />
        ))}
      </div>
      <div className="flex justify-end">
        <Button
          color="primary"
          size="small"
          className={classes.noTextTransform}
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleClickAgregarOtroMetodo}
          disabled={hayCambios || ginecoObstetrico.anticonceptivo.arrayTabla.length >= 6}
        >
          {t('agregar_otro_metodo')}
        </Button>
      </div>
      {!esPaciente && (
        <div className="mb-4">
          <TextField
            variant="outlined"
            rows={4}
            multiline
            fullWidth
            label={t('comentarios')}
            inputProps={{
              maxLength: 500,
              autoComplete: 'off',
              'aria-autocomplete': 'none',
            }}
            value={ginecoObstetrico.anticonceptivo.comentarios}
            onChange={handleChangeComentario}
            disabled={hayCambios}
          />
        </div>
      )}
    </>
  );
}

export default Anticonceptivo;
