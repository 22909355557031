import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import { setRutaAntNoPatologicos, setRutaPrincipal } from '@actions/rutasExpediente/actions';
import { useApiRequest } from '@hooks/useApiRequest';
import {
  antecedentesNoPatologicosGuardarToxicomania,
  antecedentesNoPatologicosUsuarioPacienteGuardarToxicomania,
  antecedentesNoPatologicosUsuarioGuardarToxicomania,
  antecedentesNoPatologicosUsuarioGuardarHigiene,
} from '@utils/sendInfo';
import {
  setEnGestacion,
  setEnGestacionUsuario,
  setHigiene,
  setToxicomania,
  setToxicomaniaUsuario,
} from '@actions/paciente/actions';
import Alcohol from './Alcohol';
import Cafeina from './Cafeina';
import Tabaco from './Tabaco';
import Te from './Te';
import OtrasDrogas from './OtrasDrogas';
import { IAlcohol, ICafeina, ITabaco, IToxicomania, toxicomaniaInitial } from './types';
import { IHigiene, higieneInitial } from '../Higiene/types';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { ModalForm } from '@components/modals/ModalForm';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';
import { IFormSectionComponent } from '@components/FormSections/type';
import FormSections from '@components/FormSections';

function Toxicomania() {
  const dispatch = useDispatch();
  const { apiRequest, apiRequests } = useApiRequest();
  const {
    sexo,
    idPaciente,
    idUsuario,
    edad: { claveEdad, edad },
  } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
  } = useSelector((state: RootState) => state.Me);
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    ocultarCampos,
    expediente: {
      antNoPatologicos: {
        higiene,
        toxicomania,
        ginecoObstetrico: {
          generalidades: { enGestacion },
        },
      },
    },
    usuario: {
      antNoPatologicos: { toxicomania: toxicomaniaUsuario },
    },
    cambios: {
      antNoPatologicos: { toxicomania: cambios },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  // const edadPaciente = parseInt(calculateEdad(anio, mes, dia), 10);
  const [enGestacionTemp, setEnGestacionTemp] = useState<'' | 'si' | 'no'>(enGestacion);
  const [toxicomaniaTemp, setToxicomaniaTemp] = useState<IToxicomania>(toxicomaniaInitial);
  const [higieneTemp, setHigieneTemp] = useState<IHigiene>(higieneInitial);

  const isWideScreen = useBreakPoint();
  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);

  const getDatosExpediente = (datos: IToxicomania) => ({
    idMedico,
    idPaciente,
    idUsuario,
    idConsultorio,
    idConsulta,
    toxicomania: datos,
    enGestacion: enGestacionTemp || '',
  });
  const getDatosUsuario = (datos: IToxicomania) => ({
    idUsuario: esPaciente ? idMiUsuario : idUsuario,
    toxicomania: datos,
    enGestacion: enGestacionTemp || '',
  });

  // Guarda los datos del expediente del paciente, y si este tiene usuario también guarda los datos en el usuario
  const guardarDatosExpediente = () => {
    const datosToSend = getDatosExpediente(toxicomaniaTemp);
    const sendFunctions = [antecedentesNoPatologicosGuardarToxicomania(datosToSend)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(antecedentesNoPatologicosUsuarioPacienteGuardarToxicomania(datosToSend));
    }
    apiRequests({
      type: 'send',
      requestFunctions: sendFunctions,
      successFunctions: [
        () => {
          dispatch(setToxicomania({ ...toxicomaniaTemp, loaded: true }));
          dispatch(setEnGestacion(enGestacionTemp));
        },
        () => {
          dispatch(setToxicomaniaUsuario({ ...toxicomaniaTemp, loaded: true }));
          dispatch(setEnGestacionUsuario(enGestacionTemp));
        },
      ],
    });
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    apiRequests({
      type: 'send',
      requestFunctions: [
        antecedentesNoPatologicosUsuarioGuardarHigiene({
          idUsuario: esPaciente ? idMiUsuario : idUsuario,
          higiene: { ...higieneTemp },
        }),
        antecedentesNoPatologicosUsuarioGuardarToxicomania(getDatosUsuario(toxicomaniaTemp)),
      ],
      successFunctions: [
        () => dispatch(setHigiene({ ...higieneTemp, loaded: true })),
        () => {
          dispatch(setToxicomania({ ...toxicomaniaTemp, loaded: true }));
          if (sexo === 'H') {
            dispatch(setRutaPrincipal('exploracion-fisica'));
          } else {
            dispatch(setRutaAntNoPatologicos('gineco-obstetricos'));
          }
        },
      ],
    });
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    apiRequest({
      type: 'send',
      requestFunction: antecedentesNoPatologicosGuardarToxicomania(
        getDatosExpediente(toxicomaniaUsuario),
      ),
      successFunction: () => {
        dispatch(setToxicomania(toxicomaniaUsuario));
        setToxicomaniaTemp(toxicomaniaUsuario);
      },
    });
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: antecedentesNoPatologicosUsuarioPacienteGuardarToxicomania(
        getDatosExpediente(toxicomania),
      ),
      successFunction: () => dispatch(setToxicomaniaUsuario(toxicomania)),
    });
  };

  const getCafeina = (cafeina: ICafeina) => {
    const newCafeina = { ...cafeina };
    let cafeinaAlDia = 0;
    if (newCafeina.tazaDeCafe || newCafeina.lataDeBebidaEnergetica) {
      const tazaCantidad = parseInt(newCafeina.tazaDeCafe, 10) || 0;
      const lataCantidad = parseInt(newCafeina.lataDeBebidaEnergetica, 10) || 0;
      cafeinaAlDia = (tazaCantidad + lataCantidad) * 80;
    }
    newCafeina.cafeinaAlDia = cafeinaAlDia > 0 ? cafeinaAlDia.toString() : '';
    newCafeina.alarmaConsumoExcesivo =
      (enGestacion === 'si' && cafeinaAlDia >= 240) ||
      (enGestacion !== 'si' && cafeinaAlDia >= 400);
    return newCafeina;
  };

  const getTabaco = (tabaco: ITabaco) => {
    const newTabaco = { ...tabaco };
    let ipa = 0;
    let alarmaTabaquismo: '' | 'leve' | 'moderado' | 'intenso' = '';
    if (newTabaco.cigarrillosDia && newTabaco.edadInicio) {
      let aniosFumador = 0;
      const edadInicio = parseInt(newTabaco.edadInicio, 10) || 0;
      const cigarrillosDia = parseInt(newTabaco.cigarrillosDia, 10) || 0;
      if (newTabaco.consumoTabaco === 'ex-fumador' && newTabaco.edadTermino) {
        const edadTermino = parseInt(newTabaco.edadTermino, 10) || 0;
        aniosFumador = edadTermino - edadInicio;
      } else if (newTabaco.consumoTabaco === 'activo') {
        aniosFumador = edad - edadInicio;
      }
      ipa = (cigarrillosDia * aniosFumador) / 20;
      if (ipa > 0) {
        if (ipa <= 4.9) {
          alarmaTabaquismo = 'leve';
        } else if (ipa <= 14.9) {
          alarmaTabaquismo = 'moderado';
        } else if (ipa > 14.9) {
          alarmaTabaquismo = 'intenso';
        }
      }
    }
    newTabaco.ipa = ipa > 0 ? ipa.toString() : '';
    newTabaco.alarmaTabaquismo = alarmaTabaquismo;
    return newTabaco;
  };

  const getAlcohol = (alcohol: IAlcohol) => {
    const newAlcohol = { ...alcohol };
    let alarmaConsumoIntensivo = false;
    let alarmaPosibleTrastorno = false;
    const totalTragos = newAlcohol.arrTipoAlcohol.cantidadAlcohol
      .map((cant) => (cant ? parseInt(cant, 10) : 0))
      .reduce((acc, current) => acc + current, 0);
    const sumatoriaAlcohol = newAlcohol.arrTipoAlcohol.tipoAlcohol
      .map(
        (elem, indx) =>
          elem.grUnidad * (parseInt(newAlcohol.arrTipoAlcohol.cantidadAlcohol[indx], 10) || 0),
      )
      .reduce((acc, current) => acc + current, 0);
    const totalAproxAlcohol = Math.round(sumatoriaAlcohol * 10) / 10;
    if (alcohol.frecuencia !== '') {
      if (sexo === 'M') {
        if (totalAproxAlcohol >= 52 && alcohol.consumeEnMenosDeDosHoras === 'si') {
          alarmaConsumoIntensivo = true;
        }
      } else if (totalAproxAlcohol >= 65 && alcohol.consumeEnMenosDeDosHoras === 'si') {
        alarmaConsumoIntensivo = true;
      }
    }
    if (alcohol.frecuencia === 'diario') {
      if (sexo === 'M') {
        if (totalAproxAlcohol >= 39) {
          alarmaPosibleTrastorno = true;
        }
      } else if (totalAproxAlcohol >= 52) {
        alarmaPosibleTrastorno = true;
      }
    } else if (sexo === 'M' && alcohol.frecuencia !== '') {
      if (totalAproxAlcohol >= 52 && alcohol.consumeEnMenosDeDosHoras === 'si') {
        alarmaPosibleTrastorno = true;
      }
    } else if (
      alcohol.totalAproxAlcohol &&
      totalAproxAlcohol >= 78 &&
      alcohol.consumeEnMenosDeDosHoras === 'si' &&
      alcohol.frecuencia !== ''
    ) {
      alarmaPosibleTrastorno = true;
    }
    newAlcohol.totalTragos = totalTragos > 0 ? totalTragos.toString() : '';
    newAlcohol.totalAproxAlcohol = totalAproxAlcohol > 0 ? totalAproxAlcohol.toString() : '';
    newAlcohol.alarmaConsumoIntensivo = alarmaConsumoIntensivo;
    newAlcohol.alarmaPosibleTrastorno = alarmaPosibleTrastorno;
    return newAlcohol;
  };

  useEffect(() => {
    if (toxicomania.loaded && !toxicomaniaTemp.loaded) {
      const newToxicomania = { ...toxicomania };
      if (
        toxicomania.cafeina.consumoCafeina === 'activo' &&
        !toxicomania.cafeina.cafeinaAlDia &&
        (toxicomania.cafeina.tazaDeCafe || toxicomania.cafeina.lataDeBebidaEnergetica)
      ) {
        newToxicomania.cafeina = getCafeina(toxicomania.cafeina);
      }
      if (
        toxicomania.tabaco.consumoTabaco !== 'niega' &&
        !toxicomania.tabaco.ipa &&
        toxicomania.tabaco.cigarrillosDia &&
        toxicomania.tabaco.edadInicio
      ) {
        newToxicomania.tabaco = getTabaco(toxicomania.tabaco);
      }
      if (
        toxicomania.alcohol.consumoAlcohol === 'activo' &&
        !toxicomania.alcohol.totalAproxAlcohol &&
        toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol
      ) {
        newToxicomania.alcohol = getAlcohol(toxicomania.alcohol);
      }
      if (toxicomania.alcohol.arrTipoAlcohol.tipoAlcohol.length === 0) {
        newToxicomania.alcohol = {
          ...newToxicomania.alcohol,
          arrTipoAlcohol: {
            arrTabla: ['I'],
            tipoAlcohol: [{ label: '', grUnidad: 0 }],
            cantidadAlcohol: [''],
          },
        };
      }
      setToxicomaniaTemp(newToxicomania);
      setEnGestacionTemp(enGestacion);
    }
  }, [toxicomania]);

  useEffect(() => {
    if (higiene.loaded && !higieneTemp.loaded) {
      setHigieneTemp(higiene);
    }
  }, [higiene]);

  const components: IFormSectionComponent[] = [
    {
      hide: !esPaciente,
      component: (
        <div className={isWideScreen ? 'w-1/3' : ''}>
          <label htmlFor="sueño" className="block font-medium text-base text-gray-600">
            {t('antecedentes-pnp-higiene-suenio')}
          </label>
          <NumberInput
            containerClass={isWideScreen ? 'pr-4' : ''}
            name="suenio"
            label={t(`antecedentes-pnp-higiene-horas-suenio`)}
            placeholder=""
            fixedValue={t('hora_s_')}
            value={higieneTemp.suenio}
            setValue={(e) => setHigieneTemp((prev) => ({ ...prev, suenio: e.value }))}
            max={24}
            disabled={hayCambios}
          />
        </div>
      ),
    },
    {
      hide: ocultarCampos || !(sexo === 'M' && claveEdad === 5 && edad > 9 && edad < 61),
      component: (
        <CheckboxGroup
          name="en_gestacion"
          options={[
            { value: 'si', label: t('si') },
            { value: 'no', label: t('no') },
          ]}
          value={enGestacionTemp}
          setValue={(e) => setEnGestacionTemp(e.value)}
          disabled={hayCambios}
          distribution="vertical-flex"
        />
      ),
    },
    {
      hide: ocultarCampos,
      component: (
        <Cafeina
          hayCambios={hayCambios}
          enGestacion={enGestacionTemp}
          toxicomania={toxicomaniaTemp}
          setToxicomania={setToxicomaniaTemp}
          getCafeina={getCafeina}
        />
      ),
    },
    {
      hide: ocultarCampos,
      component: (
        <Te
          hayCambios={hayCambios}
          toxicomania={toxicomaniaTemp}
          setToxicomania={setToxicomaniaTemp}
        />
      ),
    },
    {
      component: (
        <Tabaco
          hayCambios={hayCambios}
          toxicomania={toxicomaniaTemp}
          setToxicomania={setToxicomaniaTemp}
          getTabaco={getTabaco}
        />
      ),
    },
    {
      component: (
        <Alcohol
          hayCambios={hayCambios}
          toxicomania={toxicomaniaTemp}
          setToxicomania={setToxicomaniaTemp}
          getAlcohol={getAlcohol}
        />
      ),
    },
    {
      component: (
        <OtrasDrogas
          hayCambios={hayCambios}
          toxicomania={toxicomaniaTemp}
          setToxicomania={setToxicomaniaTemp}
        />
      ),
    },
  ];

  return (
    <ModalForm
      title={t('toxicomania')}
      id="antecedentes-pnp-toxicomania-form"
      aceptarCambios={guardarDatosDeUsuarioAExpediente}
      rechazarCambios={guardarDatosDeExpedienteAUsuario}
      submitForm={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
      hayCambios={hayCambios}
      cambios={cambios}
      backAction={() => dispatch(setRutaPrincipal('main'))}
      noXMargin
    >
      <FormSections components={components} />
    </ModalForm>
  );
}

export default Toxicomania;
