import {
  SET_CONSULTA,
  SET_ID_PACIENTE_CONSULTA,
  RESET_CONSULTA,
  ConsultaActionTypes,
  IConsulta,
} from '../actions/consulta/types';
import { getPaciente } from '@utils/commonStore';

const initialState: IConsulta = {
  idConsulta: 0,
  idPaciente: getPaciente(),
  tipoProceso: '',
  idProceso: -1,
};

function Consulta(state = initialState, action: ConsultaActionTypes) {
  switch (action.type) {
    case SET_CONSULTA:
      return {
        ...state,
        idConsulta: action.payload.idConsulta,
        idPaciente: action.payload.idPaciente,
        tipoProceso: action.payload.tipoProceso,
        idProceso: action.payload.idProceso,
      };
    case SET_ID_PACIENTE_CONSULTA:
      return {
        ...state,
        idPaciente: action.payload,
      };
    case RESET_CONSULTA:
      return {
        ...state,
        idConsulta: 0,
        idPaciente: 0,
        tipoProceso: '',
        idProceso: -1,
      } as IConsulta;
    default:
      return state;
  }
}

export default Consulta;
