import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import getMoneda from '@constants/monedas';
import Tabla from '../../Tabla';
import { ITablasGeneralesProps } from './types';
import { ReactComponent as Excel2021Icon } from '@assets/icons/Excel2021.svg';
import { TipoTabla } from '../types';

const TablasGenerales = (props: ITablasGeneralesProps) => {
  const {
    selectorTabla,
    seleccionTabla,
    handleChangeHabito,
    formatTabla,
  } = props;
  const { t } = useTranslation();
  const { pais } = useSelector((state: RootState) => state.Me);

  const getTituloCantidad = (titulo: string) => (
    <div>
      {t(titulo)}
      <div className="m-0">
        <span className="font-bold">{t('cantidad')}</span>
        <span className="font-normal"> (%)</span>
      </div>
    </div>
  );
  const getTituloCantidadGrado = (grado: number) => (
    <div>
      {`${t('grado')} ${grado}`}
      <div className="m-0 flex flex-col">
        <span className="font-normal">{`${t('mujer')} %`}</span>
        <span className="font-normal">{`${t('hombre')} %`}</span>
      </div>
    </div>
  );

  /* COLUMNAS */
  const columnasSinAlarma = [
    { Header: getTituloCantidad('mujer'), accessor: 'mujeres' },
    { Header: getTituloCantidad('hombre'), accessor: 'hombres' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
  ];
  const columnasAlarmaEstandar = [
    columnasSinAlarma[0],
    columnasSinAlarma[1],
    { Header: t('business_total_alarma'), accessor: 'totalAlarma' },
    columnasSinAlarma[2],
  ];
  const columnasAlarmaExtendido = [
    ...columnasAlarmaEstandar,
    { Header: t('sin_datos'), accessor: 'sin_datos' },
  ];
  const columnasExamen = [
    { Header: getTituloCantidad('estudio_expirado'), accessor: 'expirado' },
    { Header: getTituloCantidad('estudio_por_expirar'), accessor: 'porExpirar' },
    { Header: t('business_total_alarma'), accessor: 'totalAlarma' },
  ];
  const columnasExamenAudiometria = [
    ...columnasExamen,
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
  ];
  const columnasExamenEMP = [
    ...columnasExamen,
    { Header: t('sin_datos'), accessor: 'sin_datos' },
  ];
  const columnasHabitosRiesgo = [
    { Header: t('business_habitos_riesgo'), accessor: 'titulo' },
    { Header: getTituloCantidad('mujer'), accessor: 'mujeres' },
    { Header: getTituloCantidad('hombre'), accessor: 'hombres' },
    { Header: t('business_total_habito_alarma'), accessor: 'totalAlarma' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
    { Header: t('ex_consumidor'), accessor: 'exConsumidor' },
    { Header: t('observaciones'), accessor: 'comentarios' },
    {
      Header: `${t('business_perdida_anual_habito')} (${getMoneda(pais).simbolo})`,
      accessor: 'perdida',
    },
    { Header: '', accessor: 'verDetalle' },
  ];
  const columnasRiesgOperativo = [
    { Header: t('item_salud'), accessor: 'diagnostico' },
    { Header: getTituloCantidad('riesgo_operativo'), accessor: 'riesgoOperativo' },
    ...columnasSinAlarma,
  ];
  const columnasGradosSalud = [
    { Header: t('item_salud'), accessor: 'diagnostico' },
    { Header: getTituloCantidadGrado(4), accessor: 'grado4' },
    { Header: getTituloCantidadGrado(3), accessor: 'grado3' },
    { Header: getTituloCantidadGrado(2), accessor: 'grado2' },
    { Header: getTituloCantidadGrado(1), accessor: 'grado1' },
    { Header: getTituloCantidadGrado(0), accessor: 'grado0' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
  ];
  const columnasCategoriaSexo = [
    { Header: t('tipo'), accessor: 'categoria' },
    ...columnasSinAlarma,
  ];

  // Se colocan acá si la lista de columnas no es la estandar no está especificado en getTabla
  const selectoresColumnasDefault: {
    selector: TipoTabla,
    columnas: { Header: string | JSX.Element, accessor: string }[]
  }[] = [
    { selector:'audiometria', columnas: columnasExamenAudiometria},
    { selector:'emp', columnas: columnasExamenEMP},
    { selector:'reporte_general', columnas: columnasSinAlarma},
    { selector:'antecedentes_impacto_laboral', columnas: columnasCategoriaSexo},
    { selector:'discapacidades', columnas: columnasCategoriaSexo},
    { selector:'otros_riesgos', columnas: columnasAlarmaExtendido},
  ];

  const getTablaHabitos = (tabla: any[]) => {
    return (
      <Tabla
        columns={columnasHabitosRiesgo}
        centered
        firstColumnIsTitle
        data={formatTabla(tabla)}
        verDetalle={tabla.map((habito: any) => habito.totalAlarma > 0)}
        callBackVerDetalle={(tipo: string) => {
          console.log(tipo);
          handleChangeHabito(tipo as TipoTabla);
        }}
      />
    );
  }

  const getTabla = () => {
    const tabla = seleccionTabla?.general || null;
    if (tabla) {
      switch (selectorTabla) {
        case 'habitos': return getTablaHabitos(tabla);
        case 'alarma_tabaquismo': return getTablaHabitos(tabla);
        case 'alarma_cafeina_excesivo': return getTablaHabitos(tabla);
        case 'alarma_alcohol_trastorno': return getTablaHabitos(tabla);
        case 'alarma_alcohol_intensivo': return getTablaHabitos(tabla);
        case 'alarma_otras_drogas': return getTablaHabitos(tabla);
        case 'alarma_actividad_fisica': return getTablaHabitos(tabla);
        case 'alarma_refrescos': return getTablaHabitos(tabla);
        case 'riesgos_operativos':
          return (
            <div>
              <div className="flex flex-row items-center justify-end">
                <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
                <IconButton aria-label="edit" onClick={() => {}} color="primary">
                  <Excel2021Icon />
                </IconButton>
              </div>
              <Tabla
                columns={columnasRiesgOperativo}
                centered
                firstColumnIsTitle
                data={formatTabla(
                  tabla.sort((a: any, b: any) => a.riesgoOperativo > b.riesgoOperativo ? -1 : 1),
                  ['riesgoOperativo', 'mujeres', 'hombres'],
                )}
              />
            </div>
          );
        case 'grados_salud':
          return (
            <div>
              <div className="flex flex-row items-center justify-end">
                <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
                <IconButton aria-label="edit" onClick={() => {}} color="primary">
                  <Excel2021Icon />
                </IconButton>
              </div>
              <Tabla
                columns={columnasGradosSalud}
                centered
                firstColumnIsTitle
                data={formatTabla(
                  tabla,
                  ['grado0', 'grado1', 'grado2', 'grado3', 'grado4'],
                  true,
                  true,
                )}
              />
            </div>
          );
        default:
          return <Tabla
            columns={
              selectoresColumnasDefault.find(
                selCol => selCol.selector === selectorTabla
              )?.columnas || columnasAlarmaEstandar
            }
            centered
            data={['audiometria', 'emp'].includes(selectorTabla)
              ? formatTabla(tabla, ['expirado', 'porExpirar'])
              : formatTabla(tabla)
            }
            sort={false}
            firstColumnIsTitle={['antecedentes_impacto_laboral', 'discapacidades'].includes(selectorTabla)}
            pageSize={tabla.length > 15 ? 10 : 15}
          />;
      }
    }
    return <></>;
  };

  return (
    <>
      {[
        'habitos',
        'alarma_tabaquismo',
        'alarma_cafeina_excesivo',
        'alarma_alcohol_trastorno',
        'alarma_alcohol_intensivo',
        'alarma_otras_drogas',
        'alarma_actividad_fisica',
        'alarma_refrescos',
        'riesgos_operativos',
        'grados_salud',
        'antecedentes_impacto_laboral',
        'discapacidades'
      ].includes(selectorTabla) ? getTabla() : (
        <div className="grid grid-cols-8 pt-4 mb-2">
          <div className="col-span-2" />
          <div className="col-span-4">{getTabla()}</div>
        </div>
      )}
    </>
  );
};

export default TablasGenerales;
