import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, withStyles } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { ITablaExploracionProps } from './types';
import useStyles from '../styles';
import { ISintomaEF } from '../types';

const WhiteCheckbox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const BlackRadio = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const BlackTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

function TablaExploracion(props: ITablaExploracionProps) {
  const { contenidoTabla, setExploracionFisica, sexo } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const checkTotal = (tabla: ISintomaEF[]) =>
    tabla.length > 0 &&
    !tabla.find(
      (area) =>
        (area.nombreSintoma !== 'antecedentes-ef-otro' && area.normal !== 'Normal') ||
        (area.nombreSintoma === 'antecedentes-ef-otro' && area.normal === 'Anormal'),
    );
  const [totalCheck, setTotalCheck] = useState<boolean>(checkTotal(contenidoTabla));

  const checkArea = (area: ISintomaEF, normal: 'Normal' | 'Anormal' | '') => {
    let comentarioSintoma = '';
    if (normal === 'Normal' && area.nombreSintoma !== 'antecedentes-ef-otro') {
      comentarioSintoma =
        area.nombreSintoma === 'antecedentes-ef-gm'
          ? t(`${area.nombreSintoma}-o-${sexo.toLowerCase()}`)
          : t(`${area.nombreSintoma}-o`);
    }
    return {
      comentarioSintoma,
      nombreSintoma: area.nombreSintoma,
      normal,
    };
  };

  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valCheck = event.target.checked as boolean;
    setTotalCheck(valCheck);
    const newContenidoTabla = valCheck
      ? contenidoTabla.map((preR) =>
          checkArea(preR, preR.nombreSintoma === 'antecedentes-ef-otro' ? '' : 'Normal'),
        )
      : contenidoTabla.map((preR) => ({
          comentarioSintoma: '',
          nombreSintoma: preR.nombreSintoma,
          normal: '',
        }));
    setExploracionFisica((prev) => ({
      ...prev,
      contenidoTabla: newContenidoTabla,
    }));
  };

  const handleChangeRadioSeleccion = (event: React.ChangeEvent<HTMLInputElement>, indx: number) => {
    const newContenidoTabla = [...contenidoTabla];
    newContenidoTabla[indx] = checkArea(
      newContenidoTabla[indx],
      event.target.value as 'Normal' | 'Anormal' | '',
    );
    setTotalCheck(checkTotal(newContenidoTabla));
    setExploracionFisica((prev) => ({
      ...prev,
      contenidoTabla: newContenidoTabla,
    }));
  };

  const handleChangeComentarios = (event: React.ChangeEvent<{ value: unknown }>, indx: number) => {
    const newContenidoTabla = contenidoTabla.map((preR, indi) => {
      if (indi === indx) {
        return {
          comentarioSintoma: event.target.value as string,
          nombreSintoma: preR.nombreSintoma,
          normal: preR.normal,
        };
      }
      return preR;
    });
    setExploracionFisica((prev) => ({
      ...prev,
      contenidoTabla: newContenidoTabla,
    }));
  };

  return (
    <div className="border border-solid rounded-md border-gray-400">
      <div className="grid grid-cols-12 gap-4 bg-blue-600 rounded-t-md text-white text-center items-center py-2">
        <div className="col-span-1 flex items-center">
          <WhiteCheckbox name="checkbox-all" checked={totalCheck} onChange={handleChangeCheck} />
          {t('antecedentes-ef-sel-normal')}
        </div>
        <div className="col-span-1">{t('antecedentes-ef-sel-anormal')}</div>
        <span className="col-span-2">{t('antecedentes-ef-area')}</span>
        <span className="col-span-8">{t('comentarios')}</span>
      </div>
      {contenidoTabla.map((single, index) => (
        <div
          className={`grid grid-cols-12 gap-4 ${
            index % 2 === 0 ? 'bg-blue-300' : ''
          } text-center items-center py-4`}
          key={single.nombreSintoma}
        >
          <div className="col-span-1">
            <BlackRadio
              value="Normal"
              name={`radio-button-normal-${index}`}
              checked={single.normal === 'Normal'}
              onChange={(e) => handleChangeRadioSeleccion(e, index)}
            />
          </div>
          <div className="col-span-1">
            <BlackRadio
              value="Anormal"
              name={`radio-button-anormal-${index}`}
              checked={single.normal === 'Anormal'}
              onChange={(e) => handleChangeRadioSeleccion(e, index)}
            />
          </div>
          <span className="col-span-2 text-left">{t(single.nombreSintoma)}</span>
          <div className="col-span-8 pr-4">
            <BlackTextField
              disabled={contenidoTabla[index].normal.length === 0}
              multiline
              rows="2"
              variant="outlined"
              fullWidth
              InputProps={{
                className: classes.backgroundWhite,
                inputProps: { maxLength: 500, autoComplete: 'off' },
              }}
              value={single.comentarioSintoma}
              onChange={(e) => handleChangeComentarios(e, index)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default TablaExploracion;
