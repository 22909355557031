/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import WarningIcon from '@material-ui/icons/Warning';
import { IModalEliminarMedicamentoRecetaProps } from './types';

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" ref={ref} {...props}>
      {children as React.ReactElement}
    </Slide>
  ),
);

function ModalEliminarMedicamentoReceta(props: IModalEliminarMedicamentoRecetaProps) {
  const { open, titulo, descripcion, borrarMedicamentoRecetaActual, callBackClose } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="delete-medicamento-receta-dialog-titulo"
      aria-describedby="delete-medicamento-receta-dialog-descripcion"
    >
      <div className="flex justify-between items-center bg-blue-500 text-white pl-4">
        <span className="text-lg">{titulo}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <WarningIcon className="text-yellow-500" color="inherit" />
        <DialogContentText>{descripcion}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex justify-center">
          <div className="mr-4">
            <Button
              onClick={() => {
                borrarMedicamentoRecetaActual();
              }}
              color="primary"
              variant="contained"
            >
              {t('aceptar')}
            </Button>
          </div>
          <div>
            <Button onClick={callBackClose} color="primary" variant="outlined">
              {t('cancelar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ModalEliminarMedicamentoReceta;
