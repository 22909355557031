import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import AdmonNavbar from '@containers/AdmonNavbar';
import Navbar from '@containers/Navbar';
import NavbarPaciente from '@containers/NavbarPaciente';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { RootState } from 'src/store';
import { ILoggedInSubscribedRouteProps } from './types';
import { useEffect, useRef } from 'react';

function LoggedInSubscribedRoute({ children, ...otrasProps }: ILoggedInSubscribedRouteProps) {
  const { suscrito, acreditado } = useSelector((state: RootState) => state.Auth);
  const { rol, esPaciente, secciones } = useSelector((state: RootState) => state.Me);
  const { rutaPrincipal } = useSelector((state: RootState) => state.RutasExpediente);
  const dialogOpen = useSelector((state: RootState) => state.DialogOpen);
  const isWideScreen = useBreakPoint();
  const ref = useRef<HTMLDivElement | null>(null);

  const toggleOverflow = (overflow: boolean, delay?: boolean) => {
    if (!isWideScreen) {
      if (overflow) {
        if (delay) {
          setTimeout(() => {
            ref.current?.classList.remove('overflow-y-auto');
          }, 200);
        } else {
          ref.current?.classList.remove('overflow-y-auto');
        }
      } else {
        ref.current?.classList.add('overflow-y-auto');
      }
    }
  };

  useEffect(() => {
    toggleOverflow(dialogOpen);
  }, [dialogOpen]);

  useEffect(() => {
    toggleOverflow(rutaPrincipal === 'main');
  }, [rutaPrincipal]);

  if (acreditado === false) {
    return (
      <Route
        {...otrasProps}
        render={({ location }) => (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )}
      />
    );
  }

  return (
    <Route
      {...otrasProps}
      render={({ location }) =>
        rol.grupo !== 'paciente' && suscrito === false ? (
          <Redirect to={{ pathname: '/subscripcion', state: { from: location } }} />
        ) : (
          <div className="flex">
            {secciones.length > 0 &&
              secciones.find((valor) => valor.idSeccion >= 1 && valor.idSeccion <= 149) && (
                <>{esPaciente ? <NavbarPaciente /> : <Navbar />}</>
              )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion >= 150) && (
              <AdmonNavbar />
            )}
            <main
              ref={ref}
              className={`top-16 ${
                isWideScreen ? 'relative w-full' : 'fixed bottom-0 left-0 right-0'
              }`}
            >
              {children}
            </main>
          </div>
        )
      }
    />
  );
}

export default LoggedInSubscribedRoute;
