import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setSegurosPlanes, setSegurosPlanesUsuario } from '@actions/paciente/actions';
import { setRutaPerfil, setRutaPrincipal } from '@actions/rutasExpediente/actions';
import { ICatalogosEstandar } from '@common/types';
import { getCatalogoAseguradoras } from '@utils/getCatalogos';
/** importacion de funciones */
import { useApiRequest } from '@hooks/useApiRequest';
import { validarFecha, validarFechaEsfutura } from '@common/functions';
import { ModalForm } from '@components/modals/ModalForm';
import { RootState } from 'src/store';
import {
  updateSegurosPlanes,
  updateSegurosPlanesPaciente,
  updateSegurosPlanesUsuario,
} from '@utils/sendInfo';
import { ISegurosPlanes, segurosPlanesInitial } from './types';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import DateInput from '@components/FormElemntsLibrary/DateInput';
import TextArea from '@components/FormElemntsLibrary/TextArea';
import NumberInput from '@components/FormElemntsLibrary/NumberInput';

/** Formulario del seguro del paciente */
function SegurosPlanes() {
  const dispatch = useDispatch();
  const { apiRequest, apiRequests } = useApiRequest();
  const { idPaciente, idUsuario } = useSelector((state: RootState) => state.BasicosPaciente);
  const {
    idMedico,
    idUsuario: idMiUsuario,
    esPaciente,
    pais,
  } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const {
    expediente: {
      perfil: { segurosPlanes },
    },
    usuario: {
      perfil: { segurosPlanes: segurosPlanesUsuario },
    },
    cambios: {
      perfil: { segurosPlanes: cambios },
    },
  } = useSelector((state: RootState) => state.Paciente);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');

  const [segurosPlanesTemp, setSegurosPlanesTemp] = useState<ISegurosPlanes>(segurosPlanesInitial);
  /** catalogos */
  const [catAseguradoras, setCatAseguradoras] = useState<ICatalogosEstandar[]>([]);
  /** logica */
  const hayCambios: boolean = idUsuario > 0 && cambios.some((c: string) => c.length > 0);
  const verificarErrorValidacionGeneral =
    !segurosPlanesTemp.aseguradora &&
    !segurosPlanesTemp.numPoliza &&
    !segurosPlanesTemp.vigencia.dia &&
    !segurosPlanesTemp.vigencia.mes &&
    !segurosPlanesTemp.vigencia.anio &&
    !segurosPlanesTemp.sumaAsegurada &&
    !segurosPlanesTemp.noTieneAseguradora;
  /** hooks como useEffect */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getCatalogoAseguradoras(pais).then((result: ICatalogosEstandar[]) => {
      setCatAseguradoras(result);
    });
  }, []);

  useEffect(() => {
    if (segurosPlanes.loaded && !segurosPlanesTemp.loaded) {
      setSegurosPlanesTemp(segurosPlanes);
    }
  }, [segurosPlanes]);

  useEffect(() => {
    if (segurosPlanesTemp.noTieneAseguradora) {
      setSegurosPlanesTemp({
        ...segurosPlanesTemp,
        aseguradora: { id: 0, label: '' },
        cualAseguradora: '',
        numPoliza: '',
        sumaAsegurada: '',
        vigencia: { dia: '', mes: '', anio: '' },
      });
    }
  }, [segurosPlanesTemp.noTieneAseguradora]);

  /** handlers de desabilitacion o no de otros campos */
  /** Handlers del componente */
  const handleInputChange = (e: { name: string; value: any }) =>
    setSegurosPlanesTemp((prevData) => ({ ...prevData, [e.name]: e.value }));

  const getData = (datos: ISegurosPlanes, idUser?: number) => ({
    idMedico,
    idPaciente,
    idUsuario: idUser,
    idConsultorio,
    noTieneAseguradora: datos.noTieneAseguradora,
    aseguradora: datos.aseguradora ? datos.aseguradora.id : '',
    cualAseguradora: datos.cualAseguradora,
    numPoliza: datos.numPoliza,
    vigDia: datos.vigencia.dia.toString(),
    vigMes: datos.vigencia.mes.toString(),
    vigAnio: datos.vigencia.anio.toString(),
    sumaAsegurada: datos.sumaAsegurada,
    comentarios: datos.comentarios,
  });

  /** Handlers para enviar la informacion */
  const guardarDatosExpediente = () => {
    const datosSegurosPlanes = getData(segurosPlanesTemp, idUsuario);
    const sendFunctions = [updateSegurosPlanes(datosSegurosPlanes)];
    if (!esPaciente && idUsuario > 0) {
      sendFunctions.push(updateSegurosPlanesPaciente(datosSegurosPlanes));
    }
    apiRequests({
      type: 'send',
      requestFunctions: sendFunctions,
      successFunctions: [
        () => dispatch(setSegurosPlanes({ ...segurosPlanesTemp, loaded: true })),
        () => dispatch(setSegurosPlanesUsuario({ ...segurosPlanesTemp, loaded: true })),
      ],
    });
  };
  // Guarda los datos del usuario del paciente cuando el usuario es el paciente
  const guardarDatosUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: updateSegurosPlanesUsuario(getData(segurosPlanesTemp, idMiUsuario)),
      successFunction: () => {
        dispatch(setSegurosPlanes({ ...segurosPlanesTemp, loaded: true }));
        dispatch(setRutaPrincipal('antecedentes'));
      },
    });
  };
  // Guarda los datos del usuario en el expediente cuando el médico acepta los cambios
  const guardarDatosDeUsuarioAExpediente = () => {
    apiRequest({
      type: 'send',
      requestFunction: updateSegurosPlanes(getData(segurosPlanesUsuario)),
      successFunction: () => {
        dispatch(setSegurosPlanes(segurosPlanesUsuario));
        setSegurosPlanesTemp(segurosPlanesUsuario);
      },
    });
  };
  // Guarda los datos del expediente en el usuario cuando el médico rechaza los cambios
  const guardarDatosDeExpedienteAUsuario = () => {
    apiRequest({
      type: 'send',
      requestFunction: updateSegurosPlanesPaciente(getData(segurosPlanes, idUsuario)),
      successFunction: () => dispatch(setSegurosPlanesUsuario(segurosPlanes)),
    });
  };

  return (
    <div id="seguros-planes-paciente" aria-labelledby="seguros-planes-paciente">
      <ModalForm
        title={t('seguros_y_planes')}
        id="seguros-planes-form"
        submitForm={esPaciente ? guardarDatosUsuario : guardarDatosExpediente}
        aceptarCambios={guardarDatosDeUsuarioAExpediente}
        rechazarCambios={guardarDatosDeExpedienteAUsuario}
        disableButton={verificarErrorValidacionGeneral}
        hayCambios={hayCambios}
        cambios={cambios}
        backAction={() => dispatch(setRutaPerfil('main'))}
      >
        <div className="flex flex-col">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-x-4">
            <SelectInput
              name="aseguradora"
              options={catAseguradoras}
              disableOption={{
                name: 'noTieneAseguradora',
                value: segurosPlanesTemp.noTieneAseguradora,
                setValue: handleInputChange,
                label: t('no_tiene'),
                position: 'right',
              }}
              value={String(segurosPlanesTemp.aseguradora.id)}
              sendObject
              setValue={handleInputChange}
              isDisabled={hayCambios}
            />
            {String(segurosPlanesTemp.aseguradora) === '9997' && (
              <TextInput
                label={t('especifica')}
                name="cualAseguradora"
                value={segurosPlanesTemp.cualAseguradora}
                setValue={handleInputChange}
                maxLength={60}
                disabled={hayCambios}
              />
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
            <TextInput
              label={t('num_poliza')}
              name="numPoliza"
              value={segurosPlanesTemp.numPoliza}
              setValue={handleInputChange}
              maxLength={60}
              disabled={hayCambios || segurosPlanesTemp.noTieneAseguradora}
            />
            <DateInput
              containerClass="md:col-span-2"
              name="vigencia"
              value={segurosPlanesTemp.vigencia}
              setValue={handleInputChange}
              disabled={hayCambios || segurosPlanesTemp.noTieneAseguradora}
              yearsMore={5}
              validacion={(dia, mes, anio) => {
                const invalido =
                  !!dia &&
                  !!mes &&
                  !!anio &&
                  (!validarFecha(anio, mes, dia) || !validarFechaEsfutura(anio, mes, dia));
                return invalido ? t('vigencia_expirada') : '';
              }}
            />
            <NumberInput
              label={t('suma_asegurada')}
              name="sumaAsegurada"
              value={segurosPlanesTemp?.sumaAsegurada}
              setValue={handleInputChange}
              isCurrency
              disabled={hayCambios || segurosPlanesTemp.noTieneAseguradora}
            />
          </div>
          {!esPaciente && (
            <TextArea
              label={t('comentario')}
              name="comentarios"
              value={segurosPlanesTemp.comentarios}
              setValue={handleInputChange}
              disabled={hayCambios}
            />
          )}
        </div>
      </ModalForm>
    </div>
  );
}

export default SegurosPlanes;
