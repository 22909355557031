import { FC, useEffect, useState } from 'react';
import { ProgresBarProps } from './type';
import { useTranslation } from 'react-i18next';

export const ProgressBar: FC<ProgresBarProps> = ({ percentage }) => {
  const { t } = useTranslation();
  const [strPercentage, setStrPercentage] = useState('');

  useEffect(() => {
    const perc = String(percentage.toFixed(2));
    const splited = perc.split('.');
    setStrPercentage(splited[1] === '00' ? splited[0] : perc);
  }, [percentage]);

  return (
    <div className="w-full h-auto px-4 py-2 font-semibold bg-[#EEF6FF]">
      <p className="text-md font-light text-[#6B7280]">{t('avance_llenado')}</p>

      <div className="w-full h-2 my-2 rounded-lg bg-[#D5D5D5]">
        <div
          style={{ width: `${strPercentage}%`, overflow: 'hidden' }}
          className="h-2 mt-2 mb-2 rounded-lg bg-[#1E40AF]"
        />
      </div>
      <div className="flex justify-between">
        <p className="text-md font-light text-[#6B7280]">
          {percentage === 100 ? '' : `${strPercentage}%`}
        </p>
        <p className="text-md font-light text-[#6B7280] left-2">100%</p>
      </div>
    </div>
  );
};
