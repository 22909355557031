import { useTranslation } from 'react-i18next';
import IAlertDialogProps from './types';
import { ITipoMascota } from '@containers/TabsPaciente/Antecedentes/PersonalesNoPatologicos/Vivienda/types';
import MascotasComponente from './Mascotas/index';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Button } from '@components/FormElemntsLibrary/Button';
import { useBreakPoint } from '@hooks/useBreakpoint';
import Dialog from '../Dialog';

function AgregarMascotas(props: IAlertDialogProps) {
  const {
    open,
    hayCambios = false,
    viviendaTemp,
    setViviendaTemp,
    catAnimales,
    callBackClose,
  } = props;
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const isWideScreen = useBreakPoint();

  const tipoMascotaInitial: ITipoMascota = {
    idAnimal: { id: 0, label: '' },
    especifica: '',
    cantidad: '',
  };

  const handleMascotaChange = (e: { name: string; value: any; index: number }) => {
    let val = e.value;
    if (e.name === 'cantidad') {
      val = String(val);
    }
    setViviendaTemp((prevData) => ({
      ...prevData,
      tipo: prevData.tipo.map((mascota, indx) =>
        indx === e.index ? { ...mascota, [e.name]: val } : mascota,
      ),
    }));
  };

  const addAnimal = () => {
    setViviendaTemp({
      ...viviendaTemp,
      tipo: [...viviendaTemp.tipo, tipoMascotaInitial],
    });
  };
  const deleteAnimal = (index: number) => {
    if (viviendaTemp.tipo.length === 1) {
      setViviendaTemp({
        ...viviendaTemp,
        tipo: [tipoMascotaInitial],
      });
    } else {
      const newTipo = [...viviendaTemp.tipo];
      newTipo.splice(index, 1);
      setViviendaTemp({
        ...viviendaTemp,
        tipo: newTipo,
      });
    }
  };

  return (
    <Dialog
      title={t('antecedentes-pnp-vivienda-mascotas')}
      open={open}
      onClose={callBackClose}
      fullScreen={!isWideScreen}
      maxWidth="md"
    >
      {viviendaTemp.tipo.map((val, index) => (
        <MascotasComponente
          {...val}
          hayCambios={hayCambios}
          catAnimales={catAnimales}
          eliminarMascota={(index) => deleteAnimal(index)}
          handleChange={handleMascotaChange}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={String(index)}
        />
      ))}
      <div className="flex float-right mb-4">
        <Button
          label={t('antecedentes-pnp-vivienda-amascota')}
          disable={
            hayCambios ||
            viviendaTemp.tipo.some(
              (mascota) => mascota.idAnimal.id === 0 && !mascota.especifica.length,
            )
          }
          color="blue"
          onClick={addAnimal}
          icon="add"
        />
      </div>
    </Dialog>
  );
}

export default AgregarMascotas;
