import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTratamientos } from '@actions/paciente/actions';
import { RootState } from 'src/store';
import { deleteTerapiaFarmacologicaMedicamento } from '@utils/deleteInfo';
import { useApiRequest } from '@hooks/useApiRequest';
import { IListaTratamientoProps } from './types';
import Tratamiento from './Tratamiento';
import VerDetalleMedicamento from '../../../../../DiagnosticoPlan/Plan/TerapiaFarmacologica/ListaMedicamentos/VerDetalleMedicamento';
import ModalEliminarMedicamentoReceta from '../../../../../DiagnosticoPlan/Plan/TerapiaFarmacologica/ListaMedicamentos/ModalEliminarMedicamentoReceta';
import {
  IMedicamento,
  medicamentoInitial,
} from '../../../../../DiagnosticoPlan/Plan/TerapiaFarmacologica/types';

function ListaTratamiento({
  mActuales,
  mAnteriores,
  setToggleEditar,
  setMedicamentoAEditar,
  setModoEditar,
}: IListaTratamientoProps) {
  const { t } = useTranslation();
  const { apiRequest } = useApiRequest();
  const dispatch = useDispatch();
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const { tratamientos } = useSelector(
    (state: RootState) => state.Paciente.expediente.antPatologicos,
  );
  const [openDetalle, setOpenDetalle] = useState<boolean>(false);
  const [medicamentoAVerDetalle, setMedicamentoAVerDetalle] =
    useState<IMedicamento>(medicamentoInitial);

  const [openModalEditar, setOpenModalEditar] = useState<boolean>(false);
  const [openModalEliminar, setOpenModalEliminar] = useState<boolean>(false);
  const [idMedicamentoAEliminar, setIDMedicamentoAEliminar] = useState<number>(0);
  const handleDetalleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenDetalle(false);
  };

  const handleVerDetalleMedicamentoActual = (indMedicamento: number) => {
    setMedicamentoAVerDetalle(mActuales[indMedicamento]);
    setOpenDetalle(true);
  };

  const handleVerDetalleMedicamentoAnterior = (indMedicamento: number) => {
    setMedicamentoAVerDetalle(mAnteriores[indMedicamento]);
    setOpenDetalle(true);
  };

  const handleEditarMedicamentoActual = (indMedicamento: number) => {
    setMedicamentoAEditar(mActuales[indMedicamento]);
    setOpenModalEditar(true);
  };

  const handleEditarMedicamentoAnterior = (indMedicamento: number) => {
    setMedicamentoAEditar(mAnteriores[indMedicamento]);
    setOpenModalEditar(true);
  };

  const handleBorrarMedicamentoActual = (indMedicamento: number) => {
    setIDMedicamentoAEliminar(mActuales[indMedicamento].id);
    setOpenModalEliminar(true);
  };

  const handleBorrarMedicamentoAnterior = (indMedicamento: number) => {
    setIDMedicamentoAEliminar(mAnteriores[indMedicamento].id);
    setOpenModalEliminar(true);
  };

  const handleModalEliminarClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenModalEliminar(false);
  };

  const handleModalEditarClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenModalEditar(false);
  };

  const eliminarMedicamento = () => {
    apiRequest({
      type: 'delete',
      requestFunction: deleteTerapiaFarmacologicaMedicamento({
        idMedicamento: idMedicamentoAEliminar,
        idMedico,
      }),
      successFunction: () => {
        // se remueve el medicamento borrado del catalogo de medicamento
        dispatch(setTratamientos(tratamientos.filter((ma) => ma.id !== idMedicamentoAEliminar)));
      },
    });
  };

  return (
    <div>
      <VerDetalleMedicamento
        open={openDetalle}
        handleDetalleClose={handleDetalleClose}
        medicamentoAVerDetalle={medicamentoAVerDetalle}
      />
      <ModalEliminarMedicamentoReceta
        open={openModalEliminar}
        titulo={t('_atencion_')}
        descripcion={t('_seguro_que_deseas_eliminar_el_tratamiento_')}
        borrarMedicamentoRecetaActual={eliminarMedicamento}
        callBackClose={handleModalEliminarClose}
      />
      <ModalEliminarMedicamentoReceta
        open={openModalEditar}
        titulo={t('_atencion_')}
        descripcion={t('si_vas_a_agregar_un_nuevo_medicamento_se_borrara_el_que_estas_creando')}
        borrarMedicamentoRecetaActual={() => {
          setModoEditar(true);
          setOpenModalEditar(false);
          setToggleEditar((prevVal) => !prevVal);
        }}
        callBackClose={handleModalEditarClose}
      />
      <h3 className="text-blue-500 font-normal m-0">{t('tratamiento_actual')}</h3>
      {mActuales.map((medicamento, indx) => (
        <Tratamiento
          key={medicamento.id}
          indice={indx}
          medicamento={medicamento}
          handleVerDetalle={handleVerDetalleMedicamentoActual}
          handleEditar={handleEditarMedicamentoActual}
          handleBorrar={handleBorrarMedicamentoActual}
        />
      ))}
      <h3 className="text-blue-500 font-normal m-0">{t('tratamiento_anterior')}</h3>
      {mAnteriores.map((medicamento, indx) => (
        <Tratamiento
          key={medicamento.id}
          indice={indx}
          medicamento={medicamento}
          handleVerDetalle={handleVerDetalleMedicamentoAnterior}
          handleEditar={handleEditarMedicamentoAnterior}
          handleBorrar={handleBorrarMedicamentoAnterior}
        />
      ))}
    </div>
  );
}

export default ListaTratamiento;
