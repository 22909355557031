import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInputProps } from './type';
import { ArrowBigUpDashIcon, EyeIcon, EyeClosedIcon } from 'lucide-react';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { SyntheticKeyboardEvent } from 'react-draft-wysiwyg';
import { validacionSoloNumeros } from '@constants/validaciones';

export const PasswordInput: FC<TextInputProps> = ({
  name,
  containerClass = '',
  placeholder: externalPlaceholder,
  isRequired = false,
  label: externalLabel,
  floatingLabel,
  labelAligmet = 'top',
  value,
  validacion,
  validacionBlur,
  helperText,
  setValue,
  requirements: externalRequirements,
  errorMessage: externalError,
  onValidationChange,
  autocomplete = false,
  numeric = false,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [label, setLabel] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [show, setShow] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isFloating = isFocused || value?.length;
  const isWideScreen = useBreakPoint();
  const requirements = externalRequirements || [
    {
      text: t('condicion_contrasenia_n_caracteres'),
      check: value?.length ? value.length >= 8 : null,
    },
    {
      text: t('condicion_contrasenia_mayuscula'),
      check: value?.length ? /[A-Z]/.test(value) : null,
    },
    {
      text: t('condicion_contrasenia_minuscula'),
      check: value?.length ? /[a-z]/.test(value) : null,
    },
    { text: t('condicion_contrasenia_numero'), check: value?.length ? /\d/.test(value) : null },
    {
      text: t('condicion_contrasenia_caracter_especial'),
      check: value?.length ? /[!@#$%^&*(),.?":{}|<>]/.test(value) : null,
    },
  ];

  useEffect(() => {
    onValidationChange?.({
      name,
      hasError:
        errorMessage.length > 0 ||
        (isRequired && !value.length) ||
        requirements.some((req) => req.check === false) ||
        (validacion !== undefined && validacion(value).length > 0),
      isMissing: isRequired && !value.length,
    });
  }, [isRequired, value, errorMessage]);

  useEffect(() => {
    if (floatingLabel) {
      setLabel('');
    } else {
      setLabel(externalLabel === undefined ? t(name) : externalLabel);
    }
  }, [floatingLabel, externalLabel]);

  useEffect(() => {
    if (externalPlaceholder) {
      setPlaceholder(`${externalPlaceholder}${isRequired ? '*' : ''}`);
    } else if (label.length > 0) {
      setPlaceholder(`${label}${isRequired ? '*' : ''}`);
    }
  }, [label, externalPlaceholder, isRequired]);

  useEffect(() => {
    if (externalError) {
      setErrorMessage(externalError);
    }
  }, [externalError]);

  const handleKeyDown = (e: SyntheticKeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { value: newValue } = e.target;
    if (numeric && !validacionSoloNumeros(newValue)) {
      return;
    }
    setValue?.({
      name,
      value: newValue,
    });
    setErrorMessage(validacion ? validacion(newValue) : '');
  };

  const handleBlur = () => {
    setIsFocused(false);

    // Validación básica cuando pierde el focus
    if (isRequired && value?.trim() === '') {
      setErrorMessage(t('error_campo_en_blanco'));
    } else if (validacionBlur) {
      setErrorMessage(validacionBlur(value?.trim() || ''));
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isWideScreen) {
      setTimeout(() => {
        event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300);
    }
    setIsFocused(true);
  };

  const getStyle = () =>
    errorMessage
      ? 'border-red-500 ring-2 ring-red-200'
      : 'border-gray-300 focus:ring-2 focus:ring-blue-500';

  return (
    <div className={`w-full h-auto my-[10px] ${containerClass}`}>
      {labelAligmet === 'top' && label && (
        <div className="flex items-center justify-between mb-2">
          <label
            htmlFor={name}
            className={`block font-medium text-base ${errorMessage ? 'text-red-500' : 'text-gray-600'}`}
          >
            {label} {isRequired && <span className="text-red-500">*</span>}
          </label>
        </div>
      )}

      <div className="flex items-center gap-4">
        <div className="flex-1 relative">
          <div className="flex flex-row relative">
            {floatingLabel && (
              <label
                onClick={() => inputRef.current?.focus()}
                className={`absolute left-3 bg-white rounded-sm px-1 transition-all ${
                  isFocused || !!value?.length
                    ? 'text-gray-600 -top-2 text-xs'
                    : 'text-gray-400 top-[15px] text-sm'
                } cursor-text bg-[linear-gradient(to_top,rgba(255,255,255,1)_60%,rgba(255,255,255,0)_100%)]`}
              >
                {`${floatingLabel}${isRequired ? '*' : ''}`}
              </label>
            )}
            <input
              ref={inputRef}
              type={show ? 'text' : 'password'}
              id={name}
              name={name}
              placeholder={labelAligmet === 'border' && !isFloating ? '' : placeholder}
              value={value}
              onKeyDownCapture={handleKeyDown}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyUp={(e) => setIsCapsLockOn(e.getModifierState('CapsLock'))}
              onKeyDown={(e) => setIsCapsLockOn(e.getModifierState('CapsLock'))}
              onCopy={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onCut={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onPaste={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              className={`w-full h-[48px] text-base px-3 pr-20 ${getStyle()} rounded-md border transition focus:outline-none`}
              autoComplete={autocomplete ? undefined : 'off'}
            />
          </div>
          {labelAligmet === 'border' && label && (
            <label
              htmlFor={name}
              className={`absolute text-sm transition-all duration-200 ${
                isFloating
                  ? `-top-2.5 left-2 bg-white px-1 ${
                      errorMessage ? 'text-red-500' : 'text-blue-500'
                    }`
                  : 'top-[50%] -translate-y-[50%] left-3 text-gray-500'
              }`}
            >
              {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
          )}
          <div className="flex flex-row">
            <span className="absolute right-10 top-1/2 -translate-y-1/2 text-sm text-gray-500">
              {isCapsLockOn && (
                <div data-tooltip={t('mayusculas_activadas')}>
                  <ArrowBigUpDashIcon />
                </div>
              )}
            </span>
            <span
              className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-gray-500"
              onClick={() => setShow(!show)}
              data-tooltip={t(show ? 'ocultar_contrasenia' : 'mostrar_contrasenia')}
            >
              {show ? <EyeIcon /> : <EyeClosedIcon />}
            </span>
          </div>
        </div>
      </div>

      {(errorMessage || helperText) && (
        <p className={`mt-1 text-xs ${errorMessage ? 'text-red-500' : 'text-gray-500'}`}>
          {errorMessage || helperText}
        </p>
      )}
      <ul className="list-disc pl-6 pt-2">
        {requirements.map((req, index) => (
          <li
            key={index}
            className={`text-xs ${
              req.check === null ? 'text-gray-400' : `text-${req.check ? 'blue' : 'red'}-600`
            }
            `}
          >
            {req.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PasswordInput;
