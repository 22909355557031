import { FC, useEffect, useRef, useState } from 'react';
import CountryInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { PhoneInputProps } from './type';
import { useBreakPoint } from '@hooks/useBreakpoint';
import { validacionLongTelefono } from '@constants/validaciones';
import { CircleX, CircleCheckBig } from 'lucide-react';

export const PhoneInput: FC<PhoneInputProps> = ({
  index,
  name,
  containerClass = '',
  label: externalLabel,
  isRequired: isRequiredExternal = false,
  floatingLabel,
  placeholder,
  disableOption,
  setValue,
  disabled = false,
  value,
  onValidationChange,
  loginInput = false,
  hideValidationIcon = false,
}) => {
  const { pais, esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [label, setLabel] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [wasFocused, setWasFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [lenTel, setLenTel] = useState<number>(0);
  const [dropdownStyle, setDropdownStyle] = useState({});
  const inputRef = useRef<HTMLDivElement>(null);
  const isWideScreen = useBreakPoint();

  useEffect(() => {
    setLenTel(validacionLongTelefono(pais));
  }, [pais]);

  useEffect(() => {
    setIsRequired(isRequiredExternal);
  }, [isRequiredExternal]);

  useEffect(() => {
    onValidationChange?.({
      name,
      hasError:
        errorMessage.length > 0 ||
        ((isRequired || value.numero.length > 0) && value.numero.length < lenTel),
      index,
      isMissing: isRequired && !value.numero.length,
    });
  }, [isRequired, value, lenTel, errorMessage]);

  useEffect(() => {
    if (floatingLabel) {
      setLabel('');
    } else {
      setLabel(externalLabel === undefined ? t(name) : externalLabel);
    }
  }, [floatingLabel, externalLabel]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const dropdownHeight = 200; // Altura estimada del dropdown

      if (spaceBelow < dropdownHeight) {
        setDropdownStyle({ top: 'auto', bottom: '100%' });
      } else {
        setDropdownStyle({ top: '100%', bottom: 'auto' });
      }
    }
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    setWasFocused(!!inputRef.current?.contains(event.target as Node));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wasFocused]);

  const handlePhoneChange = (value: string, data: any) => {
    setErrorMessage('');

    // Crear evento sintético
    const syntheticEvent = {
      name,
      value: { numero: value.slice(data.dialCode.length), codigo: data.dialCode },
      type: 'tel',
    };

    setValue(syntheticEvent);
  };

  const handleBlur = () => {
    if (isRequired && (!value || value.numero.length === 0)) {
      setErrorMessage(t('error_campo_en_blanco'));
    } else if (value && value.numero.length > 0 && value.numero.length < lenTel) {
      setErrorMessage(`${t('error_n_digitos_1')} ${lenTel} ${t('error_n_digitos_2')}`);
    } else {
      setErrorMessage('');
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!isWideScreen) {
      setTimeout(() => {
        event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300);
    }
  };

  const handleDisableChange = () => {
    const newState = !disableOption?.value;
    setIsDisabled(newState);
    setErrorMessage('');

    setIsRequired(isRequiredExternal && !newState);
    if (disableOption?.name) {
      setValue({ name: disableOption.name, value: newState });
    }
    if (newState) {
      setValue({ name, value: { numero: '', codigo: '' } });
    }
  };

  const renderDisableCheckbox = () => (
    <label className="flex items-center text-gray-600 whitespace-nowrap ml-4">
      <input
        type="checkbox"
        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-500"
        checked={!!disableOption?.value}
        onChange={handleDisableChange}
      />
      {disableOption?.label}
    </label>
  );

  const customStyles = {
    containerStyle: {
      width: '100%',
    },
    inputStyle: {
      width: '100%',
      height: '48px',
      fontSize: '16px',
      borderRadius: loginInput ? '0 0 0.300rem 0.300rem' : '0.300rem',
      border: errorMessage
        ? '1px solid rgb(239 68 68)'
        : `1px solid ${
            esPaciente && value.numero.length > 0
              ? 'rgb(134 239 172 / var(--tw-border-opacity, 1))'
              : 'rgb(209 213 219)'
          }`,
      backgroundColor: isDisabled ? '#F9FAFB' : 'white',
      color: isDisabled ? '#9CA3AF' : 'black',
      padding: '8px 8px 8px 58px',
      boxShadow: errorMessage ? '0 0 0 2px rgb(254 202 202)' : 'none',
    },
    buttonStyle: {
      border: errorMessage
        ? '1px solid rgb(239 68 68)'
        : `1px solid ${
            esPaciente && value.numero.length > 0
              ? 'rgb(134 239 172 / var(--tw-border-opacity, 1))'
              : 'rgb(209 213 219)'
          }`,
      // borderRight: 'none',
      borderRadius: loginInput ? '0 0 0 0.300rem' : '0.300rem 0 0 0.300rem',
      backgroundColor: isDisabled ? '#F9FAFB' : 'white',
    },
  };

  return (
    <div className={`w-full h-fit my-[10px] ${containerClass}`}>
      <div className="">
        {!loginInput && (
          <div className="flex items-center justify-between mb-2">
            {label && (
              <label
                htmlFor={name}
                className={`block font-medium text-base ${
                  errorMessage ? 'text-red-500' : 'text-gray-700'
                } ${isDisabled ? 'text-gray-500' : ''}`}
              >
                {label} {isRequired && <span className="text-red-500">*</span>}
              </label>
            )}
            {disableOption?.position === 'right' && renderDisableCheckbox()}
          </div>
        )}

        <div className="flex items-center gap-4">
          <div className="flex-1 relative">
            <div ref={inputRef}>
              <CountryInput
                country={pais}
                preferredCountries={[pais, 'us']}
                localization={es}
                enableSearch
                searchPlaceholder={t('buscar')}
                searchNotFound={t('sin_resultados')}
                value={`${value?.codigo || ''}${value?.numero || ''}`}
                onChange={(e, newPhone) => handlePhoneChange(e, newPhone)}
                onBlur={handleBlur}
                onFocus={handleFocus}
                disabled={isDisabled}
                inputProps={{
                  name,
                  placeholder: placeholder || t('ingresa_telefono'),
                }}
                specialLabel={`${floatingLabel ? floatingLabel : ''}${floatingLabel && isRequired ? '*' : ''}`}
                containerStyle={customStyles.containerStyle}
                inputStyle={customStyles.inputStyle}
                buttonStyle={customStyles.buttonStyle}
                containerClass={`phone-input-container rounded-md ${
                  wasFocused ? 'ring-2 ring-blue-500' : ''
                }`}
                dropdownStyle={dropdownStyle}
                disableDropdown={isDisabled}
              />
            </div>
            {esPaciente && !hideValidationIcon && (
              <div className="w-5 h-5 absolute bg-white -right-[6px] -top-[6px] rounded-xl">
                {value.numero.length > 0 ? (
                  <CircleCheckBig size={20} color="green" />
                ) : (
                  <CircleX size={20} color="gray" />
                )}
              </div>
            )}
          </div>
          {disableOption?.position === 'after' && renderDisableCheckbox()}
        </div>

        {errorMessage && <p className="text-sm text-red-500 mt-1">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default PhoneInput;
