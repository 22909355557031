import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroupProps } from './type';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { CircleX, CircleCheckBig } from 'lucide-react';
import { useBreakPoint } from '@hooks/useBreakpoint';

export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  index,
  name,
  containerClass = '',
  label: externalLabel,
  options,
  edit,
  isRequired = false,
  layout = 'column',
  allowMultiple = false,
  labelPosition = 'top',
  distribution = 'default',
  margin,
  padding,
  helperText,
  numberPosition = -1,
  uniqueAction,
  setValue,
  onClick,
  // disableOption,
  disabled,
  value: externalValue,
  hideValidationIcon = false,
}) => {
  const { esPaciente } = useSelector((state: RootState) => state.Me);
  const { t } = useTranslation(esPaciente ? 'translation-patient' : 'translation');
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [isUniqueAction, setIsUniqueAction] = useState<boolean>(false);
  const label = externalLabel === undefined ? t(name) : externalLabel;
  const isWideScreen = useBreakPoint();

  useEffect(() => {
    setCheckedItems(
      externalValue ? (Array.isArray(externalValue) ? externalValue : [externalValue]) : [],
    );
  }, [externalValue]);

  const handleChange = (value: string, option: any) => {
    let newCheckedItems: string[];

    if (allowMultiple) {
      if (value === 'todos_servicios') {
        newCheckedItems = ['todos_servicios'];
      } else {
        const withoutTodos = checkedItems.filter((item) => item !== 'todos_servicios');
        newCheckedItems = withoutTodos.includes(value)
          ? withoutTodos.filter((item) => item !== value)
          : [...withoutTodos, value];
      }
    } else {
      newCheckedItems = [value];
    }

    setCheckedItems(newCheckedItems);

    const syntheticEvent = {
      name,
      value: allowMultiple ? newCheckedItems : value,
      type: allowMultiple ? 'checkbox' : 'radio',
      index,
    };

    setValue(syntheticEvent);

    if (onClick) {
      onClick(value, option);
    }

    if (option.action && (!uniqueAction || !isUniqueAction)) {
      option.action();
      if (uniqueAction) {
        setIsUniqueAction(true);
      }
    }
  };

  const renderLabel = () => {
    if (!label) return null;

    return (
      <div className="flex items-center gap-2">
        <span
          className={`block font-medium text-base text-gray-700 ${disabled ? 'text-gray-500' : ''}`}
        >
          {label}
          {isRequired && <span className="text-red-500 ml-1">*</span>}
        </span>
        {esPaciente && !hideValidationIcon && isWideScreen && (
          <div className="w-5 h-5 pl-4">
            {checkedItems.length > 0 ? (
              <CircleCheckBig size={20} color="green" />
            ) : (
              <CircleX size={20} color="gray" />
            )}
          </div>
        )}
      </div>
    );
  };

  const renderCheckboxInput = (option: any) => {
    return (
      <div key={option.value} className="gap-2">
        <label className="flex items-center">
          <input
            key={index}
            type={allowMultiple ? 'checkbox' : 'radio'}
            name={index !== undefined ? `${name}-${index}` : name}
            value={option.value}
            checked={checkedItems.includes(option.value)}
            onChange={() => handleChange(option.value, option)}
            required={isRequired && checkedItems.length === 0}
            disabled={disabled}
            className="w-4 h-4 text-blue-500 border-gray-300 focus:ring-blue-500"
          />

          <span className="ml-2 font-normal text-base text-gray-700">
            {option.label}
            {disabled}
          </span>
        </label>
      </div>
    );
  };

  const renderEditButton = edit && edit.show && (
    <button
      type="button"
      onClick={edit.onClick}
      className="text-sm text-blue-600 hover:text-blue-800 underline pr-6"
    >
      {edit.label ? edit.label : t('editar')}
    </button>
  );

  const renderValidationIcon = () =>
    esPaciente && !hideValidationIcon && (!isWideScreen || !label) ? (
      <div className="w-5 h-5 absolute bg-white -right-[6px] -top-[6px] rounded-xl">
        {checkedItems.length > 0 ? (
          <CircleCheckBig size={20} color="green" />
        ) : (
          <CircleX size={20} color="gray" />
        )}
      </div>
    ) : null;

  const renderOptions = () => {
    switch (distribution) {
      case 'horizontal-inline':
        return (
          <div
            className={`flex justify-between w-full gap-4 ${padding || 'p-4'} ${
              numberPosition === -1 ? '' : 'border-[#D1D5DB] border-b-[1px]'
            } ${numberPosition % 2 === 0 ? 'bg-[#F3F4F6]' : ''}`}
          >
            {label && (
              <label htmlFor={name} className="font-medium text-base text-gray-700 w-[70%]">
                {label}
                {isRequired && <span className="text-red-500 ml-1">*</span>}
              </label>
            )}
            <div className="w-fit relative flex justify-between align-middle gap-6">
              {renderEditButton}
              <div className={`flex gap-6 ${esPaciente ? 'pr-6' : ''}`}>
                {options.map((option) => renderCheckboxInput(option))}
              </div>
              {renderValidationIcon()}
            </div>
          </div>
        );

      case 'grid':
        return (
          <div className="w-full pb-2">
            <div className="space-y-2">
              {labelPosition === 'top' && renderLabel()}
              <div className={labelPosition === 'inline' ? 'flex gap-4 items-start' : ''}>
                {labelPosition === 'inline' && renderLabel()}
                <div className="relative flex flex-wrap w-fit">
                  {options.map((option) => (
                    <div key={option.value} className="w-1/2 mb-2">
                      {renderCheckboxInput(option)}
                    </div>
                  ))}
                  {renderValidationIcon()}
                  {renderEditButton}
                </div>
              </div>
            </div>
          </div>
        );

      case 'vertical':
        return (
          <div className={`w-full h-auto mb-4 ${labelPosition === 'inline' ? 'flex gap-4' : ''}`}>
            {labelPosition === 'top' && renderLabel()}
            <div className="mt-3">
              {labelPosition === 'inline' && renderLabel()}
              <div className="relative flex flex-col gap-2">
                {options.map((option) => renderCheckboxInput(option))}
                {renderValidationIcon()}
              </div>
              <div className="mt-2">{renderEditButton}</div>
            </div>
          </div>
        );

      case 'vertical-flex':
        return (
          <div
            className={`flex flex-col w-full ${margin && numberPosition === -1 ? 'py-2' : ''} ${
              numberPosition === -1 ? '' : 'border-[#D1D5DB] border-b-[1px] p-4'
            } ${numberPosition % 2 === 0 ? 'bg-[#F3F4F6]' : ''}`}
          >
            {label && (
              <div className="flex font-medium text-base text-gray-700 mb-2">
                {label}
                {isRequired && <span className="text-red-500 ml-1">*</span>}
                {esPaciente && !hideValidationIcon && isWideScreen && (
                  <div className="w-5 h-5 pl-4">
                    {checkedItems.length > 0 ? (
                      <CircleCheckBig size={20} color="green" />
                    ) : (
                      <CircleX size={20} color="gray" />
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="flex relative gap-6">
              {options.map((option) => renderCheckboxInput(option))}
              {renderEditButton}
              {renderValidationIcon()}
            </div>
          </div>
        );

      default:
        return (
          <div className="w-full h-auto">
            <div className={labelPosition === 'inline' ? 'flex items-center gap-4' : ''}>
              {labelPosition === 'top' && renderLabel()}
              <div className={`relative ${labelPosition === 'inline' ? 'flex gap-4' : ''}`}>
                {labelPosition === 'inline' && renderLabel()}
                <div className={`flex ${layout === 'column' ? 'flex-col' : 'flex-row space-x-4'}`}>
                  {options.map((option) => renderCheckboxInput(option))}
                </div>
                {renderEditButton}
                {renderValidationIcon()}
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className={`w-full h-auto ${numberPosition === -1 ? 'py-[10px]' : ''} ${containerClass}`}>
      {renderOptions()}
      {helperText && <p className="mt-1 text-sm text-gray-500">{helperText}</p>}
    </div>
  );
};

export default CheckboxGroup;
