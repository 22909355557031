import {
  SET_RUTA_PRINCIPAL,
  SET_RUTA_PERFIL,
  SET_RUTA_ANTECEDENTES,
  SET_RUTA_ANT_PATOLOGICOS,
  SET_RUTA_ANT_NO_PATOLOGICOS,
  SET_RUTA_PLAN,
  RESET_RUTAS,
  RutasExpedienteActionTypes,
  IRutasExpediente,
} from '@actions/rutasExpediente/types';

const initialState: IRutasExpediente = {
  rutaPrincipal: 'perfil',
  rutaPerfil: 'datos-personales',
  rutaAntecedentes: 'personales-patologicos',
  rutaAntPatologicos: 'enfermedades-tratamientos',
  rutaAntNoPatologicos: 'vivienda',
  rutaPlan: 'laboratorio',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function RutasExpediente(state = initialState, action: RutasExpedienteActionTypes) {
  switch (action.type) {
    case SET_RUTA_PRINCIPAL:
      return {
        ...state,
        rutaPrincipal: action.payload,
      };
    case SET_RUTA_PERFIL:
      return {
        ...state,
        rutaPerfil: action.payload,
      };
    case SET_RUTA_ANTECEDENTES:
      return {
        ...state,
        rutaAntecedentes: action.payload,
      };
    case SET_RUTA_ANT_PATOLOGICOS:
      return {
        ...state,
        rutaAntPatologicos: action.payload,
      };
    case SET_RUTA_ANT_NO_PATOLOGICOS:
      return {
        ...state,
        rutaAntNoPatologicos: action.payload,
      };
    case SET_RUTA_PLAN:
      return {
        ...state,
        rutaPlan: action.payload,
      };
    case RESET_RUTAS:
      return initialState;
    default:
      return state;
  }
}

export default RutasExpediente;
