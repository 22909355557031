import {
  CHANGE_USER,
  ILoginState,
  LoginActionTypes,
  LOGIN_ERROR,
  SET_LOGIN,
  CHANGE_ROL,
  CHANGE_TOKEN,
} from '@actions/login/types';
import { getToken } from '@utils/commonStore';

const initialState: ILoginState = {
  user: '',
  rol: '',
  token: getToken(),
  mensaje: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const LoginReducer = (state = initialState, action: LoginActionTypes) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...action.payload,
        mensaje: '',
      };
    case CHANGE_ROL:
      return {
        ...state,
        rol: action.payload,
      };
    case CHANGE_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case CHANGE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };
    default:
      return state;
  }
};

export default LoginReducer;
